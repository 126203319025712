import { FilterType } from '../types/FilterType';

const dynamicFiltersData: FilterType[] = [
  {
    "orchestraId": "darienzo",
    "label": "Inst.",
    "key": "singer",
    "value": "Instrumental"
  },
  {
    "orchestraId": "darienzo",
    "label": "Echagüe",
    "key": "singer",
    "value": "Alberto Echagüe"
  },
  {
    "orchestraId": "darienzo",
    "label": "Mauré",
    "key": "singer",
    "value": "Héctor Mauré"
  },
  {
    "orchestraId": "darienzo",
    "label": "Laborde",
    "key": "singer",
    "value": "Armando Laborde"
  },
  {
    "orchestraId": "darienzo",
    "label": "con Biagi",
    "key": "filterDarienzoBiagi",
    "value": "TRUE"
  },
  {
    "orchestraId": "darienzo",
    "label": "Reynal o Casares",
    "key": "filterDarienzoReyCas",
    "value": "TRUE"
  },
  {
    "orchestraId": "disarli",
    "label": "Inst.",
    "key": "singer",
    "value": "Instrumental"
  },
  {
    "orchestraId": "disarli",
    "label": "Rufino",
    "key": "singer",
    "value": "Roberto Rufino"
  },
  {
    "orchestraId": "disarli",
    "label": "Podestá",
    "key": "singer",
    "value": "Alberto Podestá"
  },
  {
    "orchestraId": "disarli",
    "label": "Durán",
    "key": "singer",
    "value": "Jorge Durán"
  },
  {
    "orchestraId": "disarli",
    "label": "Serpa",
    "key": "singer",
    "value": "Oscar Serpa"
  },
  {
    "orchestraId": "troilo",
    "label": "Inst.",
    "key": "singer",
    "value": "Instrumental"
  },
  {
    "orchestraId": "troilo",
    "label": "Fiorentino",
    "key": "singer",
    "value": "Francisco Fiorentino"
  },
  {
    "orchestraId": "troilo",
    "label": "Marino",
    "key": "singer",
    "value": "Alberto Marino"
  },
  {
    "orchestraId": "troilo",
    "label": "Ruíz",
    "key": "singer",
    "value": "Floreal Ruíz"
  },
  {
    "orchestraId": "troilo",
    "label": "Rivero",
    "key": "singer",
    "value": "Edmundo Rivero"
  },
  {
    "orchestraId": "pugliese",
    "label": "Inst.",
    "key": "singer",
    "value": "Instrumental"
  },
  {
    "orchestraId": "pugliese",
    "label": "Chanel",
    "key": "singer",
    "value": "Roberto Chanel"
  },
  {
    "orchestraId": "pugliese",
    "label": "Morán",
    "key": "singer",
    "value": "Alberto Morán"
  },
  {
    "orchestraId": "pugliese",
    "label": "Vidal",
    "key": "singer",
    "value": "Jorge Vidal"
  },
  {
    "orchestraId": "pugliese",
    "label": "Maciel",
    "key": "singer",
    "value": "Jorge Maciel"
  },
  {
    "orchestraId": "pugliese",
    "label": "Montero",
    "key": "singer",
    "value": "Miguel Montero"
  }
];

export default dynamicFiltersData;
