import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { IonInfiniteScroll, IonLabel, IonList, IonListHeader, IonModal } from '@ionic/react';
import RecordingModal from './RecordingModal';
import RecordingListItem from './RecordingListItem';
import useRecordingsDatabase from '../hooks/useRecordingsDatabase';
import useRecordingGroupsDatabase from '../hooks/useRecordingGroupsDatabase';
import { OrchestraType } from '../types/OrchestraType';
import { FilterType } from '../types/FilterType';
import { RecordingType } from '../types/RecordingType';
import RecordingListItemSkeleton from './RecordingListItemSkeleton';
import { useSelector } from 'react-redux';
import { StateType } from '../types/StateType';
import './OrchestraContent.scss';
import useViewPort from '../hooks/useViewPort';

const OrchestraContent: React.FC<{ orchestra: OrchestraType, filters: FilterType[], contentRef: React.RefObject<HTMLIonContentElement>, searchBarRef: React.RefObject<HTMLIonSearchbarElement> }> = ({ orchestra, filters, contentRef, searchBarRef }) => {
  const { scrollElementToTopHeader } = useViewPort();
  const recordingsDatabase = useRecordingsDatabase();
  const recordingGroupsDatabase = useRecordingGroupsDatabase();

  const favouriteRecordings = useSelector((state: StateType) => state.favourites.favouriteRecordings);

  const [recordingsByOrchestra, setRecordingsByOrchestra] = useState<RecordingType[]>([]);  // all recordings found by orchestra
  const [recordings, setRecordings] = useState<RecordingType[]>([]);  // recordings to render
  const [recordingsOffset, setRecordingsOffset] = useState<number>(0);
  const [areInitialRecordingsLoaded, setAreInitialRecordingsLoaded] = useState<boolean>(false);
  const [isRecordingModalVisible, setIsRecordingModalVisible] = useState<boolean>(false);
  const [recording, setRecording] = useState<RecordingType|null>(null);
  const [totalNumberOfRecordingsFound, setTotalNumberOfFoundRecordings] = useState<number>(0);
  const [areAllRecordingsLoaded, setAreAllRecordingsLoaded] = useState<boolean>(false);

  const recordingsBatchSize: number = 40;

  // useEffect() will be invoked when:
  //  - component is being loaded for the first time
  //  - when component is going to be re-rendered (e.g. when user ads a record to favouriteRecordings)
  //
  // In both cases we are not setting "areInitialRecordingsLoaded" to false because we don't want to display loader
  // in between component re-render.
  useEffect(() => {
    const loadInitialRecordings = async () => {

      const recordingsFound = await recordingsDatabase.findRecordingsByOrchestraIdAndFilters(orchestra.orchestraId, filters, favouriteRecordings);

      // Initial offset needs to take into the account the previously set "recordingOffset" which is being set by "loadMoreRecordings()".
      // This happens in case that user scrolls down and "loadMoreRecordings()" is being invoked setting new "recordingOffset". Then if user
      // clicks on "add to favourite" the component gets re-rendered so useEffect() is invoked and with that "loadInitialRecordings()" (which now
      // needs to know how many recordings has been loaded in to view previously) can load the same amount of recordings.
      const initialOffset = recordingsOffset > 0 ? recordingsOffset : recordingsBatchSize;

      setTimeout(() => {
        setTotalNumberOfFoundRecordings(recordingsFound.length);
        setRecordingsByOrchestra(recordingsFound);
        setRecordings(recordingsFound?.slice(0, initialOffset)); // first batch of recordings to render
        setRecordingsOffset(initialOffset);  // save offset after initial batch is loaded

        // If there are no recordings found, wait a bit before removing loader to avoid flicker (fast change from skeleton to "No results")
        if (recordingsFound.length === 0) {
          setTimeout(() => {
            setAreInitialRecordingsLoaded(true);
          }, 200);
        } else {
          setAreInitialRecordingsLoaded(true);
        }

        setAreAllRecordingsLoaded(recordingsFound.length <= initialOffset);
      }, 0);
    };

    loadInitialRecordings();
  }, [orchestra, favouriteRecordings]);

  // useLayoutEffect() blocks the rendering of a component and will be invoked when:
  //  - filters are being changed (e.g. user activates/deactivates a filter)
  //
  // In that cases we are setting "areInitialRecordingsLoaded" to true before data loading starts
  // because we want to display loader in between component re-render.
  useLayoutEffect(() => {
    const loadInitialRecordings = async () => {
      setAreInitialRecordingsLoaded(false);

      const recordingsFound = await recordingsDatabase.findRecordingsByOrchestraIdAndFilters(orchestra.orchestraId, filters, favouriteRecordings);

      // Initial offset here is always reset to recordingsBatchSize because after filter is changed we want to list recordings from the start
      // and first batch only.
      const initialOffset = recordingsBatchSize;

      setTimeout(() => {
        setTotalNumberOfFoundRecordings(recordingsFound.length);
        setRecordingsByOrchestra(recordingsFound);
        setRecordings(recordingsFound?.slice(0, initialOffset)); // first batch of recordings to render
        setRecordingsOffset(initialOffset);  // save offset after initial batch is loaded

        // If there are no recordings found, wait a bit before removing loader to avoid flicker (fast change from skeleton to "No results")
        if (recordingsFound.length === 0) {
          setTimeout(() => {
            setAreInitialRecordingsLoaded(true);
          }, 200);
        } else {
          setAreInitialRecordingsLoaded(true);
        }

        setAreAllRecordingsLoaded(recordingsFound.length <= initialOffset);
      }, 0);
    };

    loadInitialRecordings();

    setTimeout(() => {
      if (contentRef.current && searchBarRef.current) {
        // If filters are out of view then scroll filters into view (when filter is activated/deactivated)
        scrollElementToTopHeader(searchBarRef.current, contentRef.current);
      }
    }, 10);

  }, [filters]);

  const loadMoreRecordings = async (event: any) => {
    const recordingsToRender = recordingsByOrchestra?.slice(recordingsOffset, recordingsOffset + recordingsBatchSize);
    setRecordingsOffset(recordingsOffset + recordingsBatchSize);

    setRecordings([...recordings, ...recordingsToRender]);

    setAreAllRecordingsLoaded(totalNumberOfRecordingsFound <= recordingsOffset + recordingsToRender.length);
    event.target.complete();
  };

  const handleOnRecordingClick = (recording: RecordingType) => {
    setRecording(recording);
    setIsRecordingModalVisible(true);
  };

  const handleOnRecordingModalDismiss = () => {
    setRecording(null);
    setIsRecordingModalVisible(false);
  };

  /*
   * Renders records with record group information rendered once before recording.
   * While rendering the records, if we stumble upon different group, the group information header is rendered again.
   */
  const renderRecordings = (recordings: RecordingType[], handleOnRecordingClick: (recording: RecordingType) => void): JSX.Element => {
    const renderStack = [];
    let previouslyRenderedGroupId: string|null = null;

    for (let x: number = 0; x < recordings?.length; x++) {
      let recording = recordings[x];

      if (recording.groupId !== previouslyRenderedGroupId) {
        previouslyRenderedGroupId = recording.groupId;
        const recordingGroup = recordingGroupsDatabase.findRecordingGroupById(recording.groupId);
        if (recordingGroup) {
          renderStack.push(
            <div key={x}>
              <IonListHeader>
                <IonLabel>
                  <h2 className="dt-recording-group-title">{recordingGroup.groupTitle}</h2>
                  {(filters.length === 0 && recordingGroup.groupDescription) &&
                    <div className="dt-recording-group-description">{recordingGroup.groupDescription}</div>
                  }
                </IonLabel>
              </IonListHeader>
              <RecordingListItem recording={recording} onRecordingClick={handleOnRecordingClick} hideOrchestra={true}/>
            </div>
          );
        }
      } else {
        renderStack.push(
          <div key={x}>
            <RecordingListItem recording={recording} onRecordingClick={handleOnRecordingClick} hideOrchestra={true}/>
          </div>
        );
      }
    }

    return (<>{renderStack}</>);
  };

  const renderMemoizedRecordings: JSX.Element = useMemo(() => {
    return renderRecordings(recordings, handleOnRecordingClick);
  }, [recordings]);

  const isFavouritesFilterActive = () => {
    return filters.filter((filter: FilterType) => filter.key === 'isFavourite').length > 0;
  };

  const isAnyFilterActive = () => {
    return filters.length > 0;
  };

  return (
    <div className="dt-orchestra-content">
      { areInitialRecordingsLoaded
        ?
          <>
            { (filters.length === 0 && orchestra.orchestraDescription) &&
              <div className="dt-orchestra-description">
                {orchestra.orchestraDescription}
              </div>
            }

            { recordings.length > 0
              ?
                <IonList>
                  {renderMemoizedRecordings}
                </IonList>
              :
                isFavouritesFilterActive()
                  ?
                    <div className="dt-empty-results-content">
                      <div className="dt-empty-results-title">No favourites</div>
                      <div>Recordings marked with ❤<br/>will show up here.</div>
                    </div>
                  :
                    isAnyFilterActive()
                    ?
                      <div className="dt-empty-results-content">
                        <div>No recordings match the filters.</div>
                      </div>
                    :
                      <div className="dt-empty-results-content">
                        <div>No recordings</div>
                      </div>
            }
            <IonInfiniteScroll
              onIonInfinite={loadMoreRecordings}
              disabled={recordings.length >= recordingsByOrchestra.length}
            >
              {recordings.length < recordingsByOrchestra.length &&
                <><RecordingListItemSkeleton /></>
              }
            </IonInfiniteScroll>
            {(areAllRecordingsLoaded && filters.length === 0) &&
              <div className="dt-orchestra-footer-description">{orchestra.orchestraFooter}</div>
            }
          </>
        :
          <><RecordingListItemSkeleton /></>
      }

      <IonModal
        isOpen={isRecordingModalVisible}
        initialBreakpoint={0.75}
        breakpoints={[0, 0.75]}
        onDidDismiss={handleOnRecordingModalDismiss}
        handle={true}
      >
        {recording && <RecordingModal recording={recording} onClose={handleOnRecordingModalDismiss} />}
      </IonModal>
    </div>
  );
};

export default React.memo(OrchestraContent);
