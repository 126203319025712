import {createContext, ReactNode, useContext, useEffect, useState} from 'react'
import {useAuth} from "./authContext";
import {useGlobalPlayerInstance} from "../hooks/useGlobalPlayerInstance";

interface PlayerContextProps {
    player: any;
    isReady: boolean;
    deviceId: string;
    isPaused: boolean;
}
const PlayerContext = createContext({} as PlayerContextProps)
export const PlayerContextProvider = ({children}: {children: ReactNode}) => {
    const {token} = useAuth()
    const player = useGlobalPlayerInstance(token)

    const [deviceId, setDeviceId] = useState("");
    const [isPaused, setIsPaused] = useState(true);

    useEffect(()=> {
        if (player) {
            const handleReady =  ({device_id}: { device_id: string }) => {
                console.log('Ready with device ID', device_id);
                setDeviceId(device_id);
            }
            player.addListener('ready', handleReady)

            return ()=> {
                player.removeListener('ready', handleReady)
            }
        }
    }, [player])

    useEffect(()=> {
        if (player) {
            const handleNotReady = ({device_id}: { device_id: string }) => {
                console.log('Device ID has gone offline', device_id);
            }
            player.addListener('not_ready', handleNotReady);


            return () => {
                player.removeListener('not_ready', handleNotReady)
            }
        }
    }, [player])

    useEffect(()=> {
        if (player) {
            const handleStateChange = (state: PlaybackState) => {
                if (state) {
                    setIsPaused(state.paused)
                }
            }

            player.addListener('player_state_changed', handleStateChange);

            return () => {
                player.removeListener('player_state_changed', handleStateChange)
            }
        }
    }, [player])

    return (
    <PlayerContext.Provider
        value={{player, deviceId, isPaused, isReady: !!deviceId}}>
        {children}
    </PlayerContext.Provider>
  )
}


interface PlaybackState {
    loading: boolean;
    position: number;
    duration: number;
    paused: boolean;
    track_window: {
        current_track: {
            id: string;
            name: string;
            artists: Array<{ name: string }>;
            album: { images: Array<{ url: string }> };
        };
    };
}


export const useSpotifyPlayer = () => {
    const context = useContext(PlayerContext);
    if (!context) {
        throw new Error('useSpotifyPlayer must be used within a PlayerContextProvider');
    }
    return context;
}
