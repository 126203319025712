import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonToolbar } from '@ionic/react';
import { RecordingType } from '../types/RecordingType';
import { useDispatch } from 'react-redux';
import { addRecordingToFavourites, removeRecordingFromFavourites } from '../actions/stateActions';
import { chevronDownOutline, heart, heartOutline } from 'ionicons/icons';
import './RecordingModal.scss';
import useToast from '../hooks/useToast';
import SpotifyPlayer from '../components/SpotifyPlayer';

interface Props  {
  recording: RecordingType;
  onClose: () => void;
}

const RecordingModal = ({ recording, onClose }: Props) => {
  const dispatch = useDispatch();
  const { presentToast } = useToast();

  const handleOnFavouriteClick = async () => {
    if (recording.isFavourite) {
      dispatch(removeRecordingFromFavourites(recording));
      presentToast('Removed from your Favourites');
    } else {
      dispatch(addRecordingToFavourites(recording));
      presentToast('Added to your Favourites');
    }
  };

  return(
    <IonPage id="recording-modal">
      <IonHeader translucent>
        <IonToolbar className="dt-recording-modal-toolbar">
          <IonButtons slot="start">
            <IonButton onClick={onClose}>
              <IonIcon color="primary" icon={chevronDownOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="dt-recording-modal-content">
          <IonIcon className="dt-recording-modal-favourite-icon" onClick={handleOnFavouriteClick} icon={recording.isFavourite ? heart : heartOutline} />
          {recording.spotifyTrackId 
            ? <SpotifyPlayer trackId={recording.spotifyTrackId} />
            : null
          }
          <div className="dt-recording-modal-title">{recording.title}</div>
          <div className="dt-recording-modal-orchestra-singer">{recording.orchestraName} · {recording.singer}</div>
          <div className="dt-recording-modal-label">{recording.genre}{recording.year ? ' · ' : ''}{recording.year}</div>

          <hr className="dt-recording-modal-separator" />

          {recording.composer &&
            <div className="dt-recording-modal-row">
              <div className="dt-recording-modal-label">Composer</div>
              <div className="dt-recording-modal-value">{recording.composer}</div>
            </div>
          }

          {recording.lyricist &&
            <div className="dt-recording-modal-row">
              <div className="dt-recording-modal-label">Lyricist</div>
              <div className="dt-recording-modal-value">{recording.lyricist}</div>
            </div>
          }

          {(recording.composer || recording.lyricist) &&
            <hr className="dt-recording-modal-separator"/>
          }

          {recording.date &&
            <div className="dt-recording-modal-row">
              <div className="dt-recording-modal-label">Recorded on</div>
              <div className="dt-recording-modal-value">{recording.date}</div>
            </div>
          }

          {recording.publisher &&
            <div className="dt-recording-modal-row">
              <div className="dt-recording-modal-label">Publisher</div>
              <div className="dt-recording-modal-value">{recording.publisher}</div>
            </div>
          }

          {/*{recording.discNo &&*/}
          {/*  <div className="dt-recording-modal-row">*/}
          {/*    <div className="dt-recording-modal-label">Disc no./Matrix</div>*/}
          {/*    <div className="dt-recording-modal-value">{recording.discNo}</div>*/}
          {/*  </div>*/}
          {/*}*/}

          {/*{recording.discType &&*/}
          {/*  <div className="dt-recording-modal-row">*/}
          {/*    <div className="dt-recording-modal-label">Media type</div>*/}
          {/*    <div className="dt-recording-modal-value">{recording.discType}</div>*/}
          {/*  </div>*/}
          {/*}*/}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default RecordingModal;
