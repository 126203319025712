import React from 'react';
import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';

const AboutPage: React.FC = () => {
  return (
    <IonPage id="not-found-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton text="" />
          </IonButtons>
          <IonTitle>About</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">About page</IonTitle>
          </IonToolbar>
        </IonHeader>

        <div>
          <h1>About</h1>
          <p>About page</p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default AboutPage;
