import React, { useState } from 'react';
import { OrchestraType } from '../types/OrchestraType';
import OrchestraIcon from './OrchestraIcon';
import { IonItem, IonLabel } from '@ionic/react';
import './OrchestraListItem.scss';
import { useSelector } from 'react-redux';

const OrchestraListItem: React.FC<{orchestra: OrchestraType}> = ({ orchestra }) => {
  const [isListItemClicked, setIsListItemClicked] = useState<boolean>(false);
  const showFullNames = useSelector((state: any) => state.listOptions.showFullNames);

  const handleDebouncedClickEvent = (event: any) => {
    if (isListItemClicked) {
      event.preventDefault();
    } else {
      setIsListItemClicked(true);
      setTimeout(() => {
        setIsListItemClicked(false);
      }, 900);
    }
  };

  return (
    <IonItem
      routerLink={`/orchestras/${orchestra.orchestraId}`}
      lines="none"
      detail={false}
      onClick={ handleDebouncedClickEvent }
    >
      <OrchestraIcon orchestra={orchestra} />
      <IonLabel color="dark" className="dt-orchestra-list-item">
        {showFullNames ? orchestra.orchestraName : orchestra.orchestraNameShort}
      </IonLabel>
    </IonItem>
  );
};

export default OrchestraListItem;
