import { OrchestraSingerType } from '../types/OrchestraSingerType';

const orchestrasSingersData: OrchestraSingerType[] = [
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Instrumental",
    "singerShort": "Inst."
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Walter Cabral",
    "singerShort": "Cabral"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Enrique Carbel",
    "singerShort": "Carbel"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Alberto Echagüe",
    "singerShort": "Echagüe"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Alberto Reynal",
    "singerShort": "Reynal"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Carlos Casares",
    "singerShort": "Casares"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Héctor Mauré",
    "singerShort": "Mauré"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Juan Carlos Lamas",
    "singerShort": "Lamas"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Armando Laborde",
    "singerShort": "Laborde"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Roberto Lemos",
    "singerShort": "Lemos"
  },
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "singer": "Alberto Echagüe y Armando Laborde",
    "singerShort": "Echagüe y Laborde"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Instrumental",
    "singerShort": "Inst."
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Agustin Volpe",
    "singerShort": "Volpe"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Alberto Podestá",
    "singerShort": "Podestá"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Antonio Rodriguez Lesende",
    "singerShort": "Lesende"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Carlos Acuña",
    "singerShort": "Acuña"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Jorge Durán",
    "singerShort": "Durán"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Oscar Serpa",
    "singerShort": "Serpa"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "singer": "Roberto Rufino",
    "singerShort": "Rufino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Instrumental",
    "singerShort": "Inst."
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Alberto Marino",
    "singerShort": "Marino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Alberto Marino y Francisco Fiorentino",
    "singerShort": "Marino y Fiorentino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Alberto Marino y Floreal Ruiz",
    "singerShort": "Marino y Ruiz"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Aldo Calderón",
    "singerShort": "Calderón"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Edmundo Rivero",
    "singerShort": "Rivero"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Edmundo Rivero y Aldo Calderón",
    "singerShort": "Rivero y Calderón"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Floreal Ruíz",
    "singerShort": "Ruíz"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Floreal Ruíz y Alberto Marino",
    "singerShort": "Ruíz y Marino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Floreal Ruíz y Edmundo Rivero",
    "singerShort": "Ruíz y Rivero"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Francisco Fiorentino",
    "singerShort": "Fiorentino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Francisco Fiorentino y Amadeo Mandarino",
    "singerShort": "Fiorentino y Mandarino"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "singer": "Francisco Fiorentino y Alberto Marino",
    "singerShort": "Fiorentino y Marino"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Instrumental",
    "singerShort": "Inst."
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Alberto Morán",
    "singerShort": "Morán"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Alberto Morán y Roberto Chanel",
    "singerShort": "Morán y Chanel"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Alberto Morán y Juan Carlos Cobos",
    "singerShort": "Morán y Cobos"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Jorge Maciel",
    "singerShort": "Maciel"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Jorge Maciel y Carlos Guido",
    "singerShort": "Maciel y Guido"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Jorge Rubino",
    "singerShort": "Rubino"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Jorge Vidal",
    "singerShort": "Vidal"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Juan Carlos Cobos",
    "singerShort": "Cobos"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Miguel Montero",
    "singerShort": "Montero"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Miguel Montero y Jorge Maciel",
    "singerShort": "Montero y Maciel"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Ricardo Medina",
    "singerShort": "Medina"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "singer": "Roberto Chanel",
    "singerShort": "Chanel"
  }
];

export default orchestrasSingersData;
