import { FilterType } from '../types/FilterType';

const StaticFiltersData: FilterType[] = [
  {
    key: 'genre',
    value: 'Tango',
    label: 'Tango',
    isActive: false,
  },
  {
    key: 'genre',
    value: 'Vals',
    label: 'Vals',
    isActive: false,
  },
  {
    key: 'genre',
    value: 'Milonga',
    label: 'Milonga',
    isActive: false,
  },
];

export default StaticFiltersData;
