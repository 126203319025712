import React, { useEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonList,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Browser } from '@capacitor/browser';
import { search } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import SearchModal from '../components/SearchModal';
import { useDispatch, useSelector } from 'react-redux';
import {
  switchDarkThemeOff,
  switchDarkThemeOn,
  switchDarkThemeToAuto,
} from '../actions/themeActions';
import orchestrasData from '../data/orchestrasData';
import { OrchestraType } from '../types/OrchestraType';
import './OrchestrasPage.scss';
import OrchestraListItem from '../components/OrchestraIListItem';
import {
  DARK_THEME_AUTO,
  DARK_THEME_OFF,
  DARK_THEME_ON,
} from '../types/ThemeType';
import { showFullNamesOff, showFullNamesOn } from '../actions/listOptionsActions';
import LoginWithSpotify from '../components/LoginWithSpotify';
import { useAuth } from '../contexts/authContext';

const openCapacitorSite = async () => {
  await Browser.open({ url: 'http://capacitorjs.com/' });
};

const OrchestrasPage: React.FC = () => {
  const dispatch = useDispatch();
  const { hasUser, logout } = useAuth();
  const showFullNames = useSelector((state: any) => state.listOptions.showFullNames);
  const darkTheme = useSelector((state: any) => state.theme.darkTheme);
  const [orchestras, setOrchestras] = useState<OrchestraType[]>([]);
  const [isSearchModalVisible, setIsSearchModalVisible] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setOrchestras(orchestrasData);
    }, 0);
  }, [orchestrasData]);

  const handleOnSearchButtonClick = () => {
    setIsSearchModalVisible(true);
  };

  const handleOnSearchModalDismiss = () => {
    setIsSearchModalVisible(false);
  };

  const handleOnShowFullNamesOnClick = () => {
    dispatch(showFullNamesOn());
  };

  const handleOnShowFullNamesOffClick = () => {
    dispatch(showFullNamesOff());
  };

  const handleOnDarkModeAutoClick = () => {
    dispatch(switchDarkThemeToAuto());
  };

  const handleOnDarkModeOnClick = () => {
    dispatch(switchDarkThemeOn());
  };

  const handleOnDarkModeOffClick = () => {
    dispatch(switchDarkThemeOff());
  };

  const handleLoginSuccess = (code: string, codeVerifier: string) => {
    // Handle the successful login with Spotify
    console.log('Login success:', code, codeVerifier);
  };

  const handleLoginFailure = (error: Error) => {
    // Handle the login failure
    console.error('Login failure:', error);
  };

  return (
    <IonPage id="orchestras-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={handleOnSearchButtonClick}>
              <IonIcon slot="icon-only" icon={search} />
            </IonButton>
          </IonButtons>
          <IonTitle color="dark" className="dt-toolbar-title">
            Orchestras
          </IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        <IonHeader collapse="condense" className="dt-content-header">
          <IonToolbar>
            <IonTitle size="large" color="dark">
              Orchestras
            </IonTitle>
          </IonToolbar>
        </IonHeader>

       <IonList>
          {!hasUser && (
            <React.Fragment>
              <LoginWithSpotify
                onSuccess={handleLoginSuccess}
                onFailure={handleLoginFailure}
                scope="streaming user-read-email user-read-private"
              />
              {orchestras.map((orchestra: OrchestraType) => (
                <OrchestraListItem key={orchestra.orchestraId} orchestra={orchestra} />
              ))}
            </React.Fragment>
          )}
          {hasUser && (
            <React.Fragment>
              <IonButton onClick={logout}>Logout</IonButton>
              {orchestras.map((orchestra: OrchestraType) => (
                <OrchestraListItem key={orchestra.orchestraId} orchestra={orchestra} />
              ))}
            </React.Fragment>
          )}

        </IonList>
        <IonFooter className="dt-footer">
          <div className="dt-footer-options-container">
            <IonToolbar className="ion-text-center">
              <p>
                Show full names in lists:{' '}
                <a className={showFullNames === true ? 'active' : ''} onClick={handleOnShowFullNamesOnClick}>
                  Yes
                </a>{' '}
                ·{' '}
                <a className={showFullNames === false ? 'active' : ''} onClick={handleOnShowFullNamesOffClick}>
                  No
                </a>
              </p>
              <p>
                Dark theme:{' '}
                <a className={darkTheme === DARK_THEME_AUTO ? 'active' : ''} onClick={handleOnDarkModeAutoClick}>
                  Auto
                </a>{' '}
                ·{' '}
                <a className={darkTheme === DARK_THEME_ON ? 'active' : ''} onClick={handleOnDarkModeOnClick}>
                  On
                </a>{' '}
                ·{' '}
                <a className={darkTheme === DARK_THEME_OFF ? 'active' : ''} onClick={handleOnDarkModeOffClick}>
                  Off
                </a>
              </p>
            </IonToolbar>
          </div>
          <IonToolbar className="ion-text-center">
            <p>
              <Link to="about">About</Link> · <a href="mailto:feedback@tangodiscogs.app">Send Feedback</a>
            </p>
            <p>
              Made with ❤ by <a onClick={openCapacitorSite}>humanfriendly</a>
            </p>
          </IonToolbar>
        </IonFooter>
      </IonContent>

      <IonModal isOpen={isSearchModalVisible} onDidDismiss={handleOnSearchModalDismiss}>
        <SearchModal onClose={handleOnSearchModalDismiss} />
      </IonModal>
    </IonPage>
  );
};

export default OrchestrasPage;
