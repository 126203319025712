import { createReducer } from '@reduxjs/toolkit';
import { ThemeStateType } from '../types/StateType';
import { SWITCH_DARK_THEME_OFF, SWITCH_DARK_THEME_ON, SWITCH_DARK_THEME_TO_AUTO } from '../actions/themeActions';
import { DARK_THEME_AUTO, DARK_THEME_ON, DARK_THEME_OFF } from '../types/ThemeType';

const initialState: ThemeStateType = {
  darkTheme: DARK_THEME_AUTO,
};

const themeReducer: any = createReducer(initialState, {
  [SWITCH_DARK_THEME_TO_AUTO]: (state: ThemeStateType = initialState) => {
    state.darkTheme = DARK_THEME_AUTO
  },
  [SWITCH_DARK_THEME_ON]: (state: ThemeStateType = initialState) => {
    state.darkTheme = DARK_THEME_ON
  },
  [SWITCH_DARK_THEME_OFF]: (state: ThemeStateType = initialState) => {
    state.darkTheme = DARK_THEME_OFF
  },
});

export default themeReducer;
