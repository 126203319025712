import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { loadStateFromLocalStorage } from '../actions/localStorageActions';
import { SplashScreen } from '@capacitor/splash-screen';

const LocalStorageDataLoader = (props: any) => {
  const dispatch = useDispatch();
  const [isStateLoaded, setIsStateLoaded] = useState<boolean>(false);

  const onStateLoadSuccess = () => {
    setIsStateLoaded(true);
    SplashScreen.hide();
  };

  useEffect(() => {
    if (!isStateLoaded) {
      dispatch(loadStateFromLocalStorage(onStateLoadSuccess));
    }
  }, [isStateLoaded]);

  return(
    !isStateLoaded
      ? <></>
      : props.children
  );
};

export default LocalStorageDataLoader;
