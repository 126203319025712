import localStorage from '../storage/localStorage';
import { Database } from '@ionic/storage';
import { SET_STATE_FROM_LOCAL_STORAGE } from '../actions/localStorageActions';

const persistStateToLocalStorageMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type !== SET_STATE_FROM_LOCAL_STORAGE) {
    localStorage.then((localStorageDatabase: Database) => {
      localStorageDatabase.set('state', JSON.stringify(store.getState()));
    });
  }

  return next(action);
}

export default persistStateToLocalStorageMiddleware;
