import { createReducer } from '@reduxjs/toolkit';
import { SearchHistoryStateType } from '../types/StateType';
import { ADD_TO_SEARCH_HISTORY } from '../actions/searchHistoryActions';

const initialState: SearchHistoryStateType = {
  searchTerms: [],
};

const searchHistoryReducer: any = createReducer(initialState, {
  [ADD_TO_SEARCH_HISTORY]: (state: SearchHistoryStateType = initialState, action: any) => {
    const newSearchTerm = action.payload;
    let searchHistory = state.searchTerms;

    // Remove existing duplicate term
    if (searchHistory.length > 0) {
      searchHistory = searchHistory.filter((searchTerm: string) => {
        return searchTerm !== newSearchTerm;
      });
    }

    // Add new term to history stack
    searchHistory.unshift(newSearchTerm);

    // Limit stack to 7 values
    searchHistory.slice(6);

    state.searchTerms = searchHistory;
  },
});

export default searchHistoryReducer;
