import React from 'react';
import { RecordingType } from '../types/RecordingType';
import { IonIcon } from '@ionic/react';

const RecordingIcon: React.FC<{recording: RecordingType}> = ({ recording }) => {

  let recordingIconSrc = 'assets/icon/ic_item_placeholder.svg';

  if (recording.genreShort === 'T') {
    recordingIconSrc = 'assets/icon/ic_recording_tango.svg';
  }

  if (recording.genreShort === 'M') {
    recordingIconSrc = 'assets/icon/ic_recording_milonga.svg';
  }

  if (recording.genreShort === 'V') {
    recordingIconSrc = 'assets/icon/ic_recording_vals.svg';
  }

  return (
    <IonIcon src={recordingIconSrc} slot="start" />
  );
};

export default RecordingIcon;
