import { FLAG_TUTORIAL_AS_SEEN } from '../actions/tutorialActions';
import { createReducer } from '@reduxjs/toolkit';
import { TutorialStateType } from '../types/StateType';

const initialState: TutorialStateType = {
  isTutorialSeen: false,
};

const tutorialReducer: any = createReducer(initialState, {
  [FLAG_TUTORIAL_AS_SEEN]: (state: TutorialStateType = initialState) => {
    state.isTutorialSeen = true;
  },
});

export default tutorialReducer;
