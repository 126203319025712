// @ts-nocheck
import { RecordingType } from '../types/RecordingType';

const recordingsData: RecordingType[] = [
  {
    "id": "darienzo-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-07-02",
    "title": "Hotel Victoria",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37785,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Feliciano Latasa",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hotel Victoria Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "2qg8qpvEFcpENL6qjHROAL"
  },
  {
    "id": "darienzo-2",
    "trackNo": null,
    "genre": "Vals",
    "date": "1935-07-02",
    "title": "Desde el alma",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37785,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rosita Melo",
    "lyricist": "Victor Pluma Velez",
    "notes": "",
    "linkTT": "https://www.tangotunes.com/desde-el-alma-37785-rp.html",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desde el alma Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "3qyx6h4cCuHpCcTYFSMKeb"
  },
  {
    "id": "darienzo-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-08-12",
    "title": "Tinta verde",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37812,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tinta verde Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "3bUcuINvKUbTs2HSypzIIP"
  },
  {
    "id": "darienzo-4",
    "trackNo": null,
    "genre": "Vals",
    "date": "1935-08-12",
    "title": "Penas de amor",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37812,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Augusto P. Berto",
    "lyricist": "J. Fernández Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Penas de amor Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "42AChHyHZBkJCSD9ROzGV1"
  },
  {
    "id": "darienzo-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-10-03",
    "title": "Re Fa Si",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37825,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Re Fa Si Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "42ishzkI6eM80A4ZqDmNkJ"
  },
  {
    "id": "darienzo-6",
    "trackNo": null,
    "genre": "Vals",
    "date": "1935-10-03",
    "title": "Francia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37825,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Octavio Barbero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Francia Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "3F8o3mB6QIZkNUWnarPMxB"
  },
  {
    "id": "darienzo-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-11-18",
    "title": "Sábado inglés",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37844,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sábado inglés Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "42qAsaQZOcBvxprprKoVRd"
  },
  {
    "id": "darienzo-8",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1935-11-18",
    "title": "De pura cepa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37844,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Ceglie",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De pura cepa Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "2f90R0gHGTxhtJDVSIYvoV"
  },
  {
    "id": "darienzo-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-12-12",
    "title": "Joaquina",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37849,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Bergamino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Joaquina Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "0PRCiZzIFHUrzTrFObn783"
  },
  {
    "id": "darienzo-10",
    "trackNo": null,
    "genre": "Vals",
    "date": "1935-12-12",
    "title": "Pabellón de las rosas",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37849,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Filipetti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pabellón de las rosas Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "1CFpX3yCxpIDOi1Ivxeuan"
  },
  {
    "id": "darienzo-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1935-12-31",
    "title": "Nueve de julio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37860,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Luis Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nueve de julio Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-12",
    "trackNo": null,
    "genre": "Vals",
    "date": "1935-12-31",
    "title": "Orillas del plata",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37860,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1935,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Orillas del plata Instrumental Juan D’Arienzo instr. inst. instr inst 35 1935",
    "spotifyTrackId": "4eZBXkLPzrCMYr3uSRDnNp"
  },
  {
    "id": "darienzo-13",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-01-14",
    "title": "La guitarrita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37870,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Edurdo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La guitarrita Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "72HqHfpRAqo26CEWxQvUQz"
  },
  {
    "id": "darienzo-14",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1936-01-14",
    "title": "Silueta porteña",
    "singer": "Walter Cabral",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37870,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "M",
    "singerShort": "Cabral",
    "discType": "Shellac",
    "duration": "",
    "composer": "N.L. Cuccaro / J.V. Cuccaro",
    "lyricist": "C. Daniel / E. Noli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Silueta porteña Walter Cabral Juan D’Arienzo 36 1936",
    "spotifyTrackId": "5iSknaHt0z1vIIInM9fhws"
  },
  {
    "id": "darienzo-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-01-31",
    "title": "Retintín",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37883,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Retintín Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-16",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-01-31",
    "title": "Sueño florido",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37883,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Sueño florido Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "72HqHfpRAqo26CEWxQvUQz"
  },
  {
    "id": "darienzo-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-04-03",
    "title": "El Flete",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37901,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "Geronimo Gradito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El Flete Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "0e7wDIn9c1TMIWeV8c2S4V"
  },
  {
    "id": "darienzo-18",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-04-03",
    "title": "Un placer",
    "singer": "Walter Cabral",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37901,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Cabral",
    "discType": "Shellac",
    "duration": "",
    "composer": "Andrés V. Alietti",
    "lyricist": "Vicente Romeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Un placer Walter Cabral Juan D’Arienzo 36 1936",
    "spotifyTrackId": "3txGF2brMsq8WzbSvXRcdu"
  },
  {
    "id": "darienzo-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-05-08",
    "title": "Lorenzo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37916,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Lorenzo Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "74LUamzPEI0rue25ZgcdTJ"
  },
  {
    "id": "darienzo-20",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-05-08",
    "title": "Tu olvido",
    "singer": "Walter Cabral",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37916,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Cabral",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Spina",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Tu olvido Walter Cabral Juan D’Arienzo 36 1936",
    "spotifyTrackId": "4nzGoUCeBfqAyu2RZTVuft"
  },
  {
    "id": "darienzo-21",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-06-09",
    "title": "La payanca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37937,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Augusto P. Berto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La payanca Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "4wsB8WIc1F4fdgmIPkEuOk"
  },
  {
    "id": "darienzo-22",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-06-09",
    "title": "Irene",
    "singer": "Walter Cabral",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37937,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Cabral",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose y Luis Servidio / F Garcia Jimenez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Irene Walter Cabral Juan D’Arienzo 36 1936",
    "spotifyTrackId": "2ss6gCy0tXMwJxB30X05bb"
  },
  {
    "id": "darienzo-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-07-03",
    "title": "Don Esteban",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37955,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ausguto P. Berto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Don Esteban Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "6GjQm3upClN5FgeetnUpYr"
  },
  {
    "id": "darienzo-23-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-07-02",
    "title": "Don Esteban (Take 2)",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37956,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ausguto P. Berto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Don Esteban (Take 2) Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-24",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-07-03",
    "title": "No llores madre",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37955,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Lauro",
    "lyricist": "A .F. Roldan",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "No llores madre Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "6BjEBBI6nk5VM5ZtDoDxwQ"
  },
  {
    "id": "darienzo-25",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-08-05",
    "title": "El irresistible",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37969,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lorenzo Logatti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El irresistible Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "3BZWl0KxnLgqYAMxGhKAWC"
  },
  {
    "id": "darienzo-26",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-08-05",
    "title": "Inolvidable",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37969,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Salerno / Hector R. Demattei",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Inolvidable Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "1P4jnnxgS0aevohoVBvKL2"
  },
  {
    "id": "darienzo-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-09-03",
    "title": "Rawson",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37991,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Rawson Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "3VybbVAE4bo5eLsvsFrLPb"
  },
  {
    "id": "darienzo-28",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-09-03",
    "title": "Amor y celo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 37991,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Padula",
    "lyricist": "Alfredo Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Amor y celo Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "4A47jw09t9QkpK1r1d9oOK"
  },
  {
    "id": "darienzo-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-09-29",
    "title": "Don Juan",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38009,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "Ricardo Podesta",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Don Juan Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "4E4TXbQNSfYAsT9GIB7PFv"
  },
  {
    "id": "darienzo-30",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-09-29",
    "title": "Lágrimas y sonrisas",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38009,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pascual De Gullo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Lágrimas y sonrisas Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "1w6G2jTwb4z1QVKBTBHrcM"
  },
  {
    "id": "darienzo-31",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-10-27",
    "title": "Comme il faut",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38032,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Comme il faut Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "4Z6Nm8TkRnKczIY6pnDGKa"
  },
  {
    "id": "darienzo-32",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-10-27",
    "title": "Una lágrima",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38032,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Durante y José Rebollini",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Una lágrima Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "1gOZk5POXFlSpmngHAYQDq"
  },
  {
    "id": "darienzo-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-11-27",
    "title": "Ataniche",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38050,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Ataniche Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "5WUqHDvfPAMU5U492QHfwv"
  },
  {
    "id": "darienzo-34",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-11-27",
    "title": "Corazón de artista",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38050,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pascual De Gullo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Corazón de artista Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "6kR1sI9sFl6DCzrDyYB0r7"
  },
  {
    "id": "darienzo-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1936-12-30",
    "title": "La viruta",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38072,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La viruta Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "1NDWeYle1mVx1Z7qzihPZp"
  },
  {
    "id": "darienzo-36",
    "trackNo": null,
    "genre": "Vals",
    "date": "1936-12-30",
    "title": "Visión celeste",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38072,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1936,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Visión celeste Instrumental Juan D’Arienzo instr. inst. instr inst 36 1936",
    "spotifyTrackId": "1vPilpwldYnDiVhSHtJpnP"
  },
  {
    "id": "darienzo-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-01-21",
    "title": "El baqueano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38093,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El baqueano Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "14B7abwhiU2IZl34k1H2bD"
  },
  {
    "id": "darienzo-38",
    "trackNo": null,
    "genre": "Polca",
    "date": "1937-01-21",
    "title": "Fachimo la polquita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38093,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Duclos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Fachimo la polquita Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "5EhNqdXE5CZ9AzdzQZL2Q4"
  },
  {
    "id": "darienzo-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-03-05",
    "title": "Que noche",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38114,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Que noche Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "0m6gpOCKazNDjX1TBOHphE"
  },
  {
    "id": "darienzo-40",
    "trackNo": null,
    "genre": "Vals",
    "date": "1937-03-05",
    "title": "Alma dolorida",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38114,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Datta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Alma dolorida Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "2brbeVj7aPKmWEOSC4d5aO"
  },
  {
    "id": "darienzo-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-04-01",
    "title": "El apronte",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38138,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El apronte Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "3jgQQNs8mBXFCZs8IUedXc"
  },
  {
    "id": "darienzo-42",
    "trackNo": null,
    "genre": "Vals",
    "date": "1937-04-01",
    "title": "Mentías",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38138,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Cassaretto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Mentías Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "0Jy3b8KguvLHPwUOSDkOU9"
  },
  {
    "id": "darienzo-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-04-27",
    "title": "Homero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38159,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Homero Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "4Bad5aRveu5gFpk67KoLZL"
  },
  {
    "id": "darienzo-44",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1937-04-27",
    "title": "La puñalada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38159,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La puñalada Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "4etmaoE6wyAjLNk3NdxVuW"
  },
  {
    "id": "darienzo-45",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-06-02",
    "title": "El cachafaz",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38187,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Aroztegui",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El cachafaz Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "2wVNlGt6Dh0UI2fooyjzcE"
  },
  {
    "id": "darienzo-46",
    "trackNo": null,
    "genre": "Polca",
    "date": "1937-06-02",
    "title": "Amelia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38187,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Santa Cruz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Amelia Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "6cNdrdRLlsZNVTv0ompFTa"
  },
  {
    "id": "darienzo-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-07-02",
    "title": "La rosarina",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38221,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ricardo A. Gonzalez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La rosarina Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "1dvlbZrO9ehmCQk95iVV3f"
  },
  {
    "id": "darienzo-48",
    "trackNo": null,
    "genre": "Vals",
    "date": "1937-07-02",
    "title": "Pasion",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38221,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Cosentino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Pasion Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "2vhKOs8NPGGiKmwaPqnhgc"
  },
  {
    "id": "darienzo-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-07-26",
    "title": "El choclo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38242,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El choclo Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "674lDY7wovADkr3eAXZJSc"
  },
  {
    "id": "darienzo-50",
    "trackNo": null,
    "genre": "Vals",
    "date": "1937-07-26",
    "title": "Valsecito Criollo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38242,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lidio Fasoli",
    "lyricist": "Francisco Garcia Gimenez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Valsecito Criollo Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "6IVU4YSlxAPSqY3R25wDVr"
  },
  {
    "id": "darienzo-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-08-31",
    "title": "El porteñito",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38261,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El porteñito Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "6RNlmhBt4gzRLcHpr6KphX"
  },
  {
    "id": "darienzo-52",
    "trackNo": null,
    "genre": "Vals",
    "date": "1937-08-31",
    "title": "Valsecito de antes",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38261,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Sureda",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Valsecito de antes Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "05EfCltDVoymYThGkotjoQ"
  },
  {
    "id": "darienzo-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-09-22",
    "title": "El caburé",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38283,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El caburé Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "3ZuQAhZ032vcwCBMN6ugxw"
  },
  {
    "id": "darienzo-54",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1937-09-22",
    "title": "Milonga Vieja milonga",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38283,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Aranaz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Milonga Vieja milonga Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-10-29",
    "title": "Paciencia",
    "singer": "Enrique Carbel",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38305,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Carbel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Paciencia Enrique Carbel Juan D’Arienzo 37 1937",
    "spotifyTrackId": "6Wb0sWQUoVTNip3Vod0BNc"
  },
  {
    "id": "darienzo-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-10-29",
    "title": "Jueves",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38305,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rafael Rossi",
    "lyricist": "Udelino Toranzo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Jueves Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "3kXrlprwA6bOv5ht5VuCa1"
  },
  {
    "id": "darienzo-57",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-09",
    "title": "El cencerro",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38327,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martinez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El cencerro Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "2ZGtolZe8N6Gnej3wx9rDq"
  },
  {
    "id": "darienzo-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-09",
    "title": "Gallo ciego",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38327,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Gallo ciego Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "31LmHRnZAIDKaf030ibNi1"
  },
  {
    "id": "darienzo-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-14",
    "title": "El africano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38326,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Pereyra",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El africano Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "4suEW5wrND7R6l0KHkTFpI"
  },
  {
    "id": "darienzo-60",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-14",
    "title": "La cumparsita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38326,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gerardo Matos Rodriguez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "2joB3G4b527szRVC6L5VYL"
  },
  {
    "id": "darienzo-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-21",
    "title": "La morocha",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38352,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "Angel Villoldo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La morocha Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "0gd8SeqP18yMLBafP6m9PB"
  },
  {
    "id": "darienzo-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-12-21",
    "title": "Melodia porteña",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38352,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1937,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discépolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Melodia porteña Instrumental Juan D’Arienzo instr. inst. instr inst 37 1937",
    "spotifyTrackId": "1FMRyuqZuxaPGzHfqBINSj"
  },
  {
    "id": "darienzo-63",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-01-04",
    "title": "Indiferencia",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38372,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Biagi",
    "lyricist": "Juan Carlos Thorry",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Indiferencia Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "09HDxaev10fJJiLkWjMH9i"
  },
  {
    "id": "darienzo-64",
    "trackNo": null,
    "genre": "Tango*",
    "date": "1938-01-04",
    "title": "El esquinazo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38372,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El esquinazo Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "3qhTREq1TLb9u4pUtdnYiO"
  },
  {
    "id": "darienzo-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-01-07",
    "title": "Rodriguez Peña",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38368,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Rodriguez Peña Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "7krkA59J1Bu5fO06PegpCj"
  },
  {
    "id": "darienzo-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-01-07",
    "title": "Union civica",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38368,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Santa Cruz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Union civica Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "39eYvSUxCDdSbDH9OY5Xql"
  },
  {
    "id": "darienzo-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-03-23",
    "title": "El triunfo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38412,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El triunfo Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "2f9Hnz0hGnF8i1jGRmwF0p"
  },
  {
    "id": "darienzo-68",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-03-23",
    "title": "La mariposa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38412,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Celedonio Esteban Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La mariposa Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "1jgNaiz5AkHhJMXXWHlTCd"
  },
  {
    "id": "darienzo-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-04-12",
    "title": "El horizonte",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38440,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El horizonte Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "0vJ6pJACYPTskpalKes5l7"
  },
  {
    "id": "darienzo-70",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-04-12",
    "title": "El aeroplano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38440,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Datta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El aeroplano Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "75B5jG4rHSJQmDGNtOpvL4"
  },
  {
    "id": "darienzo-71",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-05-06",
    "title": "El cisne",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38468,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Maía Rizzuti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El cisne Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "0qJj59d7nrhzycqhKLIuWy"
  },
  {
    "id": "darienzo-72",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1938-05-06",
    "title": "Milonga del corazón",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38468,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Milonga del corazón Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "5P2u6Rz8GzobctBcdAojdY"
  },
  {
    "id": "darienzo-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-06-08",
    "title": "La catrera",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38474,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "La catrera Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "07hIUxqUyJmrNgsur1XM6X"
  },
  {
    "id": "darienzo-74",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1938-06-08",
    "title": "El temblor",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38474,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "El temblor Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "3bOj6kraZBoBPKnN1h6pvm"
  },
  {
    "id": "darienzo-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-06-22",
    "title": "Pensalo bien",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38495,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "J.J. Visciglio",
    "lyricist": "Luis Alberto / Nolo Lopez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "TRUE",
    "filterDarienzoReyCas": "",
    "search": "Pensalo bien Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "1tfvmDXKKIwirAMSfohfKN"
  },
  {
    "id": "darienzo-76",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-06-22",
    "title": "Champagne Tango",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38495,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Aroztegui",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Champagne Tango Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "2HPsVBUNWt6Q5MK5TGrhcL"
  },
  {
    "id": "darienzo-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-07-08",
    "title": "Nada más",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38506,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nada más Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "0V8uYuBGqfvYocLyibOmxp"
  },
  {
    "id": "darienzo-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-07-08",
    "title": "El internado",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38506,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El internado Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "7iAatt7rrNBUfnlJXatQqU"
  },
  {
    "id": "darienzo-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-08-05",
    "title": "Florida",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38536,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raimundo Petillo",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Florida Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "1WeSZP1pPujdwJsjFGGxsA"
  },
  {
    "id": "darienzo-80",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1938-08-05",
    "title": "Estampa de varón",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38536,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Americo Surde",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Estampa de varón Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "46BMUpWc7k2kGUAMtJberX"
  },
  {
    "id": "darienzo-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-08-26",
    "title": "La bruja",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38544,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Polito",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La bruja Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "2fAZCxoBpAN3c7z05akW4S"
  },
  {
    "id": "darienzo-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-08-26",
    "title": "Lelia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38544,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lelia Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "1bxloNedU2ynfaS0YoIxz4"
  },
  {
    "id": "darienzo-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-09-30",
    "title": "Lunes",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38569,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Luis Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lunes Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "5w9LVgULeo4Y3RaWpIniRK"
  },
  {
    "id": "darienzo-84",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-09-30",
    "title": "En tu corazón",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38569,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Salgan",
    "lyricist": "Carmelo Volpe",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En tu corazón Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "67fTsnA7o1Pb8RYEwGnVzm"
  },
  {
    "id": "darienzo-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-11-03",
    "title": "De mi flor",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38601,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De mi flor Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "44QcLJApWdh28tf3Ouj99T"
  },
  {
    "id": "darienzo-86",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-11-03",
    "title": "Cabeza de novia",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38601,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Nolo Lopez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cabeza de novia Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "3ebyFsi4yy0jFWzBWKEBrN"
  },
  {
    "id": "darienzo-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-11-09",
    "title": "Ansiedad",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38608,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo C. Moro",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ansiedad Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "3NnlXOeKWgSW8VAt9v9gio"
  },
  {
    "id": "darienzo-88",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1938-11-09",
    "title": "Milonga querida",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38608,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Larenza",
    "lyricist": "Lito Bayardo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga querida Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "3nhnFUY3gXfEs8f0jfFp5z"
  },
  {
    "id": "darienzo-89",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-12-28",
    "title": "No mientas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38640,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela / A. M. Lattero",
    "lyricist": "H. Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No mientas Alberto Echagüe Juan D’Arienzo 38 1938",
    "spotifyTrackId": "16XaJ4KHyaR43VSRpYJuwI"
  },
  {
    "id": "darienzo-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-12-28",
    "title": "El pollito",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38640,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pollito Instrumental Juan D’Arienzo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "4XcTgs2vmYGx6obKroPs7R"
  },
  {
    "id": "darienzo-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-01-04",
    "title": "Pico blanco",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38659,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pico blanco Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "6SHv0KZaBbwdDwcscbPT1P"
  },
  {
    "id": "darienzo-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-01-04",
    "title": "Yunta brava",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38659,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Gregorio Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yunta brava Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "28Uo8gKqWrSOBLz6FYjmZI"
  },
  {
    "id": "darienzo-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-03-03",
    "title": "Dos guitas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38676,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos guitas Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "2WmL20D17IRhKyG6vU47W0"
  },
  {
    "id": "darienzo-94",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1939-03-03",
    "title": "Meta fierro",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38676,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Meta fierro Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "0oKEsEPVdA9JU1uYpmLEcH"
  },
  {
    "id": "darienzo-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-04-18",
    "title": "Maipo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38702,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Maipo Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "3bLzD5TazfuwZpOCtoUHoS"
  },
  {
    "id": "darienzo-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-04-18",
    "title": "No me lo digas",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38702,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis N. Visca",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No me lo digas Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "0eHfjoYgUKVcQZRsvalFEj"
  },
  {
    "id": "darienzo-97",
    "trackNo": null,
    "genre": "Vals",
    "date": "1939-05-04",
    "title": "Recuerdos de la pampa",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38715,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Duclos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdos de la pampa Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "6AZzeAFsimiWc8voUESfXl"
  },
  {
    "id": "darienzo-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-05-04",
    "title": "El marne",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38715,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El marne Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "3NHsWbePAE2vwHiGqDGkdd"
  },
  {
    "id": "darienzo-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-05-30",
    "title": "Olvidame",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38742,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Olvidame Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "2htNYDK3gq0Cs1h3MJaQYQ"
  },
  {
    "id": "darienzo-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-05-30",
    "title": "Charamusca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38742,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Charamusca Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "34yCSrXMSUrsZL3fAkkJUZ"
  },
  {
    "id": "darienzo-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-07-17",
    "title": "Derecho viejo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38767,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Derecho viejo Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "5N43pms351YynvPWuqi2Jm"
  },
  {
    "id": "darienzo-102",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1939-07-17",
    "title": "Milonga del recuerdo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38767,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfonso Lacueva",
    "lyricist": "José Pecora",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga del recuerdo Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "5u5O0u396OuNgm5iZm2E8Y"
  },
  {
    "id": "darienzo-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-08-09",
    "title": "Mandria",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38785,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "J.C. Rodriguez",
    "lyricist": "F. Brancatti / J. Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mandria Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "6dq989VgUDthFkWpoU9n1U"
  },
  {
    "id": "darienzo-104",
    "trackNo": null,
    "genre": "Vals",
    "date": "1939-08-09",
    "title": "Castigo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38785,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Polito",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Castigo Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "5QtYVEa5XHzIrpg18tu5DZ"
  },
  {
    "id": "darienzo-105",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-01",
    "title": "El vino triste",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38803,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El vino triste Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "0GJSNZkyU1whOb2x9LyXD5"
  },
  {
    "id": "darienzo-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-01",
    "title": "Felicia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38803,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Felicia Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "23q8sUxjLhdbHbBQZ6HEjS"
  },
  {
    "id": "darienzo-107",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1939-09-27",
    "title": "De antaño",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38817,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De antaño Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "5USM0WSXAp986304i0DUup"
  },
  {
    "id": "darienzo-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-27",
    "title": "Qué importa",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38841,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ricardo Tanturi",
    "lyricist": "Juan Carlos Thorry",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué importa Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "7ItfrEmpuzhg6ab6TsNt5X"
  },
  {
    "id": "darienzo-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-27",
    "title": "Santa milonguita",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38817,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Enrique Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Santa milonguita Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "0BPLj3OJ0k935mnXQbmmPu"
  },
  {
    "id": "darienzo-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-27",
    "title": "Don Pacifico",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38841,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Pacifico Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "2MZmKQhiANeJ5R1DMtkXPc"
  },
  {
    "id": "darienzo-111",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-10-31",
    "title": "Pampa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38865,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pampa Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "1arm9kvi7a4zube1VT0mde"
  },
  {
    "id": "darienzo-112",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1939-10-31",
    "title": "La cicatriz",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38865,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raul Aguirrezabalaga",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cicatriz Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "3eWyk6aPxpihpWF5wQtZX2"
  },
  {
    "id": "darienzo-113",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-11-14",
    "title": "Que dios te ayude",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38887,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "J. J. Visciglio",
    "lyricist": "A. Mastronardi / Nolo López",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que dios te ayude Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "6IckFj9XZArf1xQYH1H1Sk"
  },
  {
    "id": "darienzo-114",
    "trackNo": null,
    "genre": "Vals",
    "date": "1939-11-14",
    "title": "Ay, Aurora",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38887,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Gardel",
    "lyricist": "Jose Razzano",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ay, Aurora Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "6brfiTYMEOglkQnl3DRZqN"
  },
  {
    "id": "darienzo-115",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-12-22",
    "title": "Trago amargo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38909,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rafael Iriarte",
    "lyricist": "Julio Navarrine",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Trago amargo Alberto Echagüe Juan D’Arienzo 39 1939",
    "spotifyTrackId": "7qs3wzFNE1MLOVxBcqyHS7"
  },
  {
    "id": "darienzo-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-12-22",
    "title": "Por que razón",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38909,
    "orchestraId": "darienzo",
    "groupId": "darienzo3539",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Salvador Grupillo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por que razón Instrumental Juan D’Arienzo instr. inst. instr inst 39 1939",
    "spotifyTrackId": "0edSg1HWqsoxDdnRwkhHHw"
  },
  {
    "id": "darienzo-117",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-04-12",
    "title": "Sali de perdedor",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38956,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "M",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Idalberto Nery",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Sali de perdedor Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "1Oo1yMLA38wtSaM88r6Fsc"
  },
  {
    "id": "darienzo-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-04-12",
    "title": "Entre dos fuegos",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38956,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lopez Buchardo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entre dos fuegos Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "6FYIaBMjwhLrGldRbaCDPm"
  },
  {
    "id": "darienzo-119",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-05-17",
    "title": "A una mujer",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38984,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Salgan",
    "lyricist": "Carmelo Volpe",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "A una mujer Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "5sxxjLIvlGZqN3qisvalBV"
  },
  {
    "id": "darienzo-120",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-05-17",
    "title": "Chiruza",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38984,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Nolo López",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Chiruza Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-121",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-06-07",
    "title": "Cajita de música",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38999,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "M",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castelllanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Cajita de música Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "0MzTp4xMli4wjsFDqH8fc2"
  },
  {
    "id": "darienzo-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-07",
    "title": "El pillín",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38999,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pillín Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "74EiYfucbPTtKgyTYRwWpE"
  },
  {
    "id": "darienzo-123",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-06-27",
    "title": "Fibras",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39011,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Bonesi",
    "lyricist": "Francisco Sierra",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fibras Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "7CjaHzU5mbL1jQ9cEgglHg"
  },
  {
    "id": "darienzo-124",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-27",
    "title": "Tucuman",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39011,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Luis Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tucuman Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "1qCIPWAh0L8mX5SwxzoaSr"
  },
  {
    "id": "darienzo-125",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-08-08",
    "title": "Barracas al sud",
    "singer": "Carlos Casares",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39052,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "M",
    "singerShort": "Casares",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Julio Alberto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Barracas al sud Carlos Casares Juan D’Arienzo 40 1940",
    "spotifyTrackId": "0NXi9QoZhpi88fQihumQhU"
  },
  {
    "id": "darienzo-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-08-08",
    "title": "La bicoca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39052,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Arturo Severino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La bicoca Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "1CdSwMHKXr54rna0R7oc1z"
  },
  {
    "id": "darienzo-127",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-08-22",
    "title": "El tigre Millán",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39063,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "El tigre Millán Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "0mK9xvtqBn2smMWE6L7omW"
  },
  {
    "id": "darienzo-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-08-22",
    "title": "Rie payaso",
    "singer": "Carlos Casares",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39063,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Casares",
    "discType": "Shellac",
    "duration": "",
    "composer": "Virgilio Carmona",
    "lyricist": "Emilio Falero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Rie payaso Carlos Casares Juan D’Arienzo 40 1940",
    "spotifyTrackId": "0tlQUihy4uSnRlG1lrqthP"
  },
  {
    "id": "darienzo-129",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-09",
    "title": "Yo también",
    "singer": "Carlos Casares",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39111,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Casares",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis N. Visca",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Yo también Carlos Casares Juan D’Arienzo 40 1940",
    "spotifyTrackId": "0Bayid8LAQJGyza9UpErxg"
  },
  {
    "id": "darienzo-130",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-09",
    "title": "La clavada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39111,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto J. Zambonini",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La clavada Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "1NDWeYle1mVx1Z7qzihPZp"
  },
  {
    "id": "darienzo-131",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-21",
    "title": "El corazón me engaño",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39149,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "El corazón me engaño Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "1f8lRMEyRd7U5aC8MyVqdF"
  },
  {
    "id": "darienzo-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-21",
    "title": "Canaro en Paris",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39149,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Caldarella / Alejandro Scarpino",
    "lyricist": "José Scarpino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canaro en Paris Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "4Pyk405sYaSc1ty0TUtM60"
  },
  {
    "id": "darienzo-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-12",
    "title": "Esclavas blancas",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39197,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Pettorossi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Esclavas blancas Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "1Te5VxmZkFBzvwYTGKRyhV"
  },
  {
    "id": "darienzo-134",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-12-12",
    "title": "Milonga del ochenta y tres",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39187,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "M",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Domingo Pecora",
    "lyricist": "Pedro Romano",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Milonga del ochenta y tres Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-135",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-12",
    "title": "No te engañes corazón",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39187,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciaramella",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "No te engañes corazón Alberto Reynal Juan D’Arienzo 40 1940",
    "spotifyTrackId": "41FAF0qxYdADX5Q352ZmjV"
  },
  {
    "id": "darienzo-136",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-12-12",
    "title": "Flor del mal",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39197,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "J.C. Gravis",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Flor del mal Héctor Mauré Juan D’Arienzo 40 1940",
    "spotifyTrackId": "68QdwoGkILDok0dMHZUrlE"
  },
  {
    "id": "darienzo-137",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-12-31",
    "title": "Miedo",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39215,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Maure",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Miedo Héctor Mauré Juan D’Arienzo 40 1940",
    "spotifyTrackId": "57sFCEI9Z2yodzG4g5Yqr4"
  },
  {
    "id": "darienzo-138",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-31",
    "title": "Quasi nada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39215,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quasi nada Instrumental Juan D’Arienzo instr. inst. instr inst 40 1940",
    "spotifyTrackId": "6HMYBAJg6DTNnP5TZfkBBF"
  },
  {
    "id": "darienzo-139",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-02-26",
    "title": "Almanaque de ilusión",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39237,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Tavarozzi",
    "lyricist": "Hector Marco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Almanaque de ilusión Alberto Reynal Juan D’Arienzo 41 1941",
    "spotifyTrackId": "6eiFYmgWPCooqZwsFLdVam"
  },
  {
    "id": "darienzo-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-02-26",
    "title": "Fuegos artificiales",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39237,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo ArolaS",
    "lyricist": "Roberto Firpo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fuegos artificiales Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "2CiJ6E3ITAJkVOjQbcak4u"
  },
  {
    "id": "darienzo-141",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-04-28",
    "title": "Candombe oriental",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39273,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Candombe oriental Alberto Reynal Juan D’Arienzo 41 1941",
    "spotifyTrackId": "1myJU0PRqfmOhruhbZjT16"
  },
  {
    "id": "darienzo-142",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-04-28",
    "title": "Ya lo ves",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39273,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ya lo ves Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "3NZdwcdnktSzuo4txbrJBC"
  },
  {
    "id": "darienzo-143",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-21",
    "title": "Dime mi amor",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39297,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciaramella",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dime mi amor Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "1MVDKurI4etROAbwOxr9vR"
  },
  {
    "id": "darienzo-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-21",
    "title": "Quien te iguala",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39297,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro Michetti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quien te iguala Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "5YRu9WdeFyaieT0GJgKd7u"
  },
  {
    "id": "darienzo-145",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-06-09",
    "title": "Adios Querida",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39313,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Salgan",
    "lyricist": "Carmelo Volpe",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adios Querida Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "1CYiRF3N1jyb8f5bgbl2Zh"
  },
  {
    "id": "darienzo-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-09",
    "title": "Tierrita",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39313,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "Jesus Fernández Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tierrita Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "3XEaq7Pgk97e13cXsD6MbD"
  },
  {
    "id": "darienzo-147",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-14",
    "title": "El olivo",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39362,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "A. Scatasso / D.J. Vivas",
    "lyricist": "C. Cabral",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El olivo Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "79p89FcEusKZRQZzUBE0Sx"
  },
  {
    "id": "darienzo-148",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-14",
    "title": "Humillación",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39362,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Biagi",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Humillación Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "6tOEDNHOZChgrzRghRjMbf"
  },
  {
    "id": "darienzo-149",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-14",
    "title": "Nunca mas",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39343,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Lomuto",
    "lyricist": "Oscar Lomuto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nunca mas Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "6JVV4sUfy7FFHkQ4ofKITX"
  },
  {
    "id": "darienzo-150",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-14",
    "title": "Canaro",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39343,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martinez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canaro Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "63IXaFViq3WiquQYwhIHZI"
  },
  {
    "id": "darienzo-151",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-29",
    "title": "Olvidao",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39380,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Guillermo Barbieri",
    "lyricist": "Enrique Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Olvidao Alberto Reynal Juan D’Arienzo 41 1941",
    "spotifyTrackId": "22ppSxU9uDdmhPHIIfv6fZ"
  },
  {
    "id": "darienzo-152",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-07-29",
    "title": "Bolada de aficionado",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39380,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Gregorio Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bolada de aficionado Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4eA8kppsFRCe2LPikZgvf6"
  },
  {
    "id": "darienzo-153",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-09-12",
    "title": "La serenata de ayer",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39472,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Buzon",
    "lyricist": "Ismael R. Aguilar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La serenata de ayer Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "3RSSIr046fwLs1rPUDujIu"
  },
  {
    "id": "darienzo-154",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-12",
    "title": "Sobre el pucho",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39472,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastian Piana",
    "lyricist": "José Gonzalez Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sobre el pucho Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "5w9LVgULeo4Y3RaWpIniRK"
  },
  {
    "id": "darienzo-155",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-12",
    "title": "El rey del compás",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39421,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Principe Cubano",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El rey del compás Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "5Ak6RwSHcB8PRDB8nIYu4c"
  },
  {
    "id": "darienzo-156",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-12",
    "title": "Hernani",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39421,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Santa Cruz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hernani Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3pEPqDMuG0i1WWItHkShZ4"
  },
  {
    "id": "darienzo-157",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-24",
    "title": "Orquesta Tipica",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39438,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadicamo",
    "lyricist": "Rosendo Luna",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Orquesta Tipica Alberto Reynal Juan D’Arienzo 41 1941",
    "spotifyTrackId": "20Wj5OVij1xEP1HKnwP8Ih"
  },
  {
    "id": "darienzo-158",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-10-24",
    "title": "Carnavalera",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39438,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastina Piana",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carnavalera Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "0fQg6B54FAislCPXHuUBjk"
  },
  {
    "id": "darienzo-159",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-11-12",
    "title": "Cuatro palabras",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39516,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro palabras Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "4UNLwP2NNNiQz75J65uT8G"
  },
  {
    "id": "darienzo-160",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-12",
    "title": "El resero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39540,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raymundo Petillo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El resero Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "5aJUFK769x14VCdfpgzGvW"
  },
  {
    "id": "darienzo-161",
    "trackNo": null,
    "genre": "Milongón",
    "date": "1941-12-15",
    "title": "El porteño",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39540,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan y Roberto Caló",
    "lyricist": "H.E. Callón",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El porteño Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "1ZrWZdj3tQU2l7OBZm3O85"
  },
  {
    "id": "darienzo-162",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-12-15",
    "title": "El vals de los abuelos",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39485,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El vals de los abuelos Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "6MYCDpWWPjiqlpeC9jxekm"
  },
  {
    "id": "darienzo-163",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-15",
    "title": "Infamia",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39485,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discépolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Infamia Héctor Mauré Juan D’Arienzo 41 1941",
    "spotifyTrackId": "1VtnmkFtNPWtGBm50p4v4V"
  },
  {
    "id": "darienzo-164",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-15",
    "title": "El calabozo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39516,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Augusto Gentile",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El calabozo Instrumental Juan D’Arienzo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3HCAVWb0jrM3uCRwd8nexO"
  },
  {
    "id": "darienzo-165",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-25",
    "title": "Culpas ajenas",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39651,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "Jorge Curi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Culpas ajenas Alberto Reynal Juan D’Arienzo 42 1942",
    "spotifyTrackId": "02eh9DTW2LTRIlMU09rJa4"
  },
  {
    "id": "darienzo-166",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-03-25",
    "title": "El naranjerito",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39564,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela / Alberto Nery",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El naranjerito Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "7oKKzC8kqmwXEK1MhtCbVa"
  },
  {
    "id": "darienzo-167",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-25",
    "title": "Garronero",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39564,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "J.F. Garide",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Garronero Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "1yP7tCcZsVDiSVfsl3qmLD"
  },
  {
    "id": "darienzo-168",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-22",
    "title": "Consejos",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39588,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Nolo López",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Consejos Alberto Reynal Juan D’Arienzo 42 1942",
    "spotifyTrackId": "2gkdPzzdiOIzBfYzbVnvTq"
  },
  {
    "id": "darienzo-169",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-22",
    "title": "Barranca abajo (Cacion de Soledad)",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39651,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Garcia Servetto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barranca abajo (Cacion de Soledad) Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-170",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-22",
    "title": "Loca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39588,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Joves",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Loca Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "02qRzFKIx5yPEwyvGjJ9FV"
  },
  {
    "id": "darienzo-171",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-29",
    "title": "Vidas marcadas",
    "singer": "Alberto Reynal",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39611,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Reynal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciaramella",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "TRUE",
    "search": "Vidas marcadas Alberto Reynal Juan D’Arienzo 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-172",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-04-29",
    "title": "Muchachita porteña",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39611,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Alberto Vaccarezza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachita porteña Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "6gKtAbnpvQNnJMkrfzxB00"
  },
  {
    "id": "darienzo-173",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-18",
    "title": "Mirame en la cara",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39638,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Luis Castiñeira",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mirame en la cara Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "7ibHRdhxBxRMjNjt6gXY0n"
  },
  {
    "id": "darienzo-174",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-18",
    "title": "El amasijo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39676,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Adolfo R. Avilés",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El amasijo Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "5maiGd6292nCN8FIq1WOw0"
  },
  {
    "id": "darienzo-175",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-18",
    "title": "Tierra negra",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39638,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Graciano De Leone",
    "lyricist": "Juan F Noli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tierra negra Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "5OSUynjJcdzUNbtnfzCiA1"
  },
  {
    "id": "darienzo-176",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-21",
    "title": "El retobao",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39676,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Rezzano",
    "lyricist": "Francisco A. Lio",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El retobao Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "22fA0vTPfhlKBE6eOh4tzq"
  },
  {
    "id": "darienzo-177",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-07-21",
    "title": "Tu pañuelo",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39736,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Polito",
    "lyricist": "Iván Diez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu pañuelo Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "7ynN9QiOhFsVxvC7zPTmMU"
  },
  {
    "id": "darienzo-178",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-21",
    "title": "Y se van los años",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39736,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cayetano Puglisi",
    "lyricist": "Luis Castiñeira",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y se van los años Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "5bVFdqjuUPfH5L9qfXtSeP"
  },
  {
    "id": "darienzo-179",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-12",
    "title": "Claudinette",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39689,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Julian Centeya",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Claudinette Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "31Po7c9eVJ0RK2dhJMoANH"
  },
  {
    "id": "darienzo-180",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-08-12",
    "title": "Tirando a matar",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39689,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tirando a matar Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "4jAB7zlM4mn4uITxgWvPuk"
  },
  {
    "id": "darienzo-181",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-09-24",
    "title": "Cortada de San Ignacio",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39741,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Salgan",
    "lyricist": "Carmelo Volpe",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cortada de San Ignacio Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "2Oc4IvTu7QueY2eyAAUpqm"
  },
  {
    "id": "darienzo-182",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-24",
    "title": "Embrujamiento",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39768,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "D. Smurra / R. Malerba",
    "lyricist": "M. Ferradas Campos",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Embrujamiento Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "1IqzWW8BheL3yZIIh6Q3nr"
  },
  {
    "id": "darienzo-183",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-24",
    "title": "Pompas de jabon",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39787,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Goyeneche",
    "lyricist": "Enrique Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pompas de jabon Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "10jip3hXcAi7VQP4TrndzP"
  },
  {
    "id": "darienzo-184",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-24",
    "title": "Vieja recova",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39741,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciaramella",
    "lyricist": "Enrique Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vieja recova Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "6G2FzEYsKUCi5roVGbMLFB"
  },
  {
    "id": "darienzo-185",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-24",
    "title": "Sans Souci",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39787,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sans Souci Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "46OvqeCplubxLtXWNVZpx3"
  },
  {
    "id": "darienzo-186",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-10-27",
    "title": "Criollita linda",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39768,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Di Clemente",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Criollita linda Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "55Dt0qXPRgNt3zybWXkfma"
  },
  {
    "id": "darienzo-187",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-27",
    "title": "Carancho",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39816,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Hector Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carancho Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "6ST65gcQMuO07M1vTPPAdq"
  },
  {
    "id": "darienzo-188",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-27",
    "title": "Seguime corazón",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Suarez",
    "lyricist": "Jesus Fernandez Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Seguime corazón Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "1STBciLuzyaHzQMmMWwqZL"
  },
  {
    "id": "darienzo-189",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-27",
    "title": "El lazo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Esca Anibal Caccavaio",
    "lyricist": "Eugenio Cardenas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El lazo Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "4cARQPCDnN0GbtsMjuNpr6"
  },
  {
    "id": "darienzo-190",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-22",
    "title": "Cicatrices",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Adolfo Aviles",
    "lyricist": "Enrique Pedro Maroni",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cicatrices Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "4h31Y9MPlQXq19qxaqek2k"
  },
  {
    "id": "darienzo-191",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-22",
    "title": "Compadron",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39816,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis N. Visca",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Compadron Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "3vQmH9EAMPi5GVa6Wq37S4"
  },
  {
    "id": "darienzo-192",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-12-22",
    "title": "Tan solo tu",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Servando Dominguez",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tan solo tu Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "4L3KyVLprDX86vyejMU1RC"
  },
  {
    "id": "darienzo-193",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-22",
    "title": "Chique",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ricardo Luis Brignolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chique Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "53Hzm85x77mpp91n1aBl7J"
  },
  {
    "id": "darienzo-194",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-22",
    "title": "Independencia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Bevilacqua",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Independencia Instrumental Juan D’Arienzo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "4G4YOuiTrcK8GXcTyuhoqJ"
  },
  {
    "id": "darienzo-195",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-29",
    "title": "Judas",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Marchisio",
    "lyricist": "Carmelo Santiago",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Judas Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "3USM55Xd79oZfSyHIQs7m5"
  },
  {
    "id": "darienzo-196",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-12-29",
    "title": "La rumbita candombé",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39835,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "O. Novarro / R. Fortunato",
    "lyricist": "M. Batistella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La rumbita candombé Héctor Mauré Juan D’Arienzo 42 1942",
    "spotifyTrackId": "65lMoI6b8pCImOKTCdUGDc"
  },
  {
    "id": "darienzo-197",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-29",
    "title": "Pobre Mascarita",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39835,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Calógero Granata",
    "lyricist": "Orlando Romanelli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pobre Mascarita Juan Carlos Lamas Juan D’Arienzo 42 1942",
    "spotifyTrackId": "0WUPhd9bBEH3RECWjMUo0V"
  },
  {
    "id": "darienzo-198",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-23",
    "title": "Arlette",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bonavena",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Arlette Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "5vmfaYuTccIEcpObw8k2xs"
  },
  {
    "id": "darienzo-199",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-23",
    "title": "Enamorado / Metido",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Principe Cubano",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Enamorado / Metido Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-200",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-06-23",
    "title": "Se fue",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacion Salgan L: Oscar Rubens",
    "lyricist": "P. Valdes",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Se fue Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-201",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-23",
    "title": "Tres horas",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Idelberto Nery",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tres horas Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "5oVx001iAyWPHYOFpHTcLG"
  },
  {
    "id": "darienzo-202",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-06-23",
    "title": "Musica de mi Argentina",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Musica de mi Argentina Juan Carlos Lamas Juan D’Arienzo 43 1943",
    "spotifyTrackId": "7E8HNuVE5AUEJZyNqkSlJG"
  },
  {
    "id": "darienzo-203",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-07-13",
    "title": "Tabu",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Titi Rossi",
    "lyricist": "Ricardo Thompson",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tabu Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "26JsCj1lVvVix6IqJqYju9"
  },
  {
    "id": "darienzo-204",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-07-13",
    "title": "Tango brujo",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tango brujo Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "3Xg8NKOUkPzE99sKIqNJ9h"
  },
  {
    "id": "darienzo-205",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-07-13",
    "title": "Vea, vea",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vea, vea Instrumental Juan D’Arienzo instr. inst. instr inst 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-206",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-28",
    "title": "Aguilucho",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Helena Codebo de Laporace",
    "lyricist": "Alfredo Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aguilucho Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "2dtQgU5l4kSsJP6gcTO2NI"
  },
  {
    "id": "darienzo-207",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-09-28",
    "title": "Aunque vivas en el cielo",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Hector Marco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aunque vivas en el cielo Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "0sT5PpvjK8lqqSIMYCQAG1"
  },
  {
    "id": "darienzo-208",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-28",
    "title": "Si la llegaran a ver",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "E. Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si la llegaran a ver Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "28FPuB83Euh6zCB3oEITNZ"
  },
  {
    "id": "darienzo-209",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-28",
    "title": "Yo quiero cantar un tango",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "M. Nijensohn / Jose Niesso",
    "lyricist": "J. Suñe",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo quiero cantar un tango Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "6yVEpir3LqHk1RsyQNLD9c"
  },
  {
    "id": "darienzo-210",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-28",
    "title": "Aquel muchacho de la orquesta",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Caruso",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aquel muchacho de la orquesta Juan Carlos Lamas Juan D’Arienzo 43 1943",
    "spotifyTrackId": "0Rr9NWdfgEpD3YshguaJsK"
  },
  {
    "id": "darienzo-211",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-09-28",
    "title": "Corrales viejos",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "F. Laino / Antonio Radici",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corrales viejos Instrumental Juan D’Arienzo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "200V04wgLXkAWsnu0iDupO"
  },
  {
    "id": "darienzo-212",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-23",
    "title": "Uno",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Marino Mores",
    "lyricist": "Enrique Santos Discépolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Uno Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "3fH8sExWI4u44viim8puh9"
  },
  {
    "id": "darienzo-213",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-23",
    "title": "Joaquina",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Bergamino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Joaquina Instrumental Juan D’Arienzo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "0PRCiZzIFHUrzTrFObn783"
  },
  {
    "id": "darienzo-214",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-23",
    "title": "La cumparsita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gerardo Matos Rodriguez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Instrumental Juan D’Arienzo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "2joB3G4b527szRVC6L5VYL"
  },
  {
    "id": "darienzo-215",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-11-23",
    "title": "La puñalada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La puñalada Instrumental Juan D’Arienzo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "4etmaoE6wyAjLNk3NdxVuW"
  },
  {
    "id": "darienzo-216",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-12-27",
    "title": "A Mercedes",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutierres",
    "lyricist": "Juan Venancio Clauso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A Mercedes Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "2HvsVBdq2WjXQf7WBIgiPK"
  },
  {
    "id": "darienzo-217",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-12-27",
    "title": "Prisionero",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio Carresonse",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Prisionero Héctor Mauré Juan D’Arienzo 43 1943",
    "spotifyTrackId": "020khSbi8F3DEi5nXMqDp2"
  },
  {
    "id": "darienzo-218",
    "trackNo": null,
    "genre": "Milonga*",
    "date": "1943-12-27",
    "title": "Candombe rioplatense",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "Carmelo Santiago",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Candombe rioplatense Juan Carlos Lamas Juan D’Arienzo 43 1943",
    "spotifyTrackId": "2FkPnxKJOA4Z814a33z04J"
  },
  {
    "id": "darienzo-219",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-27",
    "title": "Es inutil que la llores",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Salvador Grupillo",
    "lyricist": "Luis Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Es inutil que la llores Juan Carlos Lamas Juan D’Arienzo 43 1943",
    "spotifyTrackId": "4tBmvgB1xT77ugc3vEsms2"
  },
  {
    "id": "darienzo-220",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-27",
    "title": "Viejo tintero",
    "singer": "Juan Carlos Lamas",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Lamas",
    "discType": "Shellac",
    "duration": "",
    "composer": "Graciano De Leona",
    "lyricist": "Estella Mamán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viejo tintero Juan Carlos Lamas Juan D’Arienzo 43 1943",
    "spotifyTrackId": "3aRdgCET2IKx5p2QBPo1aC"
  },
  {
    "id": "darienzo-221",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-05-17",
    "title": "Ana Maria",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Nolo Lopez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ana Maria Héctor Mauré Juan D’Arienzo 44 1944",
    "spotifyTrackId": "6VApDGClEtUgJmpP0Dp1bu"
  },
  {
    "id": "darienzo-222",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-17",
    "title": "Las doce",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Nolo Lopez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Las doce Héctor Mauré Juan D’Arienzo 44 1944",
    "spotifyTrackId": "4IwKX8WOfw88fJ2tUORgva"
  },
  {
    "id": "darienzo-223",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-17",
    "title": "Lilian",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Luis Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lilian Héctor Mauré Juan D’Arienzo 44 1944",
    "spotifyTrackId": "1yCVxIFFibTslAfRnQwVv4"
  },
  {
    "id": "darienzo-224",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-17",
    "title": "El apache argentino",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Arostegui",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El apache argentino Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0AdBGUl7chb1GWSUPekPBH"
  },
  {
    "id": "darienzo-225",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-09",
    "title": "La mentirosa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mentirosa Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "77W60Ri0Ylc1fEtmYIcjJt"
  },
  {
    "id": "darienzo-228",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-21",
    "title": "Amarras",
    "singer": "Héctor Mauré",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4044",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Mauré",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Marchisio",
    "lyricist": "Carmelo Santiago",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amarras Héctor Mauré Juan D’Arienzo 44 1944",
    "spotifyTrackId": "6LRdIOINopHfHx9jh9YEbZ"
  },
  {
    "id": "darienzo-226",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-07",
    "title": "Despues",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutierrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Despues Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "5Ku3f4PQwkhGAGroOxTwpL"
  },
  {
    "id": "darienzo-227",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-07",
    "title": "El huracan",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Edgardo Donato / Osvaldo Donato",
    "lyricist": "Nolo López",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El huracan Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "2ljP6hkCi9vNng4WT9t2Tz"
  },
  {
    "id": "darienzo-229",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-28",
    "title": "No nos veremos nunca mas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No nos veremos nunca mas Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-230",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-08-28",
    "title": "Yo me llamo Eloy Peralta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "Jacinto Font",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo me llamo Eloy Peralta Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "1M0Yrlai9bM2HJKMCgAG5u"
  },
  {
    "id": "darienzo-231",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-21",
    "title": "La madrugada",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Maffia",
    "lyricist": "Catulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La madrugada Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "6aIiCWz3IwviXn2WoZj1Cb"
  },
  {
    "id": "darienzo-232",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-21",
    "title": "El romántico",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El romántico Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "2d4LeWI6WlyZuFxeijLX8S"
  },
  {
    "id": "darienzo-233",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-24",
    "title": "A suerte y verdad",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Maria Parodi",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A suerte y verdad Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "70jGpGofHPdHTCgEbwqzPb"
  },
  {
    "id": "darienzo-234",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-10-24",
    "title": "La mañana",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mañana Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "2IKdc6n4KfGZvD71V1olsN"
  },
  {
    "id": "darienzo-235",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-24",
    "title": "El rezongon",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rafael Natta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El rezongon Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "6rx9RaDxPZy1H2JjmFPFBt"
  },
  {
    "id": "darienzo-236",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-24",
    "title": "Venus",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bevilaqcua",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Venus Instrumental Juan D’Arienzo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0tlQUihy4uSnRlG1lrqthP"
  },
  {
    "id": "darienzo-237",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-12-15",
    "title": "Clavel",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Sosa Cordero",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Clavel Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "28CUyzAQK0j9XYJaXJSIhV"
  },
  {
    "id": "darienzo-238",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-12-15",
    "title": "El paisanito",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pascual Clausi",
    "lyricist": "Melecio L. Perez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El paisanito Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "0inyo4Xufv8veTpQ6yAwMd"
  },
  {
    "id": "darienzo-239",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-12-15",
    "title": "Oro de ley",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roman Juri",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Oro de ley Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "4L3czpMiCXlkaPR4h3CP6Y"
  },
  {
    "id": "darienzo-240",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-15",
    "title": "Seamos amigos",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Principe Cubano",
    "lyricist": "Domingo Rullo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Seamos amigos Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "1CX2Mg04TYTuDmlRKRJnf0"
  },
  {
    "id": "darienzo-241",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-12-26",
    "title": "Me gusta bailar la milonga",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me gusta bailar la milonga Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-242",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-26",
    "title": "Vieja volanta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto De La Cruz",
    "lyricist": "Francisco A. Marino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vieja volanta Alberto Echagüe Juan D’Arienzo 44 1944",
    "spotifyTrackId": "2BphWY5tExSpaPZSqzPlPs"
  },
  {
    "id": "darienzo-243",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-26",
    "title": "Color cielo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bonavena",
    "lyricist": "Monte Nieve",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Color cielo Armando Laborde Juan D’Arienzo 44 1944",
    "spotifyTrackId": "1WeSZP1pPujdwJsjFGGxsA"
  },
  {
    "id": "darienzo-244",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-26",
    "title": "Magdala",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Biagi",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Magdala Armando Laborde Juan D’Arienzo 44 1944",
    "spotifyTrackId": "1YZbgzFowv4ss83XDgpuMr"
  },
  {
    "id": "darienzo-245",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-17",
    "title": "Aparecero",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aietta",
    "lyricist": "Antonio Raddicci",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aparecero Alberto Echagüe y Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-246",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-17",
    "title": "Pajaro sin luz",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Marchisio",
    "lyricist": "Carmelo Santiago",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pajaro sin luz Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "6ow0hEsdv9InhAphRSFqH8"
  },
  {
    "id": "darienzo-247",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-17",
    "title": "Sigueme, si puedes",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro y Jose Scarpino",
    "lyricist": "J. Caldarella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sigueme, si puedes Instrumental Juan D’Arienzo instr. inst. instr inst 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-248",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-04",
    "title": "Corrientes y Esmeralda",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corrientes y Esmeralda Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "78IrIgysAvisK8EiU8cg1A"
  },
  {
    "id": "darienzo-249",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-05-04",
    "title": "La pena del payador",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "José y Luis Servidio",
    "lyricist": "E. Escaris Mendez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La pena del payador Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "3uOIvLx2kYS4cFGcL7mD9M"
  },
  {
    "id": "darienzo-250",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-04",
    "title": "Si supieras que la extraño",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si supieras que la extraño Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-251",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-25",
    "title": "Ya estamos iguales",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco Garcia Jimenez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ya estamos iguales Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "1FioXLPsVWS0EOdJHjA2KZ"
  },
  {
    "id": "darienzo-252",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-25",
    "title": "El tren de las once",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Susena",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tren de las once Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "7CDdRbrxHPG1ul5qiI2KKD"
  },
  {
    "id": "darienzo-253",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-26",
    "title": "Andanzas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eladio Blanco",
    "lyricist": "Eduardo Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Andanzas Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "5cPqG0rIOXR95cZrcO1xQh"
  },
  {
    "id": "darienzo-254",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-07-26",
    "title": "Me duele el corazón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "L. Menoza",
    "lyricist": "Oyeguit",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me duele el corazón Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "39xEL9e4CUCWD76ZZG3tBJ"
  },
  {
    "id": "darienzo-255",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-26",
    "title": "El pensamiento",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martinez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pensamiento Instrumental Juan D’Arienzo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "3vhs4gbUb10cNQf2ZtVmc6"
  },
  {
    "id": "darienzo-256",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-28",
    "title": "Aquí he venido a cantar",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamancia",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aquí he venido a cantar Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "74fkIXGSidDI4yRgpli9rb"
  },
  {
    "id": "darienzo-257",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-28",
    "title": "Pregonera",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo De Angelis",
    "lyricist": "José Rotulo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pregonera Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "1P4jnnxgS0aevohoVBvKL2"
  },
  {
    "id": "darienzo-258",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-28",
    "title": "Azul y oro",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Q. Aguirre",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Azul y oro Instrumental Juan D’Arienzo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "5yxXuPlRlgF3lo40fELtq4"
  },
  {
    "id": "darienzo-259",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-03",
    "title": "Canchero",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canchero Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "2HM2z8UZDRd8IH7JQx1nMY"
  },
  {
    "id": "darienzo-260",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-03",
    "title": "Vamos Zaino",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadicamo",
    "lyricist": "Rosendo Luna",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vamos Zaino Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "2d3lksGZN5E3etZGkfD66y"
  },
  {
    "id": "darienzo-261",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-26",
    "title": "Leguisamo solo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Modesto Papavero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Leguisamo solo Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "7JyYn6sgJNQ65jxGfUKQ7R"
  },
  {
    "id": "darienzo-262",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-26",
    "title": "Don Alfonso",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Eladio Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Alfonso Instrumental Juan D’Arienzo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "0fRCAYVJWO2qIsxw59lTpT"
  },
  {
    "id": "darienzo-263",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-24",
    "title": "Siempre amigos (Pa’ mí es igual)",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "L. Demare / R. Fugazot / A. Irusta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Siempre amigos (Pa’ mí es igual) Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "5w9LVgULeo4Y3RaWpIniRK"
  },
  {
    "id": "darienzo-264",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-24",
    "title": "Yo no espero nada",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Salvador Alonso y Julio Alberto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo no espero nada Alberto Echagüe Juan D’Arienzo 45 1945",
    "spotifyTrackId": "6u4LbBB9Htiqaza3TxjNjD"
  },
  {
    "id": "darienzo-265",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-12-24",
    "title": "Del pasado",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "M",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco A. Marino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Del pasado Alberto Echagüe y Armando Laborde Juan D’Arienzo 45 1945",
    "spotifyTrackId": "2pbhTINEfWGbUB8uXX58gf"
  },
  {
    "id": "darienzo-266",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-24",
    "title": "La galarcita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mauricio Guariglia",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La galarcita Instrumental Juan D’Arienzo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "6iBLusm35v3haJFOPd10it"
  },
  {
    "id": "darienzo-267",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-06",
    "title": "Te espero en Rodriguez Peña",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te espero en Rodriguez Peña Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "4lT00Nbz3BUsfQHmAqHUyx"
  },
  {
    "id": "darienzo-268",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-03-06",
    "title": "Tendras que llorar",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "V",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cristino Tapia",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tendras que llorar Alberto Echagüe y Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "6J9iWYOONor1aR5jHXTp12"
  },
  {
    "id": "darienzo-269",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-06",
    "title": "Con Alma de tango",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con Alma de tango Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "1nhVz5SYWONPEHFBh0Tu9p"
  },
  {
    "id": "darienzo-270",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-06",
    "title": "Don goyo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Bernstein",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don goyo Instrumental Juan D’Arienzo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "2BlVMOqaDvg6P3iTbpPB1V"
  },
  {
    "id": "darienzo-271",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-03-06",
    "title": "La espuela",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Elsa Pigrau de Gudini",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La espuela Instrumental Juan D’Arienzo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "5kzH5btce8fkNcnPxPzgBL"
  },
  {
    "id": "darienzo-272",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-03",
    "title": "Pan comido",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ismael Gomez",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pan comido Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "5cAGN9nKTZScaaxZTKp27P"
  },
  {
    "id": "darienzo-273",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-05-03",
    "title": "Lo mismo que ayer",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "V",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "H. Varela",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lo mismo que ayer Alberto Echagüe y Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "3jcX5oEDhzFdSoH3qMwOgo"
  },
  {
    "id": "darienzo-274",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-03",
    "title": "Siete leguas",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Teófilo Lespes",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Siete leguas Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "0uW6HRQTdzL5RaKOmD58Lx"
  },
  {
    "id": "darienzo-275",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-08-26",
    "title": "Chaparron",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "F. Garcia Gimenez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chaparron Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "0j5infsuBgDpocFMm9zONX"
  },
  {
    "id": "darienzo-276",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-26",
    "title": "Condesita",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Battistella",
    "lyricist": "ONofrio Verditti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Condesita Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "55D912SvbMDkpkgLEMzffk"
  },
  {
    "id": "darienzo-277",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-26",
    "title": "Desde aquella noche",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desde aquella noche Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "2FiNq6B3Nt1D2PIDXlqmio"
  },
  {
    "id": "darienzo-278",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-26",
    "title": "Me grita el corazón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bonano",
    "lyricist": "Roberto Miro",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me grita el corazón Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "4M5NPnM9BMlJsApESrvziC"
  },
  {
    "id": "darienzo-279",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-08-26",
    "title": "No tienes corazón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "N. Vaccaro / A. Travarozzi",
    "lyricist": "S. Adamini",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No tienes corazón Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "4sYGW17Um4qWQc8BjkrD3m"
  },
  {
    "id": "darienzo-280",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-26",
    "title": "El entrerriano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rosendo Mendizabal",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El entrerriano Instrumental Juan D’Arienzo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "7qsgMitt4I64vpj8HXY89k"
  },
  {
    "id": "darienzo-281",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-18",
    "title": "Con todo mi corazón",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Condercuri",
    "lyricist": "Reynaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con todo mi corazón Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "7gG7L1wkXPc5FRWpBMxajL"
  },
  {
    "id": "darienzo-282",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-18",
    "title": "Sin amor",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lalo Etchegoncelay",
    "lyricist": "Hector Sapelli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin amor Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "5vMwS4gyy8jlf4iKFHs1xq"
  },
  {
    "id": "darienzo-283",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-18",
    "title": "Loca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Joves",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Loca Instrumental Juan D’Arienzo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "02qRzFKIx5yPEwyvGjJ9FV"
  },
  {
    "id": "darienzo-284",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-18",
    "title": "Sábado inglés",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sábado inglés Instrumental Juan D’Arienzo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "42qAsaQZOcBvxprprKoVRd"
  },
  {
    "id": "darienzo-285",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-30",
    "title": "Tiene razon amigazo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Calabro",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tiene razon amigazo Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "4OjtIDPdOM8oH27MR6iaOM"
  },
  {
    "id": "darienzo-286",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-30",
    "title": "La canción de mi tristeza",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Sanchez Gorio",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La canción de mi tristeza Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "1fsERYauPbNJZ2xLB56gXY"
  },
  {
    "id": "darienzo-287",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-12-06",
    "title": "Calandria pampa",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel A. Fama",
    "lyricist": "Guillermo Medina",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Calandria pampa Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "0WjsNwFeIKWKgTwligrS2P"
  },
  {
    "id": "darienzo-288",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-06",
    "title": "Esta noche me emborracho",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discépolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche me emborracho Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "6Z6QWA8VdIbhkWRzU1iBtz"
  },
  {
    "id": "darienzo-289",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-06",
    "title": "Yo no sé llorar",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Joaquin Do Reyes",
    "lyricist": "Esteban C. Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo no sé llorar Alberto Echagüe y Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "79SwppV6oGbXZUCdi4bxp3"
  },
  {
    "id": "darienzo-290",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-23",
    "title": "El tarta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Maria Rizzuti",
    "lyricist": "Emilio Fresedo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tarta Alberto Echagüe Juan D’Arienzo 46 1946",
    "spotifyTrackId": "73ruq8AAgS42eJhPpTNua7"
  },
  {
    "id": "darienzo-291",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-12-23",
    "title": "Se apago una estrella",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1946,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "G. Yorio / Emilio Pellejero / J. Santanero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Se apago una estrella Armando Laborde Juan D’Arienzo 46 1946",
    "spotifyTrackId": "4TIWU7PmXry7XJZrfvDgm9"
  },
  {
    "id": "darienzo-292",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-02-27",
    "title": "Triste soledad",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Fernandez",
    "lyricist": "Roberto Caldas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Triste soledad Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "4bcyIDVslnNfUlCcf2G2T0"
  },
  {
    "id": "darienzo-293",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-30",
    "title": "Y entonces lloraras",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y entonces lloraras Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "2EMnWxB01wwqHUZhSt032H"
  },
  {
    "id": "darienzo-294",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-30",
    "title": "Lagrimas",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lagrimas Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "1w6G2jTwb4z1QVKBTBHrcM"
  },
  {
    "id": "darienzo-295",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-13",
    "title": "Dos amores",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Sureda",
    "lyricist": "Geronimo Sureda",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos amores Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "5GrfIJFV5WdR0IjgyfQTcO"
  },
  {
    "id": "darienzo-296",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-13",
    "title": "La guitarrita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La guitarrita Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "72HqHfpRAqo26CEWxQvUQz"
  },
  {
    "id": "darienzo-297",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-20",
    "title": "El penado catorce",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Magaldi / P. Noda",
    "lyricist": "Carlos Pesce",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El penado catorce Alberto Echagüe Juan D’Arienzo 47 1947",
    "spotifyTrackId": "2pJUauYk6uqIGtOpS2QZmA"
  },
  {
    "id": "darienzo-298",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-20",
    "title": "El pollo Ricardo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Fernandez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pollo Ricardo Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "6792TEHd59D3gHQ7BXVc1x"
  },
  {
    "id": "darienzo-299",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-06-19",
    "title": "Cambalache",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discépolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cambalache Alberto Echagüe Juan D’Arienzo 47 1947",
    "spotifyTrackId": "3Zmp6ZHaWtTHatxF0A8Usv"
  },
  {
    "id": "darienzo-300",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-06-19",
    "title": "Por dos caminos",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Lista",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por dos caminos Alberto Echagüe y Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "7kVYohOXYuHVDQS2DQPa08"
  },
  {
    "id": "darienzo-301",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-08-08",
    "title": "Carton junao",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carton junao Alberto Echagüe Juan D’Arienzo 47 1947",
    "spotifyTrackId": "70V6kEp42eR4sEflDZNpA3"
  },
  {
    "id": "darienzo-302",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-08-08",
    "title": "Rawson",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rawson Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "3VybbVAE4bo5eLsvsFrLPb"
  },
  {
    "id": "darienzo-303",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-09-17",
    "title": "Dios te salve m’hijo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Magaldi / Pedro Noda",
    "lyricist": "Luis A. Garcia",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dios te salve m’hijo Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-304",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-09-17",
    "title": "Muchachita del recuerdo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto de la Cruz / C. Puglisi",
    "lyricist": "C. Marin",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachita del recuerdo Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "1m5qJ3Emz8zCHG3ygWzmit"
  },
  {
    "id": "darienzo-305",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-12-17",
    "title": "El viento me cuenta cosas",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El viento me cuenta cosas Alberto Echagüe y Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "7pBrtcHOrytvzd4xjMheUU"
  },
  {
    "id": "darienzo-306",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-12-17",
    "title": "Dale, dale caballito",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dale, dale caballito Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-307",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-17",
    "title": "Lenguas de fuego",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Wais",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lenguas de fuego Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "5KJEW5E5QsOHgd3zQSYxZA"
  },
  {
    "id": "darienzo-308",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-12-17",
    "title": "Campeona",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Josefina C. Caprario",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Campeona Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "0tNDXbd0pwpCCCMkijqrM9"
  },
  {
    "id": "darienzo-309",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-17",
    "title": "Contraluz",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Susena",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Contraluz Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-310",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-17",
    "title": "Don Horacio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Horacio Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "4thd3RkfarX0VkcJum4vgU"
  },
  {
    "id": "darienzo-311",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-23",
    "title": "Ladron de sueños",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Diaz",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ladron de sueños Armando Laborde Juan D’Arienzo 47 1947",
    "spotifyTrackId": "6wK343crFsAVBA9LJdfvWi"
  },
  {
    "id": "darienzo-312",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-23",
    "title": "Felicia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Felicia Instrumental Juan D’Arienzo instr. inst. instr inst 47 1947",
    "spotifyTrackId": "23q8sUxjLhdbHbBQZ6HEjS"
  },
  {
    "id": "darienzo-313",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-02-18",
    "title": "Hotel Victoria",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Feliciano Latasa",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hotel Victoria Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "2qg8qpvEFcpENL6qjHROAL"
  },
  {
    "id": "darienzo-314",
    "trackNo": null,
    "genre": "Vals",
    "date": "1948-02-18",
    "title": "Olga",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Franciso Peña",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Olga Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "5El0WAWFjq4jjji096EMpy"
  },
  {
    "id": "darienzo-315",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1948-05-07",
    "title": "El raje",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El raje Alberto Echagüe Juan D’Arienzo 48 1948",
    "spotifyTrackId": "53TkfQsNQQG3muOv4rClaE"
  },
  {
    "id": "darienzo-316",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-05-07",
    "title": "Una y mil noches",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "O. Cuffaro / A. San Miguel",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una y mil noches Armando Laborde Juan D’Arienzo 48 1948",
    "spotifyTrackId": "62wtobcXoUKBQPj8A6Caxv"
  },
  {
    "id": "darienzo-317",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-05-07",
    "title": "El choclo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El choclo Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "674lDY7wovADkr3eAXZJSc"
  },
  {
    "id": "darienzo-318",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-15",
    "title": "Barajando",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Nicolas Vaccaro",
    "lyricist": "Eduardo Méndez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barajando Alberto Echagüe Juan D’Arienzo 48 1948",
    "spotifyTrackId": "6YDREt2Vl1nFdgFB4g8c0N"
  },
  {
    "id": "darienzo-319",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-15",
    "title": "Que apuro tengo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Bianco",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que apuro tengo Alberto Echagüe Juan D’Arienzo 48 1948",
    "spotifyTrackId": "1tIIaTFsmyex0avZZfFoOB"
  },
  {
    "id": "darienzo-320",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-15",
    "title": "Don Juan",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "Ricardo Podesta",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Juan Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "4E4TXbQNSfYAsT9GIB7PFv"
  },
  {
    "id": "darienzo-321",
    "trackNo": null,
    "genre": "Vals",
    "date": "1948-09-23",
    "title": "Eterna",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Eterna Alberto Echagüe Juan D’Arienzo 48 1948",
    "spotifyTrackId": "2qRetw4huLsDNIawvDG07R"
  },
  {
    "id": "darienzo-322",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-09-23",
    "title": "Pampa y huella",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juancito Diaz",
    "lyricist": "Hector Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pampa y huella Alberto Echagüe y Armando Laborde Juan D’Arienzo 48 1948",
    "spotifyTrackId": "5m4hwUbwLfRPq4hJXbUArX"
  },
  {
    "id": "darienzo-323",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-09-23",
    "title": "Derecho viejo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Derecho viejo Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "5N43pms351YynvPWuqi2Jm"
  },
  {
    "id": "darienzo-324",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-09-23",
    "title": "Pof Pof",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pof Pof Instrumental Juan D’Arienzo instr. inst. instr inst 48 1948",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-325",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Chichipia",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chichipia Alberto Echagüe Juan D’Arienzo 49 1949",
    "spotifyTrackId": "0HZOMkf81b2WQDztVZUjar"
  },
  {
    "id": "darienzo-326",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Vencido",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oreste Cuffaro",
    "lyricist": "Hector Gagliardi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vencido Alberto Echagüe Juan D’Arienzo 49 1949",
    "spotifyTrackId": "42Up1Vq2EFfwmuFGng2ngK"
  },
  {
    "id": "darienzo-327",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Caña",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Mónaco",
    "lyricist": "Enrique Esviza / Julián Araujo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Caña Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "5hI14MTfpuAylxD7UQk7j8"
  },
  {
    "id": "darienzo-328",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Santa madrecita",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela / Carlos Waiss",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Santa madrecita Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "5tI1ncyrQDHEzmrqvTl3T6"
  },
  {
    "id": "darienzo-329",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Delfino",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raul Portales Peralta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Delfino Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "2Vn3DcBB7oIICBPSdNSPGf"
  },
  {
    "id": "darienzo-330",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "La morocha",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "Angel Villoldo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La morocha Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "0gd8SeqP18yMLBafP6m9PB"
  },
  {
    "id": "darienzo-331",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "La payanca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Augusto P. Berto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La payanca Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "4wsB8WIc1F4fdgmIPkEuOk"
  },
  {
    "id": "darienzo-332",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-04-06",
    "title": "Pampa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pampa Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "1arm9kvi7a4zube1VT0mde"
  },
  {
    "id": "darienzo-333",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-07-26",
    "title": "Farabute",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Joaquin Barreiro",
    "lyricist": "Antonio Casciani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Farabute Alberto Echagüe Juan D’Arienzo 49 1949",
    "spotifyTrackId": "57LCrpLZE1jaafBVidVy3Y"
  },
  {
    "id": "darienzo-334",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-07-26",
    "title": "La maleva",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Pardo",
    "lyricist": "Antonio Buglione",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La maleva Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "0HEY2nta5Jwlr6KbrRvXH9"
  },
  {
    "id": "darienzo-335",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "Amarga sospecha",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fulvio Salamanca",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amarga sospecha Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "7ifXf5bau9cp7kLvwL3PS7"
  },
  {
    "id": "darienzo-336",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "Si yo la quiero",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "José A. Di Pilato",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si yo la quiero Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "4FJDnHtMz5wv7dYCE2293k"
  },
  {
    "id": "darienzo-337",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "Yuyo brujo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Bejamin Garcia",
    "lyricist": "Sos Taita",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yuyo brujo Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "66cJ5gKr3tLAUHdMe0QvEs"
  },
  {
    "id": "darienzo-338",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "As de Bastos (As de triunfo)",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Caruso",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "As de Bastos (As de triunfo) Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-339",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "Don Pacifico",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Pacifico Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "2MZmKQhiANeJ5R1DMtkXPc"
  },
  {
    "id": "darienzo-340",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-24",
    "title": "La catrera",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La catrera Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "07hIUxqUyJmrNgsur1XM6X"
  },
  {
    "id": "darienzo-341",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-12-19",
    "title": "Naci en pompeya",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Natty Paredes",
    "lyricist": "José Rótulo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Naci en pompeya Alberto Echagüe Juan D’Arienzo 49 1949",
    "spotifyTrackId": "77XwUuxRnDG4zxzmkv164d"
  },
  {
    "id": "darienzo-342",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1949-12-19",
    "title": "Un cambio te viene bien",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eladio Blanco",
    "lyricist": "Alfredo Marino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un cambio te viene bien Alberto Echagüe Juan D’Arienzo 49 1949",
    "spotifyTrackId": "4qfsB4BpjIVd35WWPgw7tO"
  },
  {
    "id": "darienzo-343",
    "trackNo": null,
    "genre": "Vals",
    "date": "1949-12-19",
    "title": "Mi cariñito",
    "singer": "Alberto Echagüe y Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "V",
    "singerShort": "Echagüe y Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo De Angelis",
    "lyricist": "José Rótulo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi cariñito Alberto Echagüe y Armando Laborde Juan D’Arienzo 49 1949",
    "spotifyTrackId": "32KQAZHE6nuyYaHv2ViyPj"
  },
  {
    "id": "darienzo-344",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1949-12-19",
    "title": "El potro",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El potro Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "21Jm0M1GnW3MgsbLVaaGSw"
  },
  {
    "id": "darienzo-345",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-12-19",
    "title": "El rey del compás",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Principe Cubano",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El rey del compás Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "5Ak6RwSHcB8PRDB8nIYu4c"
  },
  {
    "id": "darienzo-346",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-12-19",
    "title": "Union civica",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo4449",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Santa Cruz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Union civica Instrumental Juan D’Arienzo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "39eYvSUxCDdSbDH9OY5Xql"
  },
  {
    "id": "darienzo-347",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1950-05-05",
    "title": "Bandera baja",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bandera baja Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "7Hvtf4pSOkZ4vEZtr6ZSpW"
  },
  {
    "id": "darienzo-348",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-05",
    "title": "Bien pulenta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien pulenta Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "1Ga02YRZFTQRnyesYHWQFH"
  },
  {
    "id": "darienzo-349",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-05",
    "title": "Cruz Maidana",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / H. Varela",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cruz Maidana Armando Laborde Juan D’Arienzo 50 1950",
    "spotifyTrackId": "3fsCRLra48SIUvhAk7iLMn"
  },
  {
    "id": "darienzo-350",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-05",
    "title": "Canaro en Paris",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Caldarella / Alejandro Scarpino",
    "lyricist": "José Scarpino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canaro en Paris Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "4Pyk405sYaSc1ty0TUtM60"
  },
  {
    "id": "darienzo-351",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-05",
    "title": "El internado",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El internado Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "7iAatt7rrNBUfnlJXatQqU"
  },
  {
    "id": "darienzo-352",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-05",
    "title": "Sigueme, si puedes",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Caldarella",
    "lyricist": "Alejandro Scarpino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sigueme, si puedes Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-353",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-15",
    "title": "Pituca",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rogelio Fernandes",
    "lyricist": "Enrique Cadicamos",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pituca Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "0a5vxgghxjrk5q8YrrBGIr"
  },
  {
    "id": "darienzo-354",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1950-05-15",
    "title": "Me llamo como me llamo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me llamo como me llamo Armando Laborde Juan D’Arienzo 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-355",
    "trackNo": null,
    "genre": "Vals",
    "date": "1950-05-15",
    "title": "El cisne",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Anselmi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cisne Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "0qJj59d7nrhzycqhKLIuWy"
  },
  {
    "id": "darienzo-356",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-15",
    "title": "Homero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Homero Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "4Bad5aRveu5gFpk67KoLZL"
  },
  {
    "id": "darienzo-357",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1950-05-15",
    "title": "La parrillada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Elsa Pigrau",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La parrillada Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "3dmysAZ0CzJfpNbeLDUHkA"
  },
  {
    "id": "darienzo-358",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-15",
    "title": "Nueve de julio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Luis Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nueve de julio Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-359",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-28",
    "title": "Don Juan Mondiola",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Oscar Arona",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Juan Mondiola Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "2uPqbTqSEiXzzD0lUFeAqD"
  },
  {
    "id": "darienzo-360",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-28",
    "title": "Sin balurdos",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin balurdos Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-361",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-28",
    "title": "Un tango para mi vieja",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Alessio",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un tango para mi vieja Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "7H1dGrBRxVxr3NEPu1HSjp"
  },
  {
    "id": "darienzo-362",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-28",
    "title": "Precio",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Bernardo Sucher",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Precio Roberto Lemos Juan D’Arienzo 50 1950",
    "spotifyTrackId": "2AjQkSog9zaXLHdiClnC6W"
  },
  {
    "id": "darienzo-363",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-28",
    "title": "Tucumán",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Luis Padula",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tucumán Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "1qCIPWAh0L8mX5SwxzoaSr"
  },
  {
    "id": "darienzo-364",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1950-12-27",
    "title": "A puño limpio",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A puño limpio Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": "7vD4FO1pM1ouKzmyzQgDRm"
  },
  {
    "id": "darienzo-365",
    "trackNo": null,
    "genre": "Vals",
    "date": "1950-12-27",
    "title": "Desencanto",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "V",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Marinelli",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desencanto Roberto Lemos Juan D’Arienzo 50 1950",
    "spotifyTrackId": "3nveejcAcoLo0GbQ0meZ1K"
  },
  {
    "id": "darienzo-366",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-12-27",
    "title": "Quisieran verme llorar",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "F. Garcia / J. Hace / Pantoleon Mosca",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quisieran verme llorar Roberto Lemos Juan D’Arienzo 50 1950",
    "spotifyTrackId": "3ilUaTANogRYyWUwEq16Bj"
  },
  {
    "id": "darienzo-367",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-12-28",
    "title": "No vas mas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "G. Adroher",
    "lyricist": "Jorge Caroso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No vas mas Alberto Echagüe Juan D’Arienzo 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-368",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-12-28",
    "title": "Don Juan",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "Ricardo Podesta",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Juan Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "4E4TXbQNSfYAsT9GIB7PFv"
  },
  {
    "id": "darienzo-369",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-12-28",
    "title": "El marne",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El marne Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "3NHsWbePAE2vwHiGqDGkdd"
  },
  {
    "id": "darienzo-370",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-12-28",
    "title": "La bicoca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Arturo Severino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La bicoca Instrumental Juan D’Arienzo instr. inst. instr inst 50 1950",
    "spotifyTrackId": "1CdSwMHKXr54rna0R7oc1z"
  },
  {
    "id": "darienzo-371",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-12",
    "title": "Amarroto",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Cao",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amarroto Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6x69ycWF3IHXRNXQ7zUUtW"
  },
  {
    "id": "darienzo-372",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-12",
    "title": "El nene del abasto",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eladi Blanco",
    "lyricist": "Raul Mario Hormaza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El nene del abasto Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6KxMyd1pqh8JwTKyFlQMLB"
  },
  {
    "id": "darienzo-373",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1951-09-12",
    "title": "Ganzúa",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ganzúa Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "4pi4XHxxtHC10LqKRndI0c"
  },
  {
    "id": "darienzo-374",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-12",
    "title": "Se Pe Ño Po Ri Pi Ta Pa",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Se Pe Ño Po Ri Pi Ta Pa Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-375",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-12",
    "title": "La cumparsita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gerardo Matos Rodriguez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "2joB3G4b527szRVC6L5VYL"
  },
  {
    "id": "darienzo-376",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1951-09-12",
    "title": "La puñalada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La puñalada Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "4etmaoE6wyAjLNk3NdxVuW"
  },
  {
    "id": "darienzo-377",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-14",
    "title": "Este carnaval",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Caruso",
    "lyricist": "Luis Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Este carnaval Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "12Ol4Wpr2hWOOreLohLSXV"
  },
  {
    "id": "darienzo-378",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-14",
    "title": "Paciencia",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Paciencia Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6Wb0sWQUoVTNip3Vod0BNc"
  },
  {
    "id": "darienzo-379",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-14",
    "title": "Tu boca mintio (No me mires)",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Angeletti",
    "lyricist": "Antonio Longo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu boca mintio (No me mires) Roberto Lemos Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6ovLWEPr8vbFnjL7Lavw9b"
  },
  {
    "id": "darienzo-380",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-14",
    "title": "El irresistible",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lorenzo Logatti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El irresistible Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "3BZWl0KxnLgqYAMxGhKAWC"
  },
  {
    "id": "darienzo-381",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-09-14",
    "title": "Pampa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pampa Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "1arm9kvi7a4zube1VT0mde"
  },
  {
    "id": "darienzo-382",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-19",
    "title": "El hipo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Alessio",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El hipo Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "2HI390iQvQL0KsZKz6YH5V"
  },
  {
    "id": "darienzo-383",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-19",
    "title": "N.P. (No placé)",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "J. J. Riverol",
    "lyricist": "Francisco Loiacono",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "N.P. (No placé) Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-384",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1951-12-19",
    "title": "Viva mi tierra Argentina",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Boedo",
    "lyricist": "Encarnacion Gordillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viva mi tierra Argentina Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6d2ORlZypMdCf2iOQsif56"
  },
  {
    "id": "darienzo-385",
    "trackNo": null,
    "genre": "Vals",
    "date": "1951-12-19",
    "title": "No llores madre",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "V",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Lauro",
    "lyricist": "A .F. Roldan",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No llores madre Roberto Lemos Juan D’Arienzo 51 1951",
    "spotifyTrackId": "6BjEBBI6nk5VM5ZtDoDxwQ"
  },
  {
    "id": "darienzo-386",
    "trackNo": null,
    "genre": "Vals",
    "date": "1951-12-19",
    "title": "Penumbras",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "V",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Toscano",
    "lyricist": "Alfredo Roldan",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Penumbras Roberto Lemos Juan D’Arienzo 51 1951",
    "spotifyTrackId": "2joB3G4b527szRVC6L5VYL"
  },
  {
    "id": "darienzo-387",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-19",
    "title": "El simpático",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Santini",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El simpático Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "4BpiVkSYh7LaSFlqAtRvVM"
  },
  {
    "id": "darienzo-388",
    "trackNo": null,
    "genre": "Vals",
    "date": "1951-12-27",
    "title": "De vuelta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "V",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Lazzari",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De vuelta Alberto Echagüe Juan D’Arienzo 51 1951",
    "spotifyTrackId": "2TmlXO43QdZSdNb55uqruD"
  },
  {
    "id": "darienzo-389",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-27",
    "title": "Todo varon",
    "singer": "Roberto Lemos",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Lemos",
    "discType": "Shellac",
    "duration": "",
    "composer": "Elmo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todo varon Roberto Lemos Juan D’Arienzo 51 1951",
    "spotifyTrackId": "2TQNN4EWMhrqAndYN4u6R0"
  },
  {
    "id": "darienzo-390",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-27",
    "title": "Yapeyú",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Fuster",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yapeyú Instrumental Juan D’Arienzo instr. inst. instr inst 51 1951",
    "spotifyTrackId": "6XxQMSYJHkNdjKOWoXR9DM"
  },
  {
    "id": "darienzo-391",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "Sarampion",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eladio Blanco",
    "lyricist": "Raúl Hormaza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sarampion Alberto Echagüe Juan D’Arienzo 52 1952",
    "spotifyTrackId": "1CppTGpCYCuDzzoeIjdHtj"
  },
  {
    "id": "darienzo-392",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "Y siempre la misma historia",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y siempre la misma historia Alberto Echagüe Juan D’Arienzo 52 1952",
    "spotifyTrackId": "3bC6qda2dvD0VqUwCpyK5Q"
  },
  {
    "id": "darienzo-393",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "Y suma y sigue",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y suma y sigue Alberto Echagüe Juan D’Arienzo 52 1952",
    "spotifyTrackId": "2w8ehW9CAm2VRtX34hfOyz"
  },
  {
    "id": "darienzo-394",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "De corazón a corazón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Alessio",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De corazón a corazón Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-395",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "El agente de la esquina",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Alfredo Buron",
    "lyricist": "Raul Portolas Peralta",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El agente de la esquina Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "0D3oURuOdWVVb5ve8k4hVi"
  },
  {
    "id": "darienzo-396",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "Estas conmigo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Lazzari",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Estas conmigo Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "0mtKrZ6xtS7uUoXq1XMGhl"
  },
  {
    "id": "darienzo-397",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1952-08-13",
    "title": "Milonga que peina canas",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Gomez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga que peina canas Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "6Q05GeaLMDI37L3Eqdcyg7"
  },
  {
    "id": "darienzo-398",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1952-08-13",
    "title": "Se acabaron los guapos",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rafael Tuegols",
    "lyricist": "Alfredo Tropiani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Se acabaron los guapos Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "08rFdCOkcpQmIEHSJxXZZ0"
  },
  {
    "id": "darienzo-399",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1952-08-13",
    "title": "Trampa",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Trampa Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "6CYWF1BXtJHcpd97SvihOU"
  },
  {
    "id": "darienzo-400",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "El puntazo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro Junissi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El puntazo Instrumental Juan D’Arienzo instr. inst. instr inst 52 1952",
    "spotifyTrackId": "0iTI1XWOA853fCDAaEL3wl"
  },
  {
    "id": "darienzo-401",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-08-13",
    "title": "Luis Alberto",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Luis Alberto Instrumental Juan D’Arienzo instr. inst. instr inst 52 1952",
    "spotifyTrackId": "6l8AhFwkcAm269oq7kioVt"
  },
  {
    "id": "darienzo-402",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "El tigre Millán",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tigre Millán Alberto Echagüe Juan D’Arienzo 52 1952",
    "spotifyTrackId": "0mK9xvtqBn2smMWE6L7omW"
  },
  {
    "id": "darienzo-403",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1952-11-12",
    "title": "El comandante",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Basso / R. Mercado / D. Pisera",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El comandante Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "0vcVn8UxJJNVEdftrIuS1J"
  },
  {
    "id": "darienzo-404",
    "trackNo": null,
    "genre": "Vals",
    "date": "1952-11-12",
    "title": "Siempre tu en mi corazón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Maria Magdalena Cioveni – Carlos Bahr",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Siempre tu en mi corazón Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "5riUvtq1ymahaH8dfZ86Mz"
  },
  {
    "id": "darienzo-405",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "Un alma buena",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aquiles H. Aguilar",
    "lyricist": "José M. Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un alma buena Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "77TsayYhNUZ24PqMAyzmKO"
  },
  {
    "id": "darienzo-406",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "Viejo smoking",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gullermo Barbieri",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viejo smoking Armando Laborde Juan D’Arienzo 52 1952",
    "spotifyTrackId": "6yjfrPJquPanPZsF2Cc5dd"
  },
  {
    "id": "darienzo-407",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "El pollo Ricardo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis A. Feranandez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pollo Ricardo Instrumental Juan D’Arienzo instr. inst. instr inst 52 1952",
    "spotifyTrackId": "6792TEHd59D3gHQ7BXVc1x"
  },
  {
    "id": "darienzo-408",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "El romántico",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El romántico Instrumental Juan D’Arienzo instr. inst. instr inst 52 1952",
    "spotifyTrackId": "2d4LeWI6WlyZuFxeijLX8S"
  },
  {
    "id": "darienzo-409",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-12",
    "title": "Florida",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raimundo Petillo",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Florida Instrumental Juan D’Arienzo instr. inst. instr inst 52 1952",
    "spotifyTrackId": "1WeSZP1pPujdwJsjFGGxsA"
  },
  {
    "id": "darienzo-410",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-13",
    "title": "Giuseppe el cruner",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Rodriguez",
    "lyricist": "Domingo Sciaraffia",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Giuseppe el cruner Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-411",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-13",
    "title": "Gladiolo",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Echagüe",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gladiolo Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": "54oDFlG6K9w7JnLFTEAFuR"
  },
  {
    "id": "darienzo-412",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-13",
    "title": "Toma estas monedas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Toma estas monedas Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": "272ubZBSeE8f8ZjyhzR6Yi"
  },
  {
    "id": "darienzo-413",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1953-08-13",
    "title": "Mucho, mucho",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "M",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Cao",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mucho, mucho Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-414",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-13",
    "title": "Necesito tu cariño",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Necesito tu cariño Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": "5bfHhzjz1BUVBe8ImAqQ0Y"
  },
  {
    "id": "darienzo-415",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-09-18",
    "title": "Ché negrito",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Delia Leguizamon",
    "lyricist": "Roberto N. Pedroti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ché negrito Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": "2UiHtuubwnvBqtZZfqw6Id"
  },
  {
    "id": "darienzo-416",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1953-09-18",
    "title": "Peringundín",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "M",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pintin Castellanos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Peringundín Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": "3UN7n6G8i9kF1dLty0fSxi"
  },
  {
    "id": "darienzo-417",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-09-18",
    "title": "Vas muerto en la parada",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "H. Birriel",
    "lyricist": "Antonio Casciani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vas muerto en la parada Alberto Echagüe Juan D’Arienzo 53 1953",
    "spotifyTrackId": "2dlw4aMNJ00H1WSg7Txtua"
  },
  {
    "id": "darienzo-418",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-09-18",
    "title": "El curda",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "F. Garcia / J. Hace / Pantoleon Mosca",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El curda Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": "4E05ogoY5dSuZoUdbxT4Hp"
  },
  {
    "id": "darienzo-419",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-09-18",
    "title": "El purrete",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eladio Blanco",
    "lyricist": "Raul Hormaza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El purrete Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": "0pXGFt3MugGX9hR73Gbjvz"
  },
  {
    "id": "darienzo-420",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-09-18",
    "title": "Mi parejero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Miguel Montenegro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi parejero Instrumental Juan D’Arienzo instr. inst. instr inst 53 1953",
    "spotifyTrackId": "1QdGhzONdilNtlnokPcX7X"
  },
  {
    "id": "darienzo-421",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-28",
    "title": "Mirando al cielo",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cayetano Puglisie",
    "lyricist": "Poncel",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mirando al cielo Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": "4k1wLQXMgi8gV2hXjCSUnd"
  },
  {
    "id": "darienzo-422",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-28",
    "title": "Tango de la medianoche",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Venanzi",
    "lyricist": "Héctor Márco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tango de la medianoche Armando Laborde Juan D’Arienzo 53 1953",
    "spotifyTrackId": "0fNgV6YuoEfpmsuUzbYzZK"
  },
  {
    "id": "darienzo-423",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-28",
    "title": "Independencia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Bevilacqua",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Independencia Instrumental Juan D’Arienzo instr. inst. instr inst 53 1953",
    "spotifyTrackId": "4G4YOuiTrcK8GXcTyuhoqJ"
  },
  {
    "id": "darienzo-424",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-28",
    "title": "Joaquina",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Bergamino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Joaquina Instrumental Juan D’Arienzo instr. inst. instr inst 53 1953",
    "spotifyTrackId": "0PRCiZzIFHUrzTrFObn783"
  },
  {
    "id": "darienzo-425",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-04-29",
    "title": "Te odio y te quiero",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Alessio",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te odio y te quiero Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": ""
  },
  {
    "id": "darienzo-426",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-04-29",
    "title": "Ahí está",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gastón Bordería",
    "lyricist": "Miguel Brunetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ahí está Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "1Ktl0sRV7tgjmw4bLhMURx"
  },
  {
    "id": "darienzo-427",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-04-29",
    "title": "Tu boca mintio (No me mires)",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Angeletti",
    "lyricist": "Andrés Longo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu boca mintio (No me mires) Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6ovLWEPr8vbFnjL7Lavw9b"
  },
  {
    "id": "darienzo-428",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-04-29",
    "title": "El entrerriano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rosendo Mendizabal",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El entrerriano Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "7qsgMitt4I64vpj8HXY89k"
  },
  {
    "id": "darienzo-429",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1954-04-29",
    "title": "El torito",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Gregorio Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El torito Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "5bhbAUeMRe3N4s9xggq6V6"
  },
  {
    "id": "darienzo-430",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-04-29",
    "title": "Felicia",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Felicia Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "23q8sUxjLhdbHbBQZ6HEjS"
  },
  {
    "id": "darienzo-431",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-08-13",
    "title": "Canzoneta",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernique Lary",
    "lyricist": "Erma Suarez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canzoneta Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "0IeAes51ic9ZCXKdy70dk9"
  },
  {
    "id": "darienzo-432",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-08-13",
    "title": "El choclo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Villoldo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El choclo Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "674lDY7wovADkr3eAXZJSc"
  },
  {
    "id": "darienzo-433",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-09-01",
    "title": "Ché existencialista",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Martincho",
    "lyricist": "Mario Landi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ché existencialista Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "3E6zilRRng9CPjv5dB0RPc"
  },
  {
    "id": "darienzo-434",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-09-01",
    "title": "Sentimiento de calavera",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sentimiento de calavera Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "7p2XjysUhB3JLXSBepdedu"
  },
  {
    "id": "darienzo-435",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-09-01",
    "title": "El vino triste",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Miguel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El vino triste Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "0GJSNZkyU1whOb2x9LyXD5"
  },
  {
    "id": "darienzo-436",
    "trackNo": null,
    "genre": "Vals",
    "date": "1954-09-01",
    "title": "La sonrisa de mama",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo / F. Salamanca",
    "lyricist": "C. Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La sonrisa de mama Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6cYgS318cJlRNzZ29q5Q32"
  },
  {
    "id": "darienzo-437",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-02",
    "title": "Dos guitas",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan D'Arienzo",
    "lyricist": "Francisco Gorrindo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos guitas Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "2WmL20D17IRhKyG6vU47W0"
  },
  {
    "id": "darienzo-438",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-02",
    "title": "Muchachos arranquemos para el centro",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Rufino",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachos arranquemos para el centro Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "7i4kNHRdED4RhUpWiaTIvj"
  },
  {
    "id": "darienzo-439",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-02",
    "title": "Fatal y tanguera",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Angel Dominguez",
    "lyricist": "Aldo Queirolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fatal y tanguera Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6j1LCV8SDq6ykbBS7G4kVU"
  },
  {
    "id": "darienzo-440",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-02",
    "title": "Guapo y querendón",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 0,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto de la Cruz",
    "lyricist": "José B. Bazán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Guapo y querendón Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6Ggl0S4HimEojO04woOlMY"
  },
  {
    "id": "darienzo-441",
    "trackNo": null,
    "genre": "Vals",
    "date": "1954-11-02",
    "title": "Tristeza criolla",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "V",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ignacio Corsini",
    "lyricist": "Julian Centeya",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tristeza criolla Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "3UkfxftYIGCZkjQM2BycfX"
  },
  {
    "id": "darienzo-442",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-02",
    "title": "Don Orlando",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hector Varela",
    "lyricist": "Idalberto Nery",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Orlando Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "0dN2a6tpK1vy66qARxl41a"
  },
  {
    "id": "darienzo-443",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-10",
    "title": "El tarta",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jose Maria Rizzuti",
    "lyricist": "Emilio Fresedo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tarta Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "73ruq8AAgS42eJhPpTNua7"
  },
  {
    "id": "darienzo-444",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-10",
    "title": "Esta noche me emborracho",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discépolo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche me emborracho Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6Z6QWA8VdIbhkWRzU1iBtz"
  },
  {
    "id": "darienzo-445",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-10",
    "title": "Pero pobre de ellos",
    "singer": "Alberto Echagüe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Echagüe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos del Pueblo",
    "lyricist": "Dalia Leguisamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pero pobre de ellos Alberto Echagüe Juan D’Arienzo 54 1954",
    "spotifyTrackId": "6OsF1d4GQXZcMUktNuve0l"
  },
  {
    "id": "darienzo-446",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-11-10",
    "title": "La payanca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Augusto P. Berto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La payanca Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "4wsB8WIc1F4fdgmIPkEuOk"
  },
  {
    "id": "darienzo-447",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "No te vayas mi bien",
    "singer": "Armando Laborde",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Laborde",
    "discType": "Shellac",
    "duration": "",
    "composer": "Tito Cejas",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No te vayas mi bien Armando Laborde Juan D’Arienzo 54 1954",
    "spotifyTrackId": "493ZJfYvpIWBWN12ehtGLb"
  },
  {
    "id": "darienzo-448",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "Don Pacifico",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Pacifico Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "2MZmKQhiANeJ5R1DMtkXPc"
  },
  {
    "id": "darienzo-449",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "El internado",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El internado Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "7iAatt7rrNBUfnlJXatQqU"
  },
  {
    "id": "darienzo-450",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "El irresistible",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lorenzo Logatti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El irresistible Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "3BZWl0KxnLgqYAMxGhKAWC"
  },
  {
    "id": "darienzo-451",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "El marne",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El marne Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "3NHsWbePAE2vwHiGqDGkdd"
  },
  {
    "id": "darienzo-452",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "Homero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Homero Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "4Bad5aRveu5gFpk67KoLZL"
  },
  {
    "id": "darienzo-453",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-10",
    "title": "Pampa",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pampa Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "1arm9kvi7a4zube1VT0mde"
  },
  {
    "id": "darienzo-454",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-28",
    "title": "Viento Sur",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 0,
    "orchestraId": "darienzo",
    "groupId": "darienzo5054",
    "orchestraName": "Juan D’Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "F. Salamanca",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viento Sur Instrumental Juan D’Arienzo instr. inst. instr inst 54 1954",
    "spotifyTrackId": "4CBUlHWRRG0pJaW6gSrCSr"
  },
  {
    "id": "disarli-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-12-11",
    "title": "Corazón",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38878,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corazón Roberto Rufino Carlos Di Sarli disarli 39 1939",
    "spotifyTrackId": "4vObXrPKK7hSV4wIDpRIrF"
  },
  {
    "id": "disarli-2",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-12-11",
    "title": "El Retirao",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38878,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1939,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Posadas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Retirao Instrumental Carlos Di Sarli disarli instr. inst. instr inst 39 1939",
    "spotifyTrackId": "1zV5bLKslQDpgcMd4N3V83"
  },
  {
    "id": "disarli-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-02-09",
    "title": "Milonga del Sentimiento",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39824,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Barbará",
    "lyricist": "Juan Carlos Barbará",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga del Sentimiento Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "2x1Fjx37wMuWV9IfUy5lIV"
  },
  {
    "id": "disarli-4",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-02-15",
    "title": "Alma mía",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38932,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Diego Centeno",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alma mía Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "1GstC84FVAjmLC0XMvITS3"
  },
  {
    "id": "disarli-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-02-15",
    "title": "La Trilla",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38932,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Trilla Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "77lfwRj2hZgvcodIkCLpZo"
  },
  {
    "id": "disarli-6",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-02-09",
    "title": "Catamarca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38924,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Jesús Fernández Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Catamarca Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "1ZMcR2EYgPq3XMlBFGwsny"
  },
  {
    "id": "disarli-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-04-17",
    "title": "El Opio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38961,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Opio Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "3EyYxJUMvbc8JTPLlS0HQk"
  },
  {
    "id": "disarli-8",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-04-17",
    "title": "Milonga del Centenario",
    "singer": "Antonio Rodriguez Lesende",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 38961,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "M",
    "singerShort": "Lesende",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Carlos Moreno",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga del Centenario Antonio Rodriguez Lesende Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "6tIviwQWzXo8SAXepdzkpk"
  },
  {
    "id": "disarli-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-19",
    "title": "Cosas Olvidadas",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39005,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Rodio",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cosas Olvidadas Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "06tx7JVRClSFsUfMKHlMFb"
  },
  {
    "id": "disarli-10",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-19",
    "title": "El Incendio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39005,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Incendio Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "4nn5Ft4ULYUossSSScdyV3"
  },
  {
    "id": "disarli-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-07-04",
    "title": "Milonguero Viejo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39017,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Enrique Carrera Sotelo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonguero Viejo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "4jrDVXVlo2oRXPkoCwVpyl"
  },
  {
    "id": "disarli-12",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-07-04",
    "title": "Racing Club",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39017,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "Carlos Pesce",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Racing Club Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "0Zzs0bOycsD27yXRIkzEdb"
  },
  {
    "id": "disarli-13",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-08-05",
    "title": "Ausencia",
    "singer": "Agustin Volpe",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39041,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Volpe",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Gardel",
    "lyricist": "Francisco Bianco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ausencia Agustin Volpe Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "2kFAFkE3sTgTM7DwSrSEj3"
  },
  {
    "id": "disarli-14",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-08-05",
    "title": "El Jaguar",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39041,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Atilio Lombardo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Jaguar Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "4hwfGb8xeEbM8DOMGQ36SZ"
  },
  {
    "id": "disarli-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-09-23",
    "title": "El Pollo Ricardo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39086,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Alberto Fernández",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Pollo Ricardo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "0cfqTzcTjCXJcNY9ErAMOU"
  },
  {
    "id": "disarli-16",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-09-23",
    "title": "En un beso… la vida",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39086,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En un beso… la vida Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "7EgwoWapPSHbz5XMLrr3o3"
  },
  {
    "id": "disarli-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-08",
    "title": "Shusheta (El aristocrata)",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39110,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Cobián",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Shusheta (El aristocrata) Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "6PxS4O3UZkZzVMHYeCynfq"
  },
  {
    "id": "disarli-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-08",
    "title": "Volver a soñar",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39110,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Andrés Fraga",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Volver a soñar Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "0a6ZIoZrFJPM9rDpyaCLhi"
  },
  {
    "id": "disarli-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-23",
    "title": "Lo pasado pasó",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39151,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lo pasado pasó Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-20",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-23",
    "title": "Nobleza de Arrabal",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39151,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nobleza de Arrabal Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "22MdOYgcMc9qNq5PDcjzMT"
  },
  {
    "id": "disarli-21",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-12-11",
    "title": "Rosamel",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39185,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "V",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosamel Roberto Rufino Carlos Di Sarli disarli 40 1940",
    "spotifyTrackId": "6dzpuXwH6XgSyolOY6r5fm"
  },
  {
    "id": "disarli-22",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-11",
    "title": "Viviani",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39185,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1940,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viviani Instrumental Carlos Di Sarli disarli instr. inst. instr inst 40 1940",
    "spotifyTrackId": "50Wj0eBaMSmsJ7bjkJctiS"
  },
  {
    "id": "disarli-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-02-18",
    "title": "Charlemos",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39231,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Charlemos Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "2317gcrE0rlp7CjMLH1F6w"
  },
  {
    "id": "disarli-24",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-02-18",
    "title": "Pena Mulata",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39231,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastián Piana",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pena Mulata Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "62FKCWIcUmsb8Rc79yUPY0"
  },
  {
    "id": "disarli-25",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-03-06",
    "title": "Cortando camíno",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39247,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fausto Frontera",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cortando camíno Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "5PPyeLTYTYBoNeAj3ZmmGN"
  },
  {
    "id": "disarli-26",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-06",
    "title": "La Cachila",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39247,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Héctor Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Cachila Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "1MJ5ZkEl7UvrufBcJqu4Nd"
  },
  {
    "id": "disarli-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-04-18",
    "title": "El Estagiario",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39266,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Martín Lasala Álvarez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Estagiario Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "68wfTXtRymQYUKz0D6CwIM"
  },
  {
    "id": "disarli-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-04-18",
    "title": "Mi Refugio",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39266,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Cobián",
    "lyricist": "Pedro Numa Córdoba",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi Refugio Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "2xs5ShEOITrnVcsZLQAOzQ"
  },
  {
    "id": "disarli-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-20",
    "title": "Bien Frappé",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39295,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien Frappé Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "1qrxaBrgHCSalscqV2hkwQ"
  },
  {
    "id": "disarli-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-20",
    "title": "Marianito",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39295,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Salerno",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marianito Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4TFdgfoUKWv9oWAKpExoSv"
  },
  {
    "id": "disarli-31",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-06",
    "title": "Cascabelito",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39320,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Bohr",
    "lyricist": "Juan Andrés Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cascabelito Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "0M0H5DTJxiFwGkQ2ORrR12"
  },
  {
    "id": "disarli-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-06",
    "title": "Patotero Sentimental",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39331,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Jovés",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Patotero Sentimental Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "2yaWtzONEjIFYeSdSDfcSp"
  },
  {
    "id": "disarli-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-21",
    "title": "Griseta",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39331,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "José González Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Griseta Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "07thAe2Z7QqYi7UGI1Chdc"
  },
  {
    "id": "disarli-34",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-21",
    "title": "La Torcacita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39320,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martínez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Torcacita Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "1fXwQDpQVVGZRJ7iQdtA5q"
  },
  {
    "id": "disarli-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-16",
    "title": "Germaine",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39358,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto López Buchardo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Germaine Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "21f8cWXinjwXPDwxgj3Hhh"
  },
  {
    "id": "disarli-36",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-16",
    "title": "Marejada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39358,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "Daniel López Barreto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marejada Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4Xn7vsLCTWMKQN9NGlFRkb"
  },
  {
    "id": "disarli-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-08-02",
    "title": "Cuando el amor muere",
    "singer": "Carlos Acuña",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39368,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Acuña",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Malerba",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando el amor muere Carlos Acuña Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "7Msf023vh5jhFWVm77tcHV"
  },
  {
    "id": "disarli-38",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-08-02",
    "title": "Sentimiento Criollo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39368,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "Domingo Lombardi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sentimiento Criollo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "2bMomtqG1yWCCEL6haIkXz"
  },
  {
    "id": "disarli-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-03",
    "title": "Don Juan",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39431,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ernesto Ponzio",
    "lyricist": "Ricardo Podestá",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Juan Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3S9wjfnujwyVp8KANmWIjN"
  },
  {
    "id": "disarli-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-03",
    "title": "El Cielo en tus Ojos",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39431,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Francisco Bohigas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Cielo en tus Ojos Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "60HaRE1Uovgsh71wTervIQ"
  },
  {
    "id": "disarli-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-20",
    "title": "La Morocha",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39462,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Saborido",
    "lyricist": "Ángel Villoldo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Morocha Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "49cAOQ6lP0AAgjYM6IoQgG"
  },
  {
    "id": "disarli-42",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-11-21",
    "title": "La Mulateada",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39462,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio Eduardo Del Puerto",
    "lyricist": "Carlos Pesce",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Mulateada Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "5saJequ5u0FmGcYsnruAG7"
  },
  {
    "id": "disarli-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-03",
    "title": "El recodo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39491,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro Junnissi",
    "lyricist": "Armando Tagini",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El recodo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "2NVYWGs0269B67dkqDmFvT"
  },
  {
    "id": "disarli-44",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-12-03",
    "title": "Zorzal",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39491,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Dorita Zárate",
    "lyricist": "Dorita Zárate",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Zorzal Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "2SyhvAKyIGcttZS9OK50pF"
  },
  {
    "id": "disarli-45",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-11",
    "title": "El Paladín",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39539,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Paladín Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3xJnQEKKxW7QSKoAnNLJcU"
  },
  {
    "id": "disarli-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-11",
    "title": "Y Hasta el Cardo Tiene Flor",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39539,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Escobar",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y Hasta el Cardo Tiene Flor Instrumental Carlos Di Sarli disarli instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3vKEWTGr1jmZKZshBDwfEe"
  },
  {
    "id": "disarli-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-26",
    "title": "Lo mismo que antes",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39520,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ciriaco Ortiz",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lo mismo que antes Roberto Rufino Carlos Di Sarli disarli 41 1941",
    "spotifyTrackId": "20bWjhjKEm0SBsGO2FXoYq"
  },
  {
    "id": "disarli-48",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-03",
    "title": "Otra Vez Carnaval",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39520,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Otra Vez Carnaval Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "499mJ0KmujkTNounIZjwcf"
  },
  {
    "id": "disarli-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-30",
    "title": "Entre Pitada y Pitada",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39565,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Rodolfo Sciammarella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entre Pitada y Pitada Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-30",
    "title": "No está",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39565,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Bohr",
    "lyricist": "José Bohr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No está Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "7EODClCTEGrhPDN8v7Je4k"
  },
  {
    "id": "disarli-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-09",
    "title": "Al compas del corazón",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39583,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Al compas del corazón Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "4vObXrPKK7hSV4wIDpRIrF"
  },
  {
    "id": "disarli-52",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-09",
    "title": "Alzame en tus brazos",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39583,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Cruz",
    "lyricist": "Mario Battistella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alzame en tus brazos Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "605GyP6szJt0z38V8cqmFh"
  },
  {
    "id": "disarli-53",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-05-26",
    "title": "Rosa morena",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39623,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Maciel",
    "lyricist": "Héctor Pedro Blomberg",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosa morena Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "2Xg00BS3rNF21scuxD8rRO"
  },
  {
    "id": "disarli-54",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-05-26",
    "title": "Sombras del puerto",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39623,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sombras del puerto Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "3chqnXyC4xPI8xf9XPPvtc"
  },
  {
    "id": "disarli-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-23",
    "title": "Cuidado con los cincuenta",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39701,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ángel Villoldo",
    "lyricist": "Ángel Villoldo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuidado con los cincuenta Instrumental Carlos Di Sarli disarli instr. inst. instr inst 42 1942",
    "spotifyTrackId": "4bExrahhnUFa7aPK4s9gjU"
  },
  {
    "id": "disarli-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-23",
    "title": "El Amanecer",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39652,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Amanecer Instrumental Carlos Di Sarli disarli instr. inst. instr inst 42 1942",
    "spotifyTrackId": "3DSY1IF4V8FH3bFE029NAF"
  },
  {
    "id": "disarli-57",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-23",
    "title": "Junto a tu Corazón",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39652,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Francini",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Junto a tu Corazón Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "4xXYCTHq5uH3OjzqhbzIL7"
  },
  {
    "id": "disarli-58",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-07-29",
    "title": "Cuando un viejo se enamora",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39681,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando un viejo se enamora Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "2OhhBbNFSe72dNejAHxUBU"
  },
  {
    "id": "disarli-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-29",
    "title": "Necesito olvidar",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39681,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Necesito olvidar Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "288Qf5xSC4mOsWeLtkpB1d"
  },
  {
    "id": "disarli-60",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-12",
    "title": "Decime que pasó",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39701,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Nieso",
    "lyricist": "José María Suñé",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Decime que pasó Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "19eVpYFJbfhSUJvl6lgdZb"
  },
  {
    "id": "disarli-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-12",
    "title": "Mañana Zarpa un Barco",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39691,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mañana Zarpa un Barco Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "4NMEdfcg9UtWkbUuPJLf4u"
  },
  {
    "id": "disarli-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-12",
    "title": "Tarareando",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39691,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Juan José Guichandut",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tarareando Roberto Rufino Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "3HrVB0kYP29LHdDLESOaW3"
  },
  {
    "id": "disarli-63",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-09-09",
    "title": "Julian Centeya",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39764,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Canet",
    "lyricist": "Julián Centeya",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Julian Centeya Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "3B3Q4R18sdSQLU0euZrgLA"
  },
  {
    "id": "disarli-64",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-09-09",
    "title": "Rosas de otoño",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39734,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Guillermo Barbieri",
    "lyricist": "José Rial (h)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosas de otoño Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-12",
    "title": "La Cumparsita",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39780,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gerardo Matos Rodríguez",
    "lyricist": "Gerardo Matos Rodríguez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Cumparsita Instrumental Carlos Di Sarli disarli instr. inst. instr inst 42 1942",
    "spotifyTrackId": "5Vu6Jsy1TXH13YclUEeCqF"
  },
  {
    "id": "disarli-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-12",
    "title": "Va a cantar un ruiseñor",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39780,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Andrés Domenech",
    "lyricist": "Carlos Pesce",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Va a cantar un ruiseñor Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "5yIawHxa7r0KhwJ9cSWYYd"
  },
  {
    "id": "disarli-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-30",
    "title": "Nido gaucho",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39803,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nido gaucho Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "3chqnXyC4xPI8xf9XPPvtc"
  },
  {
    "id": "disarli-68",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-30",
    "title": "Organito de la Tarde",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39803,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cátulo Castillo",
    "lyricist": "José González Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Organito de la Tarde Instrumental Carlos Di Sarli disarli instr. inst. instr inst 42 1942",
    "spotifyTrackId": "0dYHQoSjVi941DzY9JYyv5"
  },
  {
    "id": "disarli-69",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-12-21",
    "title": "Estampa Federal",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39818,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Estampa Federal Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "2fwM80Rmt8gVGhKg943E8B"
  },
  {
    "id": "disarli-70",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-21",
    "title": "Volver a vernos",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": 39818,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Joaquín Mora",
    "lyricist": "Luis Castiñeira",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Volver a vernos Alberto Podestá Carlos Di Sarli disarli 42 1942",
    "spotifyTrackId": "4YcLkejJ64i8QAOTKNuYxf"
  },
  {
    "id": "disarli-71",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-05",
    "title": "Como se hace un tango",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo Gallucci",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como se hace un tango Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "1WhmgabhZSPIo4r7b4KM5S"
  },
  {
    "id": "disarli-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-05",
    "title": "Cornetín",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cornetín Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "5s8ZzRCPtKS9Mh3GpPibfG"
  },
  {
    "id": "disarli-73",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-03-05",
    "title": "Un día llegará",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un día llegará Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "0IN23KBoqGdlgjRTGCas5R"
  },
  {
    "id": "disarli-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-17",
    "title": "Adiós te vas",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Egidio Pittaluga",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós te vas Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "6PQK9tFiQs2urh18tOJp95"
  },
  {
    "id": "disarli-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-17",
    "title": "Canta Pajarito",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canta Pajarito Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "4EkBrRpSBMvyoiwNrW3buP"
  },
  {
    "id": "disarli-76",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-17",
    "title": "Pa´los muchachos",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Ranieri",
    "lyricist": "Julián Centeya",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pa´los muchachos Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "1goRWB2CsZP2iYeBgAhWjy"
  },
  {
    "id": "disarli-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-05",
    "title": "Bar Exposición",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Teisseire",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bar Exposición Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "1dvmFi5PbhglSGVDLRMZDG"
  },
  {
    "id": "disarli-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-05",
    "title": "Nueve Puntos",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nueve Puntos Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "3fhjVq4bilVZPc0QSe9pPy"
  },
  {
    "id": "disarli-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-05",
    "title": "Si tu quisieras",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si tu quisieras Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "0iNy0WNTJWJtnILM9gxxFK"
  },
  {
    "id": "disarli-80",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-14",
    "title": "Don José María",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rosendo Mendizábal",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don José María Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "7xKuiHUuEnqq3Xij1YckyR"
  },
  {
    "id": "disarli-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-28",
    "title": "Anselmo Acuña el Resero",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Félix Laurenz",
    "lyricist": "Luis Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Anselmo Acuña el Resero Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "4uLbLUZ9cxvXvKuMnkBxAS"
  },
  {
    "id": "disarli-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-14",
    "title": "Zorro Plateado",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Charlo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Zorro Plateado Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-05",
    "title": "La viruta",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La viruta Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "0BlGRK9pkkENjV2UlCrt2n"
  },
  {
    "id": "disarli-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-05",
    "title": "Navegante",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jaime Yanin",
    "lyricist": "José Horacio Staffolani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Navegante Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "499mJ0KmujkTNounIZjwcf"
  },
  {
    "id": "disarli-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-07",
    "title": "Ensueños",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Brighenti",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ensueños Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "6ZbDlHgIdeb5wkcrT6YZPK"
  },
  {
    "id": "disarli-86",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-07",
    "title": "Tristeza marina",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tristeza marina Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "4qWINpMgXj9TCEkpEs33fH"
  },
  {
    "id": "disarli-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-07",
    "title": "Verdemar",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Verdemar Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "02sIAUW0IYXGkLhPoPqRLu"
  },
  {
    "id": "disarli-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-07",
    "title": "Yo soy de San Telmo",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo Gallucci",
    "lyricist": "Victorino Velázquez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo soy de San Telmo Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "61K6nr8KW1btn9MLSTxdcQ"
  },
  {
    "id": "disarli-89",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-04",
    "title": "A mi me llaman Juan Tango",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Tito Ribero",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A mi me llaman Juan Tango Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "2L81zNLIrVR3M2l011KKG5"
  },
  {
    "id": "disarli-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-04",
    "title": "El jaguel",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Posadas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El jaguel Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "5ccZSBCZP1s6HB1XoBV5fX"
  },
  {
    "id": "disarli-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-04",
    "title": "Los Muñequitos (San Fernando)",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Los Muñequitos (San Fernando) Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-04",
    "title": "Todo",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todo Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "4YcLkejJ64i8QAOTKNuYxf"
  },
  {
    "id": "disarli-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "Boedo y San Juan",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadícamo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Boedo y San Juan Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "0eOxoWhtzsT85fu6M0x8qq"
  },
  {
    "id": "disarli-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "Esta noche de luna",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José García",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche de luna Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "7EODClCTEGrhPDN8v7Je4k"
  },
  {
    "id": "disarli-95",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-12-17",
    "title": "Maldonado",
    "singer": "Roberto Rufino",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Rufino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Mastra",
    "lyricist": "Alberto Mastra",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Maldonado Roberto Rufino Carlos Di Sarli disarli 43 1943",
    "spotifyTrackId": "0lp41oUchEQC2gDkHBfvdZ"
  },
  {
    "id": "disarli-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "Tierra negra",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Noli",
    "lyricist": "Francisco Bastardi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tierra negra Instrumental Carlos Di Sarli disarli instr. inst. instr inst 43 1943",
    "spotifyTrackId": "3nYhRsLPUdBttAXIn6wo3T"
  },
  {
    "id": "disarli-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-13",
    "title": "Cero al as",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo Gallucci",
    "lyricist": "Francisco Bohigas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cero al as Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "5fzha9qtMOoAuJcq5TySKW"
  },
  {
    "id": "disarli-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-13",
    "title": "Nada",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nada Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "7vjUjwgXXKZcYAeXaSYlOl"
  },
  {
    "id": "disarli-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-13",
    "title": "Otra Noche",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Rodolfo Sciammarella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Otra Noche Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "1ZWlCGKkdE32clU91khRP0"
  },
  {
    "id": "disarli-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-13",
    "title": "Que solo estoy",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Raúl Kaplún",
    "lyricist": "Roberto Miró",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que solo estoy Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "3U2MOBLnN53BEl4hp0TV8p"
  },
  {
    "id": "disarli-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-24",
    "title": "Llueve otra vez",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Juan José Guichandut",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llueve otra vez Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "4G5RV6sgf9BTXQpfaXeRyJ"
  },
  {
    "id": "disarli-102",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-24",
    "title": "Vamos",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Feliciano Brunelli",
    "lyricist": "Rodolfo Sciammarella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vamos Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "1jTNePsKleerAElavsmyqu"
  },
  {
    "id": "disarli-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-11",
    "title": "La capilla blanca",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La capilla blanca Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "7lTFRZpGyDZiPIlHttxBRe"
  },
  {
    "id": "disarli-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-11",
    "title": "Milonguero Viejo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Enrique Carrera Sotelo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonguero Viejo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 44 1944",
    "spotifyTrackId": "4jrDVXVlo2oRXPkoCwVpyl"
  },
  {
    "id": "disarli-105",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-20",
    "title": "Champagne Tango",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Aróztegui",
    "lyricist": "Pascual Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Champagne Tango Instrumental Carlos Di Sarli disarli instr. inst. instr inst 44 1944",
    "spotifyTrackId": "26OUWNd0T3F9IDJOT5lE5J"
  },
  {
    "id": "disarli-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-20",
    "title": "Motivo sentimental",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Brameri",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Motivo sentimental Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "6CZV9MctP5H9tFOXslEEYN"
  },
  {
    "id": "disarli-107",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-20",
    "title": "Lloran las campanas",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Suárez Villanueva",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lloran las campanas Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": "1Kc1IK5cjcc0Jk67qNfr1q"
  },
  {
    "id": "disarli-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-08",
    "title": "Royal Pigall",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "José González Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Royal Pigall Instrumental Carlos Di Sarli disarli instr. inst. instr inst 44 1944",
    "spotifyTrackId": "54mYblonOBdcNaH9sY57eB"
  },
  {
    "id": "disarli-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-08",
    "title": "Tu el cielo y tu",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Canaro",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu el cielo y tu Alberto Podestá Carlos Di Sarli disarli 44 1944",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-08",
    "title": "Un lamento",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Graciano De Leone",
    "lyricist": "Eugenio Cárdenas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un lamento Instrumental Carlos Di Sarli disarli instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0akbT5wlEy4w36RTsFG1Jl"
  },
  {
    "id": "disarli-111",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-03",
    "title": "Que no sepan las estrellas",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Ranieri",
    "lyricist": "Alfredo Faustino Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que no sepan las estrellas Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "2UhqShRsq49ROKouehPArm"
  },
  {
    "id": "disarli-112",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-03",
    "title": "Rodriguez Peña",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rodriguez Peña Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "6C8ORRQB9Wbvk25BD256ks"
  },
  {
    "id": "disarli-113",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-20",
    "title": "El Ingeniero",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro Junnissi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Ingeniero Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "0mHqyvRahS8Pio2m4SamJ1"
  },
  {
    "id": "disarli-114",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-20",
    "title": "Porteño y bailarin",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Porteño y bailarin Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "64LeEq2HzohpVUde6vmGR8"
  },
  {
    "id": "disarli-115",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-08",
    "title": "Solamente ella",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Solamente ella Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "7Af5FT2MdhcUq6xBOePTB7"
  },
  {
    "id": "disarli-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-08",
    "title": "Tus labios me dirán",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Brameri",
    "lyricist": "Héctor Negro",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tus labios me dirán Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "1c8cta9IXHoQcPGOXIz1y6"
  },
  {
    "id": "disarli-117",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-23",
    "title": "Soy el Cantor de la Orquesta",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Gobbi",
    "lyricist": "Osvaldo Bruzzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy el Cantor de la Orquesta Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "1aA1l8gl59n5y6y5ha4Ivy"
  },
  {
    "id": "disarli-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-23",
    "title": "Vea Vea",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vea Vea Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-119",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-04",
    "title": "Barba de choclo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "Carlos Pesce",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barba de choclo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "0G4xUIFynfCEuyEtAuHul9"
  },
  {
    "id": "disarli-120",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-04",
    "title": "Vieja luna",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo Gallucci",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vieja luna Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "7rj8hNriaDhKdDfv3S7AzF"
  },
  {
    "id": "disarli-121",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-07-05",
    "title": "Con alma y vida",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "M",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con alma y vida Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "09KbczoHjd5Tj3ifx64C6c"
  },
  {
    "id": "disarli-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-05",
    "title": "Ojos Negros",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "Pedro Numa Córdoba",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ojos Negros Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "3RpRtK2ZHjRZwDHhfQkLtV"
  },
  {
    "id": "disarli-123",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-05",
    "title": "Un tango y nada más",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Lacava",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un tango y nada más Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "4dlKwHlm1eG8q2Z2Rv2sDa"
  },
  {
    "id": "disarli-124",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-31",
    "title": "Hoy al Recordarla",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Canet",
    "lyricist": "José Canet",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hoy al Recordarla Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "1ZrWZdj3tQU2l7OBZm3O85"
  },
  {
    "id": "disarli-125",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-29",
    "title": "A la gran muñeca",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jesús Ventura",
    "lyricist": "Miguel Osés",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la gran muñeca Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "6XEOvOFeKxai6BEuqxfsBE"
  },
  {
    "id": "disarli-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-29",
    "title": "Siete Palabras",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Maglio",
    "lyricist": "Alfredo Bigeschi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Siete Palabras Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "32t7LfoKthAc3JjGXij9tb"
  },
  {
    "id": "disarli-127",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-08-29",
    "title": "Sueño De Juventud",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "V",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discepolo",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sueño De Juventud Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "1ZqgJIrJGwxcSauUzrf0i2"
  },
  {
    "id": "disarli-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-29",
    "title": "Yo",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Juan José Guichandut",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "0eWIhbw5BZLBi8MQmzk7Zb"
  },
  {
    "id": "disarli-129",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-30",
    "title": "Tinta Verde",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tinta Verde Instrumental Carlos Di Sarli disarli instr. inst. instr inst 45 1945",
    "spotifyTrackId": "1JM5HcfzdpegYcqSSCq6rY"
  },
  {
    "id": "disarli-130",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-30",
    "title": "Tu íntimo secreto",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Graciano Gómez",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu íntimo secreto Jorge Durán Carlos Di Sarli disarli 45 1945",
    "spotifyTrackId": "0h84pmxqVqAa4pAso3NaFh"
  },
  {
    "id": "disarli-131",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-31",
    "title": "Cosas de tango",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Tito Ribero",
    "lyricist": "Rodolfo Taboada",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cosas de tango Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "7janv5gb1v0SbYGj1sesOB"
  },
  {
    "id": "disarli-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-31",
    "title": "Así era mi novia",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así era mi novia Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "6EcjgbuMjSeWPpym7otfh6"
  },
  {
    "id": "disarli-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-29",
    "title": "Duelo Criollo",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Rezzano",
    "lyricist": "Lito Bayardo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Duelo Criollo Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "6YsrpLUflZp080cYv9RGHK"
  },
  {
    "id": "disarli-134",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-29",
    "title": "El Pollo Ricardo",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Alberto Fernández",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Pollo Ricardo Instrumental Carlos Di Sarli disarli instr. inst. instr inst 46 1946",
    "spotifyTrackId": "0cfqTzcTjCXJcNY9ErAMOU"
  },
  {
    "id": "disarli-135",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-03",
    "title": "Para qué te quiero tanto",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Larenza",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Para qué te quiero tanto Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "0nYXp7z0nd078KUptuNahU"
  },
  {
    "id": "disarli-136",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-09",
    "title": "El Distinguido Ciudadano",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Peregrino Paulos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Distinguido Ciudadano Instrumental Carlos Di Sarli disarli instr. inst. instr inst 46 1946",
    "spotifyTrackId": "3lEQVg5vHM7gB06c5OqLSU"
  },
  {
    "id": "disarli-137",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-06",
    "title": "Clavel del Aire",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan de Dios Filiberto",
    "lyricist": "Fernán Silva Valdés",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Clavel del Aire Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "5kCmTPIZ4FYCbpzxDIMmSg"
  },
  {
    "id": "disarli-138",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-06",
    "title": "Mañana No Estarás",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ariel Gessaghi",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mañana No Estarás Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "3uqpmLNrDnCzqHp2aXQbpv"
  },
  {
    "id": "disarli-139",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-01",
    "title": "El Caburé",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arturo De Bassi",
    "lyricist": "Roberto Lino Cayol",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Caburé Instrumental Carlos Di Sarli disarli instr. inst. instr inst 46 1946",
    "spotifyTrackId": "2OR6wY1UQKIZpqayGmGGGM"
  },
  {
    "id": "disarli-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-01",
    "title": "No Esperaba Verte Más",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Dorita Zárate",
    "lyricist": "Dorita Zárate",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No Esperaba Verte Más Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "09u72GLPFC41pBlf41E1wx"
  },
  {
    "id": "disarli-141",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-08-29",
    "title": "Acuerdate de Mi",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "V",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Cucci",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Acuerdate de Mi Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "4CuKqmfUJvQTCXxVXcFase"
  },
  {
    "id": "disarli-142",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-29",
    "title": "Gracias",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Elías Randal",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gracias Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "6xB98G46KQMmTpiXiTIWaK"
  },
  {
    "id": "disarli-143",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-05",
    "title": "El once",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Fresedo",
    "lyricist": "Emilio Fresedo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El once Instrumental Carlos Di Sarli disarli instr. inst. instr inst 46 1946",
    "spotifyTrackId": "6t4qIj4E7JPiEeOADMj8Ls"
  },
  {
    "id": "disarli-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-05",
    "title": "La vida me engañó",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfonso Casini",
    "lyricist": "Marvil (Elizardo Martínez Vilas)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La vida me engañó Jorge Durán Carlos Di Sarli disarli 46 1946",
    "spotifyTrackId": "1ITLGOYmPtOyXJwdDspVcP"
  },
  {
    "id": "disarli-145",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-01-14",
    "title": "Comme il faut",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Gabriel Clausi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Comme il faut Instrumental Carlos Di Sarli disarli instr. inst. instr inst 47 1947",
    "spotifyTrackId": "6yExm3GjHCanatjCG7auVT"
  },
  {
    "id": "disarli-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-01-14",
    "title": "Didí",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Didí Instrumental Carlos Di Sarli disarli instr. inst. instr inst 47 1947",
    "spotifyTrackId": "1mh5Q75lNDc1cB9JUt5tBU"
  },
  {
    "id": "disarli-147",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-01-14",
    "title": "Otra vez carnaval",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Di Sarli",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Otra vez carnaval Jorge Durán Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "499mJ0KmujkTNounIZjwcf"
  },
  {
    "id": "disarli-148",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-01-14",
    "title": "Pueblera",
    "singer": "Jorge Durán",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Durán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bonavena",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pueblera Jorge Durán Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "0mkD2gU6rc82SMhIm93Yk2"
  },
  {
    "id": "disarli-149",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-24",
    "title": "El Pollito",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Pollito Instrumental Carlos Di Sarli disarli instr. inst. instr inst 47 1947",
    "spotifyTrackId": "2HXRWqc0Nm8TPnSSoXq5pW"
  },
  {
    "id": "disarli-150",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-24",
    "title": "Por el camino",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Bohr",
    "lyricist": "José González Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por el camino Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "2LhajoX8dXJ8rGt9olGYO0"
  },
  {
    "id": "disarli-151",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-08",
    "title": "Déjame no quiero verte nunca mas",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "Ivo Pelay",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Déjame no quiero verte nunca mas Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-152",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-08",
    "title": "La Racha",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Racha Instrumental Carlos Di Sarli disarli instr. inst. instr inst 47 1947",
    "spotifyTrackId": "57HXnNPN40M1DmLLH95lWx"
  },
  {
    "id": "disarli-153",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-28",
    "title": "La Canción mas triste",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Munné",
    "lyricist": "Roberto Lambertucci",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Canción mas triste Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "6bYvpmRdmHhAhXG2yrQxfq"
  },
  {
    "id": "disarli-154",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-28",
    "title": "Soy aquel viajero",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Grané",
    "lyricist": "Justo Ricardo Thompson",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy aquel viajero Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "34xIff5OiOvCz4KxqZnQWh"
  },
  {
    "id": "disarli-155",
    "trackNo": null,
    "genre": "Vals",
    "date": "1947-09-26",
    "title": "De vuelta",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "V",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Lazzari",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De vuelta Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": "6obIHkzJZiepE4CMHGUNEl"
  },
  {
    "id": "disarli-156",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-09-26",
    "title": "Dinero dinero",
    "singer": "Alberto Podestá",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Podestá",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dinero dinero Alberto Podestá Carlos Di Sarli disarli 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "disarli-157",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-22",
    "title": "La novia del mar",
    "singer": "Oscar Serpa",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Serpa",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Ranieri",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La novia del mar Oscar Serpa Carlos Di Sarli disarli 48 1948",
    "spotifyTrackId": "03Y03mDwi59vXHpHXgTNTl"
  },
  {
    "id": "disarli-158",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-22",
    "title": "Los trienta y tres orientales",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "disarli",
    "groupId": "disarli3948",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Mazzeo",
    "lyricist": "Arturo Rodríguez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Los trienta y tres orientales Instrumental Carlos Di Sarli disarli instr. inst. instr inst 48 1948",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-03-07",
    "title": "Comme il faut",
    "singer": "Instrumental",
    "discMatrix": "9326",
    "publisher": "Odeon",
    "discNo": 7160,
    "orchestraId": "troilo",
    "groupId": "troilo38",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Gabriel Clausi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Comme il faut Instrumental Anibal Troilo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "6LU5TAK9fMnVf0REwR1iNU"
  },
  {
    "id": "troilo-2",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-03-07",
    "title": "Tinta verde",
    "singer": "Instrumental",
    "discMatrix": "9327",
    "publisher": "Odeon",
    "discNo": 7160,
    "orchestraId": "troilo",
    "groupId": "troilo38",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1938,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustin Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tinta verde Instrumental Anibal Troilo instr. inst. instr inst 38 1938",
    "spotifyTrackId": "03ZXDPUTH7cH7LZxqqibid"
  },
  {
    "id": "troilo-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-04",
    "title": "Cachirulo",
    "singer": "Instrumental",
    "discMatrix": "39825",
    "publisher": "RCA Victor",
    "discNo": 39256,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Cafiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cachirulo Instrumental Anibal Troilo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "58ZoLfWvxvj38igjmyI58Q"
  },
  {
    "id": "troilo-4",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-03-04",
    "title": "Mano brava",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39823",
    "publisher": "RCA Victor",
    "discNo": 39246,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Buzón",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mano brava Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "0FvYg1ALhJsk7dcM7EvXaR"
  },
  {
    "id": "troilo-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-04",
    "title": "Toda mi vida",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39824",
    "publisher": "RCA Victor",
    "discNo": 39256,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Toda mi vida Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "4vBEMSZm65qWh25GjqK4zi"
  },
  {
    "id": "troilo-6",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-04",
    "title": "Yo soy el tango",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39822",
    "publisher": "RCA Victor",
    "discNo": 39246,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo soy el tango Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "11FUgxNBhLRPFVUzQgLpqQ"
  },
  {
    "id": "troilo-7",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-04-16",
    "title": "Con toda la voz que tengo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39852",
    "publisher": "RCA Victor",
    "discNo": 39265,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con toda la voz que tengo Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "1uj6eZGOUIEY4LD6jDMMOB"
  },
  {
    "id": "troilo-8",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-04-16",
    "title": "Te aconsejo que me olvides",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39853",
    "publisher": "RCA Victor",
    "discNo": 39299,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Jorge Curi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te aconsejo que me olvides Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "2DU59ZfMS2OXyxU9tLUdHr"
  },
  {
    "id": "troilo-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-28",
    "title": "Pájaro ciego",
    "singer": "Francisco Fiorentino y Amadeo Mandarino",
    "discMatrix": "39881",
    "publisher": "RCA Victor",
    "discNo": 39299,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino y Mandarino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bonavena",
    "lyricist": "Lito Bayardo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pájaro ciego Francisco Fiorentino y Amadeo Mandarino Anibal Troilo 41 1941",
    "spotifyTrackId": "5pXEHViQrHs3lolC1IMIPI"
  },
  {
    "id": "troilo-10",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-28",
    "title": "Tabernero (El tabernero)",
    "singer": "Francisco Fiorentino",
    "discMatrix": "39880",
    "publisher": "RCA Victor",
    "discNo": 39229,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Fausto Frontera y Miguel Cafre",
    "lyricist": "Raúl Costa Oliveri",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tabernero (El tabernero) Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-17",
    "title": "El bulín de la calle Ayacucho",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59523",
    "publisher": "RCA Victor",
    "discNo": 39318,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Servidio y Luis Servidio",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El bulín de la calle Ayacucho Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "3goN7HstaSy4uVk9978yT2"
  },
  {
    "id": "troilo-12",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-17",
    "title": "Milongueando en el cuarenta",
    "singer": "Instrumental",
    "discMatrix": "59524",
    "publisher": "RCA Victor",
    "discNo": 39318,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milongueando en el cuarenta Instrumental Anibal Troilo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "5feYXO6Ks7b5XPXdUmiaec"
  },
  {
    "id": "troilo-13",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-11",
    "title": "Guapeando",
    "singer": "Instrumental",
    "discMatrix": "59571",
    "publisher": "RCA Victor",
    "discNo": 39323,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Larenza",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Guapeando Instrumental Anibal Troilo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4Gx6V2iAfhI0NutgHdgnBc"
  },
  {
    "id": "troilo-14",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-11",
    "title": "Una carta",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59572",
    "publisher": "RCA Victor",
    "discNo": 39323,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una carta Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "67FnP0GgDI9Ix84AKjSdhh"
  },
  {
    "id": "troilo-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-18",
    "title": "Cordón de oro",
    "singer": "Instrumental",
    "discMatrix": "59584",
    "publisher": "RCA Victor",
    "discNo": 39348,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Posadas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cordón de oro Instrumental Anibal Troilo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4sg4Sy6HVsztEV9DzccKcE"
  },
  {
    "id": "troilo-16",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-18",
    "title": "En esta tarde gris",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59583",
    "publisher": "RCA Victor",
    "discNo": 39348,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En esta tarde gris Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "0fBDpps9YLRZyzdpucOuvx"
  },
  {
    "id": "troilo-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-08",
    "title": "El cuarteador",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59765",
    "publisher": "RCA Victor",
    "discNo": 39403,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadícamo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cuarteador Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "3nk2EtyQ5jBmyTTlEmEtfu"
  },
  {
    "id": "troilo-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-08",
    "title": "Total pa’ qué sirvo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59764",
    "publisher": "RCA Victor",
    "discNo": 39403,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Total pa’ qué sirvo Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "573nqR5c3Fn70yHdA3gIr2"
  },
  {
    "id": "troilo-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-09",
    "title": "Cautivo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59822",
    "publisher": "RCA Victor",
    "discNo": 39460,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Egidio Pittaluga",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cautivo Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "7kdAEkTCkIa19b2agCA9jL"
  },
  {
    "id": "troilo-20",
    "trackNo": null,
    "genre": "Arr. en tango",
    "date": "1941-10-09",
    "title": "Maragata",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59820",
    "publisher": "RCA Victor",
    "discNo": 39434,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Martino",
    "lyricist": "Francisco Martino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Maragata Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "64gRxOmeeBLmEawdJ1e2e9"
  },
  {
    "id": "troilo-21",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-10-09",
    "title": "Tu diagnóstico",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59821",
    "publisher": "RCA Victor",
    "discNo": 39434,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "V",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Betinotti",
    "lyricist": "José Betinotti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu diagnóstico Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "3cjzzktmwqc7pvgtogW6bI"
  },
  {
    "id": "troilo-22",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-23",
    "title": "El tamango",
    "singer": "Instrumental",
    "discMatrix": "59846",
    "publisher": "RCA Victor",
    "discNo": 39460,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Posadas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tamango Instrumental Anibal Troilo instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3ZoE1YXa4G6UOjljZnPvac"
  },
  {
    "id": "troilo-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-23",
    "title": "No le digas que la quiero",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59845",
    "publisher": "RCA Victor",
    "discNo": 39487,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Alberto Vacarezza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No le digas que la quiero Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "0hSBJy4zgioLPKzMsidz4e"
  },
  {
    "id": "troilo-24",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-23",
    "title": "Tinta roja",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59844",
    "publisher": "RCA Victor",
    "discNo": 39487,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastián Piana",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tinta roja Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "3PzPsSTCZ7wirA4CqV2RrO"
  },
  {
    "id": "troilo-25",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-11-21",
    "title": "Del tiempo guapo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59889",
    "publisher": "RCA Victor",
    "discNo": 39502,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Fiorentino",
    "lyricist": "Marcelo De La Ferrere",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Del tiempo guapo Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "4JoiiHRqPTwRzv7D5geUva"
  },
  {
    "id": "troilo-26",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-21",
    "title": "Sencillo y compadre",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59888",
    "publisher": "RCA Victor",
    "discNo": 39502,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1941,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sencillo y compadre Francisco Fiorentino Anibal Troilo 41 1941",
    "spotifyTrackId": "5jnGvhhbPecn085eAsG3HK"
  },
  {
    "id": "troilo-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-08",
    "title": "C.T.V.",
    "singer": "Instrumental",
    "discMatrix": "59968",
    "publisher": "RCA Victor",
    "discNo": 39519,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "C.T.V. Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "2ZzcWzSeJonp1Z4KTfBbgX"
  },
  {
    "id": "troilo-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-08",
    "title": "Malena",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59967",
    "publisher": "RCA Victor",
    "discNo": 39519,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Malena Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "2RaBI5dqX3IGHtkkwKDPAE"
  },
  {
    "id": "troilo-28-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-08",
    "title": "Malena",
    "singer": "Francisco Fiorentino",
    "discMatrix": "59967/1",
    "publisher": "RCA Victor",
    "discNo": 39519,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Malena Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "2RaBI5dqX3IGHtkkwKDPAE"
  },
  {
    "id": "troilo-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-16",
    "title": "Mi castigo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69660",
    "publisher": "RCA Victor",
    "discNo": 39582,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio César Sanders",
    "lyricist": "César Vedani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi castigo Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "620IxfFw43Ky2O58CMdjvA"
  },
  {
    "id": "troilo-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-16",
    "title": "Pa’ que bailen los muchachos",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69662",
    "publisher": "RCA Victor",
    "discNo": 39579,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pa’ que bailen los muchachos Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "5iSRMY4Lj9DJs2viKleQs5"
  },
  {
    "id": "troilo-31",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-04-16",
    "title": "Papá Baltasar",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69661",
    "publisher": "RCA Victor",
    "discNo": 39579,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastián Piana",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Papá Baltasar Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "1ifum9NGgmD9i58T1GXnAR"
  },
  {
    "id": "troilo-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-12",
    "title": "Colorao colorao",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69741",
    "publisher": "RCA Victor",
    "discNo": 39627,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Acuña",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Colorao colorao Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-12",
    "title": "Fueye",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69739",
    "publisher": "RCA Victor",
    "discNo": 39630,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Charlo",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fueye Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "01afMPXDjBjdCPF8MLtmhq"
  },
  {
    "id": "troilo-34",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-06-12",
    "title": "Un placer",
    "singer": "Instrumental",
    "discMatrix": "69740",
    "publisher": "RCA Victor",
    "discNo": 39630,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Romeo",
    "lyricist": "Juan Andrés Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un placer Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "21Z9VMjjLnv9E4ocMfVJMl"
  },
  {
    "id": "troilo-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-15",
    "title": "Los mareados",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69758",
    "publisher": "RCA Victor",
    "discNo": 39637,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Cobian",
    "lyricist": "Enrique Cadicamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Los mareados Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "39zJhG81PIxwLc5bDvlBHT"
  },
  {
    "id": "troilo-36",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-15",
    "title": "Soy muchacho de la guardia",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69756",
    "publisher": "RCA Victor",
    "discNo": 39637,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Irusta",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy muchacho de la guardia Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "7uAWqBbxWlL9BvJdii2ngM"
  },
  {
    "id": "troilo-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-15",
    "title": "Suerte loca",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69757",
    "publisher": "RCA Victor",
    "discNo": 39627,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Suerte loca Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "6BrJaRUleqnGY0XDMZxlmz"
  },
  {
    "id": "troilo-38",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-07-23",
    "title": "Acordándome de vos",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69824",
    "publisher": "RCA Victor",
    "discNo": 39675,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Acordándome de vos Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "5ALAgik86kAnSeScBL6NSl"
  },
  {
    "id": "troilo-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-23",
    "title": "La tablada",
    "singer": "Instrumental",
    "discMatrix": "69825",
    "publisher": "RCA Victor",
    "discNo": 39675,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La tablada Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "10cuOA3ltMihM7HxHSQnRa"
  },
  {
    "id": "troilo-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-01",
    "title": "El encopao",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69909",
    "publisher": "RCA Victor",
    "discNo": 39699,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El encopao Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "1EeMpfI1s18HLviCxwZ2ly"
  },
  {
    "id": "troilo-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-01",
    "title": "Lejos de Buenos Aires",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69908",
    "publisher": "RCA Victor",
    "discNo": 39699,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Suárez Villanueva",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lejos de Buenos Aires Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "26c2tcaRX3TdBPkg2oeqGf"
  },
  {
    "id": "troilo-42",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-09-10",
    "title": "Pedacito de cielo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69924",
    "publisher": "RCA Victor",
    "discNo": 39718,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "V",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Francini y Héctor Stamponi",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pedacito de cielo Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "2l2MruSzdLyxtQ7Wl21e6X"
  },
  {
    "id": "troilo-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-18",
    "title": "No te apures Carablanca",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69937",
    "publisher": "RCA Victor",
    "discNo": 39744,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Garza",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No te apures Carablanca Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "3RXpBU83ARwYDqWnqQwqGi"
  },
  {
    "id": "troilo-44",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-18",
    "title": "Tristezas de la calle Corrientes",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69935",
    "publisher": "RCA Victor",
    "discNo": 39718,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tristezas de la calle Corrientes Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "2mv08Yx0XARKrgcgzBqmej"
  },
  {
    "id": "troilo-45",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-10-09",
    "title": "Ficha de oro",
    "singer": "Francisco Fiorentino",
    "discMatrix": "69987",
    "publisher": "RCA Victor",
    "discNo": 39744,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carmelo Di Napoli",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ficha de oro Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "0PyUjGxa14e5c6eb0lCW8A"
  },
  {
    "id": "troilo-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-09",
    "title": "La maleva",
    "singer": "Instrumental",
    "discMatrix": "69988",
    "publisher": "RCA Victor",
    "discNo": 39790,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Buglione",
    "lyricist": "Mario Pardo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La maleva Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "6FMB8QYYS8M1hLVWWXVukN"
  },
  {
    "id": "troilo-47",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-10-22",
    "title": "De pura cepa",
    "singer": "Instrumental",
    "discMatrix": "84020",
    "publisher": "RCA Victor",
    "discNo": 39790,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "M",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Ceglie",
    "lyricist": "Osvaldo Sosa Cordero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De pura cepa Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "0Q6wUX3LgDBGzMKEmaBmDU"
  },
  {
    "id": "troilo-48",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-22",
    "title": "El chupete",
    "singer": "Instrumental",
    "discMatrix": "84019",
    "publisher": "RCA Victor",
    "discNo": 39771,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ricardo Gaudenzio",
    "lyricist": "Antonio Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El chupete Instrumental Anibal Troilo instr. inst. instr inst 42 1942",
    "spotifyTrackId": "0AOOAfKx7SAOWVzeWJMi4X"
  },
  {
    "id": "troilo-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-30",
    "title": "Gricel",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84033",
    "publisher": "RCA Victor",
    "discNo": 39771,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gricel Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "790OUlBQk2wuML7jFNYqRP"
  },
  {
    "id": "troilo-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-14",
    "title": "Barrio de tango",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84187",
    "publisher": "RCA Victor",
    "discNo": 39812,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barrio de tango Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "7eSf7aszTShQsKf3GbqG1g"
  },
  {
    "id": "troilo-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-14",
    "title": "Pa’ qué seguir",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84188",
    "publisher": "RCA Victor",
    "discNo": 39812,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Fiorentino",
    "lyricist": "Pedro Lloret",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pa’ qué seguir Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "5iACgfGh8xknGjmfGDkwK5"
  },
  {
    "id": "troilo-52",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-14",
    "title": "Por las calles de la vida",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84192",
    "publisher": "RCA Victor",
    "discNo": 39848,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadícamo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por las calles de la vida Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "6zBnewVAc8CGtgDLZLOtg4"
  },
  {
    "id": "troilo-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-29",
    "title": "Buenos Aires",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84217",
    "publisher": "RCA Victor",
    "discNo": 39848,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1942,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Jovés",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Buenos Aires Francisco Fiorentino Anibal Troilo 42 1942",
    "spotifyTrackId": "0Q6wUX3LgDBGzMKEmaBmDU"
  },
  {
    "id": "troilo-54",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-11",
    "title": "Corazón no le hagas caso",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84250",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corazón no le hagas caso Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "7zUGIU7K2hGxr01PQ5aHAq"
  },
  {
    "id": "troilo-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-11",
    "title": "Margarita Gauthier",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84251",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Joaquín Mora",
    "lyricist": "Julio Jorge Nelson",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Margarita Gauthier Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "3B8tRl20N1LQAKtEifvxBa"
  },
  {
    "id": "troilo-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-25",
    "title": "Percal",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84273",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Percal Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "6rvuPhcaCQLuFJHXn5SYSo"
  },
  {
    "id": "troilo-57",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-03-25",
    "title": "Valsecito amigo",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84274",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Valsecito amigo Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "4b1lEEMyDjcNhtJV9SiNMQ"
  },
  {
    "id": "troilo-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-05",
    "title": "Cada vez que me recuerdes",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84290",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cada vez que me recuerdes Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "4vObXrPKK7hSV4wIDpRIrF"
  },
  {
    "id": "troilo-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-05",
    "title": "Tango y copas (Otro tango)",
    "singer": "Alberto Marino",
    "discMatrix": "84289",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Artola",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tango y copas (Otro tango) Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-60",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-27",
    "title": "Cuando tallan los recuerdos",
    "singer": "Alberto Marino",
    "discMatrix": "84315",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rafael Rossi",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando tallan los recuerdos Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "1ctRgIdATGYR1J9ebka3j6"
  },
  {
    "id": "troilo-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-27",
    "title": "Soy del noventa",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84316",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Tito Ribero",
    "lyricist": "Carlos Waiss",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy del noventa Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-03",
    "title": "Inspiración",
    "singer": "Instrumental",
    "discMatrix": "84323",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Peregrino Paulos",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Inspiración Instrumental Anibal Troilo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "1CAMHWw0mvvNKt810ntoth"
  },
  {
    "id": "troilo-63",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-05-03",
    "title": "Ropa blanca",
    "singer": "Alberto Marino",
    "discMatrix": "84324",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo Malerba",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ropa blanca Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "3miYNlhO65Awk5G0i6ufhn"
  },
  {
    "id": "troilo-64",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-02",
    "title": "De barro",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84393",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastián Piana",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De barro Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "5l3W66TDufGaj6lfSA5LRZ"
  },
  {
    "id": "troilo-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-02",
    "title": "Farolito de papel",
    "singer": "Alberto Marino",
    "discMatrix": "84394",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Lespés y Teófilo Lespés",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Farolito de papel Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "1ctRgIdATGYR1J9ebka3j6"
  },
  {
    "id": "troilo-66",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-06-02",
    "title": "Soy un porteño",
    "singer": "Francisco Fiorentino",
    "discMatrix": "84392",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Razzano",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy un porteño Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "0h3gvvBWkURE8qgvvzN7v3"
  },
  {
    "id": "troilo-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-30",
    "title": "Uno",
    "singer": "Alberto Marino",
    "discMatrix": "77037",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Uno Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "520cZd581zCTrEQaINFSQm"
  },
  {
    "id": "troilo-68",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-04",
    "title": "El distinguido ciudadano",
    "singer": "Instrumental",
    "discMatrix": "77124",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Peregrino Paulos",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El distinguido ciudadano Instrumental Anibal Troilo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "4pRFouvyp2LFiEEyfFILlc"
  },
  {
    "id": "troilo-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-04",
    "title": "Garúa",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77123",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Garúa Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "5obbx3jOhSarM7oSWQiEIs"
  },
  {
    "id": "troilo-70",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-08-04",
    "title": "Soñar y nada más",
    "singer": "Alberto Marino y Francisco Fiorentino",
    "discMatrix": "77121",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Marino y Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "Ivo Pelay",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soñar y nada más Alberto Marino y Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "2xUTblFgoaRb95pJWai8aZ"
  },
  {
    "id": "troilo-71",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-04",
    "title": "Tal vez será su voz",
    "singer": "Alberto Marino",
    "discMatrix": "77122",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tal vez será su voz Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "4K3D8iugZOHHpKBsFhAXYv"
  },
  {
    "id": "troilo-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-30",
    "title": "Cantando se van las penas",
    "singer": "Alberto Marino",
    "discMatrix": "77268",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Canaro",
    "lyricist": "Ricardo Duggan",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cantando se van las penas Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "3tQvHPeQndrJo7hlZT6cxp"
  },
  {
    "id": "troilo-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-30",
    "title": "Farol",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77269",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Virgilio Expósito",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Farol Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "2KMk0wDxLHuJxduOdXX1UU"
  },
  {
    "id": "troilo-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-20",
    "title": "Gime el viento",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77310",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Atilio Bruni",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gime el viento Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "0ghax6UwHvjXtnlN1RcSSR"
  },
  {
    "id": "troilo-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-20",
    "title": "Pablo",
    "singer": "Instrumental",
    "discMatrix": "77311",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martínez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pablo Instrumental Anibal Troilo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "7xAIHQBfPz46VT1am0hYZJ"
  },
  {
    "id": "troilo-76",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-10-20",
    "title": "Uruguaya",
    "singer": "Francisco Fiorentino y Alberto Marino",
    "discMatrix": "77309",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "V",
    "singerShort": "Fiorentino y Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Polonio",
    "lyricist": "Juan Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Uruguaya Francisco Fiorentino y Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "3sHpmS1iyg3rCl91QSKzcV"
  },
  {
    "id": "troilo-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-05",
    "title": "A bailar",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77360",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A bailar Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "1vkyeBkTQAXu2EYzWc4dP8"
  },
  {
    "id": "troilo-78",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-11-05",
    "title": "El barrio del tambor",
    "singer": "Alberto Marino",
    "discMatrix": "77358",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Bonavena",
    "lyricist": "Antonio Bonavena",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El barrio del tambor Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "14wItRIwnfd3aSU5U9QH1m"
  },
  {
    "id": "troilo-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-05",
    "title": "La cumparsita",
    "singer": "Instrumental",
    "discMatrix": "77361",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gerardo Matos Rodriguez",
    "lyricist": "Pascual Contursi y Enrique Maroni",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Instrumental Anibal Troilo instr. inst. instr inst 43 1943",
    "spotifyTrackId": "4K3D8iugZOHHpKBsFhAXYv"
  },
  {
    "id": "troilo-80",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-05",
    "title": "Sosiego en la noche",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77359",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Garza",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sosiego en la noche Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "5j6ofif7wAPpUgTV8SrzAc"
  },
  {
    "id": "troilo-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "Cada día te extraño más",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77479",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cada día te extraño más Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "61BRtXnk5lJ6ettBjRFUu6"
  },
  {
    "id": "troilo-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "La luz de un fósforo",
    "singer": "Alberto Marino",
    "discMatrix": "77476",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Suárez Villanueva",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La luz de un fósforo Alberto Marino Anibal Troilo 43 1943",
    "spotifyTrackId": "3e2inS5NtHrxnG8ex3acjR"
  },
  {
    "id": "troilo-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-17",
    "title": "Orquestas de mi ciudad",
    "singer": "Francisco Fiorentino",
    "discMatrix": "77475",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Fiorentino",
    "lyricist": "Avlis (Erasmo Silva Cabrera)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Orquestas de mi ciudad Francisco Fiorentino Anibal Troilo 43 1943",
    "spotifyTrackId": "3F0VZtOm573SzupDlOzxAw"
  },
  {
    "id": "troilo-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-03",
    "title": "Chiqué",
    "singer": "Instrumental",
    "discMatrix": "79534",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ricardo Luis Brignolo",
    "lyricist": "Ricardo Luis Brignolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chiqué Instrumental Anibal Troilo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "6PaOATDFbKyeNdQmkQ0mYK"
  },
  {
    "id": "troilo-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-03",
    "title": "Después",
    "singer": "Alberto Marino",
    "discMatrix": "79533",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Después Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "2B3HiDUMpFy6683cflM2Qc"
  },
  {
    "id": "troilo-86",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-30",
    "title": "Bien porteño",
    "singer": "Instrumental",
    "discMatrix": "79599",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Gregorio Suriff y Alberto Barbera",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien porteño Instrumental Anibal Troilo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "5r8PKhPuv8YEVuM5wAFL91"
  },
  {
    "id": "troilo-87",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-03-30",
    "title": "El desafío",
    "singer": "Francisco Fiorentino y Alberto Marino",
    "discMatrix": "79597",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Fiorentino y Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "René Ruiz",
    "lyricist": "Charrúa",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El desafío Francisco Fiorentino y Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "4o0sJFXHckKlBhhynZsSIg"
  },
  {
    "id": "troilo-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-30",
    "title": "Sombras nada más",
    "singer": "Alberto Marino",
    "discMatrix": "79600",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Lomuto",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sombras nada más Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "33mtInRJiLpWfkOCXwl0E5"
  },
  {
    "id": "troilo-89",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-30",
    "title": "Tabaco",
    "singer": "Francisco Fiorentino",
    "discMatrix": "79596",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tabaco Francisco Fiorentino Anibal Troilo 44 1944",
    "spotifyTrackId": "3iRXwAbBhpbNTIRJ1LeGhx"
  },
  {
    "id": "troilo-90",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-03-30",
    "title": "Temblando",
    "singer": "Francisco Fiorentino",
    "discMatrix": "79598",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "V",
    "singerShort": "Fiorentino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Acuña",
    "lyricist": "Charrúa (Gualberto Márquez)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Temblando Francisco Fiorentino Anibal Troilo 44 1944",
    "spotifyTrackId": "42DJp59N8bMwJuvHBTXyr2"
  },
  {
    "id": "troilo-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-11",
    "title": "Piropos",
    "singer": "Instrumental",
    "discMatrix": "79620",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Cobián",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Piropos Instrumental Anibal Troilo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0gSX3JfgJMkJK1OssLWP47"
  },
  {
    "id": "troilo-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-11",
    "title": "Tres amigos",
    "singer": "Alberto Marino",
    "discMatrix": "79621",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadícamo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tres amigos Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "35HGqNt7J7h1ijzf7bbAVX"
  },
  {
    "id": "troilo-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-07",
    "title": "Cristal",
    "singer": "Alberto Marino",
    "discMatrix": "79727",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cristal Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "5bi6IYG82lxiTokj2GutZ3"
  },
  {
    "id": "troilo-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-07",
    "title": "Siga el corso",
    "singer": "Alberto Marino",
    "discMatrix": "79726",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Siga el corso Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "5E7VRyq4koyWP7IgIAEaV9"
  },
  {
    "id": "troilo-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-27",
    "title": "El entrerriano",
    "singer": "Instrumental",
    "discMatrix": "79748",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rosendo Mendizabal",
    "lyricist": "Homero Exposito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El entrerriano Instrumental Anibal Troilo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "3O8oiCGnWWPZP1L368RRBu"
  },
  {
    "id": "troilo-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-27",
    "title": "La vi llegar",
    "singer": "Alberto Marino",
    "discMatrix": "79747",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Francini",
    "lyricist": "Julián Centeya",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La vi llegar Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "2mENBYrnltNgSCFNJTes0C"
  },
  {
    "id": "troilo-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-27",
    "title": "Naipe",
    "singer": "Alberto Marino",
    "discMatrix": "79746",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Naipe Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "1SuMywaRdENW4PngeSM6Yx"
  },
  {
    "id": "troilo-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-01",
    "title": "Me están sobrando las penas",
    "singer": "Alberto Marino",
    "discMatrix": "79796",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Basso y Argentino Galván",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me están sobrando las penas Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "2huveL1g0MPNVUrnrqEXKN"
  },
  {
    "id": "troilo-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-01",
    "title": "Rosa de tango",
    "singer": "Alberto Marino",
    "discMatrix": "79795",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosa de tango Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "3pdPTAy334Y9AfnEWFBD2g"
  },
  {
    "id": "troilo-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-31",
    "title": "Alhucema",
    "singer": "Alberto Marino",
    "discMatrix": "79833",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alhucema Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "0sWSoNxy2AH8QdB2ConWs1"
  },
  {
    "id": "troilo-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-31",
    "title": "Nada más que un corazón",
    "singer": "Alberto Marino",
    "discMatrix": "79834",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Sucher",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nada más que un corazón Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "0GQIiXSBK7CPxzYi7cZYF3"
  },
  {
    "id": "troilo-102",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-09-27",
    "title": "Con permiso",
    "singer": "Alberto Marino",
    "discMatrix": "79870",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Mastra",
    "lyricist": "Alberto Mastra",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con permiso Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "0GQIiXSBK7CPxzYi7cZYF3"
  },
  {
    "id": "troilo-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-27",
    "title": "Quejas de bandoneón",
    "singer": "Instrumental",
    "discMatrix": "79871",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan de Dios Filiberto",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quejas de bandoneón Instrumental Anibal Troilo instr. inst. instr inst 44 1944",
    "spotifyTrackId": "4BbWIBqykeHGd6BJhDbAhR"
  },
  {
    "id": "troilo-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-06",
    "title": "Marioneta",
    "singer": "Floreal Ruíz",
    "discMatrix": "79889",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan José Guichandut",
    "lyricist": "Armando Tagini",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marioneta Floreal Ruíz Anibal Troilo 44 1944",
    "spotifyTrackId": "2B3HiDUMpFy6683cflM2Qc"
  },
  {
    "id": "troilo-105",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-06",
    "title": "Torrente",
    "singer": "Alberto Marino",
    "discMatrix": "79888",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Torrente Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "6nI8abBV3HcsQ5JSaCaCVO"
  },
  {
    "id": "troilo-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-23",
    "title": "Naranjo en flor",
    "singer": "Floreal Ruíz",
    "discMatrix": "79946",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Virgilio Expósito",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Naranjo en flor Floreal Ruíz Anibal Troilo 44 1944",
    "spotifyTrackId": "3hCwWkuULPRmZM2vsQ7ubc"
  },
  {
    "id": "troilo-107",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-11-23",
    "title": "Palomita blanca",
    "singer": "Floreal Ruíz y Alberto Marino",
    "discMatrix": "79947",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "V",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Palomita blanca Floreal Ruíz y Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "0HdH9EZhFTlw383oImLZRx"
  },
  {
    "id": "troilo-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-19",
    "title": "Café de Los Angelitos",
    "singer": "Alberto Marino",
    "discMatrix": "79992",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Razzano y Cátulo Castillo",
    "lyricist": "José Razzano y Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Café de Los Angelitos Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "7LzoCdXafZsWHEjaAzRbCa"
  },
  {
    "id": "troilo-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-19",
    "title": "Copas amigas y besos",
    "singer": "Alberto Marino",
    "discMatrix": "79990",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Copas amigas y besos Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "2BGzeW3HDt9jPzxg6xzQly"
  },
  {
    "id": "troilo-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-19",
    "title": "Luna llena",
    "singer": "Floreal Ruíz",
    "discMatrix": "79993",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Perini",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Luna llena Floreal Ruíz Anibal Troilo 44 1944",
    "spotifyTrackId": "5lXv4GrGo4Rw1rvZ6IFcIf"
  },
  {
    "id": "troilo-111",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-12-19",
    "title": "Milonga en rojo",
    "singer": "Floreal Ruíz y Alberto Marino",
    "discMatrix": "79991",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Ruíz y Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare y Roberto Fugazot",
    "lyricist": "José González Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga en rojo Floreal Ruíz y Alberto Marino Anibal Troilo 44 1944",
    "spotifyTrackId": "3nk2EtyQ5jBmyTTlEmEtfu"
  },
  {
    "id": "troilo-112",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-28",
    "title": "Garras",
    "singer": "Alberto Marino",
    "discMatrix": "80565",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Garras Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "16ga3uh90pAITPZtBLVGWV"
  },
  {
    "id": "troilo-113",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-28",
    "title": "Yuyo verde",
    "singer": "Floreal Ruíz",
    "discMatrix": "80566",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yuyo verde Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "13vKPEdDGmgtKHPwlQQp89"
  },
  {
    "id": "troilo-114",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-27",
    "title": "Equipaje",
    "singer": "Floreal Ruíz",
    "discMatrix": "80593",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Artola",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Equipaje Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "4QeDShuHfsWMDC2YMZYfCN"
  },
  {
    "id": "troilo-115",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-27",
    "title": "La borrachera del tango",
    "singer": "Floreal Ruíz",
    "discMatrix": "80595",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Adolfo Avilés",
    "lyricist": "Enrique Pedro Maroni",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La borrachera del tango Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "01VSmSGCoLLIXLE96HCML9"
  },
  {
    "id": "troilo-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-27",
    "title": "Me quedé mirándola",
    "singer": "Alberto Marino",
    "discMatrix": "80596",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Spina",
    "lyricist": "Roberto Miró",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me quedé mirándola Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "7xsY19zAzBZvDhZMGgkere"
  },
  {
    "id": "troilo-117",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-27",
    "title": "Ya estamos iguales",
    "singer": "Alberto Marino",
    "discMatrix": "80594",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ya estamos iguales Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "4OvjyEA9brgBMBaWk26Ied"
  },
  {
    "id": "troilo-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-29",
    "title": "Fuegos artificiales",
    "singer": "Instrumental",
    "discMatrix": "80664",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Firpo y Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fuegos artificiales Instrumental Anibal Troilo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "7qkcKfohbmrfbHV5Nq7nVX"
  },
  {
    "id": "troilo-119",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-29",
    "title": "Juan Tango",
    "singer": "Floreal Ruíz",
    "discMatrix": "80665",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia y Sebastián Piana",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Juan Tango Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "6yr37pkn6eOwtIsks2HUKT"
  },
  {
    "id": "troilo-120",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-06-05",
    "title": "Cimarrón de ausencia",
    "singer": "Floreal Ruíz y Alberto Marino",
    "discMatrix": "80679",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "M",
    "singerShort": "Ruíz y Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Larenza",
    "lyricist": "Marsilio Robles",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cimarrón de ausencia Floreal Ruíz y Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "1Zjf1Hun1WTQPEV592dJ6E"
  },
  {
    "id": "troilo-121",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-05",
    "title": "La noche que te fuiste",
    "singer": "Floreal Ruíz",
    "discMatrix": "80678",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osmar Maderna",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La noche que te fuiste Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "2DMunnEYXSiGfOyORYWzAh"
  },
  {
    "id": "troilo-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-28",
    "title": "Cotorrita de la suerte",
    "singer": "Alberto Marino",
    "discMatrix": "80706",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alfredo De Franco",
    "lyricist": "José De Grandis",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cotorrita de la suerte Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "6OXcI1B2TRcwwShmH20Hwj"
  },
  {
    "id": "troilo-123",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-28",
    "title": "Soledad la de Barracas",
    "singer": "Alberto Marino",
    "discMatrix": "80707",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Garza",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soledad la de Barracas Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "0D5j2ZWxmfrRORlnJzwT1c"
  },
  {
    "id": "troilo-124",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-10",
    "title": "Fruta amarga",
    "singer": "Alberto Marino",
    "discMatrix": "80763",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fruta amarga Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "61ceqmtNx3gLAeHtZ6v3n8"
  },
  {
    "id": "troilo-125",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-08-10",
    "title": "Llorarás llorarás",
    "singer": "Floreal Ruíz",
    "discMatrix": "80764",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "V",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llorarás llorarás Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-09",
    "title": "Amor y tango",
    "singer": "Floreal Ruíz",
    "discMatrix": "80841",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Basso",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amor y tango Floreal Ruíz Anibal Troilo 45 1945",
    "spotifyTrackId": "60XvcToALhaffxw7KJMFFw"
  },
  {
    "id": "troilo-127",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-09",
    "title": "Canción desesperada",
    "singer": "Alberto Marino",
    "discMatrix": "80842",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discepolo",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canción desesperada Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "4mHp0whXyf80glskjIXqMK"
  },
  {
    "id": "troilo-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-09",
    "title": "María",
    "singer": "Alberto Marino",
    "discMatrix": "80840",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "María Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "05uks2bKKc665DBJhbfltJ"
  },
  {
    "id": "troilo-129",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-25",
    "title": "Color de rosa",
    "singer": "Instrumental",
    "discMatrix": "80860",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Polito y Antonio Polito",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Color de rosa Instrumental Anibal Troilo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "5sqeFYS9meJd0esnDYUpuq"
  },
  {
    "id": "troilo-130",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-15",
    "title": "Adiós pampa mía",
    "singer": "Alberto Marino y Floreal Ruiz",
    "discMatrix": "80896",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino y Ruiz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro y Mariano Mores",
    "lyricist": "Ivo Pelay",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós pampa mía Alberto Marino y Floreal Ruiz Anibal Troilo 45 1945",
    "spotifyTrackId": "7pozuuJg5hyBWcRpRL88hn"
  },
  {
    "id": "troilo-131",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-15",
    "title": "El africano",
    "singer": "Instrumental",
    "discMatrix": "80897",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Pereyra",
    "lyricist": "Francisco Lío",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El africano Instrumental Anibal Troilo instr. inst. instr inst 45 1945",
    "spotifyTrackId": "2MkNw7CmcluYl6VcVFxKTm"
  },
  {
    "id": "troilo-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-18",
    "title": "Margo",
    "singer": "Alberto Marino",
    "discMatrix": "80935",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Margo Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "6BiF7rC4EH2oHxE8M6ZJXB"
  },
  {
    "id": "troilo-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-18",
    "title": "Tedio",
    "singer": "Alberto Marino",
    "discMatrix": "80936",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Miguel Bucino",
    "lyricist": "Miguel Bucino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tedio Alberto Marino Anibal Troilo 45 1945",
    "spotifyTrackId": "3Zcq8dnXorkaEPG9Xt6DHQ"
  },
  {
    "id": "troilo-134",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-23",
    "title": "Mis amigos de ayer",
    "singer": "Floreal Ruíz",
    "discMatrix": "80982",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Lomuto",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mis amigos de ayer Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "1U4Xr4kTawk3q6XgXy5xEK"
  },
  {
    "id": "troilo-135",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-23",
    "title": "Príncipe",
    "singer": "Alberto Marino",
    "discMatrix": "80983",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta y Rafael Tuegols",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Príncipe Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "1HCDHsYr0xtHLbjZpSqnfL"
  },
  {
    "id": "troilo-136",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-12",
    "title": "Recuerdos de bohemia (part 1)",
    "singer": "Alberto Marino",
    "discMatrix": "82028",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdos de bohemia (part 1) Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-136",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-12",
    "title": "Recuerdos de bohemia (part 2)",
    "singer": "Alberto Marino",
    "discMatrix": "82029",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Manuel Romero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdos de bohemia (part 2) Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-137",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-22",
    "title": "Bandita de mi pueblo",
    "singer": "Floreal Ruíz",
    "discMatrix": "82041",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Delfino",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bandita de mi pueblo Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "7AOYrFnPQ1N54aHxs4NExm"
  },
  {
    "id": "troilo-138",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-03-22",
    "title": "Milonga triste",
    "singer": "Alberto Marino",
    "discMatrix": "82040",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "M",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Sebastián Piana",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga triste Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "79bi35gSmGo5QIk6YFF8fo"
  },
  {
    "id": "troilo-139",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-04-10",
    "title": "Bienvenida",
    "singer": "Instrumental",
    "discMatrix": "82072",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Demarco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bienvenida Instrumental Anibal Troilo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "11abAv1OsPVWcAzdyHKqeb"
  },
  {
    "id": "troilo-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-04-10",
    "title": "Fuimos",
    "singer": "Alberto Marino",
    "discMatrix": "82071",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fuimos Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "1fiMd7nCfzCKgZeBkhSwOt"
  },
  {
    "id": "troilo-141",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-05-14",
    "title": "Con mi perro",
    "singer": "Alberto Marino",
    "discMatrix": "82125",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "M",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con mi perro Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "6DTn5gn0jRXYazNcQwAO1T"
  },
  {
    "id": "troilo-142",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-14",
    "title": "Tres y dos",
    "singer": "Instrumental",
    "discMatrix": "82124",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tres y dos Instrumental Anibal Troilo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "5yavvXzqcejt8m4FGgOHP6"
  },
  {
    "id": "troilo-143",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-14",
    "title": "Camino del Tucumán",
    "singer": "Floreal Ruíz y Alberto Marino",
    "discMatrix": "82164",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz y Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Razzano y Cátulo Castillo",
    "lyricist": "José Razzano y Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Camino del Tucumán Floreal Ruíz y Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "3HbhnzN7Lh0a0oz1j2Tctu"
  },
  {
    "id": "troilo-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-14",
    "title": "En carne propia",
    "singer": "Alberto Marino",
    "discMatrix": "82162",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Sucher",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En carne propia Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "6hoyaBqylD2iGqeECcqQor"
  },
  {
    "id": "troilo-145",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-14",
    "title": "Tarde gris",
    "singer": "Floreal Ruíz",
    "discMatrix": "82163",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Bautista Guido",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tarde gris Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "24Xgyj8RWBYVK7o4VrNj07"
  },
  {
    "id": "troilo-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-11",
    "title": "Buen amigo",
    "singer": "Instrumental",
    "discMatrix": "82207",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Juan Carlos Marambio Catan",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Buen amigo Instrumental Anibal Troilo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "4N61QOxyalrKKA6hZKnm8z"
  },
  {
    "id": "troilo-147",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-11",
    "title": "Qué me van a hablar de amor",
    "singer": "Floreal Ruíz",
    "discMatrix": "82209",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Stamponi",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué me van a hablar de amor Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "58ABTRKalzMbAw6hVKVxPP"
  },
  {
    "id": "troilo-148",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-11",
    "title": "Rosicler",
    "singer": "Alberto Marino",
    "discMatrix": "82208",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Basso",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosicler Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "7MN3Ty9tmXVrTCFWZ1bULm"
  },
  {
    "id": "troilo-149",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-09-25",
    "title": "Así es Ninón",
    "singer": "Alberto Marino",
    "discMatrix": "82352",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Larenza",
    "lyricist": "Marsilio Robles",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así es Ninón Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "0FE7qFHGRfA8xd0vWoeirc"
  },
  {
    "id": "troilo-150",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-09-25",
    "title": "Sólo se quiere una vez",
    "singer": "Floreal Ruíz",
    "discMatrix": "82351",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Vicente Geroni Flores",
    "lyricist": "Claudio Frollo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sólo se quiere una vez Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "0EpmQc0Lt4msFIuSuptTmo"
  },
  {
    "id": "troilo-151",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-22",
    "title": "La revancha",
    "singer": "Instrumental",
    "discMatrix": "82416",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Laurenz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La revancha Instrumental Anibal Troilo instr. inst. instr inst 46 1946",
    "spotifyTrackId": "1heGO58v7FLjMTf93UNKEt"
  },
  {
    "id": "troilo-152",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-10-22",
    "title": "Sin palabras",
    "singer": "Alberto Marino",
    "discMatrix": "82415",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin palabras Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "0yf8yKN2SeK7JJtgqHIjZN"
  },
  {
    "id": "troilo-153",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-28",
    "title": "Mi tango triste",
    "singer": "Alberto Marino",
    "discMatrix": "82503",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Marino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi tango triste Alberto Marino Anibal Troilo 46 1946",
    "spotifyTrackId": "33umRaR74Y1KiwKucOKXjM"
  },
  {
    "id": "troilo-154",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-28",
    "title": "Mientras gime el bandoneón",
    "singer": "Floreal Ruíz",
    "discMatrix": "82504",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Cadícamo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mientras gime el bandoneón Floreal Ruíz Anibal Troilo 46 1946",
    "spotifyTrackId": "0SHXzDFaFjfwzG47op13LF"
  },
  {
    "id": "troilo-155",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-29",
    "title": "Confesión",
    "singer": "Floreal Ruíz",
    "discMatrix": "83387",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discepolo",
    "lyricist": "Enrique Santos Discepolo y Luis César Amadori",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Confesión Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "33048i5xWRGLU1LMbpfggb"
  },
  {
    "id": "troilo-156",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-29",
    "title": "El milagro",
    "singer": "Edmundo Rivero",
    "discMatrix": "83389",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El milagro Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "4rIiXKTmXv5HZAVV2jMsx1"
  },
  {
    "id": "troilo-157",
    "trackNo": null,
    "genre": "Vals",
    "date": "1947-04-29",
    "title": "Flor de lino",
    "singer": "Floreal Ruíz",
    "discMatrix": "83388",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "V",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Stamponi",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Flor de lino Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "5WJd6vt1YWJ1dQfspsV2tH"
  },
  {
    "id": "troilo-158",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-29",
    "title": "Yira yira",
    "singer": "Edmundo Rivero",
    "discMatrix": "83386",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Santos Discepolo",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yira yira Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-159",
    "trackNo": null,
    "genre": "Candombe",
    "date": "1947-07-04",
    "title": "Carnaval",
    "singer": "Edmundo Rivero",
    "discMatrix": "83518",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carnaval Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "3aJfLR7oNk3bHv1RUJom4x"
  },
  {
    "id": "troilo-160",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-07-04",
    "title": "El Morocho y el Oriental (Gardel-Razzano)",
    "singer": "Floreal Ruíz y Edmundo Rivero",
    "discMatrix": "83520",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Ruíz y Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ángel D'Agostino",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Morocho y el Oriental (Gardel-Razzano) Floreal Ruíz y Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-161",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-07-04",
    "title": "Tu pálido final",
    "singer": "Edmundo Rivero",
    "discMatrix": "83521",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Demarco",
    "lyricist": "Alfredo Faustino Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu pálido final Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "2ZmXm2KFFimJnfez8l2M2P"
  },
  {
    "id": "troilo-162",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-07-04",
    "title": "Y dicen que no te quiero",
    "singer": "Floreal Ruíz",
    "discMatrix": "83519",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Canet",
    "lyricist": "José Canet",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y dicen que no te quiero Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "3knYj9IrIS7BHM7GYUlIqn"
  },
  {
    "id": "troilo-163",
    "trackNo": null,
    "genre": "Vals",
    "date": "1947-08-19",
    "title": "Romance de barrio",
    "singer": "Floreal Ruíz",
    "discMatrix": "83598",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "V",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Romance de barrio Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "61B1VPEzKrw5w2sBaSlp5S"
  },
  {
    "id": "troilo-164",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-08-19",
    "title": "Yo te bendigo",
    "singer": "Edmundo Rivero",
    "discMatrix": "83599",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan de Dios Filiberto",
    "lyricist": "Juan Andrés Bruno",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo te bendigo Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "1br8ZlPyL89m7vNm8fuj4c"
  },
  {
    "id": "troilo-165",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-10-07",
    "title": "Los ejes de mi carreta",
    "singer": "Edmundo Rivero",
    "discMatrix": "83682",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Atahualpa Yupanqui",
    "lyricist": "Romildo Risso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Los ejes de mi carreta Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "2PECpyIkQVnLD8oN2p3QHZ"
  },
  {
    "id": "troilo-166",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-07",
    "title": "Y la perdí",
    "singer": "Floreal Ruíz",
    "discMatrix": "83683",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Rodio",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y la perdí Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "0QvxY3qRHd03nLDn5KO70o"
  },
  {
    "id": "troilo-167",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-24",
    "title": "Corazón de papel",
    "singer": "Floreal Ruíz",
    "discMatrix": "83714",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cátulo Castillo",
    "lyricist": "Alberto Franco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corazón de papel Floreal Ruíz Anibal Troilo 47 1947",
    "spotifyTrackId": "7BzQshRfJ9bJZb3r1Z7HgL"
  },
  {
    "id": "troilo-168",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-24",
    "title": "Tapera",
    "singer": "Edmundo Rivero",
    "discMatrix": "83715",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Hugo Gutiérrez",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tapera Edmundo Rivero Anibal Troilo 47 1947",
    "spotifyTrackId": "3C4KU624cAcyzuM4GKFPW1"
  },
  {
    "id": "troilo-169",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-02-23",
    "title": "De todo te olvidas (Cabeza de novia)",
    "singer": "Floreal Ruíz",
    "discMatrix": "83845",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Salvador Merico",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De todo te olvidas (Cabeza de novia) Floreal Ruíz Anibal Troilo 48 1948",
    "spotifyTrackId": "7j4ZXLdpj3ed3iXInOZk2F"
  },
  {
    "id": "troilo-170",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-02-23",
    "title": "Sur",
    "singer": "Edmundo Rivero",
    "discMatrix": "83844",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Aníbal Troilo",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sur Edmundo Rivero Anibal Troilo 48 1948",
    "spotifyTrackId": "2r1OeSJvBvDprRQRvyKZ5v"
  },
  {
    "id": "troilo-171",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-02-28",
    "title": "Desvelo (De flor en flor)",
    "singer": "Floreal Ruíz",
    "discMatrix": "83849",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Ruíz",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Bonessi",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desvelo (De flor en flor) Floreal Ruíz Anibal Troilo 48 1948",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-172",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-02-28",
    "title": "Tu perro pekinés",
    "singer": "Edmundo Rivero",
    "discMatrix": "83848",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu perro pekinés Edmundo Rivero Anibal Troilo 48 1948",
    "spotifyTrackId": "6fIGQiFKg57pVlGsZXnEiu"
  },
  {
    "id": "troilo-173",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-07-08",
    "title": "Cafetín de Buenos Aires",
    "singer": "Edmundo Rivero",
    "discMatrix": "83998",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Enrique Santos Discépolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cafetín de Buenos Aires Edmundo Rivero Anibal Troilo 48 1948",
    "spotifyTrackId": "5fG7BPKf9qPtUmVvao9EPM"
  },
  {
    "id": "troilo-174",
    "trackNo": null,
    "genre": "Vals",
    "date": "1948-07-08",
    "title": "Lagrimitas de mi corazón",
    "singer": "Floreal Ruíz y Edmundo Rivero",
    "discMatrix": "83999",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "V",
    "singerShort": "Ruíz y Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Rodríguez",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lagrimitas de mi corazón Floreal Ruíz y Edmundo Rivero Anibal Troilo 48 1948",
    "spotifyTrackId": "01RGHIqPQ2fdd4fU0JomEN"
  },
  {
    "id": "troilo-175",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-10-01",
    "title": "Como tú",
    "singer": "Edmundo Rivero",
    "discMatrix": "91095",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ángel Domínguez",
    "lyricist": "José Rótulo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como tú Edmundo Rivero Anibal Troilo 48 1948",
    "spotifyTrackId": "1Mx5ikIzCYx996Ay7Koa3H"
  },
  {
    "id": "troilo-176",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-10-01",
    "title": "Ojos negros",
    "singer": "Instrumental",
    "discMatrix": "91094",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Greco",
    "lyricist": "Pedro Numa Cordoba",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ojos negros Instrumental Anibal Troilo instr. inst. instr inst 48 1948",
    "spotifyTrackId": "7xgKLWTmNyKQpMK0MJMj1C"
  },
  {
    "id": "troilo-177",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-03-30",
    "title": "Patético",
    "singer": "Instrumental",
    "discMatrix": "91265",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jorge Caldara",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Patético Instrumental Anibal Troilo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "3Cb4iMvGVyUo0CuKAUaKG3"
  },
  {
    "id": "troilo-178",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-03-30",
    "title": "Una lágrima tuya",
    "singer": "Edmundo Rivero y Aldo Calderón",
    "discMatrix": "91264",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero y Calderón",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una lágrima tuya Edmundo Rivero y Aldo Calderón Anibal Troilo 49 1949",
    "spotifyTrackId": "2JtYG6zdEWyOjwp2DjKpM4"
  },
  {
    "id": "troilo-179",
    "trackNo": null,
    "genre": "Vals",
    "date": "1949-03-31",
    "title": "A unos ojos",
    "singer": "Edmundo Rivero y Aldo Calderón",
    "discMatrix": "91268",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "V",
    "singerShort": "Rivero y Calderón",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Montbrún Ocampo",
    "lyricist": "Hernán Videla Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A unos ojos Edmundo Rivero y Aldo Calderón Anibal Troilo 49 1949",
    "spotifyTrackId": "5gEx5Nyk9jsiwuEkfZS8Ga"
  },
  {
    "id": "troilo-180",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-03-31",
    "title": "Cuándo volverás",
    "singer": "Aldo Calderón",
    "discMatrix": "91270",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Calderón",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "José Horacio Staffolani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuándo volverás Aldo Calderón Anibal Troilo 49 1949",
    "spotifyTrackId": "53rs4PLgKgQpOM5Q8Vsb3T"
  },
  {
    "id": "troilo-181",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-03-31",
    "title": "El último organito",
    "singer": "Edmundo Rivero",
    "discMatrix": "91269",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Acho Manzi",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El último organito Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "4Yy2pBJJFBGAzxKc9pEAvY"
  },
  {
    "id": "troilo-182",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1949-03-31",
    "title": "Milonga en negro",
    "singer": "Edmundo Rivero",
    "discMatrix": "91271",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "M",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Edmundo Rivero",
    "lyricist": "Edmundo Rivero",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga en negro Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "1kW6QjDRakFZi1tcWekm31"
  },
  {
    "id": "troilo-183",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-06-30",
    "title": "A la parrilla",
    "singer": "Instrumental",
    "discMatrix": "91321",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Figari",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la parrilla Instrumental Anibal Troilo instr. inst. instr inst 49 1949",
    "spotifyTrackId": "4MN6U8EFvNXjS4IHN8rNQg"
  },
  {
    "id": "troilo-184",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-06-30",
    "title": "Mi noche triste",
    "singer": "Edmundo Rivero",
    "discMatrix": "91320",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Samuel Castriota",
    "lyricist": "Pascual Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi noche triste Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "3zH03lTR79h9zkTfQapiMo"
  },
  {
    "id": "troilo-185",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-07-22",
    "title": "La mariposa",
    "singer": "Edmundo Rivero",
    "discMatrix": "91346",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mariposa Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "2BWLVLP9kqkw7SQvz1VM20"
  },
  {
    "id": "troilo-186",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-07-22",
    "title": "Selección de tangos de Julio De Caro",
    "singer": "Instrumental",
    "discMatrix": "91347",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "#N/A",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Selección de tangos de Julio De Caro Instrumental Anibal Troilo instr. inst. instr inst 49 1949",
    "spotifyTrackId": ""
  },
  {
    "id": "troilo-187",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-10-20",
    "title": "La viajera perdida",
    "singer": "Edmundo Rivero",
    "discMatrix": "91437",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Maciel",
    "lyricist": "Héctor Blomberg",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La viajera perdida Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "4c0ISMItiFVYvJkec2hqqy"
  },
  {
    "id": "troilo-188",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1949-10-20",
    "title": "Miriñaque",
    "singer": "Edmundo Rivero y Aldo Calderón",
    "discMatrix": "91436",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "M",
    "singerShort": "Rivero y Calderón",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Mastra",
    "lyricist": "Alberto Mastra",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Miriñaque Edmundo Rivero y Aldo Calderón Anibal Troilo 49 1949",
    "spotifyTrackId": "7xaLq0Nk6Zl46gsgJUEgq8"
  },
  {
    "id": "troilo-189",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-10-26",
    "title": "Tú",
    "singer": "Edmundo Rivero",
    "discMatrix": "91445",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Rivero",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tú Edmundo Rivero Anibal Troilo 49 1949",
    "spotifyTrackId": "1Mx5ikIzCYx996Ay7Koa3H"
  },
  {
    "id": "troilo-190",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-10-26",
    "title": "Y volvemos a querernos",
    "singer": "Aldo Calderón",
    "discMatrix": "91444",
    "publisher": "RCA Victor",
    "discNo": null,
    "orchestraId": "troilo",
    "groupId": "troilo4149",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Calderón",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luciano Leocata",
    "lyricist": "Abel Aznar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y volvemos a querernos Aldo Calderón Anibal Troilo 49 1949",
    "spotifyTrackId": "1HuAoGLwTlK22yzykCKT2f"
  },
  {
    "id": "pugliese-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-07-15",
    "title": "El rodeo",
    "singer": "Instrumental",
    "discMatrix": "12926",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El rodeo Instrumental Osvaldo Pugliese instr. inst. instr inst 43 1943",
    "spotifyTrackId": "6hY5agu5oO3ZdvrYTvx8zO"
  },
  {
    "id": "pugliese-2",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-07-15",
    "title": "Farol",
    "singer": "Roberto Chanel",
    "discMatrix": "12925",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Virgilio Expósito",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Farol Roberto Chanel Osvaldo Pugliese 43 1943",
    "spotifyTrackId": "5MauhLqiQWfmdMLkGGogM9"
  },
  {
    "id": "pugliese-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-27",
    "title": "Muchachos comienza la ronda",
    "singer": "Roberto Chanel",
    "discMatrix": "13081",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Porcellana",
    "lyricist": "Leopoldo Díaz Vélez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachos comienza la ronda Roberto Chanel Osvaldo Pugliese 43 1943",
    "spotifyTrackId": "3nGtAwbhEH3MXB4mmtj4oJ"
  },
  {
    "id": "pugliese-4",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-27",
    "title": "Mala junta",
    "singer": "Instrumental",
    "discMatrix": "13082",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Juan Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mala junta Instrumental Osvaldo Pugliese instr. inst. instr inst 43 1943",
    "spotifyTrackId": "7HaSiLU1DsH5wKK05jW7Jc"
  },
  {
    "id": "pugliese-5",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-10-21",
    "title": "Milonga de mi tierra",
    "singer": "Jorge Rubino",
    "discMatrix": "13288",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "M",
    "singerShort": "Rubino",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Pugliese",
    "lyricist": "José Sassone",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga de mi tierra Jorge Rubino Osvaldo Pugliese 43 1943",
    "spotifyTrackId": "2C1vnIv6mHHOXCm4KfDR9E"
  },
  {
    "id": "pugliese-6",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-21",
    "title": "Qué bien te queda (Cómo has cambiado)",
    "singer": "Roberto Chanel",
    "discMatrix": "13289",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1943,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Salerno",
    "lyricist": "Juan Mazaroni",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué bien te queda (Cómo has cambiado) Roberto Chanel Osvaldo Pugliese 43 1943",
    "spotifyTrackId": "49Z9rp1ryhgnWGloI44o9K"
  },
  {
    "id": "pugliese-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-31",
    "title": "Recuerdo",
    "singer": "Instrumental",
    "discMatrix": "13685",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "Eduardo Moreno",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdo Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "7c9wdJgQ5jUzmR7W1UWVDL"
  },
  {
    "id": "pugliese-8",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-31",
    "title": "Silbar de boyero",
    "singer": "Roberto Chanel",
    "discMatrix": "13680",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "David Barberis",
    "lyricist": "José Barreiros Bazán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Silbar de boyero Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "0fDaSwjjqZDu9Qb9R0A1Wt"
  },
  {
    "id": "pugliese-9",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-06-01",
    "title": "Tortazos",
    "singer": "Roberto Chanel",
    "discMatrix": "13818",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "M",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Razzano",
    "lyricist": "Enrique Maroni",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tortazos Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "7ILqBQoa6LByxtmQEItC8V"
  },
  {
    "id": "pugliese-10",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-06-01",
    "title": "El remate",
    "singer": "Instrumental",
    "discMatrix": "13819",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El remate Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "3XDY4gey7aStcxOXPlclPG"
  },
  {
    "id": "pugliese-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-06",
    "title": "El día de tu ausencia",
    "singer": "Roberto Chanel",
    "discMatrix": "13923",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Argentino Galván",
    "lyricist": "Manuel Arcos",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El día de tu ausencia Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "36dwejMXvn5XduNuMkTkEK"
  },
  {
    "id": "pugliese-12",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-06",
    "title": "Tierra querida",
    "singer": "Instrumental",
    "discMatrix": "13924",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Luis Díaz",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tierra querida Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0MIBLqC76idV8aOXFwWey9"
  },
  {
    "id": "pugliese-13",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-20",
    "title": "La abandoné y no sabía",
    "singer": "Roberto Chanel",
    "discMatrix": "13958",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Canet",
    "lyricist": "José Canet",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La abandoné y no sabía Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "3lRuOhJj4L0erlCbhkFWEh"
  },
  {
    "id": "pugliese-14",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-07-20",
    "title": "Tu casa ya no está",
    "singer": "Roberto Chanel",
    "discMatrix": "13957",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "V",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Virgilio Expósito",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu casa ya no está Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "2CyUGY77BRneYNHVaDZSfu"
  },
  {
    "id": "pugliese-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-22",
    "title": "El tango es una historia",
    "singer": "Roberto Chanel",
    "discMatrix": "14039",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Chanel",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tango es una historia Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "5fzha9qtMOoAuJcq5TySKW"
  },
  {
    "id": "pugliese-16",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-22",
    "title": "El arranque",
    "singer": "Instrumental",
    "discMatrix": "14040",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Mario César Gomila",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El arranque Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "6MLfP8a4FiZpYpg8GNdqrQ"
  },
  {
    "id": "pugliese-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-17",
    "title": "Corrientes y Esmeralda",
    "singer": "Roberto Chanel",
    "discMatrix": "14166",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corrientes y Esmeralda Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "3RpZVOJpC4nu7XmB8nUTAD"
  },
  {
    "id": "pugliese-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-10-17",
    "title": "Adiós Bardi",
    "singer": "Instrumental",
    "discMatrix": "14165",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós Bardi Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "0IjSFd4iuIBMSgEVlcPN84"
  },
  {
    "id": "pugliese-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-24",
    "title": "Amurado",
    "singer": "Instrumental",
    "discMatrix": "14240",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia / Pedro Laurenz",
    "lyricist": "José De Grandis",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amurado Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": "3IwybqCoN1jnxisQeXKIzB"
  },
  {
    "id": "pugliese-20",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-24",
    "title": "Nada más que un corazón",
    "singer": "Roberto Chanel",
    "discMatrix": "14241",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Manuel Sucher",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nada más que un corazón Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "5VoyMnKL4lh5ShzLwqVTNM"
  },
  {
    "id": "pugliese-21",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-12-21",
    "title": "Puentecito de mi río",
    "singer": "Roberto Chanel",
    "discMatrix": "14331",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "V",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Tormo",
    "lyricist": "Buenaventura Luna",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Puentecito de mi río Roberto Chanel Osvaldo Pugliese 44 1944",
    "spotifyTrackId": "1BHJ9gGkjCCWQYusaalEdO"
  },
  {
    "id": "pugliese-22",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-12",
    "title": "Mala estampa (Mala Pinta)",
    "singer": "Instrumental",
    "discMatrix": "14332",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1944,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco De Caro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mala estampa (Mala Pinta) Instrumental Osvaldo Pugliese instr. inst. instr inst 44 1944",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-25",
    "title": "Yuyo verde",
    "singer": "Alberto Morán",
    "discMatrix": "14415",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Domingo Federico",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yuyo verde Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "3dEMNHdSTi8bk9l3NS1UMr"
  },
  {
    "id": "pugliese-24",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-25",
    "title": "Raza criolla (El Taita)",
    "singer": "Instrumental",
    "discMatrix": "14416",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Salvador Grupillo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Raza criolla (El Taita) Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "4YhPqyLIURV5n8gOZBJv95"
  },
  {
    "id": "pugliese-25",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-20",
    "title": "El paladín",
    "singer": "Instrumental",
    "discMatrix": "14490",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El paladín Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "52Sv9OmVLhJt6idrwtuhxX"
  },
  {
    "id": "pugliese-26",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-22",
    "title": "El sueño del pibe",
    "singer": "Roberto Chanel",
    "discMatrix": "14498",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Puey",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El sueño del pibe Roberto Chanel Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "2KMiL9ARE72At7StyjO8by"
  },
  {
    "id": "pugliese-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-28",
    "title": "Maleza",
    "singer": "Alberto Morán",
    "discMatrix": "14648",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Enrique Munné",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Maleza Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "52GiQAFMuC81bPhFUtnDlU"
  },
  {
    "id": "pugliese-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-28",
    "title": "Derecho viejo",
    "singer": "Instrumental",
    "discMatrix": "14647",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Derecho viejo Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "4P5BFFuNSDqoZy7ngFhv2A"
  },
  {
    "id": "pugliese-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-12",
    "title": "Pelele",
    "singer": "Instrumental",
    "discMatrix": "14677",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pelele Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-12",
    "title": "El monito",
    "singer": "Instrumental",
    "discMatrix": "14676",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Juan Carlos Marambio Catán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El monito Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "2CA1YXANBdHCyI5py5l4R7"
  },
  {
    "id": "pugliese-31",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-18",
    "title": "Rondando tu esquina",
    "singer": "Roberto Chanel",
    "discMatrix": "14782",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Charlo",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rondando tu esquina Roberto Chanel Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "2rPbICg7EDNctce8QKBRJS"
  },
  {
    "id": "pugliese-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-24",
    "title": "El abrojito",
    "singer": "Alberto Morán",
    "discMatrix": "14805",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Bernstein",
    "lyricist": "Jesús Fernández Blanco",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El abrojito Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "1M5zmF75pYSGtX5rXOixDE"
  },
  {
    "id": "pugliese-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-28",
    "title": "Por qué",
    "singer": "Alberto Morán y Roberto Chanel",
    "discMatrix": "14875",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán y Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Fresedo",
    "lyricist": "Emilio Fresedo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por qué Alberto Morán y Roberto Chanel Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "3XRw1L8gVVRruWVDQPwxeF"
  },
  {
    "id": "pugliese-34",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-28",
    "title": "Flor de tango",
    "singer": "Instrumental",
    "discMatrix": "14876",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio Carrasco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Flor de tango Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "33wxn7rbBwWXg3Urj5h6by"
  },
  {
    "id": "pugliese-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-24",
    "title": "Mentira",
    "singer": "Alberto Morán",
    "discMatrix": "14938",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mentira Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "3o04lIGGC31yR0WCqoiWtI"
  },
  {
    "id": "pugliese-36",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-24",
    "title": "La cachila",
    "singer": "Instrumental",
    "discMatrix": "14937",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Héctor Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cachila Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "29NuBlrVItr4etfB7JEcUM"
  },
  {
    "id": "pugliese-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-06",
    "title": "Galleguita",
    "singer": "Roberto Chanel",
    "discMatrix": "15071",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Horacio Pettorossi",
    "lyricist": "Alfredo Navarrine",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Galleguita Roberto Chanel Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "2y0CwIOQe2EoIYaL6bpJyf"
  },
  {
    "id": "pugliese-38",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-06",
    "title": "Las marionetas",
    "singer": "Instrumental",
    "discMatrix": "15070",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Las marionetas Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "2hSYs11bjDmlCNkvc0wTzE"
  },
  {
    "id": "pugliese-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-03",
    "title": "Por qué no has venido",
    "singer": "Alberto Morán",
    "discMatrix": "15153",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Julio Navarrine",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por qué no has venido Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "26koBSL68tPularYeXhLqZ"
  },
  {
    "id": "pugliese-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-03",
    "title": "Dandy",
    "singer": "Roberto Chanel",
    "discMatrix": "15154",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lucio Demare",
    "lyricist": "Agustín Irusta",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dandy Roberto Chanel Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "2mO2FDFl1OqnJqxwBwFIpC"
  },
  {
    "id": "pugliese-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-18",
    "title": "Hoy al recordarla",
    "singer": "Alberto Morán",
    "discMatrix": "15196",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Canet",
    "lyricist": "José Canet",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hoy al recordarla Alberto Morán Osvaldo Pugliese 45 1945",
    "spotifyTrackId": "3WypQ3c56oOx8tvY3ZAbbE"
  },
  {
    "id": "pugliese-42",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-18",
    "title": "Tiny",
    "singer": "Instrumental",
    "discMatrix": "15197",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1945,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tiny Instrumental Osvaldo Pugliese instr. inst. instr inst 45 1945",
    "spotifyTrackId": "2tOhRGPqXmuTJLKzmUlPr4"
  },
  {
    "id": "pugliese-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-02-27",
    "title": "Demasiado tarde",
    "singer": "Alberto Morán",
    "discMatrix": "15333",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Troppoli",
    "lyricist": "Agustín Horacio Delamónica",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Demasiado tarde Alberto Morán Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "0v3W0OfN0581fupdxMoGWk"
  },
  {
    "id": "pugliese-44",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-02-27",
    "title": "Consejo de oro",
    "singer": "Roberto Chanel",
    "discMatrix": "15332",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Arquímedes Arci",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Consejo de oro Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "4exRyCKoAao18kuTTpZMFs"
  },
  {
    "id": "pugliese-45",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-28",
    "title": "Príncipe",
    "singer": "Alberto Morán",
    "discMatrix": "15399",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Príncipe Alberto Morán Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "4wwAqZJBIDguAYNkjx84eM"
  },
  {
    "id": "pugliese-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-28",
    "title": "Fuimos",
    "singer": "Roberto Chanel",
    "discMatrix": "15398",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "Homero Manzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Fuimos Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "5PABBprgYZXcuT3NPScfgX"
  },
  {
    "id": "pugliese-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-27",
    "title": "Sin lágrimas",
    "singer": "Roberto Chanel",
    "discMatrix": "15536",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Charlo",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin lágrimas Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "0FGe7hUYVONNieCoAOjOsx"
  },
  {
    "id": "pugliese-48",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-27",
    "title": "Dos ojos tristes",
    "singer": "Alberto Morán",
    "discMatrix": "15537",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oscar de la Fuente",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos ojos tristes Alberto Morán Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "3EUJ58B2XSRSbAa43qT7Rx"
  },
  {
    "id": "pugliese-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-21",
    "title": "Vayan saliendo",
    "singer": "Instrumental",
    "discMatrix": "15607",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Víctor Donato",
    "lyricist": "Juan Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vayan saliendo Instrumental Osvaldo Pugliese instr. inst. instr inst 46 1946",
    "spotifyTrackId": "0nMPFkqmY1g4wfXTLLvoH2"
  },
  {
    "id": "pugliese-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-13",
    "title": "El tábano",
    "singer": "Instrumental",
    "discMatrix": "15584",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Baliotti",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tábano Instrumental Osvaldo Pugliese instr. inst. instr inst 46 1946",
    "spotifyTrackId": "0q32QP4lB61LNeFIAqIOUE"
  },
  {
    "id": "pugliese-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-31",
    "title": "No me escribas",
    "singer": "Alberto Morán",
    "discMatrix": "15702",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "Juan Andrés Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No me escribas Alberto Morán Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "11W0kIZxFL7IXoOXeVUO4y"
  },
  {
    "id": "pugliese-52",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-31",
    "title": "Tiempo",
    "singer": "Roberto Chanel",
    "discMatrix": "15701",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tiempo Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "0tGPciWRMCp1o5P2YlSU79"
  },
  {
    "id": "pugliese-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-21",
    "title": "Mal de amores",
    "singer": "Instrumental",
    "discMatrix": "15759",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Pedro Laurenz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mal de amores Instrumental Osvaldo Pugliese instr. inst. instr inst 46 1946",
    "spotifyTrackId": "5AyjkQBaOEofaJ2iV2x0An"
  },
  {
    "id": "pugliese-54",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-21",
    "title": "La yumba",
    "singer": "Instrumental",
    "discMatrix": "15758",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La yumba Instrumental Osvaldo Pugliese instr. inst. instr inst 46 1946",
    "spotifyTrackId": "4u1SyTVMYoCe8VQJvrtqU0"
  },
  {
    "id": "pugliese-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-08",
    "title": "Una vez",
    "singer": "Alberto Morán",
    "discMatrix": "15943",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "Cátulo Castillo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una vez Alberto Morán Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "125QRXq0Ty026vhT9E30YX"
  },
  {
    "id": "pugliese-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-08",
    "title": "La mascota del barrio",
    "singer": "Roberto Chanel",
    "discMatrix": "15942",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Abel Aznar",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mascota del barrio Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "3ushXr6cmyVrjUUIBG63KP"
  },
  {
    "id": "pugliese-57",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-01-02",
    "title": "Sin palabras",
    "singer": "Alberto Morán",
    "discMatrix": "16168",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mariano Mores",
    "lyricist": "Enrique Santos Discepolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin palabras Alberto Morán Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "0DmKXzq6rYNBXWfFLIzT5j"
  },
  {
    "id": "pugliese-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-20",
    "title": "Mirando la lluvia",
    "singer": "Alberto Morán y Roberto Chanel",
    "discMatrix": "16124",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1946,
    "genreShort": "T",
    "singerShort": "Morán y Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Adolfo Avilés",
    "lyricist": "Leopoldo Torre Ríos",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mirando la lluvia Alberto Morán y Roberto Chanel Osvaldo Pugliese 46 1946",
    "spotifyTrackId": "3TOm3zxL8wlOZTZB5wMfBO"
  },
  {
    "id": "pugliese-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-01-28",
    "title": "Ojos maulas",
    "singer": "Roberto Chanel",
    "discMatrix": "16262",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Bernstein",
    "lyricist": "Alfredo Faustino Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ojos maulas Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "4haYZtIwDDLSYAyaEQM6Uw"
  },
  {
    "id": "pugliese-60",
    "trackNo": null,
    "genre": "Vals",
    "date": "1947-01-28",
    "title": "Ilusión marina",
    "singer": "Alberto Morán",
    "discMatrix": "16263",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "V",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Sureda",
    "lyricist": "Jerónimo Sureda",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ilusión marina Alberto Morán Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "2sK1vtN5UcUWRzqVeH0XS1"
  },
  {
    "id": "pugliese-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-03-27",
    "title": "Yo te bendigo",
    "singer": "Roberto Chanel",
    "discMatrix": "16338",
    "publisher": "Odeon",
    "discNo": 7690,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan de Dios Filiberto",
    "lyricist": "Juan Andrés Bruno",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo te bendigo Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "47vy1sPHEbjVbSbvyvAoPS"
  },
  {
    "id": "pugliese-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-03-27",
    "title": "Cafetín",
    "singer": "Alberto Morán",
    "discMatrix": "16339",
    "publisher": "Odeon",
    "discNo": 7690,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Argentino Galván",
    "lyricist": "Homero Expósito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cafetín Alberto Morán Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "4Yy2pBJJFBGAzxKc9pEAvY"
  },
  {
    "id": "pugliese-63",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-28",
    "title": "N… N…",
    "singer": "Instrumental",
    "discMatrix": "16421",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "N… N… Instrumental Osvaldo Pugliese instr. inst. instr inst 47 1947",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-64",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-28",
    "title": "Desilusión",
    "singer": "Alberto Morán",
    "discMatrix": "16422",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Lito Bayardo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desilusión Alberto Morán Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "36OFik2e6O6MjLvfBkG0Iw"
  },
  {
    "id": "pugliese-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-06-10",
    "title": "Escuchame Manón (Indiferencia)",
    "singer": "Roberto Chanel",
    "discMatrix": "16522",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Pracánico",
    "lyricist": "Roberto Chanel",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Escuchame Manón (Indiferencia) Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "1wuHtooSN3q3J3reKHFnJO"
  },
  {
    "id": "pugliese-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-06-10",
    "title": "Amiga",
    "singer": "Roberto Chanel",
    "discMatrix": "16521",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Viván",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amiga Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "76Bnqo1bmo0AqpdzCEIomu"
  },
  {
    "id": "pugliese-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-08-19",
    "title": "En secreto",
    "singer": "Alberto Morán",
    "discMatrix": "16647",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Vicente Demarco",
    "lyricist": "Alfredo Faustino Roldán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En secreto Alberto Morán Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "3ZShzsSlM0VymE8JwbLhOE"
  },
  {
    "id": "pugliese-68",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-08-19",
    "title": "Jueves",
    "singer": "Instrumental",
    "discMatrix": "16646",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Udelino Toranzo",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Jueves Instrumental Osvaldo Pugliese instr. inst. instr inst 47 1947",
    "spotifyTrackId": "73sAyQ1rS6cPcV4t8yMA4k"
  },
  {
    "id": "pugliese-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-14",
    "title": "El mareo",
    "singer": "Alberto Morán y Roberto Chanel",
    "discMatrix": "16752",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Morán y Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Mario Cesar Gomila",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El mareo Alberto Morán y Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "2F40FNT9WPAcgIm4j9wZJ5"
  },
  {
    "id": "pugliese-70",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-14",
    "title": "Cabecitas blancas",
    "singer": "Roberto Chanel",
    "discMatrix": "16751",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alberto Pugliese",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cabecitas blancas Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "2LPlxnvxmrLQ93fooiISPY"
  },
  {
    "id": "pugliese-71",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1947-12-01",
    "title": "Andá que te cure Lola",
    "singer": "Roberto Chanel",
    "discMatrix": "16812",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "M",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Caruso",
    "lyricist": "Luis Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Andá que te cure Lola Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "54buwwPAr12b5G5hmhgPD6"
  },
  {
    "id": "pugliese-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-01",
    "title": "El buscapié",
    "singer": "Instrumental",
    "discMatrix": "16813",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Dames",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El buscapié Instrumental Osvaldo Pugliese instr. inst. instr inst 47 1947",
    "spotifyTrackId": "5nTsGYSVeCLuKXGm1DTEGe"
  },
  {
    "id": "pugliese-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-16",
    "title": "Bolero",
    "singer": "Roberto Chanel",
    "discMatrix": "16822",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Chanel",
    "discType": "Shellac",
    "duration": "",
    "composer": "Santos Lipesker",
    "lyricist": "Reinaldo Yiso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bolero Roberto Chanel Osvaldo Pugliese 47 1947",
    "spotifyTrackId": "4qIIqCHT78sKx0PMNw6cTD"
  },
  {
    "id": "pugliese-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-12-16",
    "title": "Entrada prohibida",
    "singer": "Instrumental",
    "discMatrix": "16823",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1947,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Teisseire",
    "lyricist": "Germán Teisseire",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entrada prohibida Instrumental Osvaldo Pugliese instr. inst. instr inst 47 1947",
    "spotifyTrackId": "74p9h4B8TNz1D3PaxVecAV"
  },
  {
    "id": "pugliese-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-04-06",
    "title": "Patético",
    "singer": "Instrumental",
    "discMatrix": "16871",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jorge Caldara",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Patético Instrumental Osvaldo Pugliese instr. inst. instr inst 48 1948",
    "spotifyTrackId": "3D1IU4vX5mmHfmGc5kueNW"
  },
  {
    "id": "pugliese-76",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-04-06",
    "title": "Dejame en paz",
    "singer": "Alberto Morán",
    "discMatrix": "16872",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Américo Juan Actis",
    "lyricist": "Oscar Rubens",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dejame en paz Alberto Morán Osvaldo Pugliese 48 1948",
    "spotifyTrackId": "26koBSL68tPularYeXhLqZ"
  },
  {
    "id": "pugliese-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-24",
    "title": "Negracha",
    "singer": "Instrumental",
    "discMatrix": "16970",
    "publisher": "Odeon",
    "discNo": 7699,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Negracha Instrumental Osvaldo Pugliese instr. inst. instr inst 48 1948",
    "spotifyTrackId": "3fqeMZYXxa1Y3VFjRX1IoC"
  },
  {
    "id": "pugliese-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-07-14",
    "title": "Boedo",
    "singer": "Instrumental",
    "discMatrix": "16997",
    "publisher": "Odeon",
    "discNo": 7699,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Dante A. Linyera",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Boedo Instrumental Osvaldo Pugliese instr. inst. instr inst 48 1948",
    "spotifyTrackId": "3LzziIpZ7CYaI0FFnu2WIa"
  },
  {
    "id": "pugliese-79",
    "trackNo": null,
    "genre": "Vals",
    "date": "1948-09-15",
    "title": "Dos que se aman",
    "singer": "Alberto Morán",
    "discMatrix": "17071",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "V",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Tormo",
    "lyricist": "Manuel María Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos que se aman Alberto Morán Osvaldo Pugliese 48 1948",
    "spotifyTrackId": "7IP5fEnPsUrYbQ7o89XpUm"
  },
  {
    "id": "pugliese-80",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-09-13",
    "title": "Punto y coma",
    "singer": "Instrumental",
    "discMatrix": "17064",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1948,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martínez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Punto y coma Instrumental Osvaldo Pugliese instr. inst. instr inst 48 1948",
    "spotifyTrackId": "5YC6XSwRk2F9dvX7rdwYRI"
  },
  {
    "id": "pugliese-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-05-04",
    "title": "Frente a una copa",
    "singer": "Alberto Morán",
    "discMatrix": "17280",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Amor",
    "lyricist": "Santiago Wainer",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Frente a una copa Alberto Morán Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "3G6NyHughCiu3XMDC72EsD"
  },
  {
    "id": "pugliese-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-05-04",
    "title": "Chuzas",
    "singer": "Instrumental",
    "discMatrix": "17279",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chuzas Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "5jI5fzaUP4wDzEPSag8jqU"
  },
  {
    "id": "pugliese-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-05-31",
    "title": "Y volvemos a querernos",
    "singer": "Alberto Morán",
    "discMatrix": "17306",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luciano Leocata",
    "lyricist": "Abel Aznar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y volvemos a querernos Alberto Morán Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "5pKhQUgyocHZ9iUFFKU2iH"
  },
  {
    "id": "pugliese-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-05-31",
    "title": "Malandraca",
    "singer": "Instrumental",
    "discMatrix": "17307",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Malandraca Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "4EBr0vzikVLWiLD1xbwxpa"
  },
  {
    "id": "pugliese-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-08-22",
    "title": "Puente Alsina",
    "singer": "Jorge Vidal",
    "discMatrix": "17396",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Benjamín Tagle Lara",
    "lyricist": "Benjamín Tagle Lara",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Puente Alsina Jorge Vidal Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "4GGVx2h8DuuTjwMJzIx721"
  },
  {
    "id": "pugliese-86",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-08-22",
    "title": "Pinta brava",
    "singer": "Instrumental",
    "discMatrix": "17395",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pinta brava Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "3qaD7KgTnZ0WpUcLdkJAWB"
  },
  {
    "id": "pugliese-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-09-27",
    "title": "La cieguita",
    "singer": "Jorge Vidal",
    "discMatrix": "17435",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Keppler Lais (Patricio Muñoz Aceña)",
    "lyricist": "Ramuncho (Ramón Bertrán Reyna)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cieguita Jorge Vidal Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "0rSJWd07Lzf8SHly2nQKCP"
  },
  {
    "id": "pugliese-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-09-27",
    "title": "Bien compadre",
    "singer": "Instrumental",
    "discMatrix": "17434",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien compadre Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "2WZvpwddAliOfp247TZoEB"
  },
  {
    "id": "pugliese-89",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-10-25",
    "title": "Descorazonado",
    "singer": "Alberto Morán",
    "discMatrix": "17467",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oscar Herrero",
    "lyricist": "Marvil (Elizardo Martínez Vilas)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Descorazonado Alberto Morán Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "0ivfg68m93AqSN0ZHxqfqe"
  },
  {
    "id": "pugliese-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-11-28",
    "title": "Canaro en París",
    "singer": "Instrumental",
    "discMatrix": "17500",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Alejandro Scarpino",
    "lyricist": "José Scarpino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canaro en París Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "4DqJNSh0oR17SoRjU4E3Ox"
  },
  {
    "id": "pugliese-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-12-07",
    "title": "Testamento de arrabal",
    "singer": "Jorge Vidal",
    "discMatrix": "17515",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oscar Castagniaro",
    "lyricist": "Raúl Hormaza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Testamento de arrabal Jorge Vidal Osvaldo Pugliese 49 1949",
    "spotifyTrackId": "3QjsX0Zlpo31QrFAvQZlJn"
  },
  {
    "id": "pugliese-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1949-12-07",
    "title": "Catuzo",
    "singer": "Instrumental",
    "discMatrix": "17516",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1949,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Catuzo Instrumental Osvaldo Pugliese instr. inst. instr inst 49 1949",
    "spotifyTrackId": "0MroKcO4WLfqGJv5kraR70"
  },
  {
    "id": "pugliese-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-03-07",
    "title": "Quiero verte una vez más",
    "singer": "Alberto Morán",
    "discMatrix": "17583",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Canaro",
    "lyricist": "José María Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quiero verte una vez más Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "2SQueNDrWek56CnTstKJeb"
  },
  {
    "id": "pugliese-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-03-07",
    "title": "Ventanita de arrabal",
    "singer": "Jorge Vidal",
    "discMatrix": "17582",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Antonio Scatasso",
    "lyricist": "Pascual Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ventanita de arrabal Jorge Vidal Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "0vSIyQrmGgjdxVQ9j3VA69"
  },
  {
    "id": "pugliese-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-03-29",
    "title": "Hacelo por la vieja",
    "singer": "Alberto Morán",
    "discMatrix": "17602",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Carlos Viván",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hacelo por la vieja Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "4t6Jrsh9qoCC4dlJQAP8hH"
  },
  {
    "id": "pugliese-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-03-29",
    "title": "De floreo",
    "singer": "Instrumental",
    "discMatrix": "17601",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Julio Carrasco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De floreo Instrumental Osvaldo Pugliese instr. inst. instr inst 50 1950",
    "spotifyTrackId": "43eKWL2LwbrKk09hbQrnDI"
  },
  {
    "id": "pugliese-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-03",
    "title": "Vieja recova",
    "singer": "Jorge Vidal",
    "discMatrix": "17636",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vieja recova Jorge Vidal Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "3wEv3Td2O8oBwKxULcLyoo"
  },
  {
    "id": "pugliese-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-05-03",
    "title": "Pastoral",
    "singer": "Instrumental",
    "discMatrix": "17635",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Caldarella",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pastoral Instrumental Osvaldo Pugliese instr. inst. instr inst 50 1950",
    "spotifyTrackId": "1YR4TeahOElrWVo72J564y"
  },
  {
    "id": "pugliese-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-06-30",
    "title": "Cadenas",
    "singer": "Alberto Morán",
    "discMatrix": "17700",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oscar Castagniaro",
    "lyricist": "Antonio Cantó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cadenas Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "20u6CLU9h2R8SpsSAgpEXx"
  },
  {
    "id": "pugliese-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-06-30",
    "title": "Porque canto el tango",
    "singer": "Jorge Vidal",
    "discMatrix": "17699",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luis Rubistein",
    "lyricist": "Luis Rubistein",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Porque canto el tango Jorge Vidal Osvaldo Pugliese 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-07-26",
    "title": "Y mientes todavía",
    "singer": "Alberto Morán",
    "discMatrix": "17732",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Luciano Leocata",
    "lyricist": "Abel Aznar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y mientes todavía Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "2b4CuOxKL7uiYPLC88SyC5"
  },
  {
    "id": "pugliese-102",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-07-26",
    "title": "Don Aniceto",
    "singer": "Instrumental",
    "discMatrix": "17731",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Esteban Gilardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Aniceto Instrumental Osvaldo Pugliese instr. inst. instr inst 50 1950",
    "spotifyTrackId": "5hGit17D7hoEtjs2a6Awgm"
  },
  {
    "id": "pugliese-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-10-24",
    "title": "Cobardía",
    "singer": "Alberto Morán",
    "discMatrix": "17847",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Charlo",
    "lyricist": "Luis César Amadori",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cobardía Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "1lIC92rNcPb3Tecf2xIOED"
  },
  {
    "id": "pugliese-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-10-24",
    "title": "El tobiano",
    "singer": "Instrumental",
    "discMatrix": "17846",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tobiano Instrumental Osvaldo Pugliese instr. inst. instr inst 50 1950",
    "spotifyTrackId": "0En3KfHLWw9B8wDXpxzwso"
  },
  {
    "id": "pugliese-105",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1950-11-16",
    "title": "Un baile a beneficio (La podrida)",
    "singer": "Jorge Vidal",
    "discMatrix": "17892",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "M",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Carlos Caccaviello",
    "lyricist": "José Alfredo Fernández",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un baile a beneficio (La podrida) Jorge Vidal Osvaldo Pugliese 50 1950",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-11-16",
    "title": "Barra querida",
    "singer": "Jorge Vidal",
    "discMatrix": "17891",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Vidal",
    "discType": "Shellac",
    "duration": "",
    "composer": "Carlos Sánchez",
    "lyricist": "César Vedani",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barra querida Jorge Vidal Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "4s0IeFQ4FaabbfDvS3ShXv"
  },
  {
    "id": "pugliese-107",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-11-24",
    "title": "Llevátelo todo",
    "singer": "Alberto Morán",
    "discMatrix": "17908",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Rodolfo Sciammarella",
    "lyricist": "Rodolfo Sciammarella",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llevátelo todo Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "5pPVS5lUArnp3sKQvZ58vJ"
  },
  {
    "id": "pugliese-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-11-24",
    "title": "Llamame",
    "singer": "Alberto Morán",
    "discMatrix": "17907",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1950,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ángel Domínguez",
    "lyricist": "José Rótulo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llamame Alberto Morán Osvaldo Pugliese 50 1950",
    "spotifyTrackId": "16IifFyT1kU9OR3bJdmrpe"
  },
  {
    "id": "pugliese-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-05-22",
    "title": "Barro",
    "singer": "Alberto Morán",
    "discMatrix": "18024",
    "publisher": "Odeon",
    "discNo": 55243,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barro Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "5IfiffaSXwipRyKf3xQCEz"
  },
  {
    "id": "pugliese-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-05-22",
    "title": "De vuelta al bulín",
    "singer": "Alberto Morán",
    "discMatrix": "18025",
    "publisher": "Odeon",
    "discNo": 55243,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martínez",
    "lyricist": "Pascual Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De vuelta al bulín Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "4bKisUbzOCmJWHJvdIdFDW"
  },
  {
    "id": "pugliese-111",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-06-27",
    "title": "Don Atilio",
    "singer": "Instrumental",
    "discMatrix": "18084",
    "publisher": "Odeon",
    "discNo": 55262,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Don Atilio Instrumental Osvaldo Pugliese instr. inst. instr inst 51 1951",
    "spotifyTrackId": "7oBlDW6WdaDBkY7PFrAJPu"
  },
  {
    "id": "pugliese-112",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-06-27",
    "title": "El mate amargo",
    "singer": "Alberto Morán",
    "discMatrix": "18085",
    "publisher": "Odeon",
    "discNo": 55262,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Oscar Herrero",
    "lyricist": "Marvil (Elizardo Martínez Vilas)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El mate amargo Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "4469sAHCFzODjMl9qfvWwq"
  },
  {
    "id": "pugliese-113",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-07-31",
    "title": "Bien milonga",
    "singer": "Instrumental",
    "discMatrix": "18136",
    "publisher": "Odeon",
    "discNo": 55280,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Ismael Spitalnik",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien milonga Instrumental Osvaldo Pugliese instr. inst. instr inst 51 1951",
    "spotifyTrackId": "0BMH0khCNBmn43bxyxPWI5"
  },
  {
    "id": "pugliese-114",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-07-31",
    "title": "Pasional",
    "singer": "Alberto Morán",
    "discMatrix": "18137",
    "publisher": "Odeon",
    "discNo": 55280,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Jorge Caldara",
    "lyricist": "Mario Soto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pasional Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "1FWlywF2x2J5jN8l7C39Im"
  },
  {
    "id": "pugliese-115",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-10-02",
    "title": "El cielo en las manos",
    "singer": "Alberto Morán",
    "discMatrix": "18216",
    "publisher": "Odeon",
    "discNo": 55304,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Astor Piazzolla",
    "lyricist": "Homero Cárpena",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cielo en las manos Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "4BH1uFJznnfprWxvlt4VE9"
  },
  {
    "id": "pugliese-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-10-02",
    "title": "El refrán",
    "singer": "Instrumental",
    "discMatrix": "18215",
    "publisher": "Odeon",
    "discNo": 55304,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Roberto Héctor Peppe",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El refrán Instrumental Osvaldo Pugliese instr. inst. instr inst 51 1951",
    "spotifyTrackId": "4Lnp3MGkqNZispf9mTFB9W"
  },
  {
    "id": "pugliese-117",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-06",
    "title": "Que nunca me falte",
    "singer": "Alberto Morán",
    "discMatrix": "18291",
    "publisher": "Odeon",
    "discNo": 55330,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Héctor Morales",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que nunca me falte Alberto Morán Osvaldo Pugliese 51 1951",
    "spotifyTrackId": "4pqyuyOFhUyrS5wmxEaHV6"
  },
  {
    "id": "pugliese-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-12-10",
    "title": "Olivero",
    "singer": "Instrumental",
    "discMatrix": "18300",
    "publisher": "Odeon",
    "discNo": 55330,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1951,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "José Martínez",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Olivero Instrumental Osvaldo Pugliese instr. inst. instr inst 51 1951",
    "spotifyTrackId": "54NHTZcWigl4ZmyDJRpXm2"
  },
  {
    "id": "pugliese-119",
    "trackNo": null,
    "genre": "Vals",
    "date": "1952-01-28",
    "title": "La noche que me esperes",
    "singer": "Alberto Morán",
    "discMatrix": "18339",
    "publisher": "Odeon",
    "discNo": 55372,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "V",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Canaro",
    "lyricist": "Juan Canaro",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La noche que me esperes Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "4ulhGsLIqvzMrUI7mECZxW"
  },
  {
    "id": "pugliese-120",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-01-28",
    "title": "Cualquier cosa",
    "singer": "Alberto Morán",
    "discMatrix": "18340",
    "publisher": "Odeon",
    "discNo": 55372,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Herminia Velich",
    "lyricist": "Herminia Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cualquier cosa Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "0uVOXOMgMYbXGiMhrjk3kF"
  },
  {
    "id": "pugliese-121",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-05-06",
    "title": "Para dos",
    "singer": "Instrumental",
    "discMatrix": "18380",
    "publisher": "Odeon",
    "discNo": 55391,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Para dos Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "0Yn93YCskfws3m5U4QeQvt"
  },
  {
    "id": "pugliese-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-05-06",
    "title": "Entrador",
    "singer": "Instrumental",
    "discMatrix": "18381",
    "publisher": "Odeon",
    "discNo": 55391,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Mario Demarco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entrador Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "0F0DYKJ9ihv5zzGX4vlM5D"
  },
  {
    "id": "pugliese-123",
    "trackNo": null,
    "genre": "Tonada",
    "date": "1952-06-27",
    "title": "La tupungatina",
    "singer": "Instrumental",
    "discMatrix": "18452",
    "publisher": "Odeon",
    "discNo": 55425,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Cristino Tapia",
    "lyricist": "Cristino Tapia",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La tupungatina Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "1rHt3vYKY7lwn0WCX6E4Yl"
  },
  {
    "id": "pugliese-124",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-06-27",
    "title": "Ahora no me conocés",
    "singer": "Alberto Morán",
    "discMatrix": "18453",
    "publisher": "Odeon",
    "discNo": 55425,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Armando Baliotti",
    "lyricist": "Carlos Giampetruzzi (Giampé)",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ahora no me conocés Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "7ClNFkcFpYy9KCL5SXxZ4f"
  },
  {
    "id": "pugliese-125",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-10-09",
    "title": "Si sos brujo",
    "singer": "Instrumental",
    "discMatrix": "18647",
    "publisher": "Odeon",
    "discNo": 55483,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "Shellac",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si sos brujo Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "01PrioWcr9vAcQh3KkPqla"
  },
  {
    "id": "pugliese-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-10-09",
    "title": "Por pecadora",
    "singer": "Alberto Morán",
    "discMatrix": "18648",
    "publisher": "Odeon",
    "discNo": 55483,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "Shellac",
    "duration": "",
    "composer": "Juan Caldarella",
    "lyricist": "Mario Soto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por pecadora Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "6bEDSbEQr5BADntryaWI9g"
  },
  {
    "id": "pugliese-127",
    "trackNo": null,
    "genre": "Tonada",
    "date": "1952-11-13",
    "title": "La tupungatina",
    "singer": "Instrumental",
    "discMatrix": "18452/1",
    "publisher": "Odeon",
    "discNo": 55425,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Cristino Tapia",
    "lyricist": "Cristino Tapia",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La tupungatina Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "1rHt3vYKY7lwn0WCX6E4Yl"
  },
  {
    "id": "pugliese-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-13",
    "title": "El embrollo",
    "singer": "Instrumental",
    "discMatrix": "18705",
    "publisher": "Odeon",
    "discNo": 55500,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Esteban Gilardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El embrollo Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "1RceDRvrhgkJcjkICIkPM4"
  },
  {
    "id": "pugliese-129",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-13",
    "title": "N… N…",
    "singer": "Instrumental",
    "discMatrix": "16421/1",
    "publisher": "Odeon",
    "discNo": 55521,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "N… N… Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-130",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-13",
    "title": "La yumba",
    "singer": "Instrumental",
    "discMatrix": "18758/1",
    "publisher": "Odeon",
    "discNo": 55521,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La yumba Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "4u1SyTVMYoCe8VQJvrtqU0"
  },
  {
    "id": "pugliese-131",
    "trackNo": null,
    "genre": "Vals",
    "date": "1952-11-18",
    "title": "Manos adoradas",
    "singer": "Alberto Morán",
    "discMatrix": "18714",
    "publisher": "Odeon",
    "discNo": 55500,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "V",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Roberto Rufino",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Manos adoradas Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "5auvwFGPeYprBc2OBd6bSM"
  },
  {
    "id": "pugliese-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-24",
    "title": "La cachila",
    "singer": "Instrumental",
    "discMatrix": "14937/1",
    "publisher": "Odeon",
    "discNo": 55565,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Héctor Polito",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cachila Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "29NuBlrVItr4etfB7JEcUM"
  },
  {
    "id": "pugliese-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-24",
    "title": "Pasional",
    "singer": "Alberto Morán",
    "discMatrix": "17137/1",
    "publisher": "Odeon",
    "discNo": 55655,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Jorge Caldara",
    "lyricist": "Mario Soto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pasional Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "1FWlywF2x2J5jN8l7C39Im"
  },
  {
    "id": "pugliese-134",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-29",
    "title": "Mala junta",
    "singer": "Instrumental",
    "discMatrix": "13082/1",
    "publisher": "Odeon",
    "discNo": 55539,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Juan Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mala junta Instrumental Osvaldo Pugliese instr. inst. instr inst 52 1952",
    "spotifyTrackId": "7HaSiLU1DsH5wKK05jW7Jc"
  },
  {
    "id": "pugliese-135",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-12-03",
    "title": "Barro",
    "singer": "Alberto Morán",
    "discMatrix": "18024/1",
    "publisher": "Odeon",
    "discNo": 55539,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "Horacio Sanguinetti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barro Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "5IfiffaSXwipRyKf3xQCEz"
  },
  {
    "id": "pugliese-136",
    "trackNo": null,
    "genre": "Vals",
    "date": "1952-12-03",
    "title": "La noche que me esperes",
    "singer": "Alberto Morán",
    "discMatrix": "18939/1",
    "publisher": "Odeon",
    "discNo": 55372,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1952,
    "genreShort": "V",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Juan Canaro",
    "lyricist": "Juan Canaro",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La noche que me esperes Alberto Morán Osvaldo Pugliese 52 1952",
    "spotifyTrackId": "4ulhGsLIqvzMrUI7mECZxW"
  },
  {
    "id": "pugliese-137",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-05-28",
    "title": "Chiqué",
    "singer": "Instrumental",
    "discMatrix": "18934",
    "publisher": "Odeon",
    "discNo": 55695,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Ricardo Luis Brignolo",
    "lyricist": "Ricardo Luis Brignolo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Chiqué Instrumental Osvaldo Pugliese instr. inst. instr inst 53 1953",
    "spotifyTrackId": "2zDWzTuHiQ3guwDbH16qAQ"
  },
  {
    "id": "pugliese-138",
    "trackNo": null,
    "genre": "Canción",
    "date": "1953-05-28",
    "title": "Caminito soleado",
    "singer": "Alberto Morán y Juan Carlos Cobos",
    "discMatrix": "18935",
    "publisher": "Odeon",
    "discNo": 55695,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "",
    "singerShort": "Morán y Cobos",
    "discType": "",
    "duration": "",
    "composer": "Carlos Gardel",
    "lyricist": "Alfredo Le Pera",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Caminito soleado Alberto Morán y Juan Carlos Cobos Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "3Vr5FsFRNevFnqOg81G6Xz"
  },
  {
    "id": "pugliese-139",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-07-14",
    "title": "Milonguera",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19018",
    "publisher": "Odeon",
    "discNo": 55759,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "José María Aguilar",
    "lyricist": "José María Aguilar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonguera Juan Carlos Cobos Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "3D1IU4vX5mmHfmGc5kueNW"
  },
  {
    "id": "pugliese-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-07-14",
    "title": "San José de Flores",
    "singer": "Alberto Morán",
    "discMatrix": "19019",
    "publisher": "Odeon",
    "discNo": 55759,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Armando Acquarone",
    "lyricist": "Enrique Gaudino",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "San José de Flores Alberto Morán Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "5A0Kt9y1r6wFRd6qho9b3n"
  },
  {
    "id": "pugliese-141",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-06",
    "title": "Olvidao",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19046",
    "publisher": "Odeon",
    "discNo": 55766,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "Guillermo Barbieri",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Olvidao Juan Carlos Cobos Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "7c5GJPpzYtiBC0ibaiJ0O8"
  },
  {
    "id": "pugliese-142",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-08-16",
    "title": "Desvelo (De flor en flor)",
    "singer": "Alberto Morán",
    "discMatrix": "19047",
    "publisher": "Odeon",
    "discNo": 55766,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Bonessi",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desvelo (De flor en flor) Alberto Morán Osvaldo Pugliese 53 1953",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-143",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-10-06",
    "title": "Seguime si podés",
    "singer": "Instrumental",
    "discMatrix": "19166",
    "publisher": "Odeon",
    "discNo": 55790,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Alejandro Scarpino",
    "lyricist": "Antonio Anselmi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Seguime si podés Instrumental Osvaldo Pugliese instr. inst. instr inst 53 1953",
    "spotifyTrackId": "37MgZ8TAOSf4I54VeeUHBC"
  },
  {
    "id": "pugliese-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-10-06",
    "title": "La última copa",
    "singer": "Alberto Morán",
    "discMatrix": "19167",
    "publisher": "Odeon",
    "discNo": 55790,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Francisco Canaro",
    "lyricist": "Juan Andres Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La última copa Alberto Morán Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "62iDxMu2HSUbzaddSGEivV"
  },
  {
    "id": "pugliese-145",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-11-25",
    "title": "La rayuela",
    "singer": "Instrumental",
    "discMatrix": "19252",
    "publisher": "Odeon",
    "discNo": 55843,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La rayuela Instrumental Osvaldo Pugliese instr. inst. instr inst 53 1953",
    "spotifyTrackId": "3As96c0O9rOyeygduK9mql"
  },
  {
    "id": "pugliese-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-11-25",
    "title": "Es preciso que te vayas",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19253",
    "publisher": "Odeon",
    "discNo": 55843,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1953,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "Juan Carlos Cobián",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Es preciso que te vayas Juan Carlos Cobos Osvaldo Pugliese 53 1953",
    "spotifyTrackId": "4bApBSLfIEVDXRfnRI8BSi"
  },
  {
    "id": "pugliese-147",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-01-05",
    "title": "Picaneao",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19344",
    "publisher": "Odeon",
    "discNo": 55864,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "Héctor Balbi",
    "lyricist": "Enrique Cadícamo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Picaneao Juan Carlos Cobos Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "4dny9FCTkd5rhuETBTqhHl"
  },
  {
    "id": "pugliese-148",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-01-05",
    "title": "Porque no te tengo más",
    "singer": "Alberto Morán",
    "discMatrix": "19345",
    "publisher": "Odeon",
    "discNo": 55864,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Oscar Luis Herrero",
    "lyricist": "Marvil",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Porque no te tengo más Alberto Morán Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "1wO27a0X1n8gaHZVLdz5yT"
  },
  {
    "id": "pugliese-149",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-03-17",
    "title": "Mi lamento",
    "singer": "Instrumental",
    "discMatrix": "19400",
    "publisher": "Odeon",
    "discNo": 55898,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Julio Carrasco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi lamento Instrumental Osvaldo Pugliese instr. inst. instr inst 54 1954",
    "spotifyTrackId": "453fpbSjpBNl1Nt4I2FwBy"
  },
  {
    "id": "pugliese-150",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-03-17",
    "title": "La mentirosa",
    "singer": "Alberto Morán",
    "discMatrix": "19402",
    "publisher": "Odeon",
    "discNo": 55898,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Anselmo Aieta",
    "lyricist": "Francisco García Jiménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mentirosa Alberto Morán Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "6cQgZzZmzmgg6JmxwvotZy"
  },
  {
    "id": "pugliese-151",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-03-17",
    "title": "No es más que yo",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19401",
    "publisher": "Odeon",
    "discNo": 55916,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "Luis Mandarino",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No es más que yo Juan Carlos Cobos Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "4uAivxy18etIcJnnO9VpaB"
  },
  {
    "id": "pugliese-152",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-03-17",
    "title": "No quiero perderte",
    "singer": "Alberto Morán",
    "discMatrix": "19403",
    "publisher": "Odeon",
    "discNo": 55916,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Morán",
    "discType": "",
    "duration": "",
    "composer": "Enrique Camerano",
    "lyricist": "Alberto Morán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No quiero perderte Alberto Morán Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "4EB0dlRMQa1t8jHdXSTmR3"
  },
  {
    "id": "pugliese-153",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-05-13",
    "title": "Nueve de julio",
    "singer": "Instrumental",
    "discMatrix": "19486",
    "publisher": "Odeon",
    "discNo": 55927,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "José Luis Padula",
    "lyricist": "Eugenio Cárdenas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nueve de julio Instrumental Osvaldo Pugliese instr. inst. instr inst 54 1954",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-154",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-05-13",
    "title": "Te aconsejo que me olvides",
    "singer": "Juan Carlos Cobos",
    "discMatrix": "19487",
    "publisher": "Odeon",
    "discNo": 55927,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Cobos",
    "discType": "",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Jorge Curi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te aconsejo que me olvides Juan Carlos Cobos Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "4F4ve30NTlS43dBwSfT3O7"
  },
  {
    "id": "pugliese-155",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-07-14",
    "title": "La guitarrita",
    "singer": "Instrumental",
    "discMatrix": "19576",
    "publisher": "Odeon",
    "discNo": 55978,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "Gabriel Clausi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La guitarrita Instrumental Osvaldo Pugliese instr. inst. instr inst 54 1954",
    "spotifyTrackId": "6sZE4WTqxei7SiSzH1DRcK"
  },
  {
    "id": "pugliese-156",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-07-14",
    "title": "A la luz del candil",
    "singer": "Miguel Montero",
    "discMatrix": "19577",
    "publisher": "Odeon",
    "discNo": 55978,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Carlos Vicente Geroni Flores",
    "lyricist": "Julio Navarrine",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la luz del candil Miguel Montero Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "7kOK47uZDcqpYYEcaxhorI"
  },
  {
    "id": "pugliese-157",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-10-29",
    "title": "Canzoneta",
    "singer": "Jorge Maciel",
    "discMatrix": "19787",
    "publisher": "Odeon",
    "discNo": 51633,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Erma Suárez",
    "lyricist": "Enrique Lary",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canzoneta Jorge Maciel Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "1IhgI4uqCNCRNFihPmT2GU"
  },
  {
    "id": "pugliese-158",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-10-29",
    "title": "Por una muñeca",
    "singer": "Miguel Montero",
    "discMatrix": "19578",
    "publisher": "Odeon",
    "discNo": 51633,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "Manuel Barros",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por una muñeca Miguel Montero Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "3LtLSRS7ZnN1h0rBhUhSdk"
  },
  {
    "id": "pugliese-159",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-13",
    "title": "Si yo pudiera olvidarla",
    "singer": "Jorge Maciel",
    "discMatrix": "19857",
    "publisher": "Odeon",
    "discNo": 51657,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Tarantino",
    "lyricist": "Alfredo Lucero Palacios",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si yo pudiera olvidarla Jorge Maciel Osvaldo Pugliese 54 1954",
    "spotifyTrackId": "4PlDyl4Y7Y2BJ0GdmXzuO2"
  },
  {
    "id": "pugliese-160",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-13",
    "title": "Mi fuelle rezonga",
    "singer": "Instrumental",
    "discMatrix": "19858",
    "publisher": "Odeon",
    "discNo": 51657,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1954,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Pedro Toscano",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi fuelle rezonga Instrumental Osvaldo Pugliese instr. inst. instr inst 54 1954",
    "spotifyTrackId": "4uNrv4GA3NzTd9OiJE9ysH"
  },
  {
    "id": "pugliese-161",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-02",
    "title": "Emancipación",
    "singer": "Instrumental",
    "discMatrix": "20440",
    "publisher": "Odeon",
    "discNo": 51802,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Alfredo Bevilacqua",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Emancipación Instrumental Osvaldo Pugliese instr. inst. instr inst 55 1955",
    "spotifyTrackId": "2By411EiLOF7tlOTA9farK"
  },
  {
    "id": "pugliese-162",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-02",
    "title": "Mi serenata",
    "singer": "Miguel Montero y Jorge Maciel",
    "discMatrix": "20441",
    "publisher": "Odeon",
    "discNo": 51802,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Montero y Maciel",
    "discType": "",
    "duration": "",
    "composer": "Edgardo Donato",
    "lyricist": "Juan Carlos Thorry",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi serenata Miguel Montero y Jorge Maciel Osvaldo Pugliese 55 1955",
    "spotifyTrackId": "0EwznFwGRtFoQgl8hlo4v8"
  },
  {
    "id": "pugliese-163",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-02",
    "title": "Esta noche de luna",
    "singer": "Jorge Maciel",
    "discMatrix": "20442",
    "publisher": "Odeon",
    "discNo": 51815,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "José García",
    "lyricist": "Héctor Marcó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche de luna Jorge Maciel Osvaldo Pugliese 55 1955",
    "spotifyTrackId": "39V1f1fG9c8lFtaOt1CLru"
  },
  {
    "id": "pugliese-164",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-02",
    "title": "Antiguo reloj de cobre",
    "singer": "Miguel Montero",
    "discMatrix": "20443",
    "publisher": "Odeon",
    "discNo": 51815,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Marvezzi",
    "lyricist": "Eduardo Marvezzi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Antiguo reloj de cobre Miguel Montero Osvaldo Pugliese 55 1955",
    "spotifyTrackId": "3HvZx0yPXNOeJPjllOLZbP"
  },
  {
    "id": "pugliese-165",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-22",
    "title": "El poncho del amor",
    "singer": "Miguel Montero",
    "discMatrix": "20470",
    "publisher": "Odeon",
    "discNo": 51829,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Antonio Scatasso",
    "lyricist": "Alberto Vacarezza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El poncho del amor Miguel Montero Osvaldo Pugliese 55 1955",
    "spotifyTrackId": "3T7lzXKr4cH7Ix0yM1THYn"
  },
  {
    "id": "pugliese-166",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-09-22",
    "title": "Cascabelito",
    "singer": "Jorge Maciel",
    "discMatrix": "20471",
    "publisher": "Odeon",
    "discNo": 51829,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1955,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "José Bohr -Juan Andrés Caruso",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cascabelito Jorge Maciel Osvaldo Pugliese 55 1955",
    "spotifyTrackId": "61NKYnjyzaz6ezsSNTeEAf"
  },
  {
    "id": "pugliese-167",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-01-31",
    "title": "A mis compañeros",
    "singer": "Instrumental",
    "discMatrix": "20770",
    "publisher": "Odeon",
    "discNo": 51883,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A mis compañeros Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": "6Y85ETDxg2tXQirfM5T5O5"
  },
  {
    "id": "pugliese-168",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-01-31",
    "title": "No juegues a la guerra",
    "singer": "Jorge Maciel",
    "discMatrix": "20771",
    "publisher": "Odeon",
    "discNo": 51883,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "M. R. Morales Miramonti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No juegues a la guerra Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "6W7nQItvjIPaXQv9oh9Nzc"
  },
  {
    "id": "pugliese-169",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-03-20",
    "title": "A Roberto Peppe",
    "singer": "Instrumental",
    "discMatrix": "20836",
    "publisher": "Odeon",
    "discNo": 51912,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Esteban Gilardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A Roberto Peppe Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": "3nDisS6hs0N2HYuoyO9AEV"
  },
  {
    "id": "pugliese-170",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-03-20",
    "title": "Qué te pasa Buenos Aires",
    "singer": "Miguel Montero",
    "discMatrix": "20837",
    "publisher": "Odeon",
    "discNo": 51912,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Cortti",
    "lyricist": "Juan José Correia",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué te pasa Buenos Aires Miguel Montero Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "16nbnoh62qscIn1J2vnJp9"
  },
  {
    "id": "pugliese-171",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-05-30",
    "title": "Muchachos mi último tango",
    "singer": "Miguel Montero",
    "discMatrix": "21025",
    "publisher": "Odeon",
    "discNo": 51968,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Ernesto Tití Rossi",
    "lyricist": "Justo Ricardo Thompson",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachos mi último tango Miguel Montero Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "2c015rgJUpDyY7H78fNZXj"
  },
  {
    "id": "pugliese-172",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-06-21",
    "title": "Y todavía te quiero",
    "singer": "Jorge Maciel",
    "discMatrix": "21093",
    "publisher": "Odeon",
    "discNo": 51961,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Luciano Leocata",
    "lyricist": "Abel Aznar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y todavía te quiero Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "3SO5WwZzlw1CZ7T2PbFedo"
  },
  {
    "id": "pugliese-173",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-06-21",
    "title": "Orgullo criollo",
    "singer": "Instrumental",
    "discMatrix": "21094",
    "publisher": "Odeon",
    "discNo": 51961,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Orgullo criollo Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-174",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-07-04",
    "title": "Remembranza",
    "singer": "Jorge Maciel",
    "discMatrix": "21123",
    "publisher": "Odeon",
    "discNo": 51968,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Mario Battistella",
    "lyricist": "Mario Melfi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Remembranza Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "74AkkLi6aHCNWxqmMgETzp"
  },
  {
    "id": "pugliese-175",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1956-08-02",
    "title": "Silueta porteña",
    "singer": "Miguel Montero y Jorge Maciel",
    "discMatrix": "21180",
    "publisher": "Odeon",
    "discNo": 51969,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "M",
    "singerShort": "Montero y Maciel",
    "discType": "",
    "duration": "",
    "composer": "Nicolás Luis Cuccaro",
    "lyricist": "Ernesto Nolli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Silueta porteña Miguel Montero y Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "53rKDpazOUevqmKUe3UrTv"
  },
  {
    "id": "pugliese-176",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-08-02",
    "title": "Te estaba esperando",
    "singer": "Miguel Montero",
    "discMatrix": "21181",
    "publisher": "Odeon",
    "discNo": 51969,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Roberto Giménez",
    "lyricist": "Roberto Giménez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te estaba esperando Miguel Montero Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "3jrkDHJXTEtC160d0b05It"
  },
  {
    "id": "pugliese-177",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-10-26",
    "title": "Muchacha",
    "singer": "Jorge Maciel",
    "discMatrix": "21472",
    "publisher": "Odeon",
    "discNo": 52047,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Jesús Rilo Suárez",
    "lyricist": "Jesús Rilo Suárez",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchacha Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-178",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-10-26",
    "title": "Suipacha",
    "singer": "Instrumental",
    "discMatrix": "21473",
    "publisher": "Odeon",
    "discNo": 52047,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Eduardo Arolas",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Suipacha Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": "5iftZqDkjsXwYf2oUfgsWY"
  },
  {
    "id": "pugliese-179",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-11-28",
    "title": "Nochero soy",
    "singer": "Instrumental",
    "discMatrix": "21600",
    "publisher": "Odeon",
    "discNo": 52068,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Oscar Herrero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nochero soy Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": "2KKPTdu84oDW3wktGnk729"
  },
  {
    "id": "pugliese-180",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-11-28",
    "title": "Y con eso dónde voy",
    "singer": "Miguel Montero",
    "discMatrix": "21601",
    "publisher": "Odeon",
    "discNo": 52068,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Ángel Cabral",
    "lyricist": "Enrique Dizeo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y con eso dónde voy Miguel Montero Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "2HswkObIxW0SiH8Lqz70sE"
  },
  {
    "id": "pugliese-181",
    "trackNo": null,
    "genre": "Arr. en Tango",
    "date": "1956-12-26",
    "title": "Mentiras",
    "singer": "Miguel Montero y Jorge Maciel",
    "discMatrix": "21726",
    "publisher": "Odeon",
    "discNo": 52090,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Montero y Maciel",
    "discType": "",
    "duration": "",
    "composer": "Juan Bautista Abad Reyes",
    "lyricist": "Juan Bautista Abad Reyes",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mentiras Miguel Montero y Jorge Maciel Osvaldo Pugliese 56 1956",
    "spotifyTrackId": "6hp1UeLB6xmpuWjDfcuGj3"
  },
  {
    "id": "pugliese-182",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-12-26",
    "title": "Marrón y azul",
    "singer": "Instrumental",
    "discMatrix": "21727",
    "publisher": "Odeon",
    "discNo": 52090,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1956,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Astor Piazzolla",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marrón y azul Instrumental Osvaldo Pugliese instr. inst. instr inst 56 1956",
    "spotifyTrackId": "5VWxiGoOFXOkk7qYPI4C1k"
  },
  {
    "id": "pugliese-183",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-04-04",
    "title": "Los largos del pibe",
    "singer": "Miguel Montero",
    "discMatrix": "21966",
    "publisher": "Odeon",
    "discNo": 52124,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Samuel Pribluda (Sampri)",
    "lyricist": "Enrique Lary",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Los largos del pibe Miguel Montero Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "4PpZ0DvoOdr55xZc4WJuVn"
  },
  {
    "id": "pugliese-184",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-04-04",
    "title": "Y sigo esperando",
    "singer": "Jorge Maciel",
    "discMatrix": "21967",
    "publisher": "Odeon",
    "discNo": 52124,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Cayetano Ziccaro",
    "lyricist": "Juan Velich",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y sigo esperando Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "7L38z58bKiynBamFj5WEAl"
  },
  {
    "id": "pugliese-185",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-05-13",
    "title": "La novia del suburbio",
    "singer": "Jorge Maciel",
    "discMatrix": "22087",
    "publisher": "Odeon",
    "discNo": 52163,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Roberto Rufino",
    "lyricist": "Agustín Minotti",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La novia del suburbio Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "0s5DymbwJetABB6QMGVrXO"
  },
  {
    "id": "pugliese-186",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-05-13",
    "title": "Pata ancha",
    "singer": "Instrumental",
    "discMatrix": "22088",
    "publisher": "Odeon",
    "discNo": 52163,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Mario Demarco",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pata ancha Instrumental Osvaldo Pugliese instr. inst. instr inst 57 1957",
    "spotifyTrackId": "7j88QokjNOcsbbCKTARW0H"
  },
  {
    "id": "pugliese-187",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-06-24",
    "title": "La conciencia",
    "singer": "Miguel Montero",
    "discMatrix": "22226",
    "publisher": "Odeon",
    "discNo": 52186,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "Manuel Barros",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La conciencia Miguel Montero Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "2P92dCli7WsVgT0jYdSqqR"
  },
  {
    "id": "pugliese-188",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-06-24",
    "title": "Un tango para el recuerdo",
    "singer": "Jorge Maciel",
    "discMatrix": "22227",
    "publisher": "Odeon",
    "discNo": 52186,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Rafael Del Bagno",
    "lyricist": "Antonio Cantó",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un tango para el recuerdo Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "62EymOFaG7i8BF1u5fRITX"
  },
  {
    "id": "pugliese-189",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-07-10",
    "title": "En otros caminos",
    "singer": "Miguel Montero y Jorge Maciel",
    "discMatrix": "22268",
    "publisher": "Odeon",
    "discNo": 52193,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Montero y Maciel",
    "discType": "",
    "duration": "",
    "composer": "Guillermo López",
    "lyricist": "Oscar Pariente",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En otros caminos Miguel Montero y Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "4wDwV0hHjKRZsl4pDkj0xR"
  },
  {
    "id": "pugliese-190",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-07-10",
    "title": "Cachá viaje",
    "singer": "Miguel Montero",
    "discMatrix": "22269",
    "publisher": "Odeon",
    "discNo": 52193,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Jaime Vila",
    "lyricist": "Ernesto Cardenal",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cachá viaje Miguel Montero Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "7oLTAZx6CNPQ82zHJeWz0C"
  },
  {
    "id": "pugliese-191",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-10-25",
    "title": "Yunta de oro",
    "singer": "Instrumental",
    "discMatrix": "22596",
    "publisher": "Odeon",
    "discNo": 55257,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Ruggiero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yunta de oro Instrumental Osvaldo Pugliese instr. inst. instr inst 57 1957",
    "spotifyTrackId": "24ebqQMwhRV8czRZC6lcSE"
  },
  {
    "id": "pugliese-192",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-10-25",
    "title": "No me hablen de ella",
    "singer": "Jorge Maciel",
    "discMatrix": "22597",
    "publisher": "Odeon",
    "discNo": 52257,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Ernesto ‘Tití’ Rossi",
    "lyricist": "Jorge Moreira",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No me hablen de ella Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "1WaD5LxmzYNryWgOjvNEue"
  },
  {
    "id": "pugliese-193",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-12-18",
    "title": "Perla fina",
    "singer": "Jorge Maciel",
    "discMatrix": "22753",
    "publisher": "Odeon",
    "discNo": 52286,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Juan Carlos Lotito",
    "lyricist": "Vladimiro Vega",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Perla fina Jorge Maciel Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "44lwf0yOwYC0MyUQh8wf0K"
  },
  {
    "id": "pugliese-194",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-12-18",
    "title": "Pa’ la muchachada",
    "singer": "Miguel Montero",
    "discMatrix": "22754",
    "publisher": "Odeon",
    "discNo": 52274,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1957,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Miguel Montero",
    "lyricist": "Carlos Jonsson",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pa’ la muchachada Miguel Montero Osvaldo Pugliese 57 1957",
    "spotifyTrackId": "3koILvERLZXGXivaRXDUBu"
  },
  {
    "id": "pugliese-195",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-01-02",
    "title": "Corazoneando",
    "singer": "Instrumental",
    "discMatrix": "22786",
    "publisher": "Odeon",
    "discNo": 52286,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Pugliese",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corazoneando Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "1fsjQWAgYfF3H3M88G0dKs"
  },
  {
    "id": "pugliese-196",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-01-02",
    "title": "Gente amiga",
    "singer": "Instrumental",
    "discMatrix": "22787",
    "publisher": "Odeon",
    "discNo": 52274,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Ismael Spitalnik",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gente amiga Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "76Bnqo1bmo0AqpdzCEIomu"
  },
  {
    "id": "pugliese-197",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-01-16",
    "title": "Julie",
    "singer": "Instrumental",
    "discMatrix": "22821",
    "publisher": "Odeon",
    "discNo": 52303,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Enrique Alessio",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Julie Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "7kIg5b6SGmzyzkiXVeehNA"
  },
  {
    "id": "pugliese-198",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-01-16",
    "title": "Gurisa",
    "singer": "Miguel Montero",
    "discMatrix": "22822",
    "publisher": "Odeon",
    "discNo": 52303,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Aminto Vidal",
    "lyricist": "Enrique García Satur",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gurisa Miguel Montero Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "4GQk5FUPglL5jHsW7O8BOF"
  },
  {
    "id": "pugliese-199",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-03-26",
    "title": "Buen amigo",
    "singer": "Instrumental",
    "discMatrix": "22929",
    "publisher": "Odeon",
    "discNo": 52327,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Julio De Caro",
    "lyricist": "Juan Carlos Marambio Catán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Buen amigo Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "3DUdHDd6YeVrCT7fi1P8ej"
  },
  {
    "id": "pugliese-200",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-03-26",
    "title": "Quién",
    "singer": "Jorge Maciel",
    "discMatrix": "22930",
    "publisher": "Odeon",
    "discNo": 52327,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Osvaldo Manzi",
    "lyricist": "Luis Lira",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quién Jorge Maciel Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "3rCkF4DI5WGf6uDwK2fhDD"
  },
  {
    "id": "pugliese-201",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-05-29",
    "title": "Adiós corazón",
    "singer": "Jorge Maciel",
    "discMatrix": "23117",
    "publisher": "Odeon",
    "discNo": 52352,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Lalo Etchegoncelay",
    "lyricist": "Héctor Sapelli",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós corazón Jorge Maciel Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "49kjjN6UySaHBjQMq8mJAB"
  },
  {
    "id": "pugliese-202",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-05-29",
    "title": "Che Cristóbal",
    "singer": "Miguel Montero",
    "discMatrix": "23118",
    "publisher": "Odeon",
    "discNo": 52352,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Orlando Calautti",
    "lyricist": "Roberto Vallejos",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Che Cristóbal Miguel Montero Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "4jm1jOx6xWMswpCxnFFzK7"
  },
  {
    "id": "pugliese-203",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-07-23",
    "title": "Las tres banderas",
    "singer": "Jorge Maciel",
    "discMatrix": "23240",
    "publisher": "Odeon",
    "discNo": 52381,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Roberto Rufino",
    "lyricist": "Carlos Antonio Russo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Las tres banderas Jorge Maciel Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "3PHfJ134IuSx4xtyDcGq1i"
  },
  {
    "id": "pugliese-204",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-07-23",
    "title": "Acquaforte",
    "singer": "Miguel Montero",
    "discMatrix": "23241",
    "publisher": "Odeon",
    "discNo": 52381,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Horacio Pettorossi",
    "lyricist": "Juan Carlos Marambio Catán",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Acquaforte Miguel Montero Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "1ZbrLBARw7vP6ulTzqbrK5"
  },
  {
    "id": "pugliese-205",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-08-06",
    "title": "La bordona",
    "singer": "Instrumental",
    "discMatrix": "23318",
    "publisher": "Odeon",
    "discNo": 52402,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Emilio Balcarce",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La bordona Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "190dKC0FUy0riNWhgkx64e"
  },
  {
    "id": "pugliese-206",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-08-06",
    "title": "Qué pinturita",
    "singer": "Instrumental",
    "discMatrix": "23319",
    "publisher": "Odeon",
    "discNo": 52402,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Esteban Gilardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué pinturita Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "41XqFinomJ83h25cmY563x"
  },
  {
    "id": "pugliese-207",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-11-19",
    "title": "Dicha pasada",
    "singer": "Miguel Montero",
    "discMatrix": "23653",
    "publisher": "Odeon",
    "discNo": 52442,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Montero",
    "discType": "",
    "duration": "",
    "composer": "Guillermo Barbieri",
    "lyricist": "Guillermo Barbieri",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dicha pasada Miguel Montero Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "31trrxo4dDLdrRbN9FxBgt"
  },
  {
    "id": "pugliese-208",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-11-19",
    "title": "Unión Cívica",
    "singer": "Instrumental",
    "discMatrix": "23654",
    "publisher": "Odeon",
    "discNo": 52442,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Domingo Santa Cruz",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Unión Cívica Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "3vrTSjO05MGVTG61Imd6ZO"
  },
  {
    "id": "pugliese-209",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-12-10",
    "title": "Alma de bohemio",
    "singer": "Instrumental",
    "discMatrix": "23695",
    "publisher": "Odeon",
    "discNo": 52445,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Roberto Firpo",
    "lyricist": "Juan Andrés Caruso",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alma de bohemio Instrumental Osvaldo Pugliese instr. inst. instr inst 58 1958",
    "spotifyTrackId": "7o74Lyw7fJ7m7LUNK5EVt7"
  },
  {
    "id": "pugliese-210",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-12-10",
    "title": "Por qué me la nombran",
    "singer": "Jorge Maciel",
    "discMatrix": "23696",
    "publisher": "Odeon",
    "discNo": 52455,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1958,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Ernesto ‘Tití’ Rossi",
    "lyricist": "Carlos Antonio Russo",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por qué me la nombran Jorge Maciel Osvaldo Pugliese 58 1958",
    "spotifyTrackId": "7L2yrMK9NoJ1eS9Wf9xxD1"
  },
  {
    "id": "pugliese-211",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "Quejumbroso",
    "singer": "Instrumental",
    "discMatrix": "24257",
    "publisher": "Odeon",
    "discNo": 52533,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Oscar Herrero",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quejumbroso Instrumental Osvaldo Pugliese instr. inst. instr inst 59 1959",
    "spotifyTrackId": "4KhcHHPApw5bL5MdlSBElW"
  },
  {
    "id": "pugliese-212",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "Mamita (Flor de angustia)",
    "singer": "Jorge Maciel",
    "discMatrix": "24258",
    "publisher": "Odeon",
    "discNo": 52533,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Ángel Danesi",
    "lyricist": "Francisco Bohigas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mamita (Flor de angustia) Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": ""
  },
  {
    "id": "pugliese-213",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "Gallo ciego",
    "singer": "Instrumental",
    "discMatrix": "24259",
    "publisher": "Odeon",
    "discNo": 52644,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Agustín Bardi",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gallo ciego Instrumental Osvaldo Pugliese instr. inst. instr inst 59 1959",
    "spotifyTrackId": "30108XxStXGbtGfP6fPiaT"
  },
  {
    "id": "pugliese-214",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "Si nace chancleta",
    "singer": "Jorge Maciel",
    "discMatrix": "24260",
    "publisher": "Odeon",
    "discNo": 52644,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Mario Demarco",
    "lyricist": "Vicente Bonvissuto",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si nace chancleta Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "41er83TjhmyVdlDKmv8naZ"
  },
  {
    "id": "pugliese-215",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "Y no podrás querer",
    "singer": "Jorge Maciel",
    "discMatrix": "24261",
    "publisher": "Odeon",
    "discNo": 52605,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Luis Riccardi",
    "lyricist": "Silvio Marinucci",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y no podrás querer Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "0ElgTRsFzEzbhqTf9hcpLk"
  },
  {
    "id": "pugliese-216",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-23",
    "title": "El pañuelito",
    "singer": "Jorge Maciel",
    "discMatrix": "24262",
    "publisher": "Odeon",
    "discNo": 52578,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Juan de Dios Filiberto",
    "lyricist": "Gabino Coria Peñaloza",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El pañuelito Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "2VXL94oALYf3QdAdwEZ0En"
  },
  {
    "id": "pugliese-217",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-24",
    "title": "Y no le erré",
    "singer": "Ricardo Medina",
    "discMatrix": "24263",
    "publisher": "Odeon",
    "discNo": 52578,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Medina",
    "discType": "",
    "duration": "",
    "composer": "Carlos Olmedo",
    "lyricist": "Abel Aznar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y no le erré Ricardo Medina Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "0CmefEnmR3gK4eBgYINM9W"
  },
  {
    "id": "pugliese-218",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-07-24",
    "title": "Sentencia",
    "singer": "Ricardo Medina",
    "discMatrix": "24264",
    "publisher": "Odeon",
    "discNo": 52589,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Medina",
    "discType": "",
    "duration": "",
    "composer": "Pedro Maffia",
    "lyricist": "Celedonio Flores",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sentencia Ricardo Medina Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "1XZ3pYZnBdCaqoYEtW1InZ"
  },
  {
    "id": "pugliese-219",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-08-06",
    "title": "Silencio",
    "singer": "Jorge Maciel",
    "discMatrix": "24298",
    "publisher": "Odeon",
    "discNo": 52589,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Carlos Gardel",
    "lyricist": "Alfredo Le Pera",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Silencio Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "1N2tn8KkEmu93OmziLTNu1"
  },
  {
    "id": "pugliese-220",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-08-06",
    "title": "La cumparsita",
    "singer": "Jorge Maciel y Carlos Guido",
    "discMatrix": "24299",
    "publisher": "Odeon",
    "discNo": 52573,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel y Guido",
    "discType": "",
    "duration": "",
    "composer": "Gerardo Matos Rodríguez",
    "lyricist": "Pascual Contursi",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Jorge Maciel y Carlos Guido Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "6tJ0zwyKkJ9pWXN0XWqLqY"
  },
  {
    "id": "pugliese-221",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-08-07",
    "title": "Por un cariño",
    "singer": "Jorge Maciel y Carlos Guido",
    "discMatrix": "24306",
    "publisher": "Odeon",
    "discNo": 52605,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel y Guido",
    "discType": "",
    "duration": "",
    "composer": "J. Demon",
    "lyricist": "Gerardo Alcázar",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por un cariño Jorge Maciel y Carlos Guido Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "4av5ux1dOoOoLEsEIxKBYx"
  },
  {
    "id": "pugliese-222",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-08-07",
    "title": "Dónde estás",
    "singer": "Jorge Maciel",
    "discMatrix": "24307",
    "publisher": "Odeon",
    "discNo": 52573,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1959,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Manuel Sucher",
    "lyricist": "Carlos Bahr",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dónde estás Jorge Maciel Osvaldo Pugliese 59 1959",
    "spotifyTrackId": "7I3ZHJdrDgJrUVFMagsJsG"
  },
  {
    "id": "pugliese-223",
    "trackNo": null,
    "genre": "Tango",
    "date": "1960-07-15",
    "title": "A los amigos",
    "singer": "Instrumental",
    "discMatrix": "25554",
    "publisher": "Odeon",
    "discNo": 52629,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1960,
    "genreShort": "T",
    "singerShort": "Inst.",
    "discType": "",
    "duration": "",
    "composer": "Armando Pontier",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A los amigos Instrumental Osvaldo Pugliese instr. inst. instr inst 60 1960",
    "spotifyTrackId": "1CWwZM7To51OqEzwB86W4L"
  },
  {
    "id": "pugliese-224",
    "trackNo": null,
    "genre": "Tango",
    "date": "1960-07-15",
    "title": "Un lamento",
    "singer": "Jorge Maciel",
    "discMatrix": "25555",
    "publisher": "Odeon",
    "discNo": 52629,
    "orchestraId": "pugliese",
    "groupId": "pugliese4360",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "year": 1960,
    "genreShort": "T",
    "singerShort": "Maciel",
    "discType": "",
    "duration": "",
    "composer": "Graciano De Leone",
    "lyricist": "Eugenio Cárdenas",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un lamento Jorge Maciel Osvaldo Pugliese 60 1960",
    "spotifyTrackId": "1LcD0dvswQ0ApPMHqMaX8g"
  },
  {
    "id": "biagi-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-08-15",
    "title": "Gólgota",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9585",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1938,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gólgota Teófilo Ibañez Rodolfo Biagi 38 1938",
    "spotifyTrackId": "6aCDsptQtvQL7CTm0lBC26"
  },
  {
    "id": "biagi-4",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-08-15",
    "title": "El Incendio",
    "singer": "Instrumental",
    "discMatrix": "9586",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Incendio Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "7guw3TFgE5JATRjZeziFD9"
  },
  {
    "id": "biagi-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-10-16",
    "title": "Unión cívica",
    "singer": "Instrumental",
    "discMatrix": "9672",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Unión cívica Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "1YKfxJdaZfJFkjDuEU4Eki"
  },
  {
    "id": "biagi-6",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-10-16",
    "title": "Viejo Porton",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9673",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viejo Porton Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "3tInGTRQcBqpHixJiGWVuA"
  },
  {
    "id": "biagi-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-11-15",
    "title": "El Trece",
    "singer": "Instrumental",
    "discMatrix": "9713",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Trece Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "6HAr6YJwyqmA6EHYkOsrer"
  },
  {
    "id": "biagi-8",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-11-15",
    "title": "Loca de Amor",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9714",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Loca de Amor Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "7guw3TFgE5JATRjZeziFD9"
  },
  {
    "id": "biagi-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-12-02",
    "title": "Una Pena",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9745",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una Pena Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "6jGsN40OlAkMaVheM32r3L"
  },
  {
    "id": "biagi-10",
    "trackNo": null,
    "genre": "Vals",
    "date": "1938-12-02",
    "title": "Lejos de tí",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9744",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lejos de tí Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "0MjOHCaUbM7UiLvsDS5enj"
  },
  {
    "id": "biagi-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-03-24",
    "title": "La maleva",
    "singer": "Instrumental",
    "discMatrix": "9834",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La maleva Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "0kFn7DAmz9AO6OYdpgrjNN"
  },
  {
    "id": "biagi-12",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-03-24",
    "title": "Alma de Bohemio",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9833",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alma de Bohemio Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "6z4UBjSgN4ovGiZFLY0EFJ"
  },
  {
    "id": "biagi-13",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1939-04-27",
    "title": "Campo afuera",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9913",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Campo afuera Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "2dyGes2yfsCbTYP6Wjgafb"
  },
  {
    "id": "biagi-14",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-04-27",
    "title": "La Novena",
    "singer": "Teófilo Ibañez",
    "discMatrix": "9914",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Novena Teófilo Ibañez Rodolfo Biagi",
    "spotifyTrackId": "3NFmyGjnbYpqSoxYmqw32R"
  },
  {
    "id": "biagi-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-07-13",
    "title": "Pura Clase",
    "singer": "Instrumental",
    "discMatrix": "10018",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pura Clase Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "1LseYzIKqyfLyDWSbuEr46"
  },
  {
    "id": "biagi-16",
    "trackNo": null,
    "genre": "Vals",
    "date": "1939-07-13",
    "title": "Dichas que viví",
    "singer": "Andres Falgas",
    "discMatrix": "10017",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dichas que viví Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "3hIG88YIYu3N5eWqHPI3R4"
  },
  {
    "id": "biagi-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-18",
    "title": "El Rapido",
    "singer": "Instrumental",
    "discMatrix": "10092",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Rapido Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "50CKDIeWKO5gDKeixby3jB"
  },
  {
    "id": "biagi-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-18",
    "title": "Cielo",
    "singer": "Andres Falgas",
    "discMatrix": "10093",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cielo Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "2HJVZeSwLrhT0OzRV7iBKL"
  },
  {
    "id": "biagi-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-09-22",
    "title": "Son Cosas del Bandoneon",
    "singer": "Andres Falgas",
    "discMatrix": "10102",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Son Cosas del Bandoneon Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "29CgOEHOWUgKBB7a74jwYL"
  },
  {
    "id": "biagi-20",
    "trackNo": null,
    "genre": "Vals",
    "date": "1939-09-22",
    "title": "Déjame amarte aunque sea un día",
    "singer": "Andres Falgas",
    "discMatrix": "10103",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Déjame amarte aunque sea un día Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "7bbJ9btY1al5RMOuIswZha"
  },
  {
    "id": "biagi-21",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-10-26",
    "title": "Griseta",
    "singer": "Andres Falgas",
    "discMatrix": "10146",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Griseta Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "1PoLh8JviMDkFKIaWoOEv4"
  },
  {
    "id": "biagi-22",
    "trackNo": null,
    "genre": "Tango",
    "date": "1939-10-26",
    "title": "Queja Indiana",
    "singer": "Andres Falgas",
    "discMatrix": "10145",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Queja Indiana Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "1DEaCb3POmDBlvlp1wtfYm"
  },
  {
    "id": "biagi-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-03-14",
    "title": "La Chacarera",
    "singer": "Andres Falgas",
    "discMatrix": "10345",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Chacarera Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "1BtKNh3mClagZ4JMobJYDg"
  },
  {
    "id": "biagi-24",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-03-14",
    "title": "Deja el mundo como está",
    "singer": "Andres Falgas",
    "discMatrix": "10344",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Deja el mundo como está Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "2q5OWUzciLqiNKMcIOYQKW"
  },
  {
    "id": "biagi-25",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-03-14",
    "title": "Cicatrices",
    "singer": "Andres Falgas",
    "discMatrix": "10367",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cicatrices Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "0QiJVnGS0Hcy0UPKLQUUjW"
  },
  {
    "id": "biagi-26",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-03-14",
    "title": "El ultimo Adios",
    "singer": "Andres Falgas",
    "discMatrix": "10364",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El ultimo Adios Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "5lIhBRvR7WU60lAEn6fArv"
  },
  {
    "id": "biagi-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-04-12",
    "title": "El Estribo",
    "singer": "Instrumental",
    "discMatrix": "10408",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Estribo Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "0WZUc42PkjrOGdgoERgyXK"
  },
  {
    "id": "biagi-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-04-12",
    "title": "A mi no me interesa",
    "singer": "Andres Falgas",
    "discMatrix": "10407",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A mi no me interesa Andres Falgas Rodolfo Biagi",
    "spotifyTrackId": "0s442Iwt0YLkStD1WfAVN4"
  },
  {
    "id": "biagi-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-19",
    "title": "Todo te Nombra",
    "singer": "Jorge Ortiz",
    "discMatrix": "10563",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todo te Nombra Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "2281uPTsGjzc71hZtEW9i7"
  },
  {
    "id": "biagi-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-19",
    "title": "El Yaguarón",
    "singer": "Instrumental",
    "discMatrix": "10564",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Yaguarón Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "1RAkWMnlxl5n4XBvoD6mhF"
  },
  {
    "id": "biagi-31",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1940-06-28",
    "title": "Cantando se van las Penas",
    "singer": "Jorge Ortiz",
    "discMatrix": "10575",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cantando se van las Penas Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "4gF2ulkXXx6IvrAsBUGVAq"
  },
  {
    "id": "biagi-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-06-28",
    "title": "Pinta Orillera",
    "singer": "Jorge Ortiz",
    "discMatrix": "10574",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pinta Orillera Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "2zRMBpoZ4FWTh3t5ZPnU0Z"
  },
  {
    "id": "biagi-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-07-16",
    "title": "Quiero verte una vez Mas",
    "singer": "Jorge Ortiz",
    "discMatrix": "10602",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quiero verte una vez Mas Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "7vh5arDV7GZXyK10ji5KnQ"
  },
  {
    "id": "biagi-34",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-07-16",
    "title": "Guapo y Varon",
    "singer": "Jorge Ortiz",
    "discMatrix": "10603",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Guapo y Varon Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "3misejQwobd5r4hMd8TICm"
  },
  {
    "id": "biagi-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-04",
    "title": "Callecita de mi Barrio",
    "singer": "Jorge Ortiz",
    "discMatrix": "10726",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Callecita de mi Barrio Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "5hKKzX1fzSh3sqsbqtpJNn"
  },
  {
    "id": "biagi-36",
    "trackNo": null,
    "genre": "Vals",
    "date": "1940-10-04",
    "title": "Por un Beso de Amor",
    "singer": "Jorge Ortiz",
    "discMatrix": "10727",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por un Beso de Amor Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "7b6HqGIszgwGinMRtY4d1U"
  },
  {
    "id": "biagi-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-15",
    "title": "Misa de Once",
    "singer": "Jorge Ortiz",
    "discMatrix": "10774",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Misa de Once Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "4XUIxkkhZqImGUD29ogD4H"
  },
  {
    "id": "biagi-38",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-10-15",
    "title": "Marcas",
    "singer": "Jorge Ortiz",
    "discMatrix": "10773",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marcas Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "5dsJ3c8ZfLutMTfGp4tiPu"
  },
  {
    "id": "biagi-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-17",
    "title": "No le digas que la Quiero",
    "singer": "Jorge Ortiz",
    "discMatrix": "10925",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No le digas que la Quiero Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "1E3950IBVTXY1F5FNso9Cn"
  },
  {
    "id": "biagi-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-17",
    "title": "La marca de fuego",
    "singer": "Instrumental",
    "discMatrix": "10924",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La marca de fuego Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "3BR0mR1GpDOGNsc20Z4t4P"
  },
  {
    "id": "biagi-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-26",
    "title": "Yo tambien",
    "singer": "Jorge Ortiz",
    "discMatrix": "10940",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo tambien Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "6o3hN2ZorBdPJC5PW5oxn4"
  },
  {
    "id": "biagi-42",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-12-26",
    "title": "Re fa si",
    "singer": "Instrumental",
    "discMatrix": "10941",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Re fa si Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "1XsCCSANIxAnEeFZ2gMZJI"
  },
  {
    "id": "biagi-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-01-14",
    "title": "Pajaro Ciego",
    "singer": "Jorge Ortiz",
    "discMatrix": "10985",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pajaro Ciego Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "24aPUiVic0TY9ASzNtD1lQ"
  },
  {
    "id": "biagi-44",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-01-14",
    "title": "El Entrerriano",
    "singer": "Instrumental",
    "discMatrix": "10986",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Entrerriano Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "1HnWt0CPL7L0F6ymshJ8zl"
  },
  {
    "id": "biagi-45",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-15",
    "title": "Humillacion",
    "singer": "Jorge Ortiz",
    "discMatrix": "11095",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Humillacion Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "3jXuUwnDDczMa2tGs543w0"
  },
  {
    "id": "biagi-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-15",
    "title": "Sera lo mejor",
    "singer": "Jorge Ortiz",
    "discMatrix": "11094",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sera lo mejor Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "1VOYDEzKYAvRUZ6q9KSS6i"
  },
  {
    "id": "biagi-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-26",
    "title": "Zaraza",
    "singer": "Jorge Ortiz",
    "discMatrix": "11111",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Zaraza Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "4TKOFwm2oTxqBV2Bsp91T1"
  },
  {
    "id": "biagi-48",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-03-26",
    "title": "Lagrimas y Sonrisas",
    "singer": "Instrumental",
    "discMatrix": "11110",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lagrimas y Sonrisas Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "4B8rIATx4XCtJVMlpK9vnj"
  },
  {
    "id": "biagi-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-26",
    "title": "La Marcha Nupcial",
    "singer": "Jorge Ortiz",
    "discMatrix": "11286",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Marcha Nupcial Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "6y1rEkDObFHBPTkbkVwnaa"
  },
  {
    "id": "biagi-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-03-26",
    "title": "Romantico Bulincito",
    "singer": "Jorge Ortiz",
    "discMatrix": "11285",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Romantico Bulincito Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "2zP59BK4h9LS4a4ZEkuCpK"
  },
  {
    "id": "biagi-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-29",
    "title": "Ya lo ves",
    "singer": "Jorge Ortiz",
    "discMatrix": "11338",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ya lo ves Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "64umL4b1Z1YXG0NFbxj5Mc"
  },
  {
    "id": "biagi-52",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-07-29",
    "title": "Cuatro Palabras",
    "singer": "Jorge Ortiz",
    "discMatrix": "11337",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro Palabras Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "3RVsAv1wtEWmLsPR87QdGB"
  },
  {
    "id": "biagi-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-26",
    "title": "Carillón de la Merced",
    "singer": "Jorge Ortiz",
    "discMatrix": "11457",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carillón de la Merced Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "35wIBWTxbp7TqC7EoyN5H5"
  },
  {
    "id": "biagi-54",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-26",
    "title": "Ahora no me conoces",
    "singer": "Jorge Ortiz",
    "discMatrix": "11456",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ahora no me conoces Jorge Ortiz Rodolfo Biagi",
    "spotifyTrackId": "4Int6TVRBuOSDHJiiLIbFY"
  },
  {
    "id": "biagi-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-07",
    "title": "Quejas de Bandoneon",
    "singer": "Instrumental",
    "discMatrix": "11490",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quejas de Bandoneon Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "2ftiLluETao3HGykMQQbpQ"
  },
  {
    "id": "biagi-56",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-10-07",
    "title": "Pajaro Herido",
    "singer": "Instrumental",
    "discMatrix": "11491-1",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pajaro Herido Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "57mh2wcXXAPYbKzVwvcEe4"
  },
  {
    "id": "biagi-57",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-10-21",
    "title": "Picante",
    "singer": "Instrumental",
    "discMatrix": "11521",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Picante Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "7qH7AXCgv84tAerqFSoRFi"
  },
  {
    "id": "biagi-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-21",
    "title": "Didi",
    "singer": "Instrumental",
    "discMatrix": "11522",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Didi Instrumental Rodolfo Biagi instr. inst. instr inst",
    "spotifyTrackId": "6ll23x7rtSiU2KUr4ysrI9"
  },
  {
    "id": "biagi-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-03",
    "title": "No puede ser",
    "singer": "Alberto Lago",
    "discMatrix": "11615",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 0,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No puede ser Alberto Lago Rodolfo Biagi",
    "spotifyTrackId": "09DzOXcEf3kswIFsLmpyNX"
  },
  {
    "id": "biagi-60",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-12-03",
    "title": "Marcheta",
    "singer": "Instrumental",
    "discMatrix": "11616",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marcheta Instrumental Rodolfo Biagi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "6uOfa5ZnLzBKYhjf47joQI"
  },
  {
    "id": "biagi-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-16",
    "title": "Arrebato",
    "singer": "Alberto Lago",
    "discMatrix": "11726",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Arrebato Alberto Lago Rodolfo Biagi 42 1942",
    "spotifyTrackId": "0JOGKQYFeCY8uZgRDnp5A3"
  },
  {
    "id": "biagi-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-20",
    "title": "Bélgica",
    "singer": "Instrumental",
    "discMatrix": "11731",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bélgica Instrumental Rodolfo Biagi instr. inst. instr inst 42 1942",
    "spotifyTrackId": "2NizvOXuornRulaC4uwuBS"
  },
  {
    "id": "biagi-63",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-02-02",
    "title": "Sentimiento Gaucho",
    "singer": "Instrumental",
    "discMatrix": "11790",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sentimiento Gaucho Instrumental Rodolfo Biagi instr. inst. instr inst 42 1942",
    "spotifyTrackId": "6kR1sI9sFl6DCzrDyYB0r7"
  },
  {
    "id": "biagi-64",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-02-02",
    "title": "Te Odio",
    "singer": "Alberto Lago",
    "discMatrix": "11789",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te Odio Alberto Lago Rodolfo Biagi 42 1942",
    "spotifyTrackId": "5nrr9YomSLY1qdQhJkgCzV"
  },
  {
    "id": "biagi-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-02-28",
    "title": "Oyendo tu Voz",
    "singer": "Jorge Ortiz",
    "discMatrix": "12182",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Oyendo tu Voz Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "6SaVnR0ZfWd8fCnxn0S93k"
  },
  {
    "id": "biagi-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-15",
    "title": "La cumparsita",
    "singer": "Jorge Ortiz",
    "discMatrix": "11946-2",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "0qocl4Wt1PSRW6TpiwJn4D"
  },
  {
    "id": "biagi-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-15",
    "title": "Calla Corazon",
    "singer": "Jorge Ortiz",
    "discMatrix": "11945",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Calla Corazon Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "55uz53SU9tOD6GQoSZjxIi"
  },
  {
    "id": "biagi-68",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-05-22",
    "title": "Amor y Vals",
    "singer": "Alberto Lago",
    "discMatrix": "12005",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amor y Vals Alberto Lago Rodolfo Biagi 42 1942",
    "spotifyTrackId": "7opP3VZ8CyyFIrZnFkm0hM"
  },
  {
    "id": "biagi-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-05-22",
    "title": "Metido",
    "singer": "Alberto Lago",
    "discMatrix": "12006",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Metido Alberto Lago Rodolfo Biagi 42 1942",
    "spotifyTrackId": "5cqawGujs1BBsFftW0PvUX"
  },
  {
    "id": "biagi-70",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-28",
    "title": "Catorce Primaveras",
    "singer": "Jorge Ortiz",
    "discMatrix": "12181",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Catorce Primaveras Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "2lr0JN0D0JavdV7aadHmvJ"
  },
  {
    "id": "biagi-71",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-10",
    "title": "La Cumparsita",
    "singer": "Instrumental",
    "discMatrix": "12208",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Cumparsita Instrumental Rodolfo Biagi instr. inst. instr inst 42 1942",
    "spotifyTrackId": "0qocl4Wt1PSRW6TpiwJn4D"
  },
  {
    "id": "biagi-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-09",
    "title": "Indiferencia",
    "singer": "Jorge Ortiz",
    "discMatrix": "12207",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Indiferencia Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "5FgM3F7Vh8lw4GVymftV3g"
  },
  {
    "id": "biagi-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-29",
    "title": "Si de mi te has Olvidado",
    "singer": "Jorge Ortiz",
    "discMatrix": "12318",
    "publisher": "Odeon",
    "discNo": 5635,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si de mi te has Olvidado Jorge Ortiz Rodolfo Biagi 42 1942",
    "spotifyTrackId": "5E2byZiC6O92twfeddrpIA"
  },
  {
    "id": "biagi-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-01-15",
    "title": "Senor, Senor",
    "singer": "Jorge Ortiz",
    "discMatrix": "12484",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Senor, Senor Jorge Ortiz Rodolfo Biagi 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-01-15",
    "title": "Pueblito de Provincia",
    "singer": "Jorge Ortiz",
    "discMatrix": "12485",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pueblito de Provincia Jorge Ortiz Rodolfo Biagi 43 1943",
    "spotifyTrackId": "3WRRSoYuWTXIzlUlXyMY0g"
  },
  {
    "id": "biagi-76",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-03",
    "title": "Arlette",
    "singer": "Alberto Amor",
    "discMatrix": "12623",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Arlette Alberto Amor Rodolfo Biagi 43 1943",
    "spotifyTrackId": "4rYkeE0lQZmVx5nBdZPOau"
  },
  {
    "id": "biagi-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-03",
    "title": "Por algo será",
    "singer": "Alberto Amor",
    "discMatrix": "12622",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por algo será Alberto Amor Rodolfo Biagi 43 1943",
    "spotifyTrackId": "4csbgMIUaxY5QlUVYeg3G3"
  },
  {
    "id": "biagi-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-03",
    "title": "Yo tengo un Punal",
    "singer": "Carlos Acuna",
    "discMatrix": "12624",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo tengo un Punal Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "3M2JrP33ZoCwq69m5bixbw"
  },
  {
    "id": "biagi-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-03",
    "title": "A la Luz del Candil",
    "singer": "Carlos Acuna",
    "discMatrix": "12625",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la Luz del Candil Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "5kJwI7du29IsDsQGK14wKh"
  },
  {
    "id": "biagi-80",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-29",
    "title": "Barrio Reo",
    "singer": "Carlos Acuna",
    "discMatrix": "12726",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barrio Reo Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "0JYgRy8DJFyBeefkhRFKV8"
  },
  {
    "id": "biagi-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-29",
    "title": "Canción de Rango",
    "singer": "Carlos Acuna",
    "discMatrix": "12727",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canción de Rango Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "1RAkWMnlxl5n4XBvoD6mhF"
  },
  {
    "id": "biagi-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-07-08",
    "title": "Tres horas",
    "singer": "Alberto Amor",
    "discMatrix": "12911",
    "publisher": "Odeon",
    "discNo": 5640,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tres horas Alberto Amor Rodolfo Biagi 43 1943",
    "spotifyTrackId": "5Rm2GfHoMDXq5Yej58h9FO"
  },
  {
    "id": "biagi-83",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-07-08",
    "title": "Soy del Noventa",
    "singer": "Carlos Acuna",
    "discMatrix": "12912",
    "publisher": "Odeon",
    "discNo": 5640,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy del Noventa Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-24",
    "title": "Adios te vas",
    "singer": "Carlos Acuna",
    "discMatrix": "13071",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adios te vas Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "7bOqf9a2aC0sWVrZWqiHK8"
  },
  {
    "id": "biagi-85",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-08-24",
    "title": "Prisionero",
    "singer": "Alberto Amor",
    "discMatrix": "13070",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Prisionero Alberto Amor Rodolfo Biagi 43 1943",
    "spotifyTrackId": "0yPdheLAgDnmy0U05sfO3l"
  },
  {
    "id": "biagi-86",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-07",
    "title": "Si la llegaran a ver",
    "singer": "Alberto Amor",
    "discMatrix": "13424",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si la llegaran a ver Alberto Amor Rodolfo Biagi 43 1943",
    "spotifyTrackId": "4KfpE6uvWbSm44m4W0G4dS"
  },
  {
    "id": "biagi-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-07",
    "title": "Lonjazos",
    "singer": "Carlos Acuna",
    "discMatrix": "13423",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lonjazos Carlos Acuna Rodolfo Biagi 43 1943",
    "spotifyTrackId": "2rKXFLr1vlti4jW5ADBwD1"
  },
  {
    "id": "biagi-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-11",
    "title": "Uno",
    "singer": "Carlos Acuna",
    "discMatrix": "13682",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Uno Carlos Acuna Rodolfo Biagi 44 1944",
    "spotifyTrackId": "1PoLh8JviMDkFKIaWoOEv4"
  },
  {
    "id": "biagi-89",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-04-11",
    "title": "Sueno de Juventud",
    "singer": "Carlos Acuna",
    "discMatrix": "13693",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sueno de Juventud Carlos Acuna Rodolfo Biagi 44 1944",
    "spotifyTrackId": "7kZjU63TfJrm1ua9AVj50Y"
  },
  {
    "id": "biagi-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-11",
    "title": "Lisón",
    "singer": "Alberto Amor",
    "discMatrix": "13694",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lisón Alberto Amor Rodolfo Biagi 44 1944",
    "spotifyTrackId": "2shxpSFetXfwO1HfDvqPE5"
  },
  {
    "id": "biagi-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-09",
    "title": "Nada",
    "singer": "Alberto Amor",
    "discMatrix": "13769",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Nada Alberto Amor Rodolfo Biagi 44 1944",
    "spotifyTrackId": "1DWrKFUYwu8BCYJE5FH1f7"
  },
  {
    "id": "biagi-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-09",
    "title": "Tu Voz",
    "singer": "Carlos Acuna",
    "discMatrix": "13770",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu Voz Carlos Acuna Rodolfo Biagi 44 1944",
    "spotifyTrackId": "5VKMcv2JuVIIRQUb0VBMv9"
  },
  {
    "id": "biagi-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-30",
    "title": "Sosiego en la Noche",
    "singer": "Carlos Acuna",
    "discMatrix": "13811",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sosiego en la Noche Carlos Acuna Rodolfo Biagi 44 1944",
    "spotifyTrackId": "5fwrl0OFSWipuQbXYgeVO7"
  },
  {
    "id": "biagi-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-11",
    "title": "Como el Hornero",
    "singer": "Alberto Amor",
    "discMatrix": "14022",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como el Hornero Alberto Amor Rodolfo Biagi 44 1944",
    "spotifyTrackId": "5P8lkrHeNdHigW8lxE2AjP"
  },
  {
    "id": "biagi-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-11",
    "title": "A Suerte Y Verdad",
    "singer": "Carlos Acuna",
    "discMatrix": "14023",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A Suerte Y Verdad Carlos Acuna Rodolfo Biagi 44 1944",
    "spotifyTrackId": "1nIXbu7f0Erfud5XRR2wE2"
  },
  {
    "id": "biagi-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-15",
    "title": "Seamos Amigos",
    "singer": "Alberto Amor",
    "discMatrix": "14097",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Seamos Amigos Alberto Amor Rodolfo Biagi 44 1944",
    "spotifyTrackId": "5FAbootmU86b40PI3jFbK6"
  },
  {
    "id": "biagi-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-15",
    "title": "Viento Malo",
    "singer": "Alberto Amor",
    "discMatrix": "14098",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viento Malo Alberto Amor Rodolfo Biagi 44 1944",
    "spotifyTrackId": "6O7kgDC2fiHy6DzG3hWTH7"
  },
  {
    "id": "biagi-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-24",
    "title": "Magdala",
    "singer": "Jorge Ortiz",
    "discMatrix": "14413",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Magdala Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "0SbtRgGUT1L6mZ8yXIEXwK"
  },
  {
    "id": "biagi-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-24",
    "title": "Yuyo Verde",
    "singer": "Jorge Ortiz",
    "discMatrix": "14414",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yuyo Verde Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "7F2fnrPD1hB3M5z0UWS8wt"
  },
  {
    "id": "biagi-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-06",
    "title": "Copas, Amigas y Besos",
    "singer": "Jorge Ortiz",
    "discMatrix": "14438",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Copas, Amigas y Besos Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "5Puqvbp96iKdqiu1jdmSKo"
  },
  {
    "id": "biagi-101",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-02-06",
    "title": "Paloma",
    "singer": "Alberto Amor",
    "discMatrix": "14440",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Paloma Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "21fjZ88wzzmCy1aPZGw9fP"
  },
  {
    "id": "biagi-102",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-22",
    "title": "Equipaje",
    "singer": "Jorge Ortiz",
    "discMatrix": "14561",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Equipaje Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "0xCfnxLcLA22bYeqil12LM"
  },
  {
    "id": "biagi-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-22",
    "title": "Trenzas",
    "singer": "Jorge Ortiz",
    "discMatrix": "14566",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Trenzas Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "0GJKFjzTCbkwKMoKDVmjpH"
  },
  {
    "id": "biagi-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-25",
    "title": "Me quedé mirándola",
    "singer": "Alberto Amor",
    "discMatrix": "14646-1",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me quedé mirándola Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "7CYeloxwyepANJWpFGbH6f"
  },
  {
    "id": "biagi-105",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-05-25",
    "title": "Tu Melodia",
    "singer": "Jorge Ortiz",
    "discMatrix": "14645-2",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu Melodia Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "62XbSSCEZAwEeJGmX2gSwr"
  },
  {
    "id": "biagi-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-15",
    "title": "Cuando se ha querido mucho",
    "singer": "Jorge Ortiz",
    "discMatrix": "14690",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando se ha querido mucho Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "5QpNWoObHWZYLynlRwmUhG"
  },
  {
    "id": "biagi-107",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-15",
    "title": "Cafe de los Angelitos",
    "singer": "Alberto Amor",
    "discMatrix": "14689",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cafe de los Angelitos Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "61Qw4BVVW5mXV06G9vQoce"
  },
  {
    "id": "biagi-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-06",
    "title": "Mis Amores de Ayer",
    "singer": "Alberto Amor",
    "discMatrix": "14898",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mis Amores de Ayer Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "73ghF5Gyr1omxDYNqhh7kh"
  },
  {
    "id": "biagi-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-06",
    "title": "Soledad la de Barracas",
    "singer": "Jorge Ortiz",
    "discMatrix": "14897",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soledad la de Barracas Jorge Ortiz Rodolfo Biagi 45 1945",
    "spotifyTrackId": "1WmVbGYgOTVjaplf0rrBPO"
  },
  {
    "id": "biagi-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-15",
    "title": "Anselmo Laguna",
    "singer": "Alberto Amor",
    "discMatrix": "15001",
    "publisher": "Odeon",
    "discNo": 5655,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Anselmo Laguna Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "6Ohk7CK8ZkIzyr9XHMVJHC"
  },
  {
    "id": "biagi-111",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-15",
    "title": "Tus Labios me diran",
    "singer": "Alberto Amor",
    "discMatrix": "15002",
    "publisher": "Odeon",
    "discNo": 5655,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tus Labios me diran Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "5jILDkwFe2PnmxeX1hPGkG"
  },
  {
    "id": "biagi-112",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-30",
    "title": "Hoy te quiero mucho mas",
    "singer": "Alberto Amor",
    "discMatrix": "15150",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hoy te quiero mucho mas Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": "5fB20RzCnLS7b1iDZLlHUZ"
  },
  {
    "id": "biagi-113",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-11-30",
    "title": "Flor de Monserrat (Pobre Negrito)",
    "singer": "Alberto Amor",
    "discMatrix": "15149",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Flor de Monserrat (Pobre Negrito) Alberto Amor Rodolfo Biagi 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-114",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-18",
    "title": "Pudo ser una Vida",
    "singer": "Alberto Amor",
    "discMatrix": "15286",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pudo ser una Vida Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "6o0gcVusgxDIgt5Cgv6adG"
  },
  {
    "id": "biagi-115",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-01-18",
    "title": "Aroma de Amor",
    "singer": "Alberto Amor",
    "discMatrix": "15285",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aroma de Amor Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "0fPBIUsihyvWUGNKygUdXY"
  },
  {
    "id": "biagi-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-31",
    "title": "Adios Pampa mia",
    "singer": "Alberto Amor",
    "discMatrix": "15326",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adios Pampa mia Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "5eDnNDdLtSaZS3KHc1glbu"
  },
  {
    "id": "biagi-117",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-31",
    "title": "Marol",
    "singer": "Alberto Amor",
    "discMatrix": "15327",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marol Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "287gslLYwRScXaUCnbEijH"
  },
  {
    "id": "biagi-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-31",
    "title": "Cuatro lágrimas",
    "singer": "Alberto Amor",
    "discMatrix": "15448",
    "publisher": "Odeon",
    "discNo": 5660,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro lágrimas Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "518J4HNGUCiRL9aVpZYIO8"
  },
  {
    "id": "biagi-119",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-04-17",
    "title": "Levanta tu corazón",
    "singer": "Alberto Amor",
    "discMatrix": "15449",
    "publisher": "Odeon",
    "discNo": 5660,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Levanta tu corazón Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-120",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-23",
    "title": "Mientras duerme la Ciudad",
    "singer": "Carlos Saavedra",
    "discMatrix": "15527",
    "publisher": "Odeon",
    "discNo": 5661,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mientras duerme la Ciudad Carlos Saavedra Rodolfo Biagi 46 1946",
    "spotifyTrackId": "1zdbOMD58Wd10pT9cz1dOT"
  },
  {
    "id": "biagi-121",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-23",
    "title": "Camino del Tucuman",
    "singer": "Alberto Amor",
    "discMatrix": "15528",
    "publisher": "Odeon",
    "discNo": 5661,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Camino del Tucuman Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "2i8hh1xyaJNTSkUU7dKxfI"
  },
  {
    "id": "biagi-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-18",
    "title": "Gracias",
    "singer": "Carlos Saavedra",
    "discMatrix": "15594",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gracias Carlos Saavedra Rodolfo Biagi 46 1946",
    "spotifyTrackId": "7LUVjcufeGd2xq6mDITKf5"
  },
  {
    "id": "biagi-123",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-06-18",
    "title": "El Irresistible",
    "singer": "Instrumental",
    "discMatrix": "15595",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Irresistible Instrumental Rodolfo Biagi instr. inst. instr inst 46 1946",
    "spotifyTrackId": "3cIiL90JnNoX6dwOxXZFc8"
  },
  {
    "id": "biagi-124",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-07-10",
    "title": "Con mi perro",
    "singer": "Alberto Amor",
    "discMatrix": "15647",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con mi perro Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "0ht7JLCXePVqJ0PtBSy75P"
  },
  {
    "id": "biagi-125",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-10",
    "title": "Lucienne",
    "singer": "Alberto Amor",
    "discMatrix": "15648",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lucienne Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "7MlvGJYioFdKC8QvzE7c4L"
  },
  {
    "id": "biagi-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-16",
    "title": "La Huella",
    "singer": "Instrumental",
    "discMatrix": "15745",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Huella Instrumental Rodolfo Biagi instr. inst. instr inst 46 1946",
    "spotifyTrackId": "5kz5NkEb68J1r5sFoKW6Hg"
  },
  {
    "id": "biagi-127",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-16",
    "title": "Por eso Grito",
    "singer": "Carlos Saavedra",
    "discMatrix": "15744",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por eso Grito Carlos Saavedra Rodolfo Biagi 46 1946",
    "spotifyTrackId": "1G3CIoYpUIMk30nulYjpVx"
  },
  {
    "id": "biagi-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-22",
    "title": "Sin Palabras",
    "singer": "Carlos Saavedra",
    "discMatrix": "16000",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sin Palabras Carlos Saavedra Rodolfo Biagi 46 1946",
    "spotifyTrackId": "3HOkXr7kWkeCITBNoh8GJB"
  },
  {
    "id": "biagi-129",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-22",
    "title": "Cuando llora la milonga",
    "singer": "Alberto Amor",
    "discMatrix": "16001",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando llora la milonga Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": "3C3FCCYqy3qWUBtDKsIVhp"
  },
  {
    "id": "biagi-130",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-12-30",
    "title": "Manana por la Manana",
    "singer": "Alberto Amor",
    "discMatrix": "16162",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Manana por la Manana Alberto Amor Rodolfo Biagi 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-131",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-30",
    "title": "Esta noche me emborracho",
    "singer": "Carlos Saavedra",
    "discMatrix": "16163",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche me emborracho Carlos Saavedra Rodolfo Biagi 46 1946",
    "spotifyTrackId": "12JOnfZqCWpTMt3QuFw0O2"
  },
  {
    "id": "biagi-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-09-23",
    "title": "Margot",
    "singer": "Carlos Saavedra",
    "discMatrix": "16178",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Margot Carlos Saavedra Rodolfo Biagi 47 1947",
    "spotifyTrackId": "1BIAKWHqdfjFqraYH7kXAG"
  },
  {
    "id": "biagi-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-09-23",
    "title": "Y dicen que no te quiero",
    "singer": "Alberto Amor",
    "discMatrix": "16179",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y dicen que no te quiero Alberto Amor Rodolfo Biagi 47 1947",
    "spotifyTrackId": "4XcEpK3NjcV01jbwg2AmRG"
  },
  {
    "id": "biagi-134",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-07-22",
    "title": "La Viruta",
    "singer": "Instrumental",
    "discMatrix": "17009",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Viruta Instrumental Rodolfo Biagi instr. inst. instr inst 48 1948",
    "spotifyTrackId": "0PKplUs2kAedWfDJpcLUdU"
  },
  {
    "id": "biagi-135",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1948-07-22",
    "title": "Por la Güella",
    "singer": "Carlos Saavedra",
    "discMatrix": "17008",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi3848",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por la Güella Carlos Saavedra Rodolfo Biagi 48 1948",
    "spotifyTrackId": "5LfuNxO9rYUnGojFJHNU50"
  },
  {
    "id": "biagi-136",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-04-13",
    "title": "A la gran Muneca",
    "singer": "Instrumental",
    "discMatrix": "17620",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la gran Muneca Instrumental Rodolfo Biagi instr. inst. instr inst 50 1950",
    "spotifyTrackId": "42jDmHg64Q6ZzItvVTbDxv"
  },
  {
    "id": "biagi-137",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-04-13",
    "title": "Matala",
    "singer": "Carlos Heredia",
    "discMatrix": "17619",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Matala Carlos Heredia Rodolfo Biagi 50 1950",
    "spotifyTrackId": "1pXPohjZTOgMhXY0zOPghj"
  },
  {
    "id": "biagi-138",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-09-13",
    "title": "Racing Club",
    "singer": "Instrumental",
    "discMatrix": "17794",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Racing Club Instrumental Rodolfo Biagi instr. inst. instr inst 50 1950",
    "spotifyTrackId": "7LUVjcufeGd2xq6mDITKf5"
  },
  {
    "id": "biagi-139",
    "trackNo": null,
    "genre": "Vals",
    "date": "1950-09-13",
    "title": "Serenata Campera",
    "singer": "Hugo Duval",
    "discMatrix": "17795",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Serenata Campera Hugo Duval Rodolfo Biagi 50 1950",
    "spotifyTrackId": "3evJ5u37KIljBGWZv6aZdy"
  },
  {
    "id": "biagi-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-07-30",
    "title": "Bailarina de Tango",
    "singer": "Hugo Duval",
    "discMatrix": "18130",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1951,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bailarina de Tango Hugo Duval Rodolfo Biagi 51 1951",
    "spotifyTrackId": "2mA4MJzYBTwhub6VWQmo6M"
  },
  {
    "id": "biagi-141",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-07-30",
    "title": "Caricias",
    "singer": "Carlos Heredia",
    "discMatrix": "18131",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1951,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Caricias Carlos Heredia Rodolfo Biagi 51 1951",
    "spotifyTrackId": "3TTEXVisfoEjXLHaQsnb9N"
  },
  {
    "id": "biagi-142",
    "trackNo": null,
    "genre": "Vals",
    "date": "1951-10-04",
    "title": "Adoracion",
    "singer": "Hugo Duval y Carlos Heredia",
    "discMatrix": "18222",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1951,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adoracion Hugo Duval y Carlos Heredia Rodolfo Biagi 51 1951",
    "spotifyTrackId": "3tNII6j9FojjuRTG5hR2zr"
  },
  {
    "id": "biagi-143",
    "trackNo": null,
    "genre": "Tango",
    "date": "1951-10-04",
    "title": "Por tener un Corazon",
    "singer": "Hugo Duval",
    "discMatrix": "18221",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1951,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por tener un Corazon Hugo Duval Rodolfo Biagi 51 1951",
    "spotifyTrackId": "4gMWW3GRz50uF4CWHgqtgm"
  },
  {
    "id": "biagi-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-05",
    "title": "La Copa del Olvido",
    "singer": "Hugo Duval",
    "discMatrix": "18692",
    "publisher": "Odeon",
    "discNo": 55416,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1952,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Copa del Olvido Hugo Duval Rodolfo Biagi 52 1952",
    "spotifyTrackId": "03uniTJTLAZrUIAOa42TFq"
  },
  {
    "id": "biagi-145",
    "trackNo": null,
    "genre": "Tango",
    "date": "1952-11-05",
    "title": "El Recodo",
    "singer": "Instrumental",
    "discMatrix": "18691",
    "publisher": "Odeon",
    "discNo": 55426,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1952,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Recodo Instrumental Rodolfo Biagi instr. inst. instr inst 52 1952",
    "spotifyTrackId": "6T8fjOxxUuRkhO9KY4lra0"
  },
  {
    "id": "biagi-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-05-19",
    "title": "Y volvemos a querernos",
    "singer": "Carlos Heredia",
    "discMatrix": "18922",
    "publisher": "Odeon",
    "discNo": 55692,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1953,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y volvemos a querernos Carlos Heredia Rodolfo Biagi 53 1953",
    "spotifyTrackId": "1rQYp6QNPdno520oPtKpLI"
  },
  {
    "id": "biagi-147",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-05-19",
    "title": "El Internado",
    "singer": "Instrumental",
    "discMatrix": "18923",
    "publisher": "Odeon",
    "discNo": 55692,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1953,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Internado Instrumental Rodolfo Biagi instr. inst. instr inst 53 1953",
    "spotifyTrackId": "4ttaJIMQI36aozFC5e6TDu"
  },
  {
    "id": "biagi-148",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-12",
    "title": "Mi Pecadora",
    "singer": "Hugo Duval",
    "discMatrix": "19281",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1953,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi Pecadora Hugo Duval Rodolfo Biagi 53 1953",
    "spotifyTrackId": "5QTLk5r1uKqmK7aWwzFfEP"
  },
  {
    "id": "biagi-149",
    "trackNo": null,
    "genre": "Tango",
    "date": "1953-12-12",
    "title": "Estas Llorando",
    "singer": "Hugo Duval",
    "discMatrix": "19282",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1953,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Estas Llorando Hugo Duval Rodolfo Biagi 53 1953",
    "spotifyTrackId": "0lyvJzpl7FJAyuZpR5aaEd"
  },
  {
    "id": "biagi-150",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-06-29",
    "title": "Cielito Iindo",
    "singer": "Instrumental",
    "discMatrix": "19550",
    "publisher": "Odeon",
    "discNo": 55986,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1954,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cielito Iindo Instrumental Rodolfo Biagi instr. inst. instr inst 54 1954",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-151",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-06-29",
    "title": "Sangre de mi Sangre",
    "singer": "Hugo Duval",
    "discMatrix": "19551",
    "publisher": "Odeon",
    "discNo": 55986,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1954,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sangre de mi Sangre Hugo Duval Rodolfo Biagi 54 1954",
    "spotifyTrackId": ""
  },
  {
    "id": "biagi-152",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-29",
    "title": "Y no te voy a Llorar",
    "singer": "Hugo Duval",
    "discMatrix": "19898",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1954,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y no te voy a Llorar Hugo Duval Rodolfo Biagi 54 1954",
    "spotifyTrackId": "3Dbl2sENynl4xxDcOXgVTp"
  },
  {
    "id": "biagi-153",
    "trackNo": null,
    "genre": "Tango",
    "date": "1954-12-29",
    "title": "No me digas que no",
    "singer": "Hugo Duval",
    "discMatrix": "19899",
    "publisher": "Odeon",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1954,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No me digas que no Hugo Duval Rodolfo Biagi 54 1954",
    "spotifyTrackId": "4bGlFVaUFDbgJ0eKZ0NlcW"
  },
  {
    "id": "biagi-154",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-11-04",
    "title": "Triste Comedia",
    "singer": "Hugo Duval",
    "discMatrix": "20546",
    "publisher": "Odeon",
    "discNo": 51838,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1955,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Triste Comedia Hugo Duval Rodolfo Biagi 55 1955",
    "spotifyTrackId": "6RVKjcnKV8k3v3GDKCrvvJ"
  },
  {
    "id": "biagi-155",
    "trackNo": null,
    "genre": "Tango",
    "date": "1955-11-04",
    "title": "Santa Milonguita",
    "singer": "Hugo Duval",
    "discMatrix": "20547",
    "publisher": "Odeon",
    "discNo": 51838,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1955,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Santa Milonguita Hugo Duval Rodolfo Biagi 55 1955",
    "spotifyTrackId": "2axEdSAuAmAaH6xm1Cfd4C"
  },
  {
    "id": "biagi-156",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-03-24",
    "title": "Organito de la Tarde",
    "singer": "Instrumental",
    "discMatrix": "20858",
    "publisher": "Odeon",
    "discNo": 51915,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1956,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Organito de la Tarde Instrumental Rodolfo Biagi instr. inst. instr inst 56 1956",
    "spotifyTrackId": "5GzK3ihSChrn0kZORmaSHr"
  },
  {
    "id": "biagi-157",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-03-24",
    "title": "Alguien",
    "singer": "Hugo Duval",
    "discMatrix": "20859",
    "publisher": "Odeon",
    "discNo": 51915,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1956,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alguien Hugo Duval Rodolfo Biagi 56 1956",
    "spotifyTrackId": "4TeLdGagUf3TX1q3Dty1ez"
  },
  {
    "id": "biagi-158",
    "trackNo": null,
    "genre": "Tango",
    "date": "1956-08-28",
    "title": "El carrerito",
    "singer": "Hugo Duval",
    "discMatrix": "950",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1956,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El carrerito Hugo Duval Rodolfo Biagi 56 1956",
    "spotifyTrackId": "6Jf3K2uUyoUpvCo4TY6O6B"
  },
  {
    "id": "biagi-159",
    "trackNo": null,
    "genre": "Vals",
    "date": "1956-08-28",
    "title": "Ramona",
    "singer": "Hugo Duval",
    "discMatrix": "949",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1956,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ramona Hugo Duval Rodolfo Biagi 56 1956",
    "spotifyTrackId": "1FI1xTXibn65S4k2sJ1p9j"
  },
  {
    "id": "biagi-160",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-01-09",
    "title": "Soñemos",
    "singer": "Hugo Duval",
    "discMatrix": "1042",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1957,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soñemos Hugo Duval Rodolfo Biagi 57 1957",
    "spotifyTrackId": "7h3Q7vcJnLu5SxdsFm6RsT"
  },
  {
    "id": "biagi-161",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-01-09",
    "title": "Mi vida en tus manos",
    "singer": "Hugo Duval",
    "discMatrix": "1043",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1957,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi vida en tus manos Hugo Duval Rodolfo Biagi 57 1957",
    "spotifyTrackId": "59Vy7LINo9z0Wcf6T71RlJ"
  },
  {
    "id": "biagi-162",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-05-24",
    "title": "Esperame en el cielo",
    "singer": "Hugo Duval",
    "discMatrix": "1153",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1957,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esperame en el cielo Hugo Duval Rodolfo Biagi 57 1957",
    "spotifyTrackId": "76o5bqjyXUfZNRYjPczaG4"
  },
  {
    "id": "biagi-163",
    "trackNo": null,
    "genre": "Tango",
    "date": "1957-05-24",
    "title": "Solamente Dios y yo",
    "singer": "Hugo Duval",
    "discMatrix": "1154",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1957,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Solamente Dios y yo Hugo Duval Rodolfo Biagi 57 1957",
    "spotifyTrackId": "6iRX01lM2NLu72CfVoF6zU"
  },
  {
    "id": "biagi-164",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-09-09",
    "title": "Aquí en la tierra",
    "singer": "Hugo Duval",
    "discMatrix": "1632",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1958,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aquí en la tierra Hugo Duval Rodolfo Biagi 58 1958",
    "spotifyTrackId": "7IfS1wsnDAK9nctYyQDTCO"
  },
  {
    "id": "biagi-165",
    "trackNo": null,
    "genre": "Vals",
    "date": "1958-09-09",
    "title": "Como en un Cuento",
    "singer": "Hugo Duval",
    "discMatrix": "1631",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1958,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como en un Cuento Hugo Duval Rodolfo Biagi 58 1958",
    "spotifyTrackId": "24vsDI28t0oIPSPwhoRRE1"
  },
  {
    "id": "biagi-166",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-11-12",
    "title": "Amor mío",
    "singer": "Hugo Duval",
    "discMatrix": "1713",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1958,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amor mío Hugo Duval Rodolfo Biagi 58 1958",
    "spotifyTrackId": "6PFgGd78HHjgS1KN4eIi1s"
  },
  {
    "id": "biagi-167",
    "trackNo": null,
    "genre": "Tango",
    "date": "1958-11-12",
    "title": "Todo es amor",
    "singer": "Hugo Duval",
    "discMatrix": "1714",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1958,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todo es amor Hugo Duval Rodolfo Biagi 58 1958",
    "spotifyTrackId": "7v9nCls1XvCnXx6SMq2BR5"
  },
  {
    "id": "biagi-168",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-03-06",
    "title": "En el lago azúl",
    "singer": "Hugo Duval",
    "discMatrix": "1796",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1959,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En el lago azúl Hugo Duval Rodolfo Biagi 59 1959",
    "spotifyTrackId": "6LVusLWHODjjfmC4BLiMNW"
  },
  {
    "id": "biagi-169",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-03-06",
    "title": "Mi alondra",
    "singer": "Hugo Duval",
    "discMatrix": "1797",
    "publisher": "Columbia",
    "discNo": null,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1959,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi alondra Hugo Duval Rodolfo Biagi 59 1959",
    "spotifyTrackId": "6u90uqI4yQh6QLvzbAA37g"
  },
  {
    "id": "biagi-170",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-09-16",
    "title": "La canción",
    "singer": "Hugo Duval",
    "discMatrix": "1939",
    "publisher": "Columbia",
    "discNo": 20890,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1959,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La canción Hugo Duval Rodolfo Biagi 59 1959",
    "spotifyTrackId": "7rT9m6zSaFz2gPZSWNeEBa"
  },
  {
    "id": "biagi-171",
    "trackNo": null,
    "genre": "Tango",
    "date": "1959-09-16",
    "title": "Oh mama mía",
    "singer": "Hugo Duval",
    "discMatrix": "1940",
    "publisher": "Columbia",
    "discNo": 20890,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1959,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Oh mama mía Hugo Duval Rodolfo Biagi 59 1959",
    "spotifyTrackId": "6hNlpURRyk0lGRhCm9QsX4"
  },
  {
    "id": "biagi-172",
    "trackNo": null,
    "genre": "Tango",
    "date": "1960-07-20",
    "title": "Te burlas tristeza",
    "singer": "Hugo Duval",
    "discMatrix": "2320",
    "publisher": "Columbia",
    "discNo": 20940,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1960,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Te burlas tristeza Hugo Duval Rodolfo Biagi 60 1960",
    "spotifyTrackId": "1IkIJVU8HjRHe1vxan6Sto"
  },
  {
    "id": "biagi-173",
    "trackNo": null,
    "genre": "Tango",
    "date": "1960-07-20",
    "title": "Ayúdame",
    "singer": "Hugo Duval",
    "discMatrix": "2321",
    "publisher": "Columbia",
    "discNo": 20940,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1960,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ayúdame Hugo Duval Rodolfo Biagi 60 1960",
    "spotifyTrackId": "3MoHUnpVQZ1nMdJyTroF2M"
  },
  {
    "id": "biagi-174",
    "trackNo": null,
    "genre": "Tango",
    "date": "1961-08-07",
    "title": "Andrajos",
    "singer": "Hugo Duval",
    "discMatrix": "2638",
    "publisher": "Columbia",
    "discNo": 21007,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1961,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Andrajos Hugo Duval Rodolfo Biagi 61 1961",
    "spotifyTrackId": "3fWcKLeJhv8Ug3dVetLPMG"
  },
  {
    "id": "biagi-175",
    "trackNo": null,
    "genre": "Tango",
    "date": "1961-08-07",
    "title": "Venus",
    "singer": "Instrumental",
    "discMatrix": "2639",
    "publisher": "Columbia",
    "discNo": 21007,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1961,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Venus Instrumental Rodolfo Biagi instr. inst. instr inst 61 1961",
    "spotifyTrackId": "2yWGyL1AT9gpWZHg98J4oS"
  },
  {
    "id": "biagi-176",
    "trackNo": null,
    "genre": "Tango",
    "date": "1961-10-04",
    "title": "Canción para un cariño",
    "singer": "Hugo Duval",
    "discMatrix": "2736",
    "publisher": "Columbia",
    "discNo": 21042,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1961,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canción para un cariño Hugo Duval Rodolfo Biagi 61 1961",
    "spotifyTrackId": "3a0lWiPYijgGc6EuaYyFvr"
  },
  {
    "id": "biagi-177",
    "trackNo": null,
    "genre": "Tango",
    "date": "1961-10-04",
    "title": "Bar exposición",
    "singer": "Instrumental",
    "discMatrix": "2737",
    "publisher": "Columbia",
    "discNo": 21042,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1961,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bar exposición Instrumental Rodolfo Biagi instr. inst. instr inst 61 1961",
    "spotifyTrackId": "4fGQ4DcZC7uwbuyP1lZXJD"
  },
  {
    "id": "biagi-178",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Amor de verano",
    "singer": "Hugo Duval",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "33/Vinyl",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amor de verano Hugo Duval Rodolfo Biagi 62 1962",
    "spotifyTrackId": "1Br4R3kOmZBvGRgzQfPJJq"
  },
  {
    "id": "biagi-179",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Arriba Jara",
    "singer": "Hugo Duval",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Arriba Jara Hugo Duval Rodolfo Biagi 62 1962",
    "spotifyTrackId": "3mAijpw6uD0gbrHxkF5Cdx"
  },
  {
    "id": "biagi-180",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Canaro",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canaro Instrumental Rodolfo Biagi instr. inst. instr inst 62 1962",
    "spotifyTrackId": "4pGWS4v8yrtYM50hinLbne"
  },
  {
    "id": "biagi-181",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Con un silbido en los labios",
    "singer": "Carlos Almagro",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con un silbido en los labios Carlos Almagro Rodolfo Biagi 62 1962",
    "spotifyTrackId": "6X04wJYpQUcZTmLFPk8noH"
  },
  {
    "id": "biagi-182",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Duerme mi niña",
    "singer": "Carlos Almagro",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Duerme mi niña Carlos Almagro Rodolfo Biagi 62 1962",
    "spotifyTrackId": "5Od9qGmEBapdevfXsKaitA"
  },
  {
    "id": "biagi-183",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Estrella",
    "singer": "Hugo Duval",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Estrella Hugo Duval Rodolfo Biagi 62 1962",
    "spotifyTrackId": "28m57SeNkwFhivJun9RdZY"
  },
  {
    "id": "biagi-184",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "La Tablada",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La Tablada Instrumental Rodolfo Biagi instr. inst. instr inst 62 1962",
    "spotifyTrackId": "1XhZ8TNa4phwzxR5j7Z6Te"
  },
  {
    "id": "biagi-185",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Mariposita",
    "singer": "Hugo Duval",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mariposita Hugo Duval Rodolfo Biagi 62 1962",
    "spotifyTrackId": "2v5cf9Zuvm6prNsEbHkaT1"
  },
  {
    "id": "biagi-186",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Milonga con Variacion",
    "singer": "Instrumental",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonga con Variacion Instrumental Rodolfo Biagi instr. inst. instr inst 62 1962",
    "spotifyTrackId": "16rMbqc5wwIUuNMUBzUeg2"
  },
  {
    "id": "biagi-187",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Palermo",
    "singer": "Carlos Almagro",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Palermo Carlos Almagro Rodolfo Biagi 62 1962",
    "spotifyTrackId": "4gLYOnD8B0y5eg7opxDTRP"
  },
  {
    "id": "biagi-188",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Si no estuvieras tú",
    "singer": "Carlos Almagro",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si no estuvieras tú Carlos Almagro Rodolfo Biagi 62 1962",
    "spotifyTrackId": "2A0ICWMOV10NmE3TO1YtAX"
  },
  {
    "id": "biagi-189",
    "trackNo": null,
    "genre": "Tango",
    "date": "1962",
    "title": "Y a mí Qué?",
    "singer": "Carlos Almagro",
    "discMatrix": "",
    "publisher": "Music Hall",
    "discNo": 894,
    "orchestraId": "biagi",
    "groupId": "biagi50s60s",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "year": 1962,
    "genreShort": "",
    "singerShort": "",
    "discType": "LP",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y a mí Qué? Carlos Almagro Rodolfo Biagi 62 1962",
    "spotifyTrackId": "6TgdKbvNewwV5t7kflZ8g8"
  },
  {
    "id": "tanturi-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-06-23",
    "title": "Tierrita",
    "singer": "Instrumental",
    "discMatrix": "9077",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1937,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tierrita Instrumental Ricardo Tanturi instr. inst. instr inst 37 1937",
    "spotifyTrackId": "39XYFi4ZSAfUpRpYPsKX7P"
  },
  {
    "id": "tanturi-2",
    "trackNo": null,
    "genre": "Tango",
    "date": "1937-06-23",
    "title": "A la luz de un candil",
    "singer": "Carlos Ortega",
    "discMatrix": "9078",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1937,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A la luz de un candil Carlos Ortega Ricardo Tanturi 37 1937",
    "spotifyTrackId": "7y1W8vGjBqF7CsVCcN48c2"
  },
  {
    "id": "tanturi-4",
    "trackNo": null,
    "genre": "Marcha",
    "date": "1938-06-09",
    "title": "Carrasco",
    "singer": "Carlos Ortega",
    "discMatrix": "9442",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1938,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Carrasco Carlos Ortega Ricardo Tanturi 38 1938",
    "spotifyTrackId": "5CxiS0P4Lvtpxr0iGIRXZG"
  },
  {
    "id": "tanturi-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1938-06-09",
    "title": "Gallo ciego",
    "singer": "Instrumental",
    "discMatrix": "9443",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1938,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gallo ciego Instrumental Ricardo Tanturi instr. inst. instr inst 38 1938",
    "spotifyTrackId": "7tfjvAyB41rM0KPEcXrm4n"
  },
  {
    "id": "tanturi-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-18",
    "title": "La cumparsita",
    "singer": "Instrumental",
    "discMatrix": "39643",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1940,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Instrumental Ricardo Tanturi instr. inst. instr inst 40 1940",
    "spotifyTrackId": "5bjqAhQKcS4mhwu215EMgh"
  },
  {
    "id": "tanturi-6",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-18",
    "title": "Argañaraz",
    "singer": "Instrumental",
    "discMatrix": "39644",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1940,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Argañaraz Instrumental Ricardo Tanturi instr. inst. instr inst 40 1940",
    "spotifyTrackId": "6NdZiYfLEpjqciMCBUVofY"
  },
  {
    "id": "tanturi-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-01-08",
    "title": "El buey solo",
    "singer": "Instrumental",
    "discMatrix": "39738",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El buey solo Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "4tXfV8JhnafGIcdH4QZh2j"
  },
  {
    "id": "tanturi-8",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-01-08",
    "title": "Recuerdo",
    "singer": "Alberto Castillo",
    "discMatrix": "39739",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdo Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "4aeLSOzo18dWJO5ReQ2MPK"
  },
  {
    "id": "tanturi-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-02-19",
    "title": "La vida es corta",
    "singer": "Alberto Castillo",
    "discMatrix": "39812",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La vida es corta Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "1j0djXINcSVRL5NF6ctHxz"
  },
  {
    "id": "tanturi-10",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-02-19",
    "title": "Mozo guapo",
    "singer": "Alberto Castillo",
    "discMatrix": "39813",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mozo guapo Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "4U59o6JM9AFLhMBrbBmxtx"
  },
  {
    "id": "tanturi-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-05-06",
    "title": "Una noche de garufa",
    "singer": "Instrumental",
    "discMatrix": "39868",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una noche de garufa Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "1JFS1FwTEcLLrqSvLv8MA9"
  },
  {
    "id": "tanturi-12",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-05-06",
    "title": "La serenata (Mi amor)",
    "singer": "Alberto Castillo",
    "discMatrix": "39869",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La serenata (Mi amor) Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "3v4w9cj16oYi8LEHPrpbLw"
  },
  {
    "id": "tanturi-13",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-16",
    "title": "Comparsa criolla",
    "singer": "Instrumental",
    "discMatrix": "59519",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Comparsa criolla Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "5IBLROXSgAFWgFdl6ZUr6F"
  },
  {
    "id": "tanturi-14",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-06-16",
    "title": "Pocas palabras",
    "singer": "Alberto Castillo",
    "discMatrix": "59520",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pocas palabras Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "5qcHjSH1hrpk9TcfVm8cfQ"
  },
  {
    "id": "tanturi-16",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-07-07",
    "title": "Mi romance",
    "singer": "Alberto Castillo",
    "discMatrix": "59560",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi romance Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "5YM9it8sSRUMl6uGcC30DI"
  },
  {
    "id": "tanturi-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-07",
    "title": "Lágrimas",
    "singer": "Instrumental",
    "discMatrix": "59599",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Lágrimas Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "3CReIw4nyJmLlUs1Vynmfw"
  },
  {
    "id": "tanturi-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-08-14",
    "title": "Noches de Colón",
    "singer": "Alberto Castillo",
    "discMatrix": "59714",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Noches de Colón Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "0kGEkhN1rXXuvzwm4YAL58"
  },
  {
    "id": "tanturi-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-08-14",
    "title": "Adiós pueblo",
    "singer": "Instrumental",
    "discMatrix": "59715",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós pueblo Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "2oquBxfd1sJk30pl8tTinF"
  },
  {
    "id": "tanturi-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-18",
    "title": "Didí",
    "singer": "Instrumental",
    "discMatrix": "59788",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Didí Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "7kVcmLaoZn9nIZRQPsR6Yr"
  },
  {
    "id": "tanturi-20",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-09-18",
    "title": "Mi morocha",
    "singer": "Alberto Castillo",
    "discMatrix": "59789",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi morocha Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "08STU5MhOmd8w3zHcIBqOW"
  },
  {
    "id": "tanturi-21",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-27",
    "title": "La huella",
    "singer": "Instrumental",
    "discMatrix": "59896",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La huella Instrumental Ricardo Tanturi instr. inst. instr inst 41 1941",
    "spotifyTrackId": "7s67VwbeE9kjnWQBcOFX04"
  },
  {
    "id": "tanturi-22",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-27",
    "title": "El moro",
    "singer": "Alberto Castillo",
    "discMatrix": "59897",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El moro Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "1hZzXEnGmnj4XU5FhV0rWQ"
  },
  {
    "id": "tanturi-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-23",
    "title": "Recuerdo malevo",
    "singer": "Alberto Castillo",
    "discMatrix": "59961",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdo malevo Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "4aeLSOzo18dWJO5ReQ2MPK"
  },
  {
    "id": "tanturi-24",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-23",
    "title": "Ese sos vos",
    "singer": "Alberto Castillo",
    "discMatrix": "59962",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1941,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ese sos vos Alberto Castillo Ricardo Tanturi 41 1941",
    "spotifyTrackId": "7CfyA5q65wIZdJ6D11dImE"
  },
  {
    "id": "tanturi-25",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-18",
    "title": "Al compás de un tango",
    "singer": "Alberto Castillo",
    "discMatrix": "69618",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Al compás de un tango Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "3PwMKwArNQIl2yuArwkONR"
  },
  {
    "id": "tanturi-26",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-18",
    "title": "Madame Ivonne",
    "singer": "Alberto Castillo",
    "discMatrix": "69619",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Madame Ivonne Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "4aTUlguQhwH0dug2NiZ9cH"
  },
  {
    "id": "tanturi-27",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-05-27",
    "title": "Así es la milonga",
    "singer": "Alberto Castillo",
    "discMatrix": "69733",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así es la milonga Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "78VYqiUtLjcJZNUCTnfPQF"
  },
  {
    "id": "tanturi-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-05-27",
    "title": "Decile que vuelva",
    "singer": "Alberto Castillo",
    "discMatrix": "69734",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Decile que vuelva Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "7qObQb6Nct2pj8Wg9fLZ8X"
  },
  {
    "id": "tanturi-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-20",
    "title": "La copa del olvido",
    "singer": "Alberto Castillo",
    "discMatrix": "69807",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La copa del olvido Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "2EP8Ts17jIzqXhqAYem6sq"
  },
  {
    "id": "tanturi-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-20",
    "title": "Tango (Voz de tango)",
    "singer": "Alberto Castillo",
    "discMatrix": "69808",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tango (Voz de tango) Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-31",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-14",
    "title": "Esta noche me emborracho",
    "singer": "Alberto Castillo",
    "discMatrix": "69873",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche me emborracho Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "0mYYw0Dcl0fN966hHVSIe2"
  },
  {
    "id": "tanturi-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-14",
    "title": "El tango es el tango",
    "singer": "Alberto Castillo",
    "discMatrix": "69874",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El tango es el tango Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-15",
    "title": "Cómo se pianta la vida",
    "singer": "Alberto Castillo",
    "discMatrix": "69931",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cómo se pianta la vida Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "2M8aNDg28wmLTj26hcP7kB"
  },
  {
    "id": "tanturi-34",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-09-15",
    "title": "Marisabel",
    "singer": "Alberto Castillo",
    "discMatrix": "69932",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Marisabel Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "3kBhSoK6DKQZiMT8bU1wzE"
  },
  {
    "id": "tanturi-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-22",
    "title": "Muñeca brava",
    "singer": "Alberto Castillo",
    "discMatrix": "69949",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muñeca brava Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "0jJmkvcRT2vGETrMAgwmIk"
  },
  {
    "id": "tanturi-36",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-22",
    "title": "Un crimen",
    "singer": "Alberto Castillo",
    "discMatrix": "69950",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un crimen Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "6E3akhwjmSniDRywVhuqwZ"
  },
  {
    "id": "tanturi-37",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-04",
    "title": "Cuatro compases",
    "singer": "Alberto Castillo",
    "discMatrix": "84036",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro compases Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "2Bxi12RwWXUCEkbp0XRJZ5"
  },
  {
    "id": "tanturi-38",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-04",
    "title": "Recuerdo",
    "singer": "Instrumental",
    "discMatrix": "84037",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recuerdo Instrumental Ricardo Tanturi instr. inst. instr inst 42 1942",
    "spotifyTrackId": "4aeLSOzo18dWJO5ReQ2MPK"
  },
  {
    "id": "tanturi-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-04",
    "title": "Canción de rango (Pa’ que se callen)",
    "singer": "Alberto Castillo",
    "discMatrix": "84092",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Canción de rango (Pa’ que se callen) Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "5FkYpTxUNn4yIoJOVamIRS"
  },
  {
    "id": "tanturi-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-04",
    "title": "Moneda De Cobre",
    "singer": "Alberto Castillo",
    "discMatrix": "84093",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Moneda De Cobre Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "4wmWaGYkIAjqNExoXIqJbz"
  },
  {
    "id": "tanturi-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-04",
    "title": "Así se baila el tango",
    "singer": "Alberto Castillo",
    "discMatrix": "84091",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así se baila el tango Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "7aYdjI2VhlHuTIVgBiwBOI"
  },
  {
    "id": "tanturi-42",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-04",
    "title": "Me llaman El Zorro",
    "singer": "Alberto Castillo",
    "discMatrix": "84094",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me llaman El Zorro Alberto Castillo Ricardo Tanturi 42 1942",
    "spotifyTrackId": "7nZQugHfJHGiTDnZnc5KIu"
  },
  {
    "id": "tanturi-43",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-02",
    "title": "Qué podrán decir",
    "singer": "Alberto Castillo",
    "discMatrix": "84238",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué podrán decir Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "2DLi2yAFmtjJ1KlS28tOTJ"
  },
  {
    "id": "tanturi-44",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-02",
    "title": "A otra cosa che pebeta",
    "singer": "Alberto Castillo",
    "discMatrix": "84240",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A otra cosa che pebeta Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "5lH2JMApMDJu3SOiLIzjoK"
  },
  {
    "id": "tanturi-45",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-03-02",
    "title": "A mi madre (Con los amigos)",
    "singer": "Alberto Castillo",
    "discMatrix": "84241",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A mi madre (Con los amigos) Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "1d7f3GZUqIIGRdhu8sFjYd"
  },
  {
    "id": "tanturi-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-02",
    "title": "Entre sueños",
    "singer": "Instrumental",
    "discMatrix": "84242",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entre sueños Instrumental Ricardo Tanturi instr. inst. instr inst 43 1943",
    "spotifyTrackId": "32DI7JHKzKrQGEfTtZqPoo"
  },
  {
    "id": "tanturi-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-16",
    "title": "Ya sale el tren",
    "singer": "Alberto Castillo",
    "discMatrix": "84254",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ya sale el tren Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "06zizak2bOEjFIC5XlLCAy"
  },
  {
    "id": "tanturi-48",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-16",
    "title": "Mi piba",
    "singer": "Alberto Castillo",
    "discMatrix": "84255",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi piba Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "0JZQRnzWuTwQ8HjKAPt8IY"
  },
  {
    "id": "tanturi-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-29",
    "title": "La última copa",
    "singer": "Alberto Castillo",
    "discMatrix": "84317",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La última copa Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "0RnoW5jRE2igvTRbbmhdHE"
  },
  {
    "id": "tanturi-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-29",
    "title": "Que me quiten lo bailao",
    "singer": "Alberto Castillo",
    "discMatrix": "84318",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que me quiten lo bailao Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "5cDSxGQP2xbb6a5z8fHJh1"
  },
  {
    "id": "tanturi-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-07",
    "title": "Barajando recuerdos",
    "singer": "Alberto Castillo",
    "discMatrix": "84334",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barajando recuerdos Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "5SR1g6th45C3Mx1mlMJ7Vd"
  },
  {
    "id": "tanturi-52",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-05-07",
    "title": "Bailongo de los domingos",
    "singer": "Alberto Castillo",
    "discMatrix": "84335",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi3643",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bailongo de los domingos Alberto Castillo Ricardo Tanturi 43 1943",
    "spotifyTrackId": "7wfbtim9e2IHqc6CGdejGg"
  },
  {
    "id": "tanturi-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-06",
    "title": "Muchachos comienza la ronda",
    "singer": "Enrique Campos",
    "discMatrix": "77129",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchachos comienza la ronda Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "2GMBZe2XZJ2HAYSvj8jVeC"
  },
  {
    "id": "tanturi-54",
    "trackNo": null,
    "genre": "Vals",
    "date": "1943-08-06",
    "title": "Al pasar",
    "singer": "Enrique Campos",
    "discMatrix": "77130",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Al pasar Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "3KqcdxdWlqFj7gBtXSFJB9"
  },
  {
    "id": "tanturi-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-06",
    "title": "Vieja esquina",
    "singer": "Enrique Campos",
    "discMatrix": "77131",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vieja esquina Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "5gJ3NgHPSOeOj0Ab9AC1lr"
  },
  {
    "id": "tanturi-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-06",
    "title": "Por eso canto yo",
    "singer": "Enrique Campos",
    "discMatrix": "77132",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por eso canto yo Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "4Vs59MbCIothLFLdSy9bgl"
  },
  {
    "id": "tanturi-57",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-19",
    "title": "Palomita mía",
    "singer": "Enrique Campos",
    "discMatrix": "77160",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Palomita mía Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "60Iu7T9mtElhWaUyO2Oqlt"
  },
  {
    "id": "tanturi-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-19",
    "title": "Así se canta",
    "singer": "Enrique Campos",
    "discMatrix": "77161",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así se canta Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "2duXBH7rZdclCbKMaSzmEA"
  },
  {
    "id": "tanturi-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-05",
    "title": "Dos palabras por favor",
    "singer": "Enrique Campos",
    "discMatrix": "77278",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos palabras por favor Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "2u251WvZqXC9QGE0wYBTVL"
  },
  {
    "id": "tanturi-60",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-10-05",
    "title": "Qué bien te queda (Como has cambiado)",
    "singer": "Enrique Campos",
    "discMatrix": "77279",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué bien te queda (Como has cambiado) Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "3D6YlhXJ0vKjZbCBNhxzjc"
  },
  {
    "id": "tanturi-63",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-17",
    "title": "Que nunca me falte",
    "singer": "Enrique Campos",
    "discMatrix": "77387",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Que nunca me falte Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "7gc3zCYmRb82QLYqUZLgwd"
  },
  {
    "id": "tanturi-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-17",
    "title": "Una emoción",
    "singer": "Enrique Campos",
    "discMatrix": "77836",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una emoción Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "67XNoYO6rbBDXPPsngft1t"
  },
  {
    "id": "tanturi-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-17",
    "title": "Oigo tu voz",
    "singer": "Enrique Campos",
    "discMatrix": "77385",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Oigo tu voz Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "4e52I06F1ZgdoevpXVisBw"
  },
  {
    "id": "tanturi-64",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-17",
    "title": "Malvón",
    "singer": "Enrique Campos",
    "discMatrix": "77388",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Malvón Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "7bOduajRO6ugcuUfx5xB59"
  },
  {
    "id": "tanturi-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-16",
    "title": "Sollozo de bandoneon",
    "singer": "Enrique Campos",
    "discMatrix": "84304",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sollozo de bandoneon Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "0Gyi3V39qLWRkleVrDQHXw"
  },
  {
    "id": "tanturi-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-16",
    "title": "Qué vas buscando muñeca",
    "singer": "Enrique Campos",
    "discMatrix": "84305",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué vas buscando muñeca Enrique Campos Ricardo Tanturi 43 1943",
    "spotifyTrackId": "0GMLu9xQF8YR8XpyzRq2HU"
  },
  {
    "id": "tanturi-68",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-01-21",
    "title": "Quien canta mejor que yo",
    "singer": "Enrique Campos",
    "discMatrix": "79513",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quien canta mejor que yo Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "1VsHc0qIeyjYOyvN5HIEQG"
  },
  {
    "id": "tanturi-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-01-21",
    "title": "Rey del tango",
    "singer": "Enrique Campos",
    "discMatrix": "79514",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rey del tango Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "6XeRfsHXT34oELpwXVgWrX"
  },
  {
    "id": "tanturi-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-24",
    "title": "Y siempre igual",
    "singer": "Enrique Campos",
    "discMatrix": "79587",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y siempre igual Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "109EyK7xDehp08SscJMVOV"
  },
  {
    "id": "tanturi-70",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-24",
    "title": "Recién",
    "singer": "Enrique Campos",
    "discMatrix": "79588",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Recién Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "3PQSBjP4LE33kB5gly4y9P"
  },
  {
    "id": "tanturi-71",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-27",
    "title": "La abandoné y no sabía",
    "singer": "Enrique Campos",
    "discMatrix": "79655",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La abandoné y no sabía Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "6WACVXTc0uq6nJuWsrEYPZ"
  },
  {
    "id": "tanturi-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-27",
    "title": "Y como le iba contando",
    "singer": "Enrique Campos",
    "discMatrix": "79656",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y como le iba contando Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "0rq11aDsgfQttbjCzkHV6b"
  },
  {
    "id": "tanturi-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-27",
    "title": "Desde lejos",
    "singer": "Enrique Campos",
    "discMatrix": "79657",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desde lejos Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "7LhndvU4nnG97O5eE0ZyCC"
  },
  {
    "id": "tanturi-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-27",
    "title": "Jirón del suburbio",
    "singer": "Enrique Campos",
    "discMatrix": "79658",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Jirón del suburbio Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "1hYHCxenGO6J8MrQRCfdsy"
  },
  {
    "id": "tanturi-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-27",
    "title": "Sombrerito",
    "singer": "Enrique Campos",
    "discMatrix": "79788",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sombrerito Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "7y1W8vGjBqF7CsVCcN48c2"
  },
  {
    "id": "tanturi-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-18",
    "title": "Calla bandoneon",
    "singer": "Enrique Campos",
    "discMatrix": "79695",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Calla bandoneon Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "15fQSULa76FDKaVXJGF44N"
  },
  {
    "id": "tanturi-76",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-05-18",
    "title": "Añoranzas",
    "singer": "Enrique Campos",
    "discMatrix": "79696",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Añoranzas Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "4rwRw7vTjRzUlUrveYdJwh"
  },
  {
    "id": "tanturi-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-27",
    "title": "Si se salva el pibe",
    "singer": "Enrique Campos",
    "discMatrix": "79787",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Si se salva el pibe Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "0A3ssBHZAgGeoQQm7RBoVp"
  },
  {
    "id": "tanturi-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-28",
    "title": "Domingo a la noche",
    "singer": "Enrique Campos",
    "discMatrix": "79827",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Domingo a la noche Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "3IwgGdRQfMAsawLm3pO2Ud"
  },
  {
    "id": "tanturi-80",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-08-28",
    "title": "Desde el alma",
    "singer": "Instrumental",
    "discMatrix": "79828",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Desde el alma Instrumental Ricardo Tanturi instr. inst. instr inst 44 1944",
    "spotifyTrackId": "3NYXrwtiPkJLViMKFTmwN8"
  },
  {
    "id": "tanturi-81",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-29",
    "title": "Encuentro",
    "singer": "Enrique Campos",
    "discMatrix": "79872",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Encuentro Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "67XNoYO6rbBDXPPsngft1t"
  },
  {
    "id": "tanturi-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-29",
    "title": "El corazón me decia",
    "singer": "Enrique campos",
    "discMatrix": "79873",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El corazón me decia Enrique campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "25deVT5BhGGKul1GftbAx6"
  },
  {
    "id": "tanturi-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-14",
    "title": "En el salon",
    "singer": "Enrique Campos",
    "discMatrix": "79933",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En el salon Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "093SOAM9DHAkqDmC4Q0TB2"
  },
  {
    "id": "tanturi-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-14",
    "title": "Prisionero",
    "singer": "Enrique Campos",
    "discMatrix": "79934",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Prisionero Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "1YgB4syLmgQDaqaFTx2GmV"
  },
  {
    "id": "tanturi-86",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-13",
    "title": "Igual que un bandoneón",
    "singer": "Enrique Campos",
    "discMatrix": "79976",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Igual que un bandoneón Enrique Campos Ricardo Tanturi 44 1944",
    "spotifyTrackId": "26oqn52dXUY0X2ccZNKWzP"
  },
  {
    "id": "tanturi-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-13",
    "title": "El taita (Raza criolla)",
    "singer": "Instrumental",
    "discMatrix": "79975",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El taita (Raza criolla) Instrumental Ricardo Tanturi instr. inst. instr inst 44 1944",
    "spotifyTrackId": "4XSwYmJvTPuux9O90DjaAU"
  },
  {
    "id": "tanturi-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-22",
    "title": "Esta noche al pasar",
    "singer": "Enrique Campos",
    "discMatrix": "80531",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche al pasar Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "0r8DEI95SCwwaI3UVrIrdo"
  },
  {
    "id": "tanturi-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-01-22",
    "title": "De seis a siete",
    "singer": "Enrique Campos",
    "discMatrix": "80532",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De seis a siete Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "1psn4F8pD5wXV5SuOr1BDE"
  },
  {
    "id": "tanturi-89",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-02-19",
    "title": "Me besó y se fue",
    "singer": "Enrique Campos",
    "discMatrix": "80551",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me besó y se fue Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "1l4COtiGHnD3zPAalByLNo"
  },
  {
    "id": "tanturi-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-02-19",
    "title": "Qué será de ti",
    "singer": "Enrique Campos",
    "discMatrix": "80552",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué será de ti Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "28AuyYu1wr54Z7P0RbEakD"
  },
  {
    "id": "tanturi-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-12",
    "title": "Igual que una sombra",
    "singer": "Enrique Campos",
    "discMatrix": "80603",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Igual que una sombra Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "1m9jIVw8N7BxKoFmFi0Kaf"
  },
  {
    "id": "tanturi-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-12",
    "title": "La uruguayita Lucía",
    "singer": "Enrique Campos",
    "discMatrix": "80604",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La uruguayita Lucía Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "1mh5Q75lNDc1cB9JUt5tBU"
  },
  {
    "id": "tanturi-93",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-03",
    "title": "Discos de Gardel",
    "singer": "Enrique Campos",
    "discMatrix": "80630",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Discos de Gardel Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "22ij2yXIjjZEUB3sKajKXJ"
  },
  {
    "id": "tanturi-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-03",
    "title": "Giuseppe el zapatero",
    "singer": "Enrique Campos",
    "discMatrix": "80631",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Giuseppe el zapatero Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "2hmzcuNNvbjZnX7MKd9SNV"
  },
  {
    "id": "tanturi-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-14",
    "title": "El sueño del pibe",
    "singer": "Enrique Campos",
    "discMatrix": "80687",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El sueño del pibe Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "4PtzokGsgSe6Z2EOH2rb8O"
  },
  {
    "id": "tanturi-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-06-14",
    "title": "Anselmo Laguna",
    "singer": "Enrique Campos",
    "discMatrix": "80688",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Anselmo Laguna Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "1innczoVNQL6FA1IybKWWw"
  },
  {
    "id": "tanturi-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-13",
    "title": "Cantor de barrio",
    "singer": "Enrique Campos",
    "discMatrix": "80722",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cantor de barrio Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "6NlNlswmUNZ4XVkliWgi5x"
  },
  {
    "id": "tanturi-98",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-07-13",
    "title": "Bien criolla y bien porteña",
    "singer": "Enrique Campos",
    "discMatrix": "80723",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bien criolla y bien porteña Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-05",
    "title": "Ivón",
    "singer": "Enrique Campos",
    "discMatrix": "80801",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ivón Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "4aTUlguQhwH0dug2NiZ9cH"
  },
  {
    "id": "tanturi-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-09-05",
    "title": "Cuatro recuerdos",
    "singer": "Enrique Campos",
    "discMatrix": "80802",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro recuerdos Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "0VZ7UWgkNbaXmJwI5HCC1c"
  },
  {
    "id": "tanturi-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-23",
    "title": "Cuatro lagrimas",
    "singer": "Enrique Campos",
    "discMatrix": "80855",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuatro lagrimas Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "0ZBLKLg4Dta1zM6SM91qt7"
  },
  {
    "id": "tanturi-102",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-10-23",
    "title": "Tu vieja ventana",
    "singer": "Enrique Campos y Roberto Videla",
    "discMatrix": "80856",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu vieja ventana Enrique Campos y Roberto Videla Ricardo Tanturi 45 1945",
    "spotifyTrackId": "7bjlK6O7XW6EtjsgPXGYN0"
  },
  {
    "id": "tanturi-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-09",
    "title": "Calor de hogar",
    "singer": "Enrique Campos",
    "discMatrix": "80884",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Calor de hogar Enrique Campos Ricardo Tanturi 45 1945",
    "spotifyTrackId": "4TlbHVltumvclZoCQJjnQC"
  },
  {
    "id": "tanturi-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-09",
    "title": "Tu llamado",
    "singer": "Roberto Videla",
    "discMatrix": "80885",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu llamado Roberto Videla Ricardo Tanturi 45 1945",
    "spotifyTrackId": "2ZoVs0zZLORvAVA5kvbqM9"
  },
  {
    "id": "tanturi-106",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-05",
    "title": "Este es tu tango",
    "singer": "Roberto Videla",
    "discMatrix": "80922",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Este es tu tango Roberto Videla Ricardo Tanturi 45 1945",
    "spotifyTrackId": "7y1W8vGjBqF7CsVCcN48c2"
  },
  {
    "id": "tanturi-105",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-12-05",
    "title": "Corazóncito (Ñafa)",
    "singer": "Roberto Videla",
    "discMatrix": "80921",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Corazóncito (Ñafa) Roberto Videla Ricardo Tanturi 45 1945",
    "spotifyTrackId": "3QYeH2Plp0D74990qTO1vm"
  },
  {
    "id": "tanturi-107",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-03",
    "title": "Seis dias",
    "singer": "Enrique Campos",
    "discMatrix": "80958",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Seis dias Enrique Campos Ricardo Tanturi 46 1946",
    "spotifyTrackId": "0BYdbaXhHUw9LUU8eV3U43"
  },
  {
    "id": "tanturi-108",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-03",
    "title": "Soy muchacho de arrabal",
    "singer": "Enrique Campos",
    "discMatrix": "80959",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Soy muchacho de arrabal Enrique Campos Ricardo Tanturi 46 1946",
    "spotifyTrackId": "2bbpd1TZb8v3uBTzqph6GA"
  },
  {
    "id": "tanturi-109",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-30",
    "title": "Barrio viejo",
    "singer": "Enrique Campos y Roberto Videla",
    "discMatrix": "80988",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barrio viejo Enrique Campos y Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "1bHruTqeg9XJTMScYVFxAQ"
  },
  {
    "id": "tanturi-110",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-01-30",
    "title": "Llevame carretero",
    "singer": "Roberto Videla",
    "discMatrix": "80989",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llevame carretero Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "5kz6z0k9Yjf55eAeOtf26H"
  },
  {
    "id": "tanturi-111",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-13",
    "title": "Así era ella muchachos",
    "singer": "Roberto Videla",
    "discMatrix": "82030",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así era ella muchachos Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "2eqn7Q34pcc3X1h1Ra68dm"
  },
  {
    "id": "tanturi-112",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-13",
    "title": "Esta noche hay una fiesta",
    "singer": "Enrique Campos",
    "discMatrix": "82031",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4346",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche hay una fiesta Enrique Campos Ricardo Tanturi 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-113",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-04-03",
    "title": "Como aquella muñeca",
    "singer": "Roberto Videla",
    "discMatrix": "82054",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como aquella muñeca Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "2Wg7YSJwRSgV3wW922LS7X"
  },
  {
    "id": "tanturi-114",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-04-23",
    "title": "Tiene razón amigazo",
    "singer": "Roberto Videla",
    "discMatrix": "82084",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tiene razón amigazo Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "1B8NQSmNsge5XJ8w80SSbQ"
  },
  {
    "id": "tanturi-115",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-16",
    "title": "Viejo ciego",
    "singer": "Roberto Videla",
    "discMatrix": "82126",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viejo ciego Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "7cJG0uOdZafsPjsWp0pqyo"
  },
  {
    "id": "tanturi-116",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-05-16",
    "title": "Espera de esquina",
    "singer": "Roberto Videla",
    "discMatrix": "82127",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Espera de esquina Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "3QYeH2Plp0D74990qTO1vm"
  },
  {
    "id": "tanturi-117",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-05",
    "title": "Alma en pena",
    "singer": "Roberto Videla",
    "discMatrix": "82194",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alma en pena Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "57VyvfnIQPPUiD0FPIR4RL"
  },
  {
    "id": "tanturi-118",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-05",
    "title": "Viviane de París",
    "singer": "Roberto Videla",
    "discMatrix": "82195",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viviane de París Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "0rlR5L6gRYmQ9eIvCxidW1"
  },
  {
    "id": "tanturi-119",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-23",
    "title": "La mesa de un café",
    "singer": "Roberto Videla",
    "discMatrix": "82241",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La mesa de un café Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "58J72Jqyb1sdtxUhG6mUuv"
  },
  {
    "id": "tanturi-120",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-23",
    "title": "No me escribas",
    "singer": "Roberto Videla",
    "discMatrix": "82242",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No me escribas Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "4gMMft3bhBwwpNR8JCT2zG"
  },
  {
    "id": "tanturi-121",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-08-16",
    "title": "Amores de estudiantes",
    "singer": "Roberto Videla y Osvaldo Ribó",
    "discMatrix": "82291",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amores de estudiantes Roberto Videla y Osvaldo Ribó Ricardo Tanturi 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-123",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-27",
    "title": "Sombras",
    "singer": "Osvaldo Ribo",
    "discMatrix": "82307",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Sombras Osvaldo Ribo Ricardo Tanturi 46 1946",
    "spotifyTrackId": "6jopUJVDBkr94z6r3XJgId"
  },
  {
    "id": "tanturi-122",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-08-27",
    "title": "Una lágrima",
    "singer": "Osvaldo Ribo",
    "discMatrix": "82306",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una lágrima Osvaldo Ribo Ricardo Tanturi 46 1946",
    "spotifyTrackId": "74YnRmAVYM7yBPZh5asdEL"
  },
  {
    "id": "tanturi-124",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-09-05",
    "title": "Noche de tango",
    "singer": "Riberto Videla",
    "discMatrix": "82330",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Noche de tango Riberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "5YM9it8sSRUMl6uGcC30DI"
  },
  {
    "id": "tanturi-125",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-15",
    "title": "En la copa de la vida",
    "singer": "Roberto Videla",
    "discMatrix": "82476",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En la copa de la vida Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "tanturi-126",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-15",
    "title": "De todo te olvidas (Cabeza de novia)",
    "singer": "Osvaldo Ribo",
    "discMatrix": "82477",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De todo te olvidas (Cabeza de novia) Osvaldo Ribo Ricardo Tanturi 46 1946",
    "spotifyTrackId": "3oFOsWiMAH0qduPFaBS1Ki"
  },
  {
    "id": "tanturi-127",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-29",
    "title": "Tus besos fueron míos",
    "singer": "Roberto Videla",
    "discMatrix": "82509",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tus besos fueron míos Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "2QyJTZeqxmVfbesnoN3r7k"
  },
  {
    "id": "tanturi-128",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-11-29",
    "title": "Ana Lucía",
    "singer": "Roberto Videla",
    "discMatrix": "82510",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ana Lucía Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "1EPIN9BObhHZMTbL5prHwG"
  },
  {
    "id": "tanturi-129",
    "trackNo": null,
    "genre": "Vals",
    "date": "1946-12-19",
    "title": "Tu olvido",
    "singer": "Roberto Videla y Osvaldo Ribó",
    "discMatrix": "82551",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tu olvido Roberto Videla y Osvaldo Ribó Ricardo Tanturi 46 1946",
    "spotifyTrackId": "3rI25QF6YWE9wNsqWPC7LZ"
  },
  {
    "id": "tanturi-130",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-12-19",
    "title": "Yo sé lo que te digo",
    "singer": "Roberto Videla",
    "discMatrix": "82552",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo sé lo que te digo Roberto Videla Ricardo Tanturi 46 1946",
    "spotifyTrackId": "3jRKQNRbmkLvsAlK1q3RV5"
  },
  {
    "id": "tanturi-131",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-03-28",
    "title": "Me da pena confesarlo",
    "singer": "Roberto Videla",
    "discMatrix": "83331",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me da pena confesarlo Roberto Videla Ricardo Tanturi 47 1947",
    "spotifyTrackId": "2Xewfz9MCfXqHXs1N0iDON"
  },
  {
    "id": "tanturi-132",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-03-28",
    "title": "Mariposita",
    "singer": "Instrumental",
    "discMatrix": "83332",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mariposita Instrumental Ricardo Tanturi instr. inst. instr inst 47 1947",
    "spotifyTrackId": "6zSwBv4U4MVVDMqvNEnME3"
  },
  {
    "id": "tanturi-133",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-02",
    "title": "Amiga",
    "singer": "Roberto Videla",
    "discMatrix": "83339",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Amiga Roberto Videla Ricardo Tanturi 47 1947",
    "spotifyTrackId": "6IMbIkOKh2RC3ADj2PR1B2"
  },
  {
    "id": "tanturi-134",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-04-02",
    "title": "Alma de bohemio",
    "singer": "Osvaldo Ribo",
    "discMatrix": "83340",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Alma de bohemio Osvaldo Ribo Ricardo Tanturi 47 1947",
    "spotifyTrackId": "3aCbp5XTPjw35OVDDWSnce"
  },
  {
    "id": "tanturi-135",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-09",
    "title": "Callejero",
    "singer": "Roberto Videla",
    "discMatrix": "83414",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Callejero Roberto Videla Ricardo Tanturi 47 1947",
    "spotifyTrackId": "62SQFjHVlnuR5e2UcEk5YV"
  },
  {
    "id": "tanturi-136",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-09",
    "title": "Remembranza",
    "singer": "Osvaldo Ribo",
    "discMatrix": "83415",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Remembranza Osvaldo Ribo Ricardo Tanturi 47 1947",
    "spotifyTrackId": "7vyLAYlr67rirzOcEK1AyR"
  },
  {
    "id": "tanturi-137",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-09",
    "title": "San José de Flores",
    "singer": "Roberto Videla",
    "discMatrix": "83416",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "San José de Flores Roberto Videla Ricardo Tanturi 47 1947",
    "spotifyTrackId": "6fHVHU2MzK208mUxJbTvPF"
  },
  {
    "id": "tanturi-138",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-05-09",
    "title": "Vagabundo",
    "singer": "Osvaldo Ribo",
    "discMatrix": "83417",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Vagabundo Osvaldo Ribo Ricardo Tanturi 47 1947",
    "spotifyTrackId": "43S9NjSLfcB6zTbuwuYsiS"
  },
  {
    "id": "tanturi-139",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-31",
    "title": "Aquel muchacho triste",
    "singer": "Roberto Videla",
    "discMatrix": "83736",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Aquel muchacho triste Roberto Videla Ricardo Tanturi 47 1947",
    "spotifyTrackId": "4ezEvF3ehHYSA1b901Bthr"
  },
  {
    "id": "tanturi-140",
    "trackNo": null,
    "genre": "Tango",
    "date": "1947-10-31",
    "title": "Quejas del alma",
    "singer": "Osvaldo Ribo",
    "discMatrix": "83737",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1947,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quejas del alma Osvaldo Ribo Ricardo Tanturi 47 1947",
    "spotifyTrackId": "6p8vtwIYWzE1UQHOnsfUtk"
  },
  {
    "id": "tanturi-141",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-01-26",
    "title": "Bolero",
    "singer": "Roberto Videla",
    "discMatrix": "83828",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bolero Roberto Videla Ricardo Tanturi 48 1948",
    "spotifyTrackId": "227jfBLEnbUzD3FGaSZRKD"
  },
  {
    "id": "tanturi-142",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-01-26",
    "title": "Papel picado",
    "singer": "Osvaldo Ribo",
    "discMatrix": "83829",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Papel picado Osvaldo Ribo Ricardo Tanturi 48 1948",
    "spotifyTrackId": "2HnrdLBycRdC1If6Vb16Y5"
  },
  {
    "id": "tanturi-143",
    "trackNo": null,
    "genre": "Vals",
    "date": "1948-06-25",
    "title": "Dos que se aman",
    "singer": "Osvaldo Ribo y Roberto Videla",
    "discMatrix": "83988",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dos que se aman Osvaldo Ribo y Roberto Videla Ricardo Tanturi 48 1948",
    "spotifyTrackId": "0l0gd2jbGRioQAvU3MdIac"
  },
  {
    "id": "tanturi-144",
    "trackNo": null,
    "genre": "Tango",
    "date": "1948-06-25",
    "title": "Campeonato",
    "singer": "Roberto Videla",
    "discMatrix": "83989",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1948,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Campeonato Roberto Videla Ricardo Tanturi 48 1948",
    "spotifyTrackId": "3PwMKwArNQIl2yuArwkONR"
  },
  {
    "id": "tanturi-145",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-08-16",
    "title": "Milonguita (Esthercita)",
    "singer": "Horacio Roca",
    "discMatrix": "93826",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Milonguita (Esthercita) Horacio Roca Ricardo Tanturi 50 1950",
    "spotifyTrackId": "7y1W8vGjBqF7CsVCcN48c2"
  },
  {
    "id": "tanturi-146",
    "trackNo": null,
    "genre": "Tango",
    "date": "1950-08-16",
    "title": "Cuando llora la milonga",
    "singer": "Osvaldo Ribo",
    "discMatrix": "93827",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "tanturi",
    "groupId": "tanturi4650",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "year": 1950,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando llora la milonga Osvaldo Ribo Ricardo Tanturi 50 1950",
    "spotifyTrackId": "0YgekGzmugep7Zp824YixB"
  },
  {
    "id": "dagostino-1",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-13",
    "title": "No aflojés",
    "singer": "Ángel Vargas",
    "discMatrix": "39631",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1940,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No aflojés Ángel Vargas Ángel D'Agostino 40 1940",
    "spotifyTrackId": "3bVdfowkpJfTGSYL8YKa2e"
  },
  {
    "id": "dagostino-2",
    "trackNo": null,
    "genre": "Tango",
    "date": "1940-11-13",
    "title": "Muchacho",
    "singer": "Ángel Vargas",
    "discMatrix": "39632",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1940,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muchacho Ángel Vargas Ángel D'Agostino 40 1940",
    "spotifyTrackId": "3CpMtKcstA5Wj0YSJ7OHFV"
  },
  {
    "id": "dagostino-3",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-24",
    "title": "Un copetín",
    "singer": "Ángel Vargas",
    "discMatrix": "59593",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un copetín Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "3kMa5ULlIWVnkMhhyWnqHX"
  },
  {
    "id": "dagostino-4",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-07-24",
    "title": "Tres esquinas",
    "singer": "Ángel Vargas",
    "discMatrix": "59594",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tres esquinas Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "5HftCmLhynna9guHF1xz8b"
  },
  {
    "id": "dagostino-5",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-09",
    "title": "Ahora no me conoces",
    "singer": "Ángel Vargas",
    "discMatrix": "59770",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ahora no me conoces Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "4t3AgeR1pbwKA3JCAwuDUt"
  },
  {
    "id": "dagostino-6",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-09-09",
    "title": "Adiós arrabal",
    "singer": "Ángel Vargas",
    "discMatrix": "59771",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós arrabal Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "7gkauZVECVVilNk60i5p9E"
  },
  {
    "id": "dagostino-7",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-10-20",
    "title": "Solo compasión",
    "singer": "Ángel Vargas",
    "discMatrix": "59834",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Solo compasión Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "0gD2jDB97vmEcawFWRndpg"
  },
  {
    "id": "dagostino-8",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1941-10-20",
    "title": "Compadreando",
    "singer": "Ángel Vargas",
    "discMatrix": "59835",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Compadreando Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "2ion9c1pbENZ1etSNF34j9"
  },
  {
    "id": "dagostino-9",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-05",
    "title": "Una pena",
    "singer": "Ángel Vargas",
    "discMatrix": "59861",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Una pena Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "2rK9emmHE37dPusAWPmkNI"
  },
  {
    "id": "dagostino-10",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-12",
    "title": "Traiga otra caña",
    "singer": "Ángel Vargas",
    "discMatrix": "59862",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Traiga otra caña Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "3SlVkS6hP7GGW9eEYYuC5o"
  },
  {
    "id": "dagostino-11",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-13",
    "title": "El choclo",
    "singer": "Ángel Vargas",
    "discMatrix": "59872",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El choclo Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "6WamF13RtUKMbBPhuDMPOK"
  },
  {
    "id": "dagostino-12",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-11-13",
    "title": "Agüa florida",
    "singer": "Ángel Vargas",
    "discMatrix": "59873",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Agüa florida Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "0LeU6B4VhJSFjB7ZR2VSjY"
  },
  {
    "id": "dagostino-13",
    "trackNo": null,
    "genre": "Vals",
    "date": "1941-12-12",
    "title": "Qué me pasará",
    "singer": "Ángel Vargas",
    "discMatrix": "59922",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué me pasará Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "4bCdwKkrHBCut2Mygjc7R6"
  },
  {
    "id": "dagostino-14",
    "trackNo": null,
    "genre": "Tango",
    "date": "1941-12-12",
    "title": "El Yacare",
    "singer": "Ángel Vargas",
    "discMatrix": "59923",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1941,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Yacare Ángel Vargas Ángel D'Agostino 41 1941",
    "spotifyTrackId": "7KGH9U5k5QnfkQhgGeam40"
  },
  {
    "id": "dagostino-15",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-16",
    "title": "Notas de bandoneón",
    "singer": "Ángel Vargas",
    "discMatrix": "69616",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Notas de bandoneón Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "4pqDvwC4BDL3FLLxw7aXVS"
  },
  {
    "id": "dagostino-16",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-03-16",
    "title": "Adiós para siempre",
    "singer": "Ángel Vargas",
    "discMatrix": "69617",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Adiós para siempre Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "0dLcN3wFN8JjgiBUzkVbEE"
  },
  {
    "id": "dagostino-17",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-07",
    "title": "Viejo coche",
    "singer": "Ángel Vargas",
    "discMatrix": "69643",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Viejo coche Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "2KqypgkNgkArihdbY8GFeR"
  },
  {
    "id": "dagostino-18",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-07",
    "title": "Pobre gallo bataraz",
    "singer": "Ángel Vargas",
    "discMatrix": "69644",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pobre gallo bataraz Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "1Hc4PjVo786LWDwJiJ6TtV"
  },
  {
    "id": "dagostino-19",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-07",
    "title": "Gorriones",
    "singer": "Ángel Vargas",
    "discMatrix": "69645",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gorriones Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "64UkseV4T2sp4U8Tq9VMu5"
  },
  {
    "id": "dagostino-20",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-04-23",
    "title": "Un tropezón",
    "singer": "Ángel Vargas",
    "discMatrix": "69682",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un tropezón Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "6AWrECaG3UTps5utK5paL1"
  },
  {
    "id": "dagostino-21",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-04-23",
    "title": "Entre copa y copa",
    "singer": "Ángel Vargas",
    "discMatrix": "69683",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Entre copa y copa Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-22",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-05-22",
    "title": "Esquinas porteñas",
    "singer": "Ángel Vargas",
    "discMatrix": "69723",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esquinas porteñas Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-23",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-05-22",
    "title": "Dice un refrán",
    "singer": "Ángel Vargas",
    "discMatrix": "69724",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Dice un refrán Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "0MXnveNFe2f10NpoHEAWD8"
  },
  {
    "id": "dagostino-24",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-15",
    "title": "Guitarra que llora",
    "singer": "Ángel Vargas",
    "discMatrix": "69759",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Guitarra que llora Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-25",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-06-15",
    "title": "Trasnochando",
    "singer": "Ángel Vargas",
    "discMatrix": "69760",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Trasnochando Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "0TvXfLJe3jvnyQRDWYEdUL"
  },
  {
    "id": "dagostino-26",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-15",
    "title": "Un lamento",
    "singer": "Ángel Vargas",
    "discMatrix": "69801",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un lamento Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "3nYsFOU9tp2omAZ5MCzMIw"
  },
  {
    "id": "dagostino-27",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-07-15",
    "title": "Al volverte a ver",
    "singer": "Ángel Vargas",
    "discMatrix": "69802",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Al volverte a ver Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-28",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-08-11",
    "title": "Gil a Rayas",
    "singer": "Instrumental",
    "discMatrix": "69683",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gil a Rayas Instrumental Ángel D'Agostino instr. inst. instr inst 42 1942",
    "spotifyTrackId": "1jQWTOUA9dI3ioMGSRLG7w"
  },
  {
    "id": "dagostino-29",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-02",
    "title": "Hay que vivirla compadre",
    "singer": "Ángel Vargas",
    "discMatrix": "69910",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hay que vivirla compadre Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "1N4K04dkFsD0ymJZyV1lmw"
  },
  {
    "id": "dagostino-30",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-09-02",
    "title": "Pero yo sé",
    "singer": "Ángel Vargas",
    "discMatrix": "69911",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pero yo sé Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "55enrtqJ10wvtZq8Fd4BBa"
  },
  {
    "id": "dagostino-31",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-15",
    "title": "De salto y carta",
    "singer": "Ángel Vargas",
    "discMatrix": "84001",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De salto y carta Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "60wYARCUwuFlDHumkWOIv7"
  },
  {
    "id": "dagostino-32",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-15",
    "title": "La chiflada",
    "singer": "Instrumental",
    "discMatrix": "84002",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La chiflada Instrumental Ángel D'Agostino instr. inst. instr inst 42 1942",
    "spotifyTrackId": "3rfUMwznaiTKET7MBFrWFa"
  },
  {
    "id": "dagostino-33",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-29",
    "title": "Noviecita mía",
    "singer": "Ángel Vargas",
    "discMatrix": "84031",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Noviecita mía Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "0RwXpnb8opdfgPW3Mjb7eq"
  },
  {
    "id": "dagostino-34",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-10-29",
    "title": "Oiga mozo",
    "singer": "Ángel Vargas",
    "discMatrix": "84032",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Oiga mozo Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "721iI7V78XPOxCX2G77E8B"
  },
  {
    "id": "dagostino-35",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-11-17",
    "title": "Llora vida mia",
    "singer": "Ángel Vargas",
    "discMatrix": "84059",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Llora vida mia Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "0wDLNM50ILZq8FgSXfYDbY"
  },
  {
    "id": "dagostino-36",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1942-11-17",
    "title": "Así me gusta a mí",
    "singer": "Ángel Vargas",
    "discMatrix": "84059",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así me gusta a mí Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "39vhtDRnJ6gwhycgqG8cLb"
  },
  {
    "id": "dagostino-37",
    "trackNo": null,
    "genre": "Vals",
    "date": "1942-11-17",
    "title": "Yo tengo una novia",
    "singer": "Ángel Vargas",
    "discMatrix": "84060",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo tengo una novia Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "2YrZGuxQMoMunxTg5vCZAE"
  },
  {
    "id": "dagostino-38",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-14",
    "title": "Todo terminó",
    "singer": "Ángel Vargas",
    "discMatrix": "84189",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todo terminó Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "5EU8rJaATbZoYsh1XeK7nR"
  },
  {
    "id": "dagostino-39",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-30",
    "title": "Ninguna",
    "singer": "Ángel Vargas",
    "discMatrix": "84222",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ninguna Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "33JREiGRbiu6xpnVHM7aow"
  },
  {
    "id": "dagostino-40",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-12-30",
    "title": "Barrio de tango",
    "singer": "Ángel Vargas",
    "discMatrix": "84223",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Barrio de tango Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "5Iaiuhe4AFQ2ThMABbqCrq"
  },
  {
    "id": "dagostino-41",
    "trackNo": null,
    "genre": "Tango",
    "date": "1942-01-01",
    "title": "El cuarteador",
    "singer": "Ángel Vargas",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1942,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cuarteador Ángel Vargas Ángel D'Agostino 42 1942",
    "spotifyTrackId": "60wYARCUwuFlDHumkWOIv7"
  },
  {
    "id": "dagostino-42",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-12",
    "title": "El trece",
    "singer": "Ángel Vargas",
    "discMatrix": "84252",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El trece Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "1Hc4PjVo786LWDwJiJ6TtV"
  },
  {
    "id": "dagostino-43",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1943-03-12",
    "title": "En lo de Laura",
    "singer": "Ángel Vargas",
    "discMatrix": "84253",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "En lo de Laura Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "0LyLGes4BnzUa7MTCYmHAy"
  },
  {
    "id": "dagostino-44",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-23",
    "title": "No creas",
    "singer": "Ángel Vargas",
    "discMatrix": "84269",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No creas Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "2Mi8gC1t63Wm00f5Bta8FF"
  },
  {
    "id": "dagostino-45",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-03-23",
    "title": "El porteñito",
    "singer": "Ángel Vargas",
    "discMatrix": "84270",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El porteñito Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "6Wa16UHGBibtui76UGdUOY"
  },
  {
    "id": "dagostino-46",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-30",
    "title": "Madre hay una sola",
    "singer": "Ángel Vargas",
    "discMatrix": "84321",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Madre hay una sola Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "4nzxKSdAJt46C4Rm5OjzV3"
  },
  {
    "id": "dagostino-47",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-04-30",
    "title": "Me llaman tango",
    "singer": "Ángel Vargas",
    "discMatrix": "84322",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Me llaman tango Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "4gj7m9FA3X8uAyf8dxDtQc"
  },
  {
    "id": "dagostino-48",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-10",
    "title": "El trompito",
    "singer": "Ángel Vargas",
    "discMatrix": "77001",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El trompito Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "5EU8rJaATbZoYsh1XeK7nR"
  },
  {
    "id": "dagostino-49",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-06-10",
    "title": "Tomo y obligo",
    "singer": "Ángel Vargas",
    "discMatrix": "77002",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tomo y obligo Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "60wYARCUwuFlDHumkWOIv7"
  },
  {
    "id": "dagostino-50",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-10",
    "title": "A las 7 en el cafe",
    "singer": "Raúl Aldao",
    "discMatrix": "77138",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Aldao",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A las 7 en el cafe Raúl Aldao Ángel D'Agostino 43 1943",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-51",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-10",
    "title": "Ronda de tango",
    "singer": "Raúl Aldao",
    "discMatrix": "77139",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Aldao",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ronda de tango Raúl Aldao Ángel D'Agostino 43 1943",
    "spotifyTrackId": "3OZADJqrgwgoTktohxtwFp"
  },
  {
    "id": "dagostino-52",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-08-10",
    "title": "Y te dejé partir",
    "singer": "Raúl Aldao",
    "discMatrix": "77140",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Aldao",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Y te dejé partir Raúl Aldao Ángel D'Agostino 43 1943",
    "spotifyTrackId": "3J7RGuXskLlm8kl0Zz6TNw"
  },
  {
    "id": "dagostino-53",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-09",
    "title": "Un tango argentino",
    "singer": "Ángel Vargas",
    "discMatrix": "77206",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Un tango argentino Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "34QlmMElQu620RjMbGqYOg"
  },
  {
    "id": "dagostino-54",
    "trackNo": null,
    "genre": "Estilo",
    "date": "1943-09-09",
    "title": "El cardo azul",
    "singer": "Ángel Vargas",
    "discMatrix": "77207",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cardo azul Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "0KbXopTkVyc0hDptnsUepC"
  },
  {
    "id": "dagostino-55",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-09-03",
    "title": "Qué lento corre el tren",
    "singer": "Ángel Vargas",
    "discMatrix": "77208",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Qué lento corre el tren Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-56",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-15",
    "title": "La carreta",
    "singer": "Ángel Vargas",
    "discMatrix": "77378",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La carreta Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "3FVUjLXlvyylxW1LZZyIz6"
  },
  {
    "id": "dagostino-57",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-15",
    "title": "Cantando olvidaré",
    "singer": "Ángel Vargas",
    "discMatrix": "77379",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cantando olvidaré Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "02yN7mzt7JIHwLmguUf60B"
  },
  {
    "id": "dagostino-58",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-11-15",
    "title": "El cornetin del tranvía",
    "singer": "Ángel Vargas",
    "discMatrix": "77380",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cornetin del tranvía Ángel Vargas Ángel D'Agostino 43 1943",
    "spotifyTrackId": "4S1gZJft7qftpFT2oAtI42"
  },
  {
    "id": "dagostino-59",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-05",
    "title": "Gran muñeca",
    "singer": "Instrumental",
    "discMatrix": "77442",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Gran muñeca Instrumental Ángel D'Agostino instr. inst. instr inst 43 1943",
    "spotifyTrackId": "6j84ETnlvTCrDcBoJGV7sM"
  },
  {
    "id": "dagostino-60",
    "trackNo": null,
    "genre": "Tango",
    "date": "1943-12-05",
    "title": "De pura cepa",
    "singer": "Instrumental",
    "discMatrix": "77443",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1943,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De pura cepa Instrumental Ángel D'Agostino instr. inst. instr inst 43 1943",
    "spotifyTrackId": "2YbtuoyurYD0ejhqIdYwKe"
  },
  {
    "id": "dagostino-61",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-01-31",
    "title": "Quién tuviera dieciocho años",
    "singer": "Ángel Vargas",
    "discMatrix": "79523",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Quién tuviera dieciocho años Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "5mSBXjpcoHckIsgcsH4F7F"
  },
  {
    "id": "dagostino-62",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-01-31",
    "title": "Esta noche en Buenos Aires",
    "singer": "Ángel Vargas",
    "discMatrix": "79524",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Esta noche en Buenos Aires Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "5soHCXNRipyTi7zqi3fv7x"
  },
  {
    "id": "dagostino-63",
    "trackNo": null,
    "genre": "Vals",
    "date": "1944-03-09",
    "title": "El espejo de tus ojos",
    "singer": "Ángel Vargas",
    "discMatrix": "79546",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El espejo de tus ojos Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "4NvGSBs4hot2iv1JjEQ4lm"
  },
  {
    "id": "dagostino-64",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-03-09",
    "title": "Mano blanca",
    "singer": "Ángel Vargas",
    "discMatrix": "79616",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mano blanca Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "4c1IKzL6Z13ljytHeTPThk"
  },
  {
    "id": "dagostino-65",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-05",
    "title": "Así era el tango",
    "singer": "Ángel Vargas",
    "discMatrix": "79615",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Así era el tango Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-66",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-05",
    "title": "Mas solo que nunca",
    "singer": "Ángel Vargas",
    "discMatrix": "79547",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mas solo que nunca Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "0AN9ZW5V3lNP3Qgo59MnwL"
  },
  {
    "id": "dagostino-67",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-04-05",
    "title": "Como el hornero",
    "singer": "Ángel Vargas",
    "discMatrix": "79617",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Como el hornero Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "7lmUy8nVNMecxd9HeQKc6Y"
  },
  {
    "id": "dagostino-68",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-26",
    "title": "Su carta no llegó",
    "singer": "Ángel Vargas",
    "discMatrix": "79710",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Su carta no llegó Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "0MKdSFZ3V9tlfHXdczkov4"
  },
  {
    "id": "dagostino-69",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-05-25",
    "title": "El Poncho del olvido",
    "singer": "Ángel Vargas",
    "discMatrix": "79711",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Poncho del olvido Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "5ER24PoQvGNWgEkhgvvISt"
  },
  {
    "id": "dagostino-70",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-07-19",
    "title": "Muñequita",
    "singer": "Ángel Vargas",
    "discMatrix": "79780",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Muñequita Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "0g1bCoxx98laeAR109DNy0"
  },
  {
    "id": "dagostino-71",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1944-07-19",
    "title": "Todos te quieren",
    "singer": "Instrumental",
    "discMatrix": "79781",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Todos te quieren Instrumental Ángel D'Agostino instr. inst. instr inst 44 1944",
    "spotifyTrackId": "5KPa7dypHTFbSCOwrERUkC"
  },
  {
    "id": "dagostino-72",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-08",
    "title": "Mi viejo barrio",
    "singer": "Ángel Vargas",
    "discMatrix": "79806",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mi viejo barrio Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "2qFeCtMEna8XdO1ubA5pqS"
  },
  {
    "id": "dagostino-73",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-08-08",
    "title": "La última cita",
    "singer": "Ángel Vargas",
    "discMatrix": "79807",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La última cita Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "01VpWYhizZ6szHushiChF7"
  },
  {
    "id": "dagostino-74",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-13",
    "title": "La nueva vecina",
    "singer": "Ángel Vargas",
    "discMatrix": "79847",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La nueva vecina Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "24rtcv3EmIJBWJEhHfJxPc"
  },
  {
    "id": "dagostino-75",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-09-13",
    "title": "Palais de Glace",
    "singer": "Ángel Vargas",
    "discMatrix": "79848",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Palais de Glace Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "5oJeSDm5tHdxzqJaH1fXnu"
  },
  {
    "id": "dagostino-76",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-02",
    "title": "Rosita la santiagueña",
    "singer": "Ángel Vargas",
    "discMatrix": "79916",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rosita la santiagueña Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "3tUZzvuVVgJNRlo2WSozX9"
  },
  {
    "id": "dagostino-77",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-11-02",
    "title": "El cocherito",
    "singer": "Ángel Vargas",
    "discMatrix": "79917",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El cocherito Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "0XQ95sxZDZ0MKl7JYhV6Jg"
  },
  {
    "id": "dagostino-78",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-05",
    "title": "Yo soy de Parque Patricios",
    "singer": "Ángel Vargas",
    "discMatrix": "79966",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Yo soy de Parque Patricios Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "5IvmPd1Q1eB44cgEKiHkOZ"
  },
  {
    "id": "dagostino-79",
    "trackNo": null,
    "genre": "Tango",
    "date": "1944-12-05",
    "title": "Madreselva",
    "singer": "Ángel Vargas",
    "discMatrix": "79967",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1944,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Madreselva Ángel Vargas Ángel D'Agostino 44 1944",
    "spotifyTrackId": "6uM0i86j4zQ1ACUVhsTrnU"
  },
  {
    "id": "dagostino-80",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-03-20",
    "title": "Cuando se ha querido mucho",
    "singer": "Ángel Vargas",
    "discMatrix": "80579",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Cuando se ha querido mucho Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "5X22YsPJcKZirHXqa2TxmV"
  },
  {
    "id": "dagostino-81",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-03-20",
    "title": "Señores yo soy del centro",
    "singer": "Ángel Vargas",
    "discMatrix": "82001",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Señores yo soy del centro Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "3rwqnbpCwHcyglgQHrRCVS"
  },
  {
    "id": "dagostino-82",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-05",
    "title": "Mentira",
    "singer": "Ángel Vargas",
    "discMatrix": "80599",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Mentira Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-83",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-04-05",
    "title": "Shusheta (El aristócrata)",
    "singer": "Ángel Vargas",
    "discMatrix": "80580",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Shusheta (El aristócrata) Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "1r5Xobxrs8PhHv4SbtGnl1"
  },
  {
    "id": "dagostino-84",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-21",
    "title": "De corte criollo",
    "singer": "Instrumental",
    "discMatrix": "80654",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De corte criollo Instrumental Ángel D'Agostino instr. inst. instr inst 45 1945",
    "spotifyTrackId": "50pgV0shSQZp9lZQE4mC2C"
  },
  {
    "id": "dagostino-85",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-05-21",
    "title": "Hotel Victoria",
    "singer": "Ángel Vargas",
    "discMatrix": "80655",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Hotel Victoria Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "1Sx3RZmJuWnb686INP8DCs"
  },
  {
    "id": "dagostino-86",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1945-07-17",
    "title": "Por que me siento feliz",
    "singer": "Ángel Vargas",
    "discMatrix": "80724",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Por que me siento feliz Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "2zzhrrnKX2Qe090DnwNMoX"
  },
  {
    "id": "dagostino-87",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-17",
    "title": "Menta y cedrón",
    "singer": "Ángel Vargas",
    "discMatrix": "80725",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Menta y cedrón Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "6NdUGcweazvYSLlI11UwHr"
  },
  {
    "id": "dagostino-88",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-07-17",
    "title": "Caricias",
    "singer": "Ángel Vargas",
    "discMatrix": "80757",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Caricias Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "5WpEHnjiKiUYMyj4evoMdW"
  },
  {
    "id": "dagostino-89",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-07",
    "title": "A quien le puede importar",
    "singer": "Ángel Vargas",
    "discMatrix": "80758",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A quien le puede importar Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "0Oihez0NjHnWr7WcZEKUFv"
  },
  {
    "id": "dagostino-90",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-27",
    "title": "Bailarín de contraseña",
    "singer": "Ángel Vargas",
    "discMatrix": "80780",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Bailarín de contraseña Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "6Xt4RIP3mrPNdRyiCfLyxO"
  },
  {
    "id": "dagostino-91",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-08-27",
    "title": "Pinta Blanca",
    "singer": "Ángel Vargas",
    "discMatrix": "80781",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Pinta Blanca Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "2DEQZTIRhAOeENsgqbmNWS"
  },
  {
    "id": "dagostino-92",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-10-02",
    "title": "A pan y agua",
    "singer": "Ángel Vargas",
    "discMatrix": "80833",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "A pan y agua Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "2s5hvUqLoFmXvoVkvfkCpL"
  },
  {
    "id": "dagostino-93",
    "trackNo": null,
    "genre": "Vals",
    "date": "1945-10-02",
    "title": "Tristeza criolla",
    "singer": "Ángel Vargas",
    "discMatrix": "80834",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Tristeza criolla Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "1wxm8i7V3BAp4rnCfLdVhC"
  },
  {
    "id": "dagostino-94",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-02",
    "title": "De igual a igual",
    "singer": "Ángel Vargas",
    "discMatrix": "80869",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "De igual a igual Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-95",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-02",
    "title": "Rondando tu esquina",
    "singer": "Ángel Vargas",
    "discMatrix": "80870",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Rondando tu esquina Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "7LGA5wpl0KxYaqU8kQXS2a"
  },
  {
    "id": "dagostino-96",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-02",
    "title": "No vendrás",
    "singer": "Ángel Vargas",
    "discMatrix": "80871",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "No vendrás Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-97",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-02",
    "title": "Ave de paso",
    "singer": "Ángel Vargas",
    "discMatrix": "80872",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Ave de paso Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "3T12J0fK75UqyMi1qmrRRX"
  },
  {
    "id": "dagostino-98",
    "trackNo": null,
    "genre": "Tango",
    "date": "1945-11-02",
    "title": "La cumparsita",
    "singer": "Ángel Vargas",
    "discMatrix": "80872",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1945,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "La cumparsita Ángel Vargas Ángel D'Agostino 45 1945",
    "spotifyTrackId": "0SSJPvY3Ll0xEJdxW7Zsi3"
  },
  {
    "id": "dagostino-99",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-02-08",
    "title": "Serpentinas de esperanza",
    "singer": "Ángel Vargas",
    "discMatrix": "80600",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Serpentinas de esperanza Ángel Vargas Ángel D'Agostino 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-100",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-29",
    "title": "Racing club",
    "singer": "Instrumental",
    "discMatrix": "82052",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Racing club Instrumental Ángel D'Agostino instr. inst. instr inst 46 1946",
    "spotifyTrackId": "0owpwthSo48UTEpj4OISO7"
  },
  {
    "id": "dagostino-101",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-03-29",
    "title": "Destellos",
    "singer": "Ángel Vargas",
    "discMatrix": "82053",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Destellos Ángel Vargas Ángel D'Agostino 46 1946",
    "spotifyTrackId": "1jMYFkSkWt3cs7XseZLSwh"
  },
  {
    "id": "dagostino-102",
    "trackNo": null,
    "genre": "Milonga",
    "date": "1946-07-12",
    "title": "El Morocho y el Oriental",
    "singer": "Ángel Vargas",
    "discMatrix": "82214",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "El Morocho y el Oriental Ángel Vargas Ángel D'Agostino 46 1946",
    "spotifyTrackId": ""
  },
  {
    "id": "dagostino-103",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-07-12",
    "title": "Con sabor a tango",
    "singer": "Instrumental",
    "discMatrix": "82215",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Con sabor a tango Instrumental Ángel D'Agostino instr. inst. instr inst 46 1946",
    "spotifyTrackId": "3SmyckXuZUNXzln4Lw0bYL"
  },
  {
    "id": "dagostino-104",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-09-10",
    "title": "Demasiado tarde",
    "singer": "Ángel Vargas",
    "discMatrix": "82335",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Demasiado tarde Ángel Vargas Ángel D'Agostino 46 1946",
    "spotifyTrackId": "3Vvq2peLmgu6dmAd3x8nNo"
  },
  {
    "id": "dagostino-105",
    "trackNo": null,
    "genre": "Tango",
    "date": "1946-09-10",
    "title": "Camino del Tucumán",
    "singer": "Ángel Vargas",
    "discMatrix": "82336",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "dagostino",
    "groupId": "dagostino4046",
    "orchestraName": "Ángel D'Agostino",
    "orchestraNameShort": "D'Agostino",
    "year": 1946,
    "genreShort": "",
    "singerShort": "Vargas",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "Camino del Tucumán Ángel Vargas Ángel D'Agostino 46 1946",
    "spotifyTrackId": "5DqywLXuRhAkUVHuoi5KBM"
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  },
  {
    "id": "",
    "trackNo": null,
    "genre": "",
    "date": "",
    "title": "",
    "singer": "",
    "discMatrix": "",
    "publisher": "",
    "discNo": 0,
    "orchestraId": "",
    "groupId": "",
    "orchestraName": "",
    "orchestraNameShort": "",
    "year": 1899,
    "genreShort": "",
    "singerShort": "",
    "discType": "",
    "duration": "",
    "composer": "",
    "lyricist": "",
    "notes": "",
    "linkTT": "",
    "linkTTT": "",
    "lyricsEsp": "",
    "lyricsEn": "",
    "lyricsEnCredits": "",
    "filterDarienzoBiagi": "",
    "filterDarienzoReyCas": "",
    "search": "99 1899",
    "spotifyTrackId": ""
  }
];

export default recordingsData;
