import React from 'react';
import { IonIcon, IonItem, IonLabel, IonSkeletonText } from '@ionic/react';
import RecordingIcon from './RecordingIcon';

const RecordingListItemSkeleton: React.FC = () => {
  return (
    <>
      <IonItem lines="none" style={{ height: '64px' }}>
        <IonSkeletonText animated style={{ width: '40px', height: '40px' }} slot="start" />
        <IonLabel>
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated style={{ width: '90%' }} />
        </IonLabel>
        <IonLabel slot="end">
          <span className="ion-padding-horizontal"><IonSkeletonText animated style={{ width: '40px' }} /></span>
        </IonLabel>
      </IonItem>
      <IonItem lines="none" style={{ height: '64px' }}>
        <IonSkeletonText animated style={{ width: '40px', height: '40px' }} slot="start" />
        <IonLabel>
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated style={{ width: '90%' }} />
        </IonLabel>
        <IonLabel slot="end">
          <span className="ion-padding-horizontal"><IonSkeletonText animated style={{ width: '40px' }} /></span>
        </IonLabel>
      </IonItem>
      <IonItem lines="none" style={{ height: '64px' }}>
        <IonSkeletonText animated style={{ width: '40px', height: '40px' }} slot="start" />
        <IonLabel>
          <IonSkeletonText animated style={{ width: '60%' }} />
          <IonSkeletonText animated style={{ width: '90%' }} />
        </IonLabel>
        <IonLabel slot="end">
          <span className="ion-padding-horizontal"><IonSkeletonText animated style={{ width: '40px' }} /></span>
        </IonLabel>
      </IonItem>
    </>
  );
};

export default RecordingListItemSkeleton;
