import React from 'react';
import { Redirect } from 'react-router';
import { useSelector } from 'react-redux';

const OrchestrasOrTutorial: React.FC = () => {
  const isTutorialSeen = useSelector((state: any) => state.tutorial.isTutorialSeen);

  return isTutorialSeen ? <Redirect to="/orchestras" /> : <Redirect to="/tutorial" />;
};

export default OrchestrasOrTutorial;
