import React from 'react';
import { IonSkeletonText } from '@ionic/react';
import './OrchestraSkeleton.scss';

const OrchestraSkeleton: React.FC = () => {
  return (
    <>
      <div className='skeleton-image-container'>
        <IonSkeletonText animated={false} className='skeleton-image'></IonSkeletonText>
      </div>
      <div className='skeleton-search-filter-container'>
        <IonSkeletonText animated={false} className='skeleton-search-filter'></IonSkeletonText>
      </div>
      <div className='skeleton-tag-filter-container'>
        <IonSkeletonText animated={false} className='skeleton-tag-filter'></IonSkeletonText>
        <IonSkeletonText animated={false} className='skeleton-tag-filter'></IonSkeletonText>
        <IonSkeletonText animated={false} className='skeleton-tag-filter'></IonSkeletonText>
      </div>
      <div className='skeleton-title-container'>
        <IonSkeletonText animated={false} className='skeleton-title'></IonSkeletonText>
      </div>
      <div className='skeleton-record-container'>
        <IonSkeletonText animated={false} className='skeleton-record'></IonSkeletonText>
        <IonSkeletonText animated={false} className='skeleton-record'></IonSkeletonText>
        <IonSkeletonText animated={false} className='skeleton-record'></IonSkeletonText>
      </div>
    </>
  );
};

export default OrchestraSkeleton;
