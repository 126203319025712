import {
  IonBackButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import './OrchestraPage.scss';
import OrchestraContent from '../components/OrchestraContent';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Filters from '../components/Filters';
import ActiveFilters from '../components/ActiveFilters';
import useOrchestrasDatabase from '../hooks/useOrchestrasDatabase';
import useFiltersDatabase from '../hooks/useFiltersDatabase';
import { FilterType } from '../types/FilterType';
import { OrchestraType } from '../types/OrchestraType';
import { debounce } from 'lodash';
import useViewPort from '../hooks/useViewPort';
import OrchestraSkeleton from '../components/OrchestraSkeleton';
import { useSelector } from 'react-redux';

const toggleFilterStateAndResetOthers = (subjectFilter: FilterType, filters: FilterType[]): FilterType[] => {
  return filters.map((filter: FilterType) => {
    if (filter.value === subjectFilter.value && filter.key === subjectFilter.key) {
      if (subjectFilter.isActive) {
        filter.isActive = false;
        // trigger state "filter was removed"
      } else {
        filter.isActive = true;
        // trigger state "filter was added"
      }
    } else {
      filter.isActive = false;
    }

    return filter;
  })
};

const getActiveFilters = (searchFilter: FilterType, staticFilters: FilterType[], dynamicFilters: FilterType[]): FilterType[] => {
  const activeFilters: FilterType[] = [];

  if (searchFilter.isActive) {
    activeFilters.push(Object.assign({}, searchFilter));
  }

  staticFilters.forEach((filter: FilterType) => {
    if (filter.isActive) {
      activeFilters.push(Object.assign({}, filter));
    }
  });

  dynamicFilters.forEach((filter: FilterType) => {
    if (filter.isActive) {
      activeFilters.push(Object.assign({}, filter));
    }
  });

  return activeFilters;
};

const preloadImage = (src: string) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = () => {
      resolve(image);
    }
    image.onerror = image.onabort = () => {
      image.src = 'assets/img/illustrations/img_orchestra_placeholder@2x.png';
      resolve(image);
    }
    image.src = src;
  });
}

const OrchestraPage: React.FC = () => {
  const { orchestraId, singer } = useParams<{ orchestraId: string, singer?: string }>();

  const { isElementInOrBelowViewPort } = useViewPort();
  const { findOrchestraById } = useOrchestrasDatabase();
  const { getSearchFilter, getStaticFilters, findDynamicFiltersByOrchestraId } = useFiltersDatabase();

  const [orchestra, setOrchestra] = useState<OrchestraType|null>(null);
  const [searchFilter, setSearchFilter] = useState<FilterType>(getSearchFilter());
  const [staticFilters, setStaticFilters] = useState<FilterType[]>([]);
  const [dynamicFilters, setDynamicFilters] = useState<FilterType[]>([]);
  const [activeFilters, setActiveFilters] = useState<FilterType[]>([]);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  const showFullNames = useSelector((state: any) => state.listOptions.showFullNames);

  const contentRef = React.createRef<HTMLIonContentElement>();

  useEffect(() => {
    const loadInitialData = async () => {
      // Orchestras
      const orchestra = await findOrchestraById(orchestraId);

      // Preload orchestra image to avoid image loading while rendering
      await preloadImage(`assets/img/illustrations/img_${orchestra?.orchestraImageId}_large_light@2x.png`);

      // Dynamic filters
      const dynamicFiltersByOrchestra = await findDynamicFiltersByOrchestraId(orchestraId);

      // If we receive additional dynamic filter received as parameter:
      //   - add it dynamically to the list of dynamic filters (if it does not exist)
      //   - set is as active
      if (singer) {
        const clickedFilter: FilterType = {
          orchestraId: orchestraId,
          label: singer.split(' ').pop()!,
          key: 'singer',
          value: singer,
          isActive: false,
        };

        // Check if it exists in list of dynamic filters
        if (!dynamicFiltersByOrchestra.find((filter: FilterType) => filter.value === singer)) {
          // dynamicFiltersByOrchestra.unshift(clickedFilter);
          dynamicFiltersByOrchestra.splice(1, 0, clickedFilter);
        }

        setDynamicFilters(toggleFilterStateAndResetOthers(clickedFilter, dynamicFiltersByOrchestra));
        setActiveFilters(getActiveFilters(searchFilter, staticFilters, dynamicFiltersByOrchestra));
      } else {
        // otherwise just use loaded list of dynamic filters
        setDynamicFilters(dynamicFiltersByOrchestra);
      }

      setTimeout(() => {
        setOrchestra(orchestra);
      }, 0);

      // Static filters
      setTimeout(() => {
        setStaticFilters(getStaticFilters());
      }, 0);
    };

    loadInitialData().then(()=> null);

  }, [orchestraId]);

  const onSearchFilterChange = (event: any) => {
    const searchTerm = event.detail.value;

    // Set searchFilter value only if it has actually changed, otherwise when onSearchFilterClick sets it to empty string
    // this change triggers onSearchFilterChange and as a result the recordings list is rendered twice.
    if (searchTerm !== searchFilter.value) {
      searchFilter.isActive = searchTerm.length > 0;
      searchFilter.value = searchTerm;

      setSearchFilter(searchFilter);
      setActiveFilters(getActiveFilters(searchFilter, staticFilters, dynamicFilters));
    }
  };

  const onSearchFilterClick = (clickedFilter: FilterType) => {
    clickedFilter.value = '';
    clickedFilter.isActive = false;

    setSearchFilter(clickedFilter);
    setActiveFilters(getActiveFilters(clickedFilter, staticFilters, dynamicFilters));
  };

  const onStaticFilterClick = (clickedFilter: FilterType) => {
    setStaticFilters(toggleFilterStateAndResetOthers(clickedFilter, staticFilters));
    setActiveFilters(getActiveFilters(searchFilter, staticFilters, dynamicFilters));
  };

  const onDynamicFilterClick = (clickedFilter: FilterType) => {
    setDynamicFilters(toggleFilterStateAndResetOthers(clickedFilter, dynamicFilters));
    setActiveFilters(getActiveFilters(searchFilter, staticFilters, dynamicFilters));
  };

  const onScroll = () => {
    // Toggle filters and active-filters toolbar visibility for MD design
    const filtersElement = document.getElementById('filters-toolbar');
    const activeFiltersElement = document.getElementById('active-filters-toolbar');
    if (!filtersElement) {
      return;
    }

    if (isElementInOrBelowViewPort(filtersElement)) {
      filtersElement.classList.remove('hidden');
      if (activeFiltersElement) {
        activeFiltersElement.classList.remove('visible');
      }
    } else {
      filtersElement.classList.add('hidden');
      if (activeFiltersElement) {
        activeFiltersElement.classList.add('visible');
      }
    }
  };

  const handleDebouncedScroll = useMemo(() => {
    return debounce(onScroll, 100, { leading: true, 'trailing': true, maxWait: 100 });
  }, []);

  const searchBarRef = React.createRef<HTMLIonSearchbarElement>();

  return (
    <IonPage id="orchestra-page">
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton color="primary" text="" />
          </IonButtons>
          <IonTitle color="dark" className="dt-toolbar-title">{orchestra ? showFullNames ? orchestra.orchestraName : orchestra.orchestraNameShort : ''}</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent
        fullscreen={true}
        scrollEvents={true}
        onIonScroll={handleDebouncedScroll}
        ref={contentRef}
      >

      {orchestra &&
        <>
          <div className="dt-orchestra-image-container">
            <IonImg
              src={`assets/img/illustrations/img_${orchestra?.orchestraImageId}_large_light@2x.png`}
              onIonError={(e) => {
                e.target.src = 'assets/img/illustrations/img_orchestra_placeholder@2x.png';
              }}
              onIonImgDidLoad={() => setIsImageLoaded(true)}
              className="dt-orchestra-image"
            />
          </div>

          <div className={isImageLoaded ? 'ion-hide' : ''}>
            <OrchestraSkeleton />
          </div>

          <div className={isImageLoaded ? '' : 'ion-page-invisible'}>

            <IonToolbar id="searchbar-toolbar">
              <IonSearchbar
                placeholder="Filter by song title"
                value={searchFilter.value}
                onIonChange={e => onSearchFilterChange(e)}
                ref={searchBarRef}
                inputmode="search"
                className="dt-searchbar"
              />
            </IonToolbar>

            {/* Tag filters */}
            <IonToolbar id="filters-toolbar" className="dt-tag-filters-toolbar">
              <Filters
                staticFilters={staticFilters}
                dynamicFilters={dynamicFilters}
                onStaticFilterClick={onStaticFilterClick}
                onDynamicFilterClick={onDynamicFilterClick}
              />
            </IonToolbar>

            {/* Active filters (tags + text filter) */}
            {activeFilters && activeFilters.length > 0 &&
              <IonToolbar id="active-filters-toolbar" className="active-filters">
                <IonButtons className="scroll-horizontally dt-active-filters-buttons" collapse={true}>
                  <ActiveFilters
                    searchFilter={searchFilter}
                    staticFilters={staticFilters}
                    dynamicFilters={dynamicFilters}
                    onSearchFilterClick={onSearchFilterClick}
                    onStaticFilterClick={onStaticFilterClick}
                    onDynamicFilterClick={onDynamicFilterClick}
                  />
                </IonButtons>
              </IonToolbar>
            }

            {orchestra &&
              <OrchestraContent
                orchestra={orchestra}
                filters={activeFilters}
                contentRef={contentRef}
                searchBarRef={searchBarRef}
              />
            }
          </div>
        </>
      }
      </IonContent>
    </IonPage>
  );
};

export default React.memo(OrchestraPage);
