import React from 'react';
import { IonButtons, } from '@ionic/react';
import FilterChip from './FilterChip';
import { FilterType } from '../types/FilterType';

interface PropsType {
  staticFilters: FilterType[],
  dynamicFilters: FilterType[],
  onStaticFilterClick: (filter: FilterType) => void,
  onDynamicFilterClick: (filter: FilterType) => void
}

const Filters: React.FC<PropsType> = ({ staticFilters, dynamicFilters, onStaticFilterClick, onDynamicFilterClick }) => {

  return (
    <>
      { staticFilters.length > 0 &&
        <IonButtons className="scroll-horizontally dt-active-filters-buttons" collapse={true}>
          {staticFilters.map((filter: any, index: number) => (
            filter.key === 'genre' ? <FilterChip key={index} filter={filter} onClick={() => onStaticFilterClick(filter)} /> : null
          ))}
        </IonButtons>
      }

      { dynamicFilters.length > 0 &&
        <IonButtons className="scroll-horizontally dt-active-filters-buttons" collapse={true}>
          {dynamicFilters.map((filter: any, index: number) => (
            filter.key !== 'genre' ? <FilterChip key={index} filter={filter} onClick={() => onDynamicFilterClick(filter)} /> : null
          ))}
        </IonButtons>
      }
    </>
  );
};

export default Filters;
