export const SWITCH_DARK_THEME_TO_AUTO = 'SWITCH_DARK_THEME_TO_AUTO';
export const SWITCH_DARK_THEME_ON = 'SWITCH_DARK_THEME_ON';
export const SWITCH_DARK_THEME_OFF = 'SWITCH_DARK_THEME_OFF';

export const switchDarkThemeToAuto = () => ({
  type: SWITCH_DARK_THEME_TO_AUTO,
});

export const switchDarkThemeOn = () => ({
  type: SWITCH_DARK_THEME_ON,
});

export const switchDarkThemeOff = () => ({
  type: SWITCH_DARK_THEME_OFF,
});
