import { FilterType } from '../types/FilterType';
import dynamicFiltersData from '../data/dynamicFiltersData';
import staticFiltersData from '../data/staticFiltersData';

const createFavouritesFilter = (): FilterType => {
  return {
    label: '♥',
    key: 'isFavourite',
    value: 'true',
    isActive: false,
  }
};

const useFiltersDatabase = () => {
  const getSearchFilter = (): FilterType => {
    return {
      label: 'Filter',
      key: 'title',
      value: '',
      isActive: false
    }
  };

  const getStaticFilters = () => {
    const results = [];
    for (let i = 0; i < staticFiltersData.length; i++) {
      results.push(
        Object.assign({}, staticFiltersData[i])
      );
    }

    return results;
  }

  const findDynamicFiltersByOrchestraId = (orchestraId: string): Promise<FilterType[]> => {
    return new Promise((resolve) => {
      const results = [];

      results.push(createFavouritesFilter());

      for (let i = 0; i < dynamicFiltersData.length; i++) {
        if (dynamicFiltersData[i].orchestraId === orchestraId) {
          results.push(
            Object.assign({}, dynamicFiltersData[i])
          );
        }
      }

      resolve(results);
    });
  };

  return { getSearchFilter, getStaticFilters, findDynamicFiltersByOrchestraId };
};

export default useFiltersDatabase;
