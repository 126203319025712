import React, { useState } from 'react';
import { IonContent, IonPage, IonToolbar, IonButton } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Swiper as SwiperCore } from 'swiper';
import './TutorialPage.scss';
import 'swiper/css/bundle';
import '@ionic/react/css/ionic-swiper.css';

import { useHistory } from 'react-router';
import { flagTutorialIsSeen } from '../actions/tutorialActions';
import { useDispatch } from 'react-redux';

const TutorialPage: React.FC = () => {
  const dispatch = useDispatch();
  let [swiper, setSwiper] = useState<SwiperCore>();
  const history = useHistory();

  const startApp = async () => {
    if (!swiper) return;
    if (swiper.isEnd) {
      dispatch(flagTutorialIsSeen());
      history.replace('/orchestras', {direction: 'none'});
    } else {
      swiper.slideNext();
    }
  };

  return (
    <IonPage id="tutorial-page">
      <IonContent fullscreen>
        <Swiper pagination={true} modules={[Pagination]} onSwiper={setSwiper}>
          <SwiperSlide>
            <img src="assets/img/slidebox/ica-slidebox-img-1.png" alt="" className="slide-image" />
            <h2 className="slide-title">Get to know the orchestras</h2>
            <p>The ionic conference app is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          </SwiperSlide>

          <SwiperSlide>
            <img src="assets/img/slidebox/ica-slidebox-img-2.png" alt="" className="slide-image" />
            <h2 className="slide-title">Browse, filter & search</h2>
            <p>The ionic conference app is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          </SwiperSlide>

          <SwiperSlide>
            <img src="assets/img/slidebox/ica-slidebox-img-3.png" alt="" className="slide-image" />
            <h2 className="slide-title">Future plans</h2>
            <p>The ionic conference app is a practical preview of the ionic framework in action, and a demonstration of proper code use.</p>
          </SwiperSlide>
        </Swiper>
      </IonContent>
      <IonToolbar className="swiper-continue-toolbar">
        <IonButton expand="block" onClick={startApp}><span className="dt-button-text">Continue</span></IonButton>
      </IonToolbar>
    </IonPage>
  );
};

export default TutorialPage;
