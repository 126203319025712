import { combineReducers } from 'redux';
import themeReducer from './themeReducer';
import tutorialReducer from './tutorialReducer';
import favouritesReducer from './favouritesReducer';
import { SET_STATE_FROM_LOCAL_STORAGE } from '../actions/localStorageActions';
import { StateType } from '../types/StateType';
import searchHistoryReducer from './searchHistoryReducer';
import listOptionsReducer from './listOptionsReducer';

const mainReducer = combineReducers({
  theme: themeReducer,
  tutorial: tutorialReducer,
  favourites: favouritesReducer,
  searchHistory: searchHistoryReducer,
  listOptions: listOptionsReducer,
});

const rootReducer = (state: StateType, action: any) => {
  if (action.type === SET_STATE_FROM_LOCAL_STORAGE) {
    state = action.state;
  }

  return mainReducer(state, action);
};

export default rootReducer;
