const getElementOffsetToTopOfPage = function (elem: any) {
  var distance = 0;

  // Loop up the DOM
  if (elem.offsetParent) {
    do {
      console.log('loop', elem);
      distance += elem.offsetTop;
      elem = elem.offsetParent;
    } while (elem);
  }

  console.log('distance', distance);

  return distance < 0 ? 0 : distance;
};

const useViewPort = () => {
  const isElementInOrBelowViewPort = (element: any) => {
    const rect = element.getBoundingClientRect();

    return (
      rect.top >= -50
    );
  };

  const scrollElementToTopHeader = (targetElement: HTMLIonSearchbarElement, contentElement: HTMLIonContentElement) => {
    const headerOffset = 54;
    const elementsOffsetToTopOfPage = getElementOffsetToTopOfPage(targetElement);

    const scrollOffset = elementsOffsetToTopOfPage - headerOffset;

    contentElement.scrollToPoint(0, scrollOffset, 300);
  }

  return { isElementInOrBelowViewPort, scrollElementToTopHeader };
};

export default useViewPort;
