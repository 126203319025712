import React from 'react';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { closeCircle, heart } from 'ionicons/icons';
import { FilterType } from '../types/FilterType';
import './FilterChip.scss';

const FilterChip: React.FC<{ filter: FilterType, onClick: (filter: FilterType) => void }> = ({ filter, onClick }) => {
  return (
    <IonChip
      color={filter.isActive ? 'primary' : 'light'}
      onClick={() => onClick(filter)}
    >
      {filter.label === '♥'
        ? <IonLabel><IonIcon className='dt-icon-favourite' icon={heart}/></IonLabel>
        : <IonLabel>{filter.label}</IonLabel>
      }
      {filter.isActive && <IonIcon icon={closeCircle} />}
    </IonChip>
  );
};

export default FilterChip;
