import { createReducer } from '@reduxjs/toolkit';
import { FavouritesStateType } from '../types/StateType';
import { ADD_RECORDING_TO_FAVOURITES, REMOVE_RECORDING_FROM_FAVOURITES } from '../actions/stateActions';

const initialState: FavouritesStateType = {
  favouriteRecordings: [],
};

const favouritesReducer: any = createReducer(initialState, {
  [ADD_RECORDING_TO_FAVOURITES]: (state: FavouritesStateType = initialState, action: any) => {
    state.favouriteRecordings.push(action.payload);
  },
  [REMOVE_RECORDING_FROM_FAVOURITES]: (state: FavouritesStateType = initialState, action: any) => {
    state.favouriteRecordings = state.favouriteRecordings.filter((trackNo: string) => {
      return trackNo !== action.payload;
    });
  },
});

export default favouritesReducer;
