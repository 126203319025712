import React, { useEffect, useLayoutEffect, useState } from 'react';
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonModal,
  IonPage,
  IonSearchbar,
  IonTitle,
  IonToolbar,
} from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';
import { close, heart } from 'ionicons/icons';
import { OrchestraType } from '../types/OrchestraType';
import useRecordingsDatabase from '../hooks/useRecordingsDatabase';
import { RecordingType } from '../types/RecordingType';
import useOrchestrasDatabase from '../hooks/useOrchestrasDatabase';
import useOrchestrasSingersDatabase from '../hooks/useOrchestrasSingersDatabase';
import { OrchestraSingerType } from '../types/OrchestraSingerType';
import RecordingModal from './RecordingModal';
import { useDispatch, useSelector } from 'react-redux';
import { StateType } from '../types/StateType';
import { useHistory } from 'react-router';
import { addToSearchHistory } from '../actions/searchHistoryActions';
import RecordingIcon from './RecordingIcon';
import SingerIcon from './SingerIcon';
import OrchestraIcon from './OrchestraIcon';
import './SearchModal.scss';
import { Capacitor } from '@capacitor/core';

const SearchModal: React.FC<{onClose: () => void}> = ({ onClose }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { findRecordingsBySearch } = useRecordingsDatabase();
  const { findOrchestrasSingersBySearch } = useOrchestrasSingersDatabase();
  const { findOrchestrasBySearch } = useOrchestrasDatabase();

  const favouriteRecordings = useSelector((state: StateType) => state.favourites.favouriteRecordings);
  const searchHistoryTerms = useSelector((state: StateType) => state.searchHistory.searchTerms);
  const showFullNames = useSelector((state: any) => state.listOptions.showFullNames);

  const [searchText, setSearchText] = useState<string>('');
  const [selectedRecording, setSelectedRecording] = useState<RecordingType|null>(null);
  const [recordings, setRecordings] = useState<RecordingType[]>([]);
  const [orchestrasSingers, setOrchestrasSingers] = useState<OrchestraSingerType[]>([]);
  const [orchestras, setOrchestras] = useState<OrchestraType[]>([]);
  const [areRecordingsExceedingLimit, setAreRecordingsExceedingLimit] = useState<boolean>(false);
  const [showAllRecordings, setShowAllRecordings] = useState<boolean>(false);

  const searchBarRef = React.createRef<HTMLIonSearchbarElement>();

  const maxRecordingsResultLimit = 50;
  const recordingsResultLimit = 5;

  const hideKeyboardOnEnterKeyPress = (event: any) => {
    if (event.key === 'Enter') {
      if (Capacitor.isPluginAvailable('Keyboard')) {
        Keyboard.hide();
      }
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (searchText === "" && searchBarRef.current) {
        searchBarRef.current.setFocus();
      }
    }, 400);
    setTimeout(() => {
      if (searchText === "" && searchBarRef.current) {
        searchBarRef.current.setFocus();
      }
    }, 800);
  });

  useLayoutEffect(() => {
    const loadFoundResults = () => {
      if (!searchText) {
        setRecordings([]);
        setOrchestrasSingers([]);
        setOrchestras([]);
        setShowAllRecordings(false);

        return;
      }

      let foundRecordings = findRecordingsBySearch(searchText, favouriteRecordings, maxRecordingsResultLimit);
      const totalRecordingsCount = foundRecordings.length;
      const areRecordingsExceedingLimit = totalRecordingsCount > recordingsResultLimit;
      if (areRecordingsExceedingLimit && showAllRecordings) {
        foundRecordings = foundRecordings.slice(0, recordingsResultLimit);
      }

      const foundOrchestrasSingers = findOrchestrasSingersBySearch(searchText);
      const foundOrchestras = findOrchestrasBySearch(searchText);

      setRecordings(foundRecordings);
      setOrchestrasSingers(foundOrchestrasSingers);
      setOrchestras(foundOrchestras);
      setAreRecordingsExceedingLimit(areRecordingsExceedingLimit);
    };

    loadFoundResults();

    window.addEventListener('keyup', hideKeyboardOnEnterKeyPress);
    return () => {
      window.removeEventListener('keyup', hideKeyboardOnEnterKeyPress);
    };

  }, [searchText, favouriteRecordings, showAllRecordings]);

  const handleOnClose = () => {
    onClose();
  };

  const handleOnSearchTermChange = (event: any) => {
    setSearchText(event.detail.value!);
    setShowAllRecordings(true);
  };

  const handleOnShowAllRecordings = () => {
    setShowAllRecordings(false);
  };

  const handleOnFoundRecordingClick = (foundRecording: RecordingType) => {
    setSelectedRecording(foundRecording);
    dispatch(addToSearchHistory(foundRecording.title));
  };

  const handleOnFoundOrchestraSingerClick = (orchestraSinger: OrchestraSingerType) => {
    dispatch(addToSearchHistory(orchestraSinger.singer));
    history.push(`orchestras/${orchestraSinger.orchestraId}/${orchestraSinger.singer}`);
    handleOnClose();
  };

  const handleOnFoundOrchestraClick = (orchestra: OrchestraType) => {
    dispatch(addToSearchHistory(orchestra.orchestraName));
    history.push(`orchestras/${orchestra.orchestraId}`);
    handleOnClose();
  };

  const renderSearchHistory = (): JSX.Element => {
    return (
      <IonList>
        <IonListHeader className="dt-search-list-title">Recent searches</IonListHeader>
        {searchHistoryTerms.map((searchTerm: string) => (
          <IonItem
            key={`${searchTerm}`}
            lines="none"
            detail={false}
            href="#"
            onClick={(event: any) => { event.preventDefault(); setSearchText(searchTerm); }}
          >
            <IonLabel className="dt-recording-list-item-label">
              <div className="dt-recording-list-item-first-row">
                <h2 className="dt-search-list-item-title">{searchTerm}</h2>
              </div>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  };

  const renderFoundRecordings = (foundRecordings: RecordingType[]): JSX.Element|void => {
    return (
      <IonList className="dt-search-list">
        <IonListHeader className="dt-search-list-title">Recordings</IonListHeader>
        {foundRecordings.map((foundRecording: RecordingType) => (
          <IonItem
            key={`${foundRecording.id}`}
            lines="none"
            detail={false}
            href="#"
            onClick={(event: any) => { event.preventDefault(); handleOnFoundRecordingClick(foundRecording); }}
          >
            <RecordingIcon recording={foundRecording}></RecordingIcon>
            <IonLabel className="dt-recording-list-item-label">
              <div className="dt-recording-list-item-first-row">
                <h2 className="dt-recording-list-item-recording-title">{foundRecording.title}</h2>
                {foundRecording.isFavourite && <div className="dt-recording-item-icon-favourites"><IonIcon color="primary" icon={heart} /></div>}
              </div>
              <h3 className="dt-recording-list-item-orchestra-name">{showFullNames ? foundRecording.orchestraName : foundRecording.orchestraNameShort} · {showFullNames ? foundRecording.singer : foundRecording.singerShort}</h3>
            </IonLabel>
            <IonLabel className="dt-recording-list-item-year" slot="end">{foundRecording.year}</IonLabel>
          </IonItem>
        ))}
        {(showAllRecordings && areRecordingsExceedingLimit) && (
          <IonItem
            key="show-all"
            lines="none"
            detail={false}
            href="#"
            onClick={ (event: any) => { event.preventDefault(); handleOnShowAllRecordings(); }}
            style={{ textAlign: 'center' }}
          >
            <IonLabel>
              <h2 className="dt-recording-list-show-more">SHOW MORE</h2>
            </IonLabel>
          </IonItem>
        )}
      </IonList>
    );
  };

  const renderFoundOrchestrasSingers = (foundOrchestrasSingers: OrchestraSingerType[]): JSX.Element|void => {
    return (
      <IonList className="dt-search-list">
        <IonListHeader className="dt-search-list-title">Orchestras & Singers</IonListHeader>
        {foundOrchestrasSingers.map((foundOrchestraSinger: OrchestraSingerType) => (
          <IonItem
            key={`${foundOrchestraSinger.orchestraId}${foundOrchestraSinger.singerShort}`}
            lines="none"
            detail={false}
            href="#"
            onClick={(event: any) => { event.preventDefault(); handleOnFoundOrchestraSingerClick(foundOrchestraSinger); }}
          >
            <SingerIcon />
            <IonLabel className="dt-recording-list-item-label">
              <div className="dt-recording-list-item-first-row">
                <h2 className="dt-recording-list-item-recording-title">{showFullNames ? foundOrchestraSinger.orchestraName : foundOrchestraSinger.orchestraNameShort}</h2>
              </div>
              <h3 className="dt-recording-list-item-orchestra-name">{showFullNames ? foundOrchestraSinger.singer : foundOrchestraSinger.singerShort}</h3>
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  };

  const renderFoundOrchestras = (foundOrchestras: OrchestraType[]): JSX.Element|void => {
    return (
      <IonList className="dt-search-list">
        <IonListHeader className="dt-search-list-title">Orchestras</IonListHeader>
        {foundOrchestras.map((foundOrchestra: OrchestraType) => (
          <IonItem
            key={foundOrchestra.orchestraId}
            lines="none"
            detail={false}
            href="#"
            onClick={(event: any) => { event.preventDefault(); handleOnFoundOrchestraClick(foundOrchestra); }}
          >
            <OrchestraIcon orchestra={foundOrchestra} />
            <IonLabel className="dt-orchestra-list-item">
              {showFullNames ? foundOrchestra.orchestraName : foundOrchestra.orchestraNameShort}
            </IonLabel>
          </IonItem>
        ))}
      </IonList>
    );
  };

  const renderSearchResults = (): JSX.Element => {
    if (!searchText || searchText.length < 3) {
      if (searchHistoryTerms.length > 0) {
        return renderSearchHistory();
      }

      return (
        <div className="dt-empty-results-content">
          Try searching for something like<br/>“Di Sarli Rufino Corazon”
        </div>
      );
    }

    if (recordings.length === 0 && orchestrasSingers.length === 0 && orchestras.length === 0) {
      return (
        <>
          <div className="dt-empty-results-content">
            <div className="dt-search-list-title">Nada.</div>
            <div>Try searching for something like “D’Arienzo Trago amargo”.</div>
          </div>
        </>
      );
    }

    return (
      <>
        {recordings.length > 0 && renderFoundRecordings(recordings)}
        {orchestrasSingers.length > 0 && renderFoundOrchestrasSingers(orchestrasSingers)}
        {orchestras.length > 0 && renderFoundOrchestras(orchestras)}
      </>
    );
  };

  return(
    <IonPage id="search-modal">
      <IonHeader className="ion-no-border">
        <IonToolbar>
          <IonButtons slot="end">
            <IonButton color="primary" onClick={handleOnClose}>
              <IonIcon slot="icon-only" icon={close} />
            </IonButton>
          </IonButtons>
          <IonTitle className="dt-toolbar-title">Search</IonTitle>
        </IonToolbar>
          <IonToolbar>
            <IonSearchbar
              placeholder="Song title, singer or orchestra"
              value={searchText}
              onIonChange={handleOnSearchTermChange}
              ref={searchBarRef}
              inputmode="search"
              className="dt-searchbar"
            />
          </IonToolbar>
      </IonHeader>

      <IonContent fullscreen>
        {renderSearchResults()}
      </IonContent>

      <IonModal
        isOpen={selectedRecording !== null}
        onDidDismiss={() => setSelectedRecording(null)}
        initialBreakpoint={0.75}
        breakpoints={[0, 0.75]}
      >
        {selectedRecording &&
          <RecordingModal
            recording={selectedRecording}
            onClose={() => setSelectedRecording(null)}
          />
        }
      </IonModal>
    </IonPage>
  );
};

export default SearchModal;
