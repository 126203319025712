import React from 'react';
import FilterChip from './FilterChip';
import { FilterType } from '../types/FilterType';
import SearchFilterChip from './SearchFilterChip';

interface PropsType {
  searchFilter: FilterType,
  staticFilters: FilterType[],
  dynamicFilters: FilterType[],
  onSearchFilterClick: (filter: FilterType) => void,
  onStaticFilterClick: (filter: FilterType) => void,
  onDynamicFilterClick: (filter: FilterType) => void
}

const ActiveFilters: React.FC<PropsType> = ({ searchFilter, staticFilters, dynamicFilters, onSearchFilterClick, onStaticFilterClick, onDynamicFilterClick }) => {
  return (
    <>
      {searchFilter.isActive &&
        <SearchFilterChip key={searchFilter.key} filter={searchFilter} onClick={() => onSearchFilterClick(searchFilter)}/>
      }
      {staticFilters.map((filter: FilterType, index: number) => (
        filter.isActive ? <FilterChip key={index} filter={filter} onClick={() => onStaticFilterClick(filter)}/> : null
      ))}
      {dynamicFilters.map((filter: FilterType, index: number) => (
        filter.isActive ? <FilterChip key={index} filter={filter} onClick={() => onDynamicFilterClick(filter)}/> : null
      ))}
    </>
  );
};

export default ActiveFilters;
