import { createReducer } from '@reduxjs/toolkit';
import { ListOptionsStateType } from '../types/StateType';
import { SHOW_FULL_NAMES_OFF, SHOW_FULL_NAMES_ON } from '../actions/listOptionsActions';

const initialState: ListOptionsStateType = {
  showFullNames: true,
};

const listOptionsReducer: any = createReducer(initialState, {
  [SHOW_FULL_NAMES_ON]: (state: ListOptionsStateType = initialState) => {
    state.showFullNames = true
  },
  [SHOW_FULL_NAMES_OFF]: (state: ListOptionsStateType = initialState) => {
    state.showFullNames = false
  },
});

export default listOptionsReducer;
