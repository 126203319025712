import { OrchestraType } from '../types/OrchestraType';

const orchestrasData: OrchestraType[] = [
  {
    "orchestraId": "darienzo",
    "orchestraName": "Juan D'Arienzo",
    "orchestraNameShort": "D'Arienzo",
    "orchestraDescription": "Juan D'Arienzo made his first recordings in the years 1928/29 before he became hugely popular from 1935 onwards. From that time he was continiously recording till 1975. The early recordings and the time from 1955 onwards are not yet covered here.",
    "orchestraFooter": "",
    "orchestraImageId": "darienzo"
  },
  {
    "orchestraId": "disarli",
    "orchestraName": "Carlos Di Sarli",
    "orchestraNameShort": "Di Sarli",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "disarli"
  },
  {
    "orchestraId": "troilo",
    "orchestraName": "Anibal Troilo",
    "orchestraNameShort": "Troilo",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "troilo"
  },
  {
    "orchestraId": "pugliese",
    "orchestraName": "Osvaldo Pugliese",
    "orchestraNameShort": "Pugliese",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "pugliese"
  },
  {
    "orchestraId": "biagi",
    "orchestraName": "Rodolfo Biagi",
    "orchestraNameShort": "Biagi",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "biagi"
  },
  {
    "orchestraId": "tanturi",
    "orchestraName": "Ricardo Tanturi",
    "orchestraNameShort": "Tanturi",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "tanturi"
  },
  {
    "orchestraId": "dagostino",
    "orchestraName": "Angel D’Agostino",
    "orchestraNameShort": "D'Agostino",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "dagostino"
  },
  {
    "orchestraId": "calo",
    "orchestraName": "Miguel Caló",
    "orchestraNameShort": "Caló",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "calo"
  },
  {
    "orchestraId": "demare",
    "orchestraName": "Lucio Demare",
    "orchestraNameShort": "Demare",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "demare"
  },
  {
    "orchestraId": "laurenz",
    "orchestraName": "Pedro Laurenz",
    "orchestraNameShort": "Laurenz",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "laurenz"
  },
  {
    "orchestraId": "federico",
    "orchestraName": "Domingo Federico",
    "orchestraNameShort": "Federico",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "federico"
  },
  {
    "orchestraId": "rodriguez",
    "orchestraName": "Enrique Rodriguez",
    "orchestraNameShort": "Rodriguez",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "rodriguez"
  },
  {
    "orchestraId": "deangelis",
    "orchestraName": "Alfredo de Angelis",
    "orchestraNameShort": "De Angelis",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "deangelis"
  },
  {
    "orchestraId": "otv",
    "orchestraName": "Orquesta Típica Victor",
    "orchestraNameShort": "OTV",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "otv"
  },
  {
    "orchestraId": "fresedo",
    "orchestraName": "Osvaldo Fresedo",
    "orchestraNameShort": "Fresedo",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "fresedo"
  },
  {
    "orchestraId": "donato",
    "orchestraName": "Edgardo Donato",
    "orchestraNameShort": "Donato",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "donato"
  },
  {
    "orchestraId": "canaro",
    "orchestraName": "Francisco Canaro",
    "orchestraNameShort": "Canaro",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "canaro"
  },
  {
    "orchestraId": "gobbi",
    "orchestraName": "Alfredo Gobbi",
    "orchestraNameShort": "Gobbi",
    "orchestraDescription": "",
    "orchestraFooter": "",
    "orchestraImageId": "gobbi"
  }
];

export default orchestrasData;
