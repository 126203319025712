import {useEffect, useRef, useState} from "react";

const loadSpotifySDK = () => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = 'https://sdk.scdn.co/spotify-player.js';
        script.async = true;
        script.crossOrigin = "crossOrigin";
        script.onload = () => resolve(true);
        script.onerror = () => reject(new Error('Failed to load Spotify SDK'));
        document.body.appendChild(script);
    });
};
export function useGlobalPlayerInstance (token: string | null) {
    const playerRef = useRef<any>(null)
    const [isReady, setIsReady] = useState(false)
    useEffect(()=> {
        if (!token) {
            return;
        }

        (async function startScript() {
            if (window.Spotify) {
                checkForPlayer();
            } else {
                try {
                    await loadSpotifySDK();
                    checkForPlayer();
                } catch (e) {
                    console.error(e);
                }
            }
        })();

        (window as any).onSpotifyWebPlaybackSDKReady = () => {
            checkForPlayer()

        };

        function checkForPlayer(){
            if (playerRef.current === null) {
                playerRef.current = new window.Spotify.Player({
                    name: 'Web Playback SDK Template',
                    getOAuthToken: (cb: (token: string) => void) => {
                        cb(token ?? '');
                    },
                });
                setIsReady(true);
            }
        }

    }, [token])

    useEffect(() => {
        if (isReady) {
            playerRef.current!.connect();
        }
    }, [isReady]);

    return playerRef.current;
}

