export const SHOW_FULL_NAMES_ON = 'SHOW_FULL_NAMES_ON';
export const SHOW_FULL_NAMES_OFF = 'SHOW_FULL_NAMES_OFF';

export const showFullNamesOn = () => ({
  type: SHOW_FULL_NAMES_ON,
});

export const showFullNamesOff = () => ({
  type: SHOW_FULL_NAMES_OFF,
});
