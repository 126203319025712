import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { DARK_THEME_ON, DARK_THEME_OFF } from '../types/ThemeType';


const ThemeWrapper = (props: any) => {
  const darkTheme = useSelector((state: any) => state.theme.darkTheme);

  const applyDarkThemePreference = (shouldAdd: boolean) => {
    // Add or remove the "dark" class based on if the media query matches
    if (darkTheme === DARK_THEME_ON) {
      document.body.classList.toggle('dark', true);
    } else if (darkTheme === DARK_THEME_OFF) {
      document.body.classList.toggle('dark', false);
    } else {
      document.body.classList.toggle('dark', shouldAdd);
    }
  }

  useEffect(() => {
    // Use matchMedia to check the user preference
    const prefersDark: MediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
    applyDarkThemePreference(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) => applyDarkThemePreference(mediaQuery.matches));
  }, [darkTheme]);

  return (
    <>{props.children}</>
  );
};

export default ThemeWrapper;
