import { RecordingType } from '../types/RecordingType';

export const ADD_RECORDING_TO_FAVOURITES = 'ADD_RECORDING_TO_FAVOURITES';
export const REMOVE_RECORDING_FROM_FAVOURITES = 'REMOVE_RECORDING_FROM_FAVOURITES';

export const addRecordingToFavourites = (recording: RecordingType) => ({
  type: ADD_RECORDING_TO_FAVOURITES,
  payload: recording.id,
});

export const removeRecordingFromFavourites = (recording: RecordingType) => ({
  type: REMOVE_RECORDING_FROM_FAVOURITES,
  payload: recording.id,
});
