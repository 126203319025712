import React, { useEffect, useState } from 'react';
import { useAuth } from "../contexts/authContext";
import { IonButton } from '@ionic/react';

const clientId = process.env["REACT_APP_SPOTIFY_CLIENT_ID"];
const redirectUrl = process.env["REACT_APP_SPOTIFY_REDIRECT_URL"];

if (!clientId || !redirectUrl) {
  throw new Error('Missing or invalid environment variables: REACT_APP_SPOTIFY_CLIENT_ID or REACT_APP_SPOTIFY_REDIRECT_URL');
}

interface LoginWithSpotifyProps {
  onSuccess: (code: string, codeVerifier: string) => void;
  onFailure: (error: Error) => void;
  scope?: string;
}

const LoginWithSpotify = ({
  onSuccess,
  onFailure,
  scope,
}: LoginWithSpotifyProps) => {
  const { authenticateUser } = useAuth();
  const [refreshToken, setRefreshToken] = useState<string>('');

  const handleLogin = (): void => {
    const codeVerifier = generateRandomString(128);
    localStorage.setItem('code_verifier', codeVerifier);

    if (clientId && redirectUrl) {
      generateCodeChallenge(codeVerifier)
        .then((codeChallenge) => {
          const state = generateRandomString(16);
          const params = new URLSearchParams();
          params.append('response_type', 'code');
          params.append('client_id', clientId);
          params.append('redirect_uri', redirectUrl);
          params.append('code_challenge_method', 'S256');
          params.append('code_challenge', codeChallenge);
          params.append('state', state);
          if (scope) {
            params.append('scope', scope);
          }
          const authorizationUrl = `https://accounts.spotify.com/authorize?${params.toString()}`;
          window.location.href = authorizationUrl;
        })
        .catch((error) => {
          onFailure(error);
        });
    }
  };

  useEffect(() => {
    (async function () {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

      // Check if a refresh token is stored in localStorage
      const storedRefreshToken = localStorage.getItem('refresh_token');
      if (storedRefreshToken) {
        setRefreshToken(storedRefreshToken);
      }

      if (code && state && clientId && redirectUrl) {
        const codeVerifier = localStorage.getItem('code_verifier') || '';
        onSuccess(code, codeVerifier);
        localStorage.removeItem('code_verifier');
        window.history.replaceState({}, document.title, window.location.pathname);

        const body = new URLSearchParams({
          grant_type: 'authorization_code',
          code: code || '',
          redirect_uri: redirectUrl,
          client_id: clientId,
          code_verifier: codeVerifier,
        });
        try {
          const response = await fetch('https://accounts.spotify.com/api/token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: body.toString(),
          });

          if (!response.ok) {
            throw new Error('HTTP status ' + response.status);
          }

          const data = await response.json();

          const accessToken = data.access_token;
          const newRefreshToken = data.refresh_token; // Retrieve the refresh token from the response
          localStorage.setItem('access_token', accessToken);
          localStorage.setItem('refresh_token', newRefreshToken); // Store the refresh token
          setRefreshToken(newRefreshToken); // Update the refresh token state
          authenticateUser(accessToken);
          await getProfile(accessToken);
        } catch (e) {
          console.log('error:', e);
        }
      }
    })();
  }, [onSuccess]);

  const getProfile = async (accessToken: string): Promise<void> => {
    const response = await fetch('https://api.spotify.com/v1/me', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });

    const data = await response.json();
    console.log('Profile:', data);
    const { display_name, email, id } = data;
    console.log('User display name:', display_name);
    console.log('User email:', email);
    console.log('User ID:', id);
  };

  return <IonButton onClick={handleLogin}>Login with Spotify</IonButton>;
};

export default LoginWithSpotify;

const generateRandomString = (length: number): string => {
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let text = '';

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};

const generateCodeChallenge = async (codeVerifier: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(codeVerifier);
  const digestBuffer = await window.crypto.subtle.digest('SHA-256', data);
  const digestArray = Array.from(new Uint8Array(digestBuffer));
  const base64String = btoa(String.fromCharCode(...digestArray))
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=+$/, '');

  return base64String;
};
