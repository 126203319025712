import { OrchestraType } from '../types/OrchestraType';
import stringNormalizer from '../util/stringNormalizer';
import orchestrasData from '../data/orchestrasData';

const useOrchestrasDatabase = () => {
  const findOrchestrasBySearch = (searchTerm: string): OrchestraType[] => {
    const normalizedSearchTerm = stringNormalizer.normalizeString(searchTerm);
    const pattern = "^(?=.*\\b" + normalizedSearchTerm.split(/\s+/).join("\\b)(?=.*\\b") + ").*$";
    const regex = RegExp(pattern, "i");

    const results = [];
    for (let i = 0; i < orchestrasData.length; i++) {
      const searchField = stringNormalizer.normalizeString(orchestrasData[i].orchestraName);
      if (regex.test(searchField)) {
        results.push(orchestrasData[i]);
      }
    }

    return results;
  }

  const findOrchestraById = (orchestraId: string): Promise<OrchestraType|null> => {
    return new Promise((resolve) => {
      for (let i = 0; i < orchestrasData.length; i++) {
        if (orchestrasData[i].orchestraId === orchestraId) {
          resolve(orchestrasData[i]);
        }
      }

      resolve(null);
    });
  };

  return { findOrchestrasBySearch, findOrchestraById };
};

export default useOrchestrasDatabase;
