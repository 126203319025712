import React from 'react';
import { IonChip, IonIcon, IonLabel } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import { FilterType } from '../types/FilterType';

const SearchFilterChip: React.FC<{ filter: FilterType, onClick: (filter: FilterType) => void }> = ({ filter, onClick }) => {
  return (
    <IonChip
      color={filter.isActive ? 'primary' : 'secondary'}
      onClick={() => onClick(filter)}
    >
      <IonLabel>"{filter.value}"</IonLabel>
      {filter.isActive && <IonIcon icon={closeCircle} />}
    </IonChip>
  );
};

export default SearchFilterChip;
