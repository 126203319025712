import { useIonToast } from '@ionic/react';

const useToast = () => {
  const [present, dismiss] = useIonToast();

  const presentToast = async (message: string) => {
    await dismiss();
    await present({
      message: message,
      duration: 1500,
      position: 'bottom',
    });
  };

  return { presentToast }
};

export default useToast;
