import React from 'react';
import { IonIcon, IonItem, IonItemOption, IonItemOptions, IonItemSliding, IonLabel, useIonToast } from '@ionic/react';
import { RecordingType } from '../types/RecordingType';
import { addRecordingToFavourites, removeRecordingFromFavourites } from '../actions/stateActions';
import { useDispatch, useSelector } from 'react-redux';
import { heart, heartOutline } from 'ionicons/icons';
import './RecordingListItem.scss';
import RecordingIcon from './RecordingIcon';
import useToast from '../hooks/useToast';

const RecordingListItem: React.FC<{recording: RecordingType, onRecordingClick: (recording: RecordingType) => void, hideOrchestra?: boolean}> = ({ recording, onRecordingClick, hideOrchestra = false }) => {
  const dispatch = useDispatch();
  const showFullNames = useSelector((state: any) => state.listOptions.showFullNames);
  const { presentToast } = useToast();

  const itemSlidingRef = React.createRef<HTMLIonItemSlidingElement>();

  const handleOnFavouriteClick = () => {
    if (recording.isFavourite) {
      dispatch(removeRecordingFromFavourites(recording));
      presentToast('Removed from your Favourites');
    } else {
      dispatch(addRecordingToFavourites(recording));
      presentToast('Added to your Favourites');
    }
    itemSlidingRef.current?.close();
  };

  return (
    <IonItemSliding ref={itemSlidingRef}>
      <IonItem
          lines="none"
          detail={false}
          href="#"
          onClick={(event: any) => { event.preventDefault(); onRecordingClick(recording); }}
      >
        <RecordingIcon recording={recording}></RecordingIcon>
        <IonLabel className="dt-recording-list-item-label">
          <div className="dt-recording-list-item-first-row">
            <h2 className="dt-recording-list-item-recording-title">{recording.title}</h2>
            {recording.isFavourite && <div className="dt-recording-item-icon-favourites"><IonIcon color="primary" icon={heart} /></div>}
          </div>
          <h3 className="dt-recording-list-item-orchestra-name">
            {hideOrchestra ? '' : `${showFullNames ? recording.orchestraName : recording.orchestraNameShort} · `}{showFullNames ? recording.singer : recording.singerShort}
          </h3>
        </IonLabel>
        <IonLabel slot="end">
          <IonLabel className="dt-recording-list-item-year" slot="end">{recording.year}</IonLabel>
        </IonLabel>
      </IonItem>
      <IonItemOptions side="end">
        <IonItemOption expandable onClick={handleOnFavouriteClick}>
          <IonIcon slot='icon-only' icon={recording.isFavourite ? heart : heartOutline} />
        </IonItemOption>
      </IonItemOptions>
    </IonItemSliding>
  );
};

export default RecordingListItem;
