import { RecordingGroupType } from '../types/RecordingGroupType';
import recordingGroupsData from '../data/recordingGroupsData';

const useRecordingGroupsDatabase = () => {
  const findRecordingGroupById = (recordingGroupId: string): RecordingGroupType|null => {
    for (let i = 0; i < recordingGroupsData.length; i++) {
      if (recordingGroupsData[i].groupId === recordingGroupId) {
        return recordingGroupsData[i];
      }
    }

    return null;
  };

  return { findRecordingGroupById };
};

export default useRecordingGroupsDatabase;
