import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react'

interface AuthContextProps {
    token: string | null;
    hasUser: boolean;
    authenticateUser: (token: string) => void;
    logout: ()=> void;
}
const authContext = createContext({} as AuthContextProps)
export const AuthContextProvider = ({children}: {children: ReactNode}) => {
    const [token, setToken] = useState<string | null>(null)
   const authenticateUser = (token: string) => {
        setToken(token)
   }

   const logout = ()=> {
        localStorage.removeItem('access_token');
        setToken(null)
   }

   useEffect(()=> {
      setToken(localStorage.getItem('access_token'))
   }, [])

  return (
   <authContext.Provider value={{hasUser: !!token, token, authenticateUser, logout}}>
       {children}
   </authContext.Provider>
  )
}

export const useAuth = ()=>{
    const context = useContext(authContext)
    if (!context) {
        throw new Error('no context')
    }
    return context
}
