import axios from 'axios';
import { IonButton } from '@ionic/react';
import { useAuth } from "../contexts/authContext";
import {useSpotifyPlayer} from "../contexts/playerContext";

interface SpotifyPlayerProps {
  trackId: string;
}

const SpotifyPlayer = ({ trackId }:SpotifyPlayerProps) => {
  const { token, logout } = useAuth(); // Access the token and logout function from the auth context
  const { player, isPaused, deviceId } = useSpotifyPlayer()

  const playTrack = async (trackId: string) => {
    if (!deviceId) {
      alert('No device ID')
      return;
    }
    try {
      await axios.put(
        `https://api.spotify.com/v1/me/player/play?device_id=${deviceId}`,
        {
          uris: [`spotify:track:${trackId}`],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (player) {
        await player.togglePlay();
      }

    } catch (error: any) {
      console.error("Failed to play track", error);
      if (error?.response?.status === 401) {
        // If the access token expired, call the logout function to clear the token
        logout();
      }
    }
  };

  if (!player || !player.isLoaded) {
    return null
  }

  const isReady = deviceId;
  const isPauseButtonDisabled = !isReady || isPaused;
  const isPlayButtonDisabled = !isReady || !isPaused;
  const isPlayTrackButtonDisabled = !isReady;

  const togglePlay =()=> {
    player.togglePlay()
  }

  return (
    <div>
      <IonButton onClick={togglePlay} disabled={isPauseButtonDisabled}>
        Pause
      </IonButton>
      <IonButton onClick={togglePlay} disabled={isPlayButtonDisabled}>
        Play
      </IonButton>
      <IonButton onClick={() => playTrack(trackId)} disabled={isPlayTrackButtonDisabled}>
        Play Track
      </IonButton>
    </div>
  );
};

export default SpotifyPlayer;
