import { OrchestraSingerType } from '../types/OrchestraSingerType';
import stringNormalizer from '../util/stringNormalizer';
import orchestrasSingersData from '../data/orchestrasSingersData';

const useOrchestrasSingersDatabase = () => {
  const findOrchestrasSingersBySearch = (searchTerm: string): OrchestraSingerType[] => {
    const normalizedSearchTerm = stringNormalizer.normalizeString(searchTerm);
    const pattern = "^(?=.*\\b" + normalizedSearchTerm.split(/\s+/).join("\\b)(?=.*\\b") + ").*$";
    const regex = RegExp(pattern, "i");

    const results = [];
    for (let i = 0; i < orchestrasSingersData.length; i++) {
      const searchField = stringNormalizer.normalizeString(orchestrasSingersData[i].singer);
      if (regex.test(searchField)) {
        results.push(orchestrasSingersData[i]);
      }
    }

    return results;
  };

  return { findOrchestrasSingersBySearch };
};

export default useOrchestrasSingersDatabase;
