import React from 'react';
import { IonImg } from '@ionic/react';
import { OrchestraType } from '../types/OrchestraType';
import './OrchestraIcon.scss';

const OrchestraIcon: React.FC<{orchestra: OrchestraType}> = ({ orchestra }) => {
  // Expected image file name format: img_{orchestraImageId}_{"large"|"small"}_{"light"|"dark"}.png

  return (
      <IonImg
        src={`assets/img/illustrations/img_${orchestra.orchestraImageId}_small_light@2x.png`} slot="start"
        onIonError={(e) => {e.target.src = 'assets/icon/ic_orchestra.svg'}}
        className="orchestra-icon-image"
      />

  );
};

export default OrchestraIcon;
