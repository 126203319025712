import React from 'react';
import { IonIcon } from '@ionic/react';

const SingerIcon: React.FC = () => {
  return (
    <IonIcon src={'assets/icon/ic_singer.svg'} slot="start" />
  );
};

export default SingerIcon;
