import { RecordingGroupType } from '../types/RecordingGroupType';

const recordingGroupsData: RecordingGroupType[] = [
  {
    "groupId": "darienzo3539",
    "groupTitle": "The first orchestra 1935-39",
    "groupDescription": "Recordings that kicked off new tango wave as we know it today..."
  },
  {
    "groupId": "darienzo4044",
    "groupTitle": "The second orchestra with Mauré 1940-44",
    "groupDescription": ""
  },
  {
    "groupId": "darienzo4449",
    "groupTitle": "The return of Alberto Echagüe and Armando Laborde 1945-49",
    "groupDescription": ""
  },
  {
    "groupId": "darienzo5054",
    "groupTitle": "Change in the sound",
    "groupDescription": "With singers Alberto Echagüe and Armando Laborde continuing until 1954."
  },
  {
    "groupId": "disarli3948",
    "groupTitle": "Recordings on RCA-Victor 1939-48",
    "groupDescription": ""
  },
  {
    "groupId": "troilo38",
    "groupTitle": "Recordings on Odeon",
    "groupDescription": "Just the two recordings. There was a dispute and Troilo didn't record unti he signed with a new publisher."
  },
  {
    "groupId": "troilo4149",
    "groupTitle": "Recordings on RCA-Victor 1941-49",
    "groupDescription": ""
  },
  {
    "groupId": "pugliese4360",
    "groupTitle": "Recordings on Odeon 1943-60",
    "groupDescription": ""
  },
  {
    "groupId": "biagi3848",
    "groupTitle": "Golden Age recordings",
    "groupDescription": ""
  },
  {
    "groupId": "biagi50s60s",
    "groupTitle": "Recordings from 1950 onwards",
    "groupDescription": ""
  },
  {
    "groupId": "tanturi3643",
    "groupTitle": "With Castillo",
    "groupDescription": ""
  },
  {
    "groupId": "tanturi4346",
    "groupTitle": "With Campos",
    "groupDescription": ""
  },
  {
    "groupId": "tanturi4650",
    "groupTitle": "With Videla and Ribó",
    "groupDescription": ""
  },
  {
    "groupId": "dagostino4046",
    "groupTitle": "Recordings with Angel Vargas 1940-46",
    "groupDescription": ""
  }
];

export default recordingGroupsData;
