import { Action, Dispatch, ThunkAction } from '@reduxjs/toolkit';
import { Database } from '@ionic/storage';
import localStorage from '../storage/localStorage';

export const SET_STATE_FROM_LOCAL_STORAGE = 'SET_STATE_FROM_LOCAL_STORAGE';

const setStateFromLocalStorage = (stateFromLocalStorage: any) => ({
  type: SET_STATE_FROM_LOCAL_STORAGE,
  state: stateFromLocalStorage,
});

export const loadStateFromLocalStorage = (onLoadSuccess: () => void): ThunkAction<void, any, unknown, Action<string>> => {
  return async (dispatch: Dispatch) => {
    const localStorageDatabase: Database = await localStorage;
    const savedStateSerialized = await localStorageDatabase.get('state');

    let state = {};

    if (savedStateSerialized !== null && savedStateSerialized !== undefined) {
      state = JSON.parse(savedStateSerialized);
    }

    dispatch(setStateFromLocalStorage(state));
    onLoadSuccess();
  };
}
