import { FilterType } from '../types/FilterType';
import { RecordingType } from '../types/RecordingType';
import recordingsData from '../data/recordingsData';
import stringNormalizer from '../util/stringNormalizer';

const useRecordingsDatabase = () => {
  const findRecordingsBySearch = (searchTerm: string, favouriteRecordings: any[], maxResults: number): RecordingType[] => {
    const normalizedSearchTerm = stringNormalizer.normalizeString(searchTerm);
    const pattern = "^(?=.*\\b" + normalizedSearchTerm.split(/\s+/).join("\\b)(?=.*\\b") + ").*$";
    const regex = RegExp(pattern, "i");

    let results: RecordingType[] = [];
    for (let i = 0; i < recordingsData.length; i++) {
      const searchField = stringNormalizer.normalizeString(recordingsData[i].search);
      if (regex.test(searchField)) {
        results.push(recordingsData[i]);

        if (results.length >= maxResults) {
          break;
        }
      }
    }

    results = results.map((recording: RecordingType) => {
      recording.isFavourite = favouriteRecordings.includes(recording.id);

      return recording;
    });

    results.sort((resultA: RecordingType, resultB: RecordingType) => {
      if (resultB.date > resultA.date) {
        return -1;
      } else if (resultB.date > resultA.date) {
        return 1;
      } else {
        return 0;
      }
    });

    return results;
  };

  const findRecordingsByOrchestraIdAndFilters = (orchestraId: string, filters: FilterType[], favouriteRecordings: any[]): Promise<RecordingType[]> => {
    return new Promise((resolve) => {
      const results: RecordingType[] = [];
      recordingsLoop: for (let i = 0; i < recordingsData.length; i++) {
        let recording: RecordingType = recordingsData[i];
        if (recording.orchestraId !== orchestraId) {
          continue;
        }

        recording.isFavourite = favouriteRecordings.includes(recording.id);

        for (let j = 0; j < filters.length; j++) {

          // Use regex search by pattern (and not by exact string comparison below):
          const normalizedFilterValue = stringNormalizer.normalizeString(filters[j].value);
          const pattern = "^(?=.*\\b" + normalizedFilterValue.split(/\s+/).join("\\b)(?=.*\\b") + ").*$";
          const regex = RegExp(pattern, "i");

          //@ts-ignore
          let recordingDataValue = recording[filters[j].key];
          if (typeof recordingDataValue === 'string') {
            recordingDataValue = stringNormalizer.normalizeString(recordingDataValue);
          }

          if (!regex.test(recordingDataValue)) {
            continue recordingsLoop;
          }

          // Don't search by direct string comparison
          // //@ts-ignore
          // if (recording[filters[j].key] !== filters[j].value) {
          //   continue recordingsLoop;
          // }
        }

        results.push(recording);
      }

      resolve(results);

      // const searchParams: SearchParamsType = {};
      // filters.forEach((filter: any) => {
      //   searchParams[filter.key] = `=^"${filter.value}"$`;
      // });
      // searchParams['orchestraId'] = `=^"${orchestraId}"$`;
      //
      // setTimeout(() => {
      //   resolve(recordingsDatabase.search(searchParams).map((result) => result.item));
      // }, 0);
    });
  };

  return { findRecordingsBySearch, findRecordingsByOrchestraIdAndFilters };
};

export default useRecordingsDatabase;
