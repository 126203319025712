import React from 'react';
import { Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Provider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';
import OrchestrasPage from './pages/OrchestrasPage';
import OrchestrasOrTutorial from './components/OrchestrasOrTutorial';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import LocalStorageDataLoader from './components/LocalStorageDataLoader';
import OrchestraPage from './pages/OrchestraPage';
import ThemeWrapper from './components/ThemeWrapper';
import TutorialPage from './pages/TutorialPage';
import persistStateToLocalStorageMiddleware from './middleware/persistStateToLocalStorageMiddleware';
import NotFoundPage from './pages/NotFoundPage';
import FeedbackPage from './pages/FeedbackPage';
import AboutPage from './pages/AboutPage';

import './App.scss';
import {AuthContextProvider} from "./contexts/authContext";
import {PlayerContextProvider} from "./contexts/playerContext";

setupIonicReact({
  mode: 'ios',  // todo: remove
});

const store = configureStore({
  // @ts-ignore
    reducer: rootReducer,
  // @ts-ignore
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(persistStateToLocalStorageMiddleware),
});

const App: React.FC = () => (
  <Provider store={store}>
    <AuthContextProvider>
            <LocalStorageDataLoader>
                <PlayerContextProvider>
                    <ThemeWrapper>
                      <IonApp>
                        <IonReactRouter>
                          <IonRouterOutlet>
                            <Route exact path="/orchestras">
                              <OrchestrasPage />
                            </Route>
                            <Route exact path="/orchestras/:orchestraId">
                              <OrchestraPage />
                            </Route>
                            <Route exact path="/orchestras/:orchestraId/:singer">
                              <OrchestraPage />
                            </Route>
                            <Route exact path="/about">
                              <AboutPage />
                            </Route>
                            <Route exact path="/feedback">
                              <FeedbackPage />
                            </Route>
                            <Route exact path="/tutorial">
                              <TutorialPage />
                            </Route>
                            <Route exact path="/404">
                              <NotFoundPage />
                            </Route>
                            <Route exact path="/">
                              <OrchestrasOrTutorial />
                            </Route>
                          </IonRouterOutlet>
                        </IonReactRouter>
                      </IonApp>
                    </ThemeWrapper>
                </PlayerContextProvider>
            </LocalStorageDataLoader>
    </AuthContextProvider>
  </Provider>
);

export default App;
